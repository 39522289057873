import React, { useState } from 'react';
import { Animated } from 'react-animated-css';
import { Modal, Spinner } from 'react-bootstrap';
import Helmet from 'react-helmet';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { resetPasswordApi } from '../../../services/auth';
import ErrorBoundary from '../../ErrorBoundary/ErrorBoundary';
export const ResetPassword = ({
    patientIdReset, hideModal, onSuccess
}: any) => {
    const [isLoading, setIsLoading] = useState(false);
    const { register, setError, handleSubmit, setValue, getValues, errors, trigger } = useForm();
    const history = useHistory();
    const location: any = useLocation();
    const match: any = useRouteMatch();
    const resetPasswordClick = (data: any) => {
        if (patientIdReset) {
            if (getValues('newpassword').trim() !== getValues('cnewpassword').trim()) {
                setError('cnewpassword', { type: 'custom', message: 'Both password should match.' });
                return;
            }
            setIsLoading(true);
            resetPasswordApi({
                patientuniqueId: patientIdReset,
                newpwd: getValues('newpassword').trim(),
                modelType: "resetpwd"
            }).then((success) => {
                setIsLoading(false);
                if (success && success.status && success.status.error === false) {
                    toast.success('Reset password success');
                    onSuccess();
                } else {
                    toast.error(success.status.msg);
                }
            }).catch((err) => {
                setIsLoading(false);
                toast.error(err.msg);
            });
        } else {
            toast.error("Invalid request");
        }
    }
    return (
        <ErrorBoundary>
            <Modal className="master_popup public_popup regPopup" tabIndex={1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" show={true} onHide={hideModal} >
                <Modal.Header closeButton>
                    <Modal.Title>Reset Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="">
                        <div className="mb-3">
                            <label className="form-label fw-medium">New password</label>
                            <input type="password" disabled={isLoading} className="form-control" name={'newpassword'} ref={register({
                                required: true,
                                pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
                            })} />
                            {
                                (errors && errors.newpassword && errors.newpassword.type === 'required') &&
                                <div className="text-danger error">New password is required.</div>
                            }
                            {
                                (errors && errors.newpassword && errors.newpassword.type === 'pattern') &&
                                <div className="text-danger error">Password must contain 8 characters with at least 1 upper case, 1 lower case, 1 special and 1 numeric character.</div>
                            }
                            {
                                (errors && errors.newpassword && errors.newpassword.type === 'custom') &&
                                <div className="text-danger error">{errors.newpassword.message}</div>
                            }
                        </div>
                        <div className="mb-3">
                            <label className="form-label fw-medium">Confirm new password</label>
                            <input type="password" disabled={isLoading} className="form-control" name={'cnewpassword'} ref={register({
                                required: true,
                                pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
                            })} />
                            {
                                (errors && errors.cnewpassword && errors.cnewpassword.type === 'required') &&
                                <div className="text-danger error">Confirm new password is required.</div>
                            }
                            {
                                (errors && errors.cnewpassword && errors.cnewpassword.type === 'pattern') &&
                                <div className="text-danger error">Password must contain 8 characters with at least 1 upper case, 1 lower case, 1 special and 1 numeric character.</div>
                            }
                            {
                                (errors && errors.cnewpassword && errors.cnewpassword.type === 'custom') &&
                                <div className="text-danger error">{errors.cnewpassword.message}</div>
                            }
                        </div>
                        <button type="button" className="btn btn-dark w-100" onClick={handleSubmit(resetPasswordClick)}>Reset password
                            <span className="aft_load right">
                                <>
                                    {
                                        isLoading === true &&
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    }
                                </>
                            </span>
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </ErrorBoundary>
    )
}
import React,{useState, useEffect} from 'react';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';
import { getLanguageList} from '../../../../services/landingPageSearch';
import { toast } from 'react-toastify';
import { IAppState } from '../../../../store';
import { addFilterItems, addLanguage } from '../../../../store/filters/actions';
import * as _ from 'lodash';
import { connect,useSelector,useDispatch } from 'react-redux';
import { searchFilter } from '../../../../store/v2/searchFilter/actions';
import { patientblocksearch, appointmentSlot } from '../../../../services/landingPageSearch';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../../store/loadingIndicator/actions';
import { clinicAndProviderList, existingClinicProvider } from '../../../../services/landingPageSearch';
import { addClinicProviderList } from '../../../../store/clinicAndProviderList/actions';
import moment from 'moment';
import { addSlotData } from '../../../../store/slot/actions';
import { specialityWiseClinicList , fetchClinicList} from '../../services/AppointmentBook';
function MoreFilterModal(props:any) {
    const [gender,setGender]:any=useState([]);
    const [languages,setLanguages]=useState([]);
    const [selectedLanguage,setSelectedLanguage]=useState([]);
    const [distance,setDistance]=useState('');
    const [selectedClinic,setSelectedClinic]:any=useState('');
    const [selectedProvider,setSelectedProvider]=useState('');
    const [clinicWiseProviderList,setClinicWiseProviderList]=useState([]);
    const clinicProviderList: any = useSelector((state: IAppState | any) => state.clinicProviderList.clinicProviderList);
    const dispatch = useDispatch();
    const searchFilterData = useSelector((state: IAppState) => state.searchFilter.searchFilterData);
    const [apiClinicProviderRequest, setApiClinicProviderRequest]: any = useState();
    const [apiAppointmentSlotRequest, setAppointmentSlotRequest]: any = useState();
    const [totalCount, setTotalCount] = useState(0);
    const [clinicList, setClinicList] = useState([]);
    const [apiClinicListRequest, setApiClinicListRequest]: any = useState();
    useEffect(() => {
        getClinicList();
    }, []);
    
    const getClinicList = () => {
        if (searchFilterData.patientUniqueId || searchFilterData.newSpeciality) {
            let latitude: any = '', longitude: any = '';
            if (searchFilterData.location) {
                latitude = searchFilterData.location.latitude;
                longitude = searchFilterData.location.longitude;
            }
            let data = {
                "patientUniqueId": searchFilterData.patientUniqueId,
                "speciality": searchFilterData.newSpeciality,
                "latitude": latitude,
                "longitude":longitude
            };
            dispatch(fetchLoadingPending());
            specialityWiseClinicList(data).then((success) => {
                dispatch(fetchLoadingSuccess(false));
                if (success && success.status && success.status.error === false) {
                    let data = success.clinics.map((item: any) => {
                        return {
                            value: item.id,
                            label: item.name,
                            id: item.id,
                            name: item.name,
                            uniqueId: item.uniqueId,
                            doctors: item.doctors
                        }
                    });
                    setClinicList(data);
                    if (success.clinics && success.clinics.length > 0) {
                        if (searchFilterData.clinicId) {
                            let providers: any = [];
                            providers = data.find((item: any) => item.id === searchFilterData.clinicId);
                            setSelectedClinic(providers);
                            setClinicWiseProviderList(providers.doctors);
                            if (searchFilterData.providerId) {
                                let provider: any = [];
                                provider = providers.doctors.find((item: any) => item.id === searchFilterData.providerId);
                                setSelectedProvider(provider.id);
                            }
                        }
                    }
                }
            }).catch((err) => {
                dispatch(fetchLoadingSuccess(false));
                console.log(err);
            })
        }
    }

    useEffect(()=>{
        setGender(searchFilterData.gender);
        setDistance(searchFilterData.distance);
        setSelectedLanguage(searchFilterData.languages);
        getLanguageListApi();
    },[searchFilterData]);

    const selectGender = (value: any) => {
        if (gender) {
            let findIndex = gender.findIndex((item: any) => {
            return (item === value)
        })
        let gen:any = gender;
            if (findIndex === -1) {
                setGender([...gender, value]);
            } else {
                gen = gender.filter((item: any) => item !== value)
                setGender(gen);
            }
        } else {
            setGender([...gender, value]);
        }
    }

    const getLanguageListApi = () => {
        getLanguageList().then((success: any) => {
            try {
                if (success && success.response && success.response.data && success.response.status === 200) {
                    if (success.response.data && success.response.data.status.error === false) {
                        let data= success.response.data.languages.map((item: any) => {
                            return {
                                value: item.id,
                                label: item.name
                            }
                        })
                        setLanguages(data);
                    } else {
                        if (success.response.data && success.response.data.status.error === true) {
                            toast.error(success.response.data.status.msg);
                        }
                    }
                }
            } catch (error) {
                console.log('error', error);
            }
        });
    }
    const selectLanguage=(id:any)=>{
        setSelectedLanguage(id);
    }
    const selectCinic = (id: any) => {
        console.log('id', id);
        setSelectedClinic(id);
        setClinicWiseProviderList(id.doctors);
    }
    const handleDistance=(distance:any)=>{
        setDistance(distance);
    }

    const handleClinicSelect=(e:any)=>{
        setSelectedClinic(e.target.value);
        if(e.target.value !== ""){
            let providers:any=[];
            providers = clinicList.find((item: any) => item.id === e.target.value);
            setClinicWiseProviderList(providers.doctors);
            setSelectedProvider('');
        }
    }

    const handleProviderSelect=(e:any)=>{
        setSelectedProvider(e.target.value);
    }

    const filterApply = () => {
        dispatch(searchFilter({
            ...searchFilterData,
            gender: gender,
            distance: distance,
            languages: selectedLanguage,
            clinicId: selectedClinic.id,
            providerId: selectedProvider,
        }));
        if (props.selectedTab === "provider") {
            getClinicProviderList();
        } else {
            fetchClinicListApi();
        }
        props.onHide();
    }

    const fetchClinicListApi = () => {
        const { oldSpeciality, newSpeciality, location, date, howToPay, gender, patientUniqueId, clinicPage, results } = searchFilterData;
        let latitude: any = "", longitude: any = "";
        if (location !== "") {
            latitude=location.latitude;
            longitude=location.longitude;
        }
        let langIds: any = [];
        if (languages && languages.length > 0) {
            langIds = languages.map((item: any) => {
                return item.value
            });
        }
        let request = {
                location: {
                    latitude: latitude,
                    longitude: longitude,
                    specialityId:newSpeciality,
                    searchdate: date === null ?'':date,
                    radiuskm: distance,
                    insuranceProviderId: howToPay.type === 'insurance' ? howToPay.value : '',
                    acceptAssignment: howToPay.type === 'cash' ? 'no' : '',
                    socialAssistTypeID: howToPay.type === 'socialAssistance' ? howToPay.value : '',
                    clinicId: selectedClinic.id,
                    patientUniqueId: patientUniqueId,
                    page: 1,
                    results: 25
                }
        }
        let isApiCall: boolean = false;
        if ( JSON.stringify(apiClinicListRequest) !== JSON.stringify(request)) {
            setApiClinicListRequest(request);
            isApiCall = true;
        }
        if (isApiCall) {
            dispatch(fetchLoadingPending());
            fetchClinicList(request).then((success) => {
                dispatch(fetchLoadingSuccess(false));
                if (success && success.status && success.status.error === false) {
                    console.log('successs', success);
                    // setClinicList(success.clinic);
                    if (success && success.total) {
                        setTotalCount(success.total);
                        // setEnd((25) > success.total ?success.total:(25))
                    }
                    dispatch(addClinicProviderList({
                        ...clinicProviderList,
                        clinicList: _.uniqBy(success.clinic, 'id'),
                        clinicTotalCount: success.total ? success.total : totalCount
                    }));
                }
            }).catch((err) => {
                dispatch(fetchLoadingSuccess(false));
                dispatch(addClinicProviderList({
                    ...clinicProviderList,
                    clinicList: [],
                    clinicTotalCount: 0,
                }));
                dispatch(searchFilter({
                    ...searchFilterData,
                    clinicPage: 1
                }));
                console.log(err);
            })
        }
    }

    const getClinicProviderList=()=>{
        const { newSpeciality, location, date, howToPay, patientUniqueId, page, results } = searchFilterData;
        let latitude: any = "", longitude: any = "", langIds: any = [], clinic: any = '';
        if(location !== ""){
            latitude=location.latitude;
            longitude=location.longitude;
        }
        if(selectedLanguage && selectedLanguage.length > 0){
            langIds = selectedLanguage.map((item: any) => {
                return item.value
            });
        }
        if (selectedClinic && clinicProviderList && clinicProviderList.cliniclist && clinicProviderList.cliniclist.length > 0) {
            clinic = clinicProviderList.cliniclist.find((item: any) => item.uniqueId === selectedClinic);
        }
        let request={
            location:{
                latitude: latitude,
                longitude: longitude,
                specialityId: newSpeciality,
                searchdate:date !== null ? date : "",
                radiuskm: distance,
                gender:gender ? gender.toString():'',
                languages: langIds.toString(),
                insuranceProviderId: howToPay.type === 'insurance' ? howToPay.value : '',
                acceptAssignment: howToPay.type === 'cash' ? 'no' : '',
                socialAssistTypeID: howToPay.type === 'socialAssistance' ? howToPay.value : '',
                clinicId: selectedClinic.id,
                providerId:selectedProvider,
                patientUniqueId: patientUniqueId,
                page: 1,
                results:results
            }
        }
        let isApiCall: boolean=false;
        if ( JSON.stringify(apiClinicProviderRequest) !== JSON.stringify(request)) {
            setApiClinicProviderRequest(request);
            isApiCall = true;
        }
        if (isApiCall) {
            dispatch(fetchLoadingPending());
            try {
                clinicAndProviderList(request).then((success: any) => {
                    if (success && success.response && success.response.data && success.response.status === 200) {
                        if (success.response.data && success.response.data.status.error === false) {
                            setTimeout(() => {
                                dispatch(fetchLoadingSuccess(false));
                            }, 2000);
                            if (success.response.data && success.response.data.total) {
                                setTotalCount(success.response.data.total);
                            }
                            dispatch(addClinicProviderList({
                                ...clinicProviderList,
                                clinicList:_.uniqBy(success.response.data.clinic, 'id'),
                                providerList: success.response.data.doctors,
                                providerTotalCount:success.response.data.total?success.response.data.total:totalCount
                            }));
                            // appointmentSlotApi(success.response.data.doctors);
                        }else if(success.response.data && success.response.data.status.error === true){
                            dispatch(addClinicProviderList({
                                ...clinicProviderList,
                                clinicList:[],
                                providerList: [],
                                providerTotalCount: 0,
                                page:1
                            }));
                            console.log('error');
                            dispatch(fetchLoadingSuccess(false));
                        }
                    }
                });
            } catch (error) {
                toast.error('Something went wrong.');
                console.log('error', error);
                dispatch(fetchLoadingSuccess(false));
            }
        } else {
            // dispatch(fetchLoadingSuccess(false));
        }
    }

    const appointmentSlotApi = (providerList?: any, currentWeek?: any) => {
        var request: any = [];
        let startDate: any = searchFilterData.date ?searchFilterData.date:moment().format('YYYY-MM-DD');
        let endDate: any = searchFilterData.date ? moment(searchFilterData.date).add('days', 4).format('YYYY-MM-DD'):moment().add('days',4).format('YYYY-MM-DD');
        if (providerList.length > 0 ) {
            providerList.map((item: any) => {
                if (item.sharecalendar && item.sharecalendar.calender) {
                     request.push({
                        "doctorId": item.id,
                        "clinicId": item.clinic_id,
                        "start_date": startDate,
                        "end_date": endDate
                    });
                }
            });
            if (request.length > 0) {
                let data = { 'appointmentData': request };
                let isApiCall: boolean = false;
                if ( JSON.stringify(apiAppointmentSlotRequest) !== JSON.stringify(data)) {
                    setAppointmentSlotRequest(data);
                    isApiCall = true;
                }
                if (isApiCall) {
                    dispatch(fetchLoadingPending());
                    appointmentSlot(data).then((success: any) => {
                        try {
                            if (success && success.response && success.response.data && success.response.status === 200) {
                                if (success.response.data && success.response.data.status.error === false) {
                                    dispatch(fetchLoadingSuccess(false));
                                    var result = groupBy(success.response.data.appointments, function (item: any) {
                                        return [item.doctorid, item.clinicid];
                                    });
                                    let data: any = [];
                                    result.map((item: any) => {
                                        let groupData = _.groupBy(item, 'startdate');
                                        data.push(groupData);
                                    })
                                    // setGroupByDate(data);
                                    dispatch(addSlotData({ slot: data }));
                                    // this.setState({ appointmentSlotData: result,isAppointmentBook:false,isLoading:false });
                                } else {
                                    if (success.response.data && success.response.data.status.error === true) {
                                        toast.error(success.response.data.status.msg);
                                    }
                                    dispatch(fetchLoadingSuccess(false));
                                }
                            }
                        } catch (error) {
                            toast.error('Something went wrong.');
                            console.log('error', error);
                            dispatch(fetchLoadingSuccess(false));
                        }
                    });
                } else {
                    dispatch(fetchLoadingSuccess(false));
                }
            }
        }
    }
    const groupBy = (array: any, f: any) => {
        var groups: any = {};
        array.forEach(function (o: any) {
            var group = JSON.stringify(f(o));
            groups[group] = groups[group] || [];
            groups[group].push(o);
        });
        return Object.keys(groups).map(function (group) {
            return groups[group];
        })
    }

    

    return (
        <>
            <Modal className="filter-popup" tabIndex={1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" show={props.isShow} onHide={props.onHide} centered>
                <Modal.Header className="pb-0 pt-2 pe-3" closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className={props.selectedTab === "provider" ?"more-filter-box mb-3":"d-none"}>
                        <label className="form-label d-block">Gender</label>
                        <div className="form-row row">
                            <div className="form-group col-6 col-md-3 my-1">
                                <div className="form-check">
                                    <input onClick={() => { selectGender("Male") }} checked={gender.includes("Male")} className="form-check-input" type="checkbox" id="inlineCheckbox1" value="Male" />
                                    <label className="form-check-label">Male</label>
                                </div>
                            </div>
                            <div className="form-group col-6 col-md-3 my-1">
                                <div className="form-check">
                                    <input onClick={() => { selectGender("Female") }} checked={gender.includes("Female")} className="form-check-input" type="checkbox" id="inlineCheckbox3" value="Female" />
                                    <label className="form-check-label">Female</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mb-3 more-filter-box">
                        <label className="form-label d-block">Languages spoken</label>
                        <Select options={languages} isMulti
                            value={selectedLanguage}
                            onChange={(e: any) => { selectLanguage(e) }}
                        ></Select>
                    </div>
                    {
                        (searchFilterData && searchFilterData.location !== "") &&
                            <div className="mb-3 more-filter-box">
                                <label className="form-label d-block">Distance</label>
                                <select className="select-spec form-control" id="exampleFormControlSelect2" value={distance}
                                    onChange={(e) => { handleDistance(e.target.value) }}>
                                    <option value="">Select distance</option>
                                    <option value={'2'}>2 km.</option>
                                    <option value={'5'}>5 km.</option>
                                    <option value={'10'}>10 km.</option>
                                </select>
                            </div>
                    }
                    {
                        (clinicList && clinicList.length > 0) &&
                        <div className="mb-3 more-filter-box">
                            <label className="form-label d-block">Clinics</label>
                                {/* <select className="select-spec form-control" id="exampleFormControlSelect2" value={selectedClinic}
                                    onChange={(e) => { handleClinicSelect(e) }}
                                    >
                                    <option value="">Select clinic</option>
                                    {
                                        clinicList.map((item:any,index:any)=>{
                                            return(
                                                <option key={index+Math.random+'clinic'} value={item.id}>{item.name}</option>
                                            )
                                        })
                                    }
                                </select> */}
                            <Select options={clinicList}
                                value={selectedClinic}
                                onChange={(e: any) => { selectCinic(e) }}
                            ></Select>
                        </div>
                    }
                    {
                        (clinicWiseProviderList && clinicWiseProviderList.length > 0 && props.selectedTab === 'provider') &&
                        <div className="mb-3 more-filter-box">
                            <label className="form-label d-block">Provider</label>
                                <select className="select-spec form-control" id="exampleFormControlSelect2" value={selectedProvider}
                                    onChange={(e) => {  handleProviderSelect(e) }}
                                    >
                                    <option value="">Select provider</option>
                                    {
                                        clinicWiseProviderList.map((item:any,index:any)=>{
                                            return(
                                                <option key={index+Math.random+'provider'} value={item.id}>{item.fullname}</option>
                                            )
                                        })
                                    }
                                </select>
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <button  className="btn btn-primary btn-lg" onClick={() => {
                        filterApply()
                    }}>Apply</button>
                </Modal.Footer>
            </Modal>
            
        </>
    )
}

export default MoreFilterModal

import React, { useEffect, useState, useRef } from "react";
// import '../../assets/css/clinic-search.scss';
// import '../../assets/css/provider-clinic-list.scss';
import "../../assets/css/new-ui.scss";
import HeaderWhite from "../../pages/HeaderWhite/HeaderWhite";
import {
  getSpeciality,
  getTopClinic,
  getTopProvider,
} from "../../../../services/landingPageSearch";
import { toast } from "react-toastify";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { PaymentMethodModal } from "../../components/LandingPage/PaymentMethodModal";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { addFilterData } from "../../../../store/landingPageSearch/actions";
import { prepareAddress } from "../../../../utils/googleFunction";
import { addFilterItems, addLanguage } from "../../../../store/filters/actions";
import { IAppState } from "../../../../store";
import { addSlotData } from "../../../../store/slot/actions";
import { addClinicProviderList } from "../../../../store/clinicAndProviderList/actions";
import {
  fetchLoadingPending,
  fetchLoadingSuccess,
} from "../../../../store/loadingIndicator/actions";
import { useHistory } from "react-router-dom";
import { searchFilter } from "../../../../store/v2/searchFilter/actions";
import Slider from "react-slick";
import configs from "../../../../configs/apiConfigs";
import { showAddress } from "../../../renderAddress";
export const LandingPage = (props: any) => {
  let autcompleteRef: any = useRef();
  let autocomplete: any;
  const [date, setDate] = useState(null as any);
  const [specialities, setSpecialities] = useState([]);
  const [topProvider, setTopProvider] = useState([]);
  const [topClinic, setTopClinic] = useState([]);
  const [selectedSpeciality, setselectedSpeciality] = useState("");
  const [location, setLocation]: any = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [userLocation, setUserLocation] = useState({
    latitude: "",
    longitude: "",
  });
  const dispatch = useDispatch();
  const [iFram, setiFram] = useState(false);
  const history: any = useHistory();
  const clinicProviderList: any = useSelector(
    (state: IAppState | any) => state.clinicProviderList.clinicProviderList
  );

  const openVideoInNewTab = () => {
    const url = `https://www.youtube.com/watch?v=jMKfJTkMMRU`;
    window.open(url, "_blank");
  };

  useEffect(() => {
    getSpecialityApi();
    onLoadGetPlace();
    getMyLocation(false);
    if (new URLSearchParams(history.location.search).get("iframe") === "yes") {
      setiFram(true);
    }
    // from clinic or provider profile
    if (
      history &&
      history.location &&
      history.location.state &&
      history.location.state.iFrame
    ) {
      setiFram(true);
    }
  }, []);

  useEffect(() => {
    const request = {
      location: userLocation,
    };
    getTopProviderApi(request);
    getTopClinicApi(request);
  }, [userLocation]);

  var settings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: false,
    dots: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  var storySettings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    infinite: true,
    dots: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  var appSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    autoplay: true,
    autoplaySpeed: 1500,
    dots: false,
    arrows: false,
    infinite: true,
  };
  const onLoadGetPlace = () => {
    if (!document.getElementById("googlePlaceScript")) {
      const googleScript = document.createElement("script");
      googleScript.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDIDMklRxHmFYWU8Vvo1P-dVkB_nbHARj8&libraries=places`;
      googleScript.id = "googlePlaceScript";
      window.document.body.appendChild(googleScript);
      googleScript.addEventListener("load", () => {
        try {
          //@ts-ignore
          autocomplete = new window.google.maps.places.Autocomplete(
            autcompleteRef.current,
            {
              types: ["(regions)"],
            }
          );
          autocomplete.addListener("place_changed", () => {
            const place = autocomplete.getPlace();
            setLocation(place);
          });
        } catch (err) {
          console.log(err);
        }
      });
    } else {
      try {
        //@ts-ignore
        autocomplete = new window.google.maps.places.Autocomplete(
          autcompleteRef.current,
          {
            types: ["(regions)"],
          }
        );
        autocomplete.addListener("place_changed", () => {
          const place = autocomplete.getPlace();
          setLocation(place);
        });
      } catch (err) {
        console.log(err);
      }
    }
  };
  const getSpecialityApi = () => {
    getSpeciality().then((success: any) => {
      try {
        if (
          success &&
          success.response &&
          success.response.data &&
          success.response.status === 200
        ) {
          if (
            success.response.data &&
            success.response.data.status.error === false
          ) {
            dispatch(fetchLoadingSuccess(false));
            setSpecialities(success.response.data.specialities);
          } else {
            if (
              success.response.data &&
              success.response.data.status.error === true
            ) {
              toast.error(success.response.data.status.msg);
            }
            dispatch(fetchLoadingSuccess(false));
          }
        }
      } catch (error) {
        console.log("error", error);
        dispatch(fetchLoadingSuccess(false));
      }
    });
  };

  const getTopProviderApi = (request: any) => {
    getTopProvider(request).then((success: any) => {
      try {
        if (
          success &&
          success.response &&
          success.response.data &&
          success.response.status === 200
        ) {
          if (
            success.response.data &&
            success.response.data.status.error === false
          ) {
            dispatch(fetchLoadingSuccess(false));
            console.log("get provider data", success.response.data);
            setTopProvider(success.response.data.providers);
          } else {
            if (
              success.response.data &&
              success.response.data.status.error === true
            ) {
              toast.error(success.response.data.status.msg);
            }
            dispatch(fetchLoadingSuccess(false));
          }
        }
      } catch (error) {
        console.log("error", error);
        dispatch(fetchLoadingSuccess(false));
      }
    });
  };

  const getTopClinicApi = (request: any) => {
    getTopClinic(request).then((success: any) => {
      try {
        if (
          success &&
          success.response &&
          success.response.data &&
          success.response.status === 200
        ) {
          if (
            success.response.data &&
            success.response.data.status.error === false
          ) {
            dispatch(fetchLoadingSuccess(false));
            console.log("get top clinic", success.response.data);
            setTopClinic(success.response.data.clinics);
          } else {
            if (
              success.response.data &&
              success.response.data.status.error === true
            ) {
              toast.error(success.response.data.status.msg);
            }
            dispatch(fetchLoadingSuccess(false));
          }
        }
      } catch (error) {
        console.log("error", error);
        dispatch(fetchLoadingSuccess(false));
      }
    });
  };

  const handleSpeciality = (value: any) => {
    if (value) {
      setselectedSpeciality(value);
    }
  };
  const handleChange = (date: any) => {
    setDate(date);
  };
  const clickSearch = () => {
    if (
      selectedSpeciality === "" ||
      selectedSpeciality === "Select Speciality"
    ) {
      toast.warn("Please select speciality.");
      return;
    }
    setShowModal(true);
  };
  const getMyLocation = (isNearMe: boolean) => {
    const location = window.navigator && window.navigator.geolocation;
    if (location) {
      location.getCurrentPosition(
        (position: any) => {
          setUserLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
          if (isNearMe) {
            setShowModal(true);
          } else {
            setUserLocation({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            });
          }
        },
        (error) => {}
      );
    }
  };
  return (
    <>
      {!iFram && <HeaderWhite></HeaderWhite>}

      <main>
        <section className="banner-sec">
          <div className="container h-100">
            <div className="row h-100 align-items-lg-center justify-content-center">
              <div className="col-12 text-center text-white">
                <h1>
                  Find trusted <span>providers</span> with just a few clicks
                </h1>
                <h4 className="fst-normal my-3">
                  Book Instant appointments with top rated healthcare providers
                  in your area.
                </h4>

                <form
                  className="d-lg-flex d-block mt-5 flex-lg-nowrap flex-wrap justify-content-center"
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div className="input-group search-input mb-3 mb-lg-0">
                    <select
                      className="form-control h-100"
                      id="exampleFormControlSelect2"
                      value={selectedSpeciality}
                      onChange={(e) => {
                        handleSpeciality(e.target.value);
                      }}
                    >
                      <option
                        key={"Select Speciality"}
                        value="Select Speciality"
                      >
                        Search Speciality, Doctor or Clinic
                      </option>
                      {specialities &&
                        specialities.length > 0 &&
                        specialities.map((item: any, index: number) => {
                          return (
                            <option
                              key={index + Math.random() + "speciality"}
                              value={item.id}
                            >
                              {item.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                  <div className="input-group location-date-input mb-3 mb-lg-0">
                    <span className="input-group-text">
                      <i className="bi bi-geo-alt"></i>
                    </span>
                    <input
                      type="text"
                      id="autocomplete"
                      value={location ? location.formatted_address : location}
                      onChange={(e) => {
                        setLocation(e.target.value);
                      }}
                      ref={autcompleteRef}
                      placeholder="City, state, or zip code"
                      className="form-control"
                    />
                  </div>
                  {/* <div className="input-group location-date-input mb-3 mb-lg-0">
                    <KeyboardDatePicker
                      className="form-control"
                      placeholder={"Select date"}
                      id="date-picker-dialog"
                      format="YYYY-MM-DD"
                      minDate={new Date()}
                      value={date}
                      onChange={(date) => {
                        handleChange(date);
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </div> */}
                  <button
                    disabled={!location && !selectedSpeciality}
                    onClick={() => {
                      clickSearch();
                    }}
                    className="btn btn-primary py-3"
                    data-toggle="modal"
                    data-target="#exampleModal"
                  >
                    Get started
                  </button>
                </form>
                <ul className="custom-list d-md-flex d-block text-start justify-content-center my-5">
                  <li>Simple and Streamlined Search</li>
                  <li>Comprehensive Provider Booking</li>
                  <li>Get Rewarded with our Loyalty Program</li>
                </ul>
                {/* <button className="location-title btn mx-auto p-0" onClick={() => { getMyLocation(true) }}>
                                    <img src={require("../../assets/images/ic_my_location_24px.svg")} alt="image" />
                                    Near me
                                </button> */}
              </div>
            </div>
          </div>
        </section>

        <section className="quality-providers">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6 col-lg-5 text-center text-md-start order-md-1 order-2">
                <h2 className="h1">Quality providers at your fingertips</h2>
                <p>Empowering Your Healthcare Journey with Trusted Providers</p>
                <ul className="custom-list my-5 text-start">
                  <li>
                    Access providers through you mobile app and web browser
                  </li>
                  <li>Find a convenient appointment using online scheduling</li>
                  <li>
                    Choose from the highest rated providers based on your
                    address
                  </li>
                  <li>Read other patient reviews</li>
                </ul>
                <a onClick={openVideoInNewTab} className="btn btn-dark btn-lg">
                  Watch patient journey{" "}
                  <i className="ms-3 bi bi-play-circle-fill"></i>
                </a>
              </div>
              <div className="col-md-6 col-lg-7 mb-5 mb-md-0 order-md-2 order-1">
                <img
                  src={require("../../assets/images/quality-providers.png")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>
        <section className="cta-one cta-box-sec">
          <div className="container py-5">
            <div className="row">
              <div className="col-12 text-center">
                <h2 className="h1 text-white mx-auto">Xcare for Providers</h2>
                <p className="h4 text-white my-4">
                  Are you interested in reaching out to new patients?
                </p>
                <div className="mx-auto btn-box">
                  <a
                    // onClick={() => {
                    //   history.push({
                    //     pathname: "/list",
                    //     state: { tab: "provider" },
                    //   });
                    // }}
                    href={configs.PROVIDER_URL}
                    target="_blank"
                    className="btn btn-primary btn-lg me-md-2 my-2"
                  >
                    List as a provider
                  </a>
                  <a
                    // onClick={() => {
                    //   history.push({
                    //     pathname: "/list",
                    //     state: { tab: "clinic" },
                    //   });
                    // }}
                    href={configs.PRACTICE_URL}
                    target="_blank"
                    className="btn btn-primary btn-lg ms-md-2 my-2"
                  >
                    List a Practice
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="top-providers">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="sub-title">Providers</div>
                <h2 className="h1">
                  Top rated <span className="text-primary">providers</span> near
                  you
                </h2>
              </div>
            </div>
            <div className="provider-slider mt-5">
              <Slider {...settings}>
                {topProvider.map((provider: any, index) => {
                  return (
                    <div className="slider-box" key={index}>
                      <div className="inner-box">
                        <div className="img-box">
                          <img
                            src={
                              configs.IMAGE_URL +
                              "doctor/" +
                              provider.photo.name +
                              "-200x200." +
                              provider.photo.type
                            }
                            alt=""
                          />
                        </div>
                        <div className="content-box">
                          <h4>
                            {provider.name}{" "}
                            <i className="bi bi-patch-check-fill ms-1"></i>
                          </h4>
                          <div className="small">
                            {provider.speciality
                              .slice(0, 2)
                              .map((item: any) => item.name)
                              .join(",")}
                          </div>
                          <div className="d-flex align-items-center small mb-2">
                            {/* <i className="bi bi-star-fill text-danger me-2"></i>{" "}
                            {provider.rating} ({provider.reviewCount}) */}
                          </div>
                          <div className="d-flex small mb-2">
                            <i className="bi bi-geo-alt-fill me-2"></i>
                            {showAddress(provider.address)}
                          </div>
                          <div className="mt-3">
                            <div className="availability">Available today</div>
                            <a
                              onClick={() => {
                                history.push(
                                  `/${provider.address.locality}/${provider.name}/${provider.provideruniqueId}/d`
                                );
                              }}
                              className="btn btn-dark w-100"
                            >
                              Book online
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </div>
          </div>
        </section>
        <section className="top-clinic">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="sub-title">Clinics</div>
                <h2 className="h1">
                  Top rated <span className="text-warning">clinics</span> near
                  you
                </h2>
              </div>
            </div>
            <div className="clinic-slider mt-5">
              <Slider {...settings}>
                {topClinic.map((clinic: any, index) => {
                  return (
                    <div className="slider-box" key={index}>
                      <div className="inner-box">
                        <div className="img-box">
                          <img
                            src={
                              configs.IMAGE_URL +
                              "clinic/" +
                              clinic.logo.name +
                              "-200x200." +
                              clinic.logo.type
                            }
                            alt=""
                          />
                        </div>
                        <div className="content-box">
                          <h4>
                            {clinic.name}{" "}
                            <i className="bi bi-patch-check-fill ms-1"></i>
                          </h4>
                          <div className="small">
                            {clinic.primarySpeciality
                              .slice(0, 2)
                              .map((item: any) => item.name)
                              .join(",")}
                          </div>
                          <div className="d-flex align-items-center small mb-2">
                            {/* <i className="bi bi-star-fill text-danger me-2"></i>{" "}
                            {clinic.rating} ({clinic.reviewCount}) */}
                          </div>
                          <div className="d-flex small mb-2">
                            <i className="bi bi-geo-alt-fill me-2"></i>
                            {showAddress(clinic.address)}
                          </div>
                          <div className="mt-3">
                            <div className="availability">Available today</div>
                            <a
                              onClick={() => {
                                history.push(
                                  `/${clinic.address.locality}/${clinic.name}/${clinic.uniqueId}/d`
                                );
                              }}
                              className="btn btn-dark w-100"
                            >
                              Book online
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
                {/* <div className="slider-box">
                                    <div className="inner-box">
                                        <div className="img-box">
                                            <img src={require("../../assets/images/user.svg")} alt="" />
                                        </div>
                                        <div className="content-box">
                                            <h4>Dr. Derek Pope, DDS <i className="bi bi-patch-check-fill ms-1"></i></h4>
                                            <div className="small">Dentist</div>
                                            <div className="d-flex align-items-center small mb-2"><i
                                                className="bi bi-star-fill text-danger me-2"></i> 4.82 (400)</div>
                                            <div className="d-flex small mb-2"><i className="bi bi-geo-alt-fill me-2"></i> 19 Shady Lawn
                                                Court, Mississauga, ON, L5N 1H2</div>
                                            <div className="mt-3">
                                                <div className="availability">Available today</div>
                                                <a href="" className="btn btn-dark w-100">Book online</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="slider-box">
                                    <div className="inner-box">
                                        <div className="img-box">
                                            <img src={require("../../assets/images/user.svg")} alt="" />
                                        </div>
                                        <div className="content-box">
                                            <h4>Dr. Derek Pope, DDS <i className="bi bi-patch-check-fill ms-1"></i></h4>
                                            <div className="small">Dentist</div>
                                            <div className="d-flex align-items-center small mb-2"><i
                                                className="bi bi-star-fill text-danger me-2"></i> 4.82 (400)</div>
                                            <div className="d-flex small mb-2"><i className="bi bi-geo-alt-fill me-2"></i> 19 Shady Lawn
                                                Court, Mississauga, ON, L5N 1H2</div>
                                            <div className="mt-3">
                                                <div className="availability">Available today</div>
                                                <a href="" className="btn btn-dark w-100">Book online</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="slider-box">
                                    <div className="inner-box">
                                        <div className="img-box">
                                            <img src={require("../../assets/images/user.svg")} alt="" />
                                        </div>
                                        <div className="content-box">
                                            <h4>Dr. Derek Pope, DDS <i className="bi bi-patch-check-fill ms-1"></i></h4>
                                            <div className="small">Dentist</div>
                                            <div className="d-flex align-items-center small mb-2"><i
                                                className="bi bi-star-fill text-danger me-2"></i> 4.82 (400)</div>
                                            <div className="d-flex small mb-2"><i className="bi bi-geo-alt-fill me-2"></i> 19 Shady Lawn
                                                Court, Mississauga, ON, L5N 1H2</div>
                                            <div className="mt-3">
                                                <div className="availability">Available today</div>
                                                <a href="" className="btn btn-dark w-100">Book online</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="slider-box">
                                    <div className="inner-box">
                                        <div className="img-box">
                                            <img src={require("../../assets/images/user.svg")} alt="" />
                                        </div>
                                        <div className="content-box">
                                            <h4>Dr. Derek Pope, DDS <i className="bi bi-patch-check-fill ms-1"></i></h4>
                                            <div className="small">Dentist</div>
                                            <div className="d-flex align-items-center small mb-2"><i
                                                className="bi bi-star-fill text-danger me-2"></i> 4.82 (400)</div>
                                            <div className="d-flex small mb-2"><i className="bi bi-geo-alt-fill me-2"></i> 19 Shady Lawn
                                                Court, Mississauga, ON, L5N 1H2</div>
                                            <div className="mt-3">
                                                <div className="availability">Available today</div>
                                                <a href="" className="btn btn-dark w-100">Book online</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="slider-box">
                                    <div className="inner-box">
                                        <div className="img-box">
                                            <img src={require("../../assets/images/user.svg")} alt="" />
                                        </div>
                                        <div className="content-box">
                                            <h4>Dr. Derek Pope, DDS <i className="bi bi-patch-check-fill ms-1"></i></h4>
                                            <div className="small">Dentist</div>
                                            <div className="d-flex align-items-center small mb-2"><i
                                                className="bi bi-star-fill text-danger me-2"></i> 4.82 (400)</div>
                                            <div className="d-flex small mb-2"><i className="bi bi-geo-alt-fill me-2"></i> 19 Shady Lawn
                                                Court, Mississauga, ON, L5N 1H2</div>
                                            <div className="mt-3">
                                                <div className="availability">Available today</div>
                                                <a href="" className="btn btn-dark w-100">Book online</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="slider-box">
                                    <div className="inner-box">
                                        <div className="img-box">
                                            <img src={require("../../assets/images/user.svg")} alt="" />
                                        </div>
                                        <div className="content-box">
                                            <h4>Dr. Derek Pope, DDS <i className="bi bi-patch-check-fill ms-1"></i></h4>
                                            <div className="small">Dentist</div>
                                            <div className="d-flex align-items-center small mb-2"><i
                                                className="bi bi-star-fill text-danger me-2"></i> 4.82 (400)</div>
                                            <div className="d-flex small mb-2"><i className="bi bi-geo-alt-fill me-2"></i> 19 Shady Lawn
                                                Court, Mississauga, ON, L5N 1H2</div>
                                            <div className="mt-3">
                                                <div className="availability">Available today</div>
                                                <a href="" className="btn btn-dark w-100">Book online</a>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
              </Slider>
            </div>
          </div>
        </section>
        {/* <section className="provider-clinic-list">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="sub-text">In your area</div>
                                <h2 className="h1">Find providers in your city</h2>
                            </div>
                            <div className="col-12">
                                <ul className="d-flex flex-wrap mt-5">
                                    <li className="d-flex justify-content-between border-bottom pb-2 align-items-center">
                                        <a href="">Burlington</a>
                                        <i className="bi bi-arrow-right"></i>
                                    </li>
                                    <li className="d-flex justify-content-between border-bottom pb-2 align-items-center">
                                        <a href="">Burlington</a>
                                        <i className="bi bi-arrow-right"></i>
                                    </li>
                                    <li className="d-flex justify-content-between border-bottom pb-2 align-items-center">
                                        <a href="">Burlington</a>
                                        <i className="bi bi-arrow-right"></i>
                                    </li>
                                    <li className="d-flex justify-content-between border-bottom pb-2 align-items-center">
                                        <a href="">Burlington</a>
                                        <i className="bi bi-arrow-right"></i>
                                    </li>
                                    <li className="d-flex justify-content-between border-bottom pb-2 align-items-center">
                                        <a href="">Mississauga</a>
                                        <i className="bi bi-arrow-right"></i>
                                    </li>
                                    <li className="d-flex justify-content-between border-bottom pb-2 align-items-center">
                                        <a href="">Mississauga</a>
                                        <i className="bi bi-arrow-right"></i>
                                    </li>
                                    <li className="d-flex justify-content-between border-bottom pb-2 align-items-center">
                                        <a href="">Mississauga</a>
                                        <i className="bi bi-arrow-right"></i>
                                    </li>
                                    <li className="d-flex justify-content-between border-bottom pb-2 align-items-center">
                                        <a href="">Mississauga</a>
                                        <i className="bi bi-arrow-right"></i>
                                    </li>
                                    <li className="d-flex justify-content-between border-bottom pb-2 align-items-center">
                                        <a href="">Oakville</a>
                                        <i className="bi bi-arrow-right"></i>
                                    </li>
                                    <li className="d-flex justify-content-between border-bottom pb-2 align-items-center">
                                        <a href="">Oakville</a>
                                        <i className="bi bi-arrow-right"></i>
                                    </li>
                                    <li className="d-flex justify-content-between border-bottom pb-2 align-items-center">
                                        <a href="">Oakville</a>
                                        <i className="bi bi-arrow-right"></i>
                                    </li>
                                    <li className="d-flex justify-content-between border-bottom pb-2 align-items-center">
                                        <a href="">Oakville</a>
                                        <i className="bi bi-arrow-right"></i>
                                    </li>
                                    <li className="d-flex justify-content-between border-bottom pb-2 align-items-center">
                                        <a href="">Ottawa</a>
                                        <i className="bi bi-arrow-right"></i>
                                    </li>
                                    <li className="d-flex justify-content-between border-bottom pb-2 align-items-center">
                                        <a href="">Ottawa</a>
                                        <i className="bi bi-arrow-right"></i>
                                    </li>
                                    <li className="d-flex justify-content-between border-bottom pb-2 align-items-center">
                                        <a href="">Ottawa</a>
                                        <i className="bi bi-arrow-right"></i>
                                    </li>
                                    <li className="d-flex justify-content-between border-bottom pb-2 align-items-center">
                                        <a href="">Ottawa</a>
                                        <i className="bi bi-arrow-right"></i>
                                    </li>
                                    <li className="d-flex justify-content-between border-bottom pb-2 align-items-center">
                                        <a href="">Toronto</a>
                                        <i className="bi bi-arrow-right"></i>
                                    </li>
                                    <li className="d-flex justify-content-between border-bottom pb-2 align-items-center">
                                        <a href="">Toronto</a>
                                        <i className="bi bi-arrow-right"></i>
                                    </li>
                                    <li className="d-flex justify-content-between border-bottom pb-2 align-items-center">
                                        <a href="">Toronto</a>
                                        <i className="bi bi-arrow-right"></i>
                                    </li>
                                    <li className="d-flex justify-content-between border-bottom pb-2 align-items-center">
                                        <a href="">Toronto</a>
                                        <i className="bi bi-arrow-right"></i>
                                    </li>
                                    <li className="d-flex justify-content-between border-bottom pb-2 align-items-center">
                                        <a href="">Hamilton</a>
                                        <i className="bi bi-arrow-right"></i>
                                    </li>
                                    <li className="d-flex justify-content-between border-bottom pb-2 align-items-center">
                                        <a href="">Hamilton</a>
                                        <i className="bi bi-arrow-right"></i>
                                    </li>
                                    <li className="d-flex justify-content-between border-bottom pb-2 align-items-center">
                                        <a href="">Hamilton</a>
                                        <i className="bi bi-arrow-right"></i>
                                    </li>
                                    <li className="d-flex justify-content-between border-bottom pb-2 align-items-center">
                                        <a href="">Hamilton</a>
                                        <i className="bi bi-arrow-right"></i>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section> */}
        <section className="industry-sec bg-info">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <h2 className="h1">
                  Manage all your Healthcare appointments from one place
                </h2>
                <p className="mb-5">Find top rated practices near you.</p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 col-md-4 col-6">
                <div className="industry-box border rounded-4 bg-white p-3 p-md-4">
                  <img
                    src={require("../../assets/images/dentist.svg")}
                    alt=""
                    className="mb-3"
                  />
                  <div className="d-flex">
                    <div>
                      <strong>Dental</strong>
                      <p>Find top rated dentist near you</p>
                    </div>
                    <a href="">
                      <i className="bi bi-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-6">
                <div className="industry-box border rounded-4 bg-white p-3 p-md-4">
                  <img
                    src={require("../../assets/images/vision.svg")}
                    alt=""
                    className="mb-3"
                  />
                  <div className="d-flex">
                    <div>
                      <strong>Vision</strong>
                      <p>Find top rated vision near you</p>
                    </div>
                    <a href="">
                      <i className="bi bi-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-6">
                <div className="industry-box border rounded-4 bg-white p-3 p-md-4">
                  <img
                    src={require("../../assets/images/chiro.svg")}
                    alt=""
                    className="mb-3"
                  />
                  <div className="d-flex">
                    <div>
                      <strong>Chiro</strong>
                      <p>Find top rated chiro near you</p>
                    </div>
                    <a href="">
                      <i className="bi bi-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-6">
                <div className="industry-box border rounded-4 bg-white p-3 p-md-4">
                  <img
                    src={require("../../assets/images/podiatry.svg")}
                    alt=""
                    className="mb-3"
                  />
                  <div className="d-flex">
                    <div>
                      <strong>Podiatry</strong>
                      <p>Find top rated podiatry near you</p>
                    </div>
                    <a href="">
                      <i className="bi bi-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-6">
                <div className="industry-box border rounded-4 bg-white p-3 p-md-4">
                  <img
                    src={require("../../assets/images/physio.svg")}
                    alt=""
                    className="mb-3"
                  />
                  <div className="d-flex">
                    <div>
                      <strong>Physio</strong>
                      <p>Find top rated physio near you</p>
                    </div>
                    <a href="">
                      <i className="bi bi-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-6">
                <div className="industry-box border rounded-4 bg-white p-3 p-md-4">
                  <img
                    src={require("../../assets/images/audiology.svg")}
                    alt=""
                    className="mb-3"
                  />
                  <div className="d-flex">
                    <div>
                      <strong>Audiology</strong>
                      <p>Find top rated audiology near you</p>
                    </div>
                    <a href="">
                      <i className="bi bi-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-6">
                <div className="industry-box border rounded-4 bg-white p-3 p-md-4">
                  <img
                    src={require("../../assets/images/fertility.svg")}
                    alt=""
                    className="mb-3"
                  />
                  <div className="d-flex">
                    <div>
                      <strong>Fertility</strong>
                      <p>Find top rated fertility clinic near you</p>
                    </div>
                    <a href="">
                      <i className="bi bi-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-3 col-md-4 col-6">
                <div className="industry-box border bg-primary rounded-4 d-flex align-items-center text-center justify-content-center last-box">
                  <div className="w-50 text-white">Any cross sell CTA link</div>
                </div>
              </div> */}
            </div>
          </div>
        </section>
        <section className="success-stories-sec">
          <div className="container">
            <h2 className="h1">Patient Success Stories</h2>
            <p>
              Real stories, real experiences: Hear from our patients through
              their reviews.
            </p>
            <div className="success-slider mt-5">
              <Slider {...storySettings}>
                <div className="slider-box">
                  <p>
                    It made it very easy for me to find a new dentist and book
                    all of my future appointments. I would recommend this app to
                    anyone that is looking to streamline their health care
                    experience
                  </p>
                  <div className="user-strip d-flex">
                    {/* <div className="img-box">
                      <img
                        src={require("../../assets/images/slider-user.svg")}
                        alt=""
                      />
                    </div> */}
                    <div className="content-box">
                      <div className="u-name">Luke B.</div>
                      {/* <div className="u-location">Toronto</div> */}
                    </div>
                  </div>
                </div>
                <div className="slider-box">
                  <p>
                    Easy to schedule my apps in one place. I forgot when my last
                    appt had been but the app pulled my old appts so I don’t
                    have to stress about remembering when I have an appointment,
                    I also liked the emergency feature. Also it auto fills in
                    the time and address to my calendar, 10/10 will keep using
                    this app
                  </p>
                  <div className="user-strip d-flex">
                    {/*  <div className="img-box">
                      <img
                        src={require("../../assets/images/slider-user.svg")}
                        alt=""
                      />
                    </div> */}
                    <div className="content-box">
                      <div className="u-name">P Glassman</div>
                      {/* <div className="u-location">Toronto</div> */}
                    </div>
                  </div>
                </div>
                <div className="slider-box">
                  <p>
                    Very impressed with this app! During such a time, this saved
                    us with the contactless payment options and easy-to-navigate
                    engine! Worth the buy and it even includes a pre-screening
                    feature for all the patients. Love the idea!!
                  </p>
                  <div className="user-strip d-flex">
                    {/* <div className="img-box">
                      <img
                        src={require("../../assets/images/slider-user.svg")}
                        alt=""
                      />
                    </div> */}
                    <div className="content-box">
                      <div className="u-name">Kathy Fisher</div>
                      {/* <div className="u-location">Toronto</div> */}
                    </div>
                  </div>
                </div>
                <div className="slider-box">
                  <p>
                    Easy to manage mobile app which gives me a lot more control
                    over my dental care experience. I love how easy it is to
                    request new appointments, communicate with my dentist and
                    pay for my treatments on the go.
                  </p>
                  <div className="user-strip d-flex">
                    {/* <div className="img-box">
                      <img
                        src={require("../../assets/images/slider-user.svg")}
                        alt=""
                      />
                    </div> */}
                    <div className="content-box">
                      <div className="u-name">George W.</div>
                      {/* <div className="u-location">Toronto</div> */}
                    </div>
                  </div>
                </div>
                <div className="slider-box">
                  <p>
                    This is SO much easier and convenient than ANY healthcare
                    provider app I’ve seen, and it works with all of them! This
                    one works, so smoothly.
                  </p>
                  <div className="user-strip d-flex">
                    {/* <div className="img-box">
                      <img
                        src={require("../../assets/images/slider-user.svg")}
                        alt=""
                      />
                    </div> */}
                    <div className="content-box">
                      <div className="u-name">Ron Price</div>
                      {/* <div className="u-location">Toronto</div> */}
                    </div>
                  </div>
                </div>
                <div className="slider-box">
                  <p>
                    I appreciate how helpful having Xcare was during the recent
                    snow storm. I did not have to go through the pain of getting
                    on the phone for hours to get in line with my previous
                    vendor to send emergency notifications to patients and then
                    follow-up to reschedule. I was able to complete this task in
                    a few minutes with Xcare. Thank You Xcare!
                  </p>
                  <div className="user-strip d-flex">
                    {/* <div className="img-box">
                      <img
                        src={require("../../assets/images/slider-user.svg")}
                        alt=""
                      />
                    </div> */}
                    <div className="content-box">
                      <div className="u-name">Annie D'souza</div>
                      {/* <div className="u-location">Toronto</div> */}
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </section>
        <section className="app-sec">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6 order-md-1 order-2 text-center text-md-start">
                <h2 className="h1">
                  Thousands of providers.{" "}
                  <span className="d-block text-primary">One app.</span>
                </h2>
                <p className="my-4">
                  The Xcare app is the quickest, easiest way to book and keep
                  track of your appointments.
                </p>
                <div className="text-primary">
                  Scan the QR code to get the app now
                </div>
                <div className="d-flex justify-content-center justify-content-md-start">
                  <a href="" className="mt-3">
                    <img
                      src={require("../../assets/images/appstore-desk.svg")}
                      alt=""
                    />
                  </a>
                  <a href="" className="mt-3 ms-5">
                    <img
                      src={require("../../assets/images/android-desk.svg")}
                      alt=""
                    />
                  </a>
                </div>
              </div>
              <div className="col-md-6 mb-5 mb-md-0 order-md-2 order-1 ">
                <div className="app-ss-slider">
                  <Slider {...appSettings}>
                    <div>
                      <img
                        className="img-fluid"
                        src={require("../../assets/images/ss-1.png")}
                        alt=""
                      />
                    </div>
                    <div>
                      <img
                        className="img-fluid"
                        src={require("../../assets/images/ss-2.png")}
                        alt=""
                      />
                    </div>
                    <div>
                      <img
                        className="img-fluid"
                        src={require("../../assets/images/ss-3.png")}
                        alt=""
                      />
                    </div>
                    <div>
                      <img
                        className="img-fluid"
                        src={require("../../assets/images/ss-4.png")}
                        alt=""
                      />
                    </div>
                    <div>
                      <img
                        className="img-fluid"
                        src={require("../../assets/images/ss-5.png")}
                        alt=""
                      />
                    </div>
                    <div>
                      <img
                        className="img-fluid"
                        src={require("../../assets/images/ss-6.png")}
                        alt=""
                      />
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="cta-two cta-box-sec">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <h2 className="h1 mx-auto text-white">
                  Find trusted providers with just a few clicks
                </h2>
                <p className="h4 my-4 text-white">
                  Simplify your healthcare journey with centralized appointment
                  management.
                </p>
                <a
                  onClick={() => {
                    history.push({
                      pathname: "/list",
                      state: { tab: "provider" },
                    });
                  }}
                  className="btn btn-primary btn-lg px-5"
                >
                  Book appointment
                </a>
              </div>
            </div>
          </div>
        </section>
      </main>
      <footer className="main-footer my-5">
        <div className="container">
          <div className="row">
            <div className="col-md-3 mb-md-0 mb-4">
              <img
                src={require("../../assets/images/logo-black.svg")}
                alt=""
                className="mb-4"
              />
              <p>
                Save time and effort in finding the right healthcare
                professional for your needs.
              </p>
            </div>
            <div className="col-md-9">
              <div className="row">
                <div className="col-md-3 col-6 mb-md-0 mb-4">
                  <strong>COMPANY</strong>
                  <ul>
                    <li>
                      <a href="/about">About</a>
                    </li>
                    <li>
                      <a href={configs.PATIENT_URL}>Patient Login</a>
                    </li>
                    {/* <li>
                      <a href="">Promo Code?</a>
                    </li> */}
                    {/* <li>
                      <a href="/referral">Referrals</a>
                    </li> */}
                    <li>
                      <a href="/how-it-works">How It Works</a>
                    </li>
                  </ul>
                </div>
                <div className="col-md-3 col-6 mb-md-0 mb-4">
                  <strong>FOR DENTISTS</strong>
                  <ul>
                    <li>
                      <a href={configs.CLINIC_URL}>Practice Login</a>
                    </li>
                    <li>
                      <a href={configs.CLINIC_URL}>Grow Your Practice</a>
                    </li>
                  </ul>
                </div>
                <div className="col-md-3 col-6">
                  <strong>NEED HELP?</strong>
                  <ul>
                    <li>
                      <a href={configs.FRESHDESK_URL}>Patient</a>
                    </li>
                    <li>
                      <a href={configs.FRESHDESK_URL}>Practice</a>
                    </li>
                  </ul>
                </div>
                <div className="col-md-3 col-6">
                  <strong>LEGAL</strong>
                  <ul>
                    <li>
                      <a href="/terms-condition">Terms of Use</a>
                    </li>
                    <li>
                      <a href="/privacy-policy">Privacy Policy</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-center border-top pt-4 mt-5">
              © {moment().year()} XcarePro. All rights reserved.
            </div>
          </div>
        </div>
      </footer>
      {showModal && (
        <PaymentMethodModal
          isShow={showModal}
          onHide={() => {
            setShowModal(false);
          }}
          onClickSave={(
            selectedPaymentMethod: any,
            selectedValue: any,
            insuranceDetail?: any
          ) => {
            dispatch(
              searchFilter({
                oldSpeciality: selectedSpeciality,
                newSpeciality: selectedSpeciality,
                location: location ? prepareAddress(location) : userLocation,
                date: date !== null ? moment(date).format("YYYY-MM-DD") : date,
                howToPay: { type: selectedPaymentMethod, value: selectedValue },
                insuranceDetail,
                gender: "",
                distance: "",
                languages: "",
                clinicId: "",
                providerId: "",
                patientUniqueId: "",
                page: 1,
                clinicPage: 1,
                results: 0,
              })
            );
            dispatch(
              addClinicProviderList({
                ...clinicProviderList,
                clinicList: [],
                providerList: [],
                providerTotalCount: 0,
                clinicTotalCount: 0,
              })
            );
            dispatch(addSlotData({ slot: [] }));
            props.history.push({ pathname: "/list", state: { iFrame: iFram } });
          }}
        />
      )}
    </>
  );
};

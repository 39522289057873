import React from 'react';
import { Link } from 'react-router-dom';
import { CONSTANT } from '../../constants';
import Cookies from 'js-cookie';
import apiConfigs from '../../configs/apiConfigs';
import { Dropdown, Modal } from 'react-bootstrap';
import LoginModal from '../LoginModal/LoginModal';
import { logoutUser } from '../../utils/firebaseUtils';
import $ from 'jquery';
import OTPModal from '../OTPModal/OTPModal';
import RegisterModal from '../../components/LandingPageSearch/RegisterModal';
import { isLogin } from '../../utils/global';
import cookie from 'react-cookies';
import { signInWithCustomToken } from '../../utils/firebaseUtils';
interface Props {

}
interface State {
	showModal: boolean;
	userId: any;
	phone: any;
	showOTPModal: boolean;
	isLogoutConfirm: boolean;
	isRegister: boolean;
	isLogin: any;
}
export default class HeaderWhite extends React.Component<Props, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			showModal: false, userId: '', phone: '', showOTPModal: false,
			isLogoutConfirm: false, isRegister: false, isLogin: ''
		}
		this.hideModal = this.hideModal.bind(this);
	}
	componentDidMount() {
		window.addEventListener('focus', this.onFocus);
		$('a.target-burger').click(function (e) {
			$('nav.mobile-nav, a.target-burger, body').toggleClass('toggled');
			e.preventDefault();
			$('.mobile-nav .menu li:has(ul)').append('<span class="dsarrow"></span>');

			$('.mobile-nav .sub-menu').slideUp();
			$(".mobile-nav .menu li:has(ul)").find(".dsarrow").click(function () {
				// if(false == $(this).parent().children(".mobile-nav .menu li ul.sub-menu").is(':visible')) {
				//     // $('.mobile-nav .menu li ul.sub-menu').slideUp();
				// }
				$(this).parent().children(".mobile-nav .menu li ul.sub-menu").slideToggle();
			});
		});
		if (!isLogin('patient_cookie')) {
			this.setState({ isLogin: '' });
			// window.location.reload();
		} else {
			let isLogin: any = Cookies.getJSON('patient_cookie');
			this.setState({ isLogin: isLogin });
		}
	}
	onFocus = () => {
		if (!isLogin('patient_cookie')) {
			this.setState({ isLogin: '' });
		} else {
			let isLogin: any = Cookies.getJSON('patient_cookie');
			this.setState({ isLogin: isLogin });
			{/*try {
				signInWithCustomToken(isLogin.jwtToken).then(async (signInSuccess) => {
					console.log('firebase Login success');
				});
			} catch (error) {
				console.log('error Firebase Login', error);
			}*/}
		}
	}
	hideModal() {
		if (!isLogin('patient_cookie')) {
			this.setState({ showModal: false, isLogoutConfirm: false, isRegister: false, isLogin: '' });
		} else {
			let isLogin: any = Cookies.getJSON('patient_cookie');
			this.setState({ showModal: false, isLogoutConfirm: false, isRegister: false, isLogin: isLogin });
		}
	}
	handleConfirmSignOut = () => {
		Cookies.remove('patient_cookie');
		Cookies.remove('patient_cookie', { domain: apiConfigs.COOKIE_DOMAIN });
		Cookies.remove('patient_cookie', { domain: apiConfigs.PATIENT_COOKIE_DOMAIN });
		logoutUser();
		this.forceUpdate();
		this.setState({ isLogoutConfirm: false, isLogin: '' });
	}
	render() {
		let isLogin: any = Cookies.getJSON('patient_cookie');
		return (
			<header className="fixed-top bg-white main-header py-md-2 screen">
				<div className="container">
					<nav className="navbar navbar-light d-flex">
						<Link className="navbar-brand" to="/">
							<img src={process.env.PUBLIC_URL + "/images/logo-white.svg"} alt="logo" className="logo-w" />
							<img src={process.env.PUBLIC_URL + "/images/logo-black.svg"} alt="logo" className="logo-b" />
						</Link>
						{/* <div id="toggle" className="d-inline-block d-md-none">
							<span className="top"></span>
							<span className="middle"></span>
							<span className="bottom"></span>
						</div> */}
						<div id="overlay">
							<ul className="d-md-flex d-block align-items-center">
								<li className="nav-item d-none">
									<a
										className="nav-link"
										// href="/clinic-registration"
										target="_blank"
										href={apiConfigs.PROVIDER_URL}
									>
										For Providers
									</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href="/about">
										About Us
									</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href="/how-it-works">
										How It Works
									</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href="/new-patients">
										New Patients
									</a>
								</li>
								<li >
								{
									(this.state.isLogin) ?
										<Dropdown>
											<Dropdown.Toggle className="yes_login" id="dropdown-basic">
												{this.state.isLogin.xprUserName}
											</Dropdown.Toggle>

											<Dropdown.Menu>
												<Dropdown.Item onClick={() => { window.open(apiConfigs.PATIENT_URL + 'appointments') }}>Appointments</Dropdown.Item>
												{/* <Dropdown.Item href={apiConfigs.PATIENT_URL + 'referrals'}>Referrals</Dropdown.Item> */}
												{/* <Dropdown.Item href={apiConfigs.PATIENT_URL + 'claim-reward'}>Claim your reward</Dropdown.Item> */}
												<Dropdown.Item onClick={() => { window.open(apiConfigs.PATIENT_URL + 'account') }}>Account</Dropdown.Item>
												{/* <Dropdown.Item href={apiConfigs.PATIENT_URL}>My Coverage</Dropdown.Item> */}
												<Dropdown.Item onClick={(e: any) => {
													this.setState({ isLogoutConfirm: true });
													// Cookies.remove('patient_cookie');
													// Cookies.remove('patient_cookie', { domain: apiConfigs.COOKIE_DOMAIN });
													// logoutUser();
													// this.forceUpdate();
												}}>Sign out</Dropdown.Item>
											</Dropdown.Menu>
										</Dropdown>
										:
										<a className="btn-outline-secondary btn" onClick={(e) => {
											this.setState({ showModal: true });
										}}>Sign In</a>
								}
								</li>
								<li>
								{
								(!this.state.isLogin) &&
								<li className="nav-item">
									<a className="btn-outline-secondary btn" onClick={(e) => {
										this.setState({ isRegister: true });
									}}>Sign up</a>
								</li>
							}

								</li>

							</ul>
						</div>
						{/* <ul className="navbar-nav mr-auto">
							<li className="nav-item">
								<a className="nav-link" href="/clinic-registration">For Dentists</a>
							</li>
							<li className="nav-item">
								<a className="nav-link" href="/about">About Us</a>
							</li>
							<li className="nav-item">
								<a className="nav-link" href="/how-it-works">How It Works</a>
							</li>
							<li className="nav-item">
								<a className="nav-link" href="/new-patients">New Patients</a>
							</li>
						</ul> */}
						{/* <ul className="nav navbar-nav ml-auto"> */}
							{/* <li className="nav-item">
								<a className="nav-link refer" href="/referral">
									Referrals
							<div className="tooltip right">
										<div className="tooltip-arrow"></div>
										<div className="tooltip-inner">Get ${CONSTANT.REWARD}</div>
									</div>
								</a>
							</li> */}
							{/* <li className="nav-item">
					<a className="nav-link" href="#">
						<img src={process.env.PUBLIC_URL + "/images/promo-code.svg"} />
						Promo code?
					</a>
					</li> */}
							
{/* 							
						</ul> */}
					</nav>
				</div>
				<div className="mobile-navigation">
					<div className="logo-sec">
						<Link to="#">
							<img src={process.env.PUBLIC_URL + "/images/logo-black.svg"} alt="logo" />
						</Link>
					</div>
					<a className="target-burger">
						<ul className="buns">
							<li className="bun"></li>
							<li className="bun"></li>
						</ul>
					</a>
					<nav className="mobile-nav">
						<ul className="navbar-nav mr-auto">
							<li className="nav-item">
								<a className="nav-link" href="/clinic-registration">For Dentist</a>
							</li>
							<li className="nav-item">
								<a className="nav-link" href="/about">About Us</a>
							</li>
							<li className="nav-item">
								<a className="nav-link" href="/how-it-works">How It Works</a>
							</li>
							<li className="nav-item">
								<a className="nav-link" href="/new-patients">New Patients</a>
							</li>
							{/* <li className="nav-item">
								<a className="nav-link refer" href="/refferal">
									Referrals
							<div className="tooltip right">
										<div className="tooltip-arrow"></div>
										<div className="tooltip-inner">Get $99</div>
									</div>
								</a>
							</li> */}
							{/* <li className="nav-item">
					<a className="nav-link" href="#">
						<img src={process.env.PUBLIC_URL + "/images/promo-code.svg"} />
						Promo code?
					</a>
					</li> */}
							<li className="nav-item">
								{
									(this.state.isLogin) ?
										<Dropdown className="menuBtnSec">
											<Dropdown.Toggle className="yes_login" id="dropdown-basic">
												{this.state.isLogin.xprUserName}
											</Dropdown.Toggle>

											<Dropdown.Menu>
												<Dropdown.Item href={apiConfigs.PATIENT_URL + 'appointments'}>Appointments</Dropdown.Item>
												<Dropdown.Item href={apiConfigs.PATIENT_URL + 'referrals'}>Referrals</Dropdown.Item>
												<Dropdown.Item href={apiConfigs.PATIENT_URL + 'claim-reward'}>Claim your reward</Dropdown.Item>
												<Dropdown.Item href={apiConfigs.PATIENT_URL + 'account'}>Account</Dropdown.Item>
												<Dropdown.Item href={apiConfigs.PATIENT_URL}>My Coverage</Dropdown.Item>
												<Dropdown.Item onClick={(e: any) => {
													Cookies.remove('patient_cookie');
													Cookies.remove('patient_cookie', { domain: apiConfigs.COOKIE_DOMAIN });
													logoutUser();
													this.forceUpdate();
												}}>Logout</Dropdown.Item>
											</Dropdown.Menu>
										</Dropdown>
										:
										<a className="btn btn-primary" onClick={(e) => {
											this.setState({ showModal: true });
										}}>SIGN IN</a>
								}
							</li>
						</ul>
					</nav>
				</div>
				{
					this.state.showModal &&
					<LoginModal
						showModal={this.state.showModal}
						hideModal={this.hideModal.bind(this)}
						userNotVerified={(id: any, phone: any) => {
							this.setState({ userId: id, phone: phone, showModal: false, showOTPModal: true });
						}}
					></LoginModal>
				}
				{
					this.state.showOTPModal &&
					<OTPModal
						isOTP={this.state.showOTPModal}
						hideModal={() => { this.setState({ showOTPModal: false }) }}
						patientMobile={this.state.phone}
						userId={this.state.userId}
						otpSuccess={(uniqueId: any) => {
							this.setState({ showOTPModal: false })
						}}
					/>
				}
				<Modal className="confirmPopup" show={this.state.isLogoutConfirm} onHide={this.hideModal} backdrop={true} keyboard={true} centered>
					<Modal.Header closeButton>
						<Modal.Title>Are you sure you want to sign out ?</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{/* <div className="hptitle">Are you sure you want to sign out ?</div> */}
						{/* <div className="hp-text">Help us understand your needs so we can find the best dentist for you in your area.</div> */}
						<div className="d-flex justify-content-center btns-box">
							<button onClick={this.handleConfirmSignOut} className="btn btn-primary mx-2">Yes</button>
							<button onClick={this.hideModal} className="btn btn-secondary mx-2">No</button>
						</div>
					</Modal.Body>
				</Modal>
				{
					this.state.isRegister &&
					<RegisterModal
						isRegister={this.state.isRegister}
						hideModal={this.hideModal}
						backToLogin={
							() => {
								this.setState({ isRegister: false, showModal: true });
							}
						}
						loginSuccess={(id: any) => {
							this.setState({ showModal: false, isRegister: false });
						}}
						userNotVerified={(userId: any, mobile?: any, patientUniqueId?: any) => {
							this.setState({ isRegister: false, showOTPModal: true, userId: userId, phone: mobile });
						}}
					/>
				}

			</header>
		);
	}
}
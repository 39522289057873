import {
    LANDING_PAGE, AddLocationAction, AddLocation, AddSpeciality,
    AddSpecialityAction, AddDate, AddDateAction , AddIsInsurance, AddIsInsuranceAction ,
    AddInsuranceProvider , AddInsuranceProviderAction , AddInsurancePlan , AddInsurancePlanAction ,
    AddPaymentMethod , AddPaymentMethodAction , AddSocialAssistanceAction, AddSocialAssistance , AddfilterDataRequest , AddfilterDataAction, AddPrimaryInsurance, AddPrimaryInsuranceAction, AddSecondaryInsurance, AddSecondaryInsuranceAction, AddIsIframe, AddIsIframeAction
} from './types';

export const addLocation = (landingPage: AddLocation): AddLocationAction => {
    return {
        type: LANDING_PAGE.ADD_LOCATION,
        payload: landingPage.location
    };
}
export const addSpeciality = (landingPage: AddSpeciality): AddSpecialityAction => {
    return {
        type: LANDING_PAGE.ADD_SPECIALITY,
        payload: landingPage.speciality
    };
}
export const addDate = (landingPage: AddDate): AddDateAction => {
    return {
        type: LANDING_PAGE.ADD_DATE,
        payload: landingPage.date
    };
}

export const addIsInsurance = (landingPage: AddIsInsurance): AddIsInsuranceAction => {
    return {
        type: LANDING_PAGE.ADD_ISINSURANCE,
        payload: landingPage.isInsurance
    };
}
export const addInsuranceProvider = (landingPage: AddInsuranceProvider): AddInsuranceProviderAction => {
    return {
        type: LANDING_PAGE.ADD_ISURANCE_PROVIDER,
        payload: landingPage.insuranceProvider
    };
}
export const addInsurancePlan = (landingPage: AddInsurancePlan): AddInsurancePlanAction => {
    return {
        type: LANDING_PAGE.ADD_INSURANCE_PLAN,
        payload: landingPage.insurancePlan
    };
}
export const addPaymentMethod = (landingPage: AddPaymentMethod): AddPaymentMethodAction => {
    return {
        type: LANDING_PAGE.ADD_PAYMENT_METHOD,
        payload: landingPage.paymentMethod
    };
}
export const addSocialAssistance = (landingPage: AddSocialAssistance): AddSocialAssistanceAction => {
    return {
        type: LANDING_PAGE.ADD_SOCIAL_ASSISTANCE,
        payload: landingPage.socialAssistance
    };
}
export const addFilterData = (landingPage: AddfilterDataRequest): AddfilterDataAction => {
    return {
        type: LANDING_PAGE.FILTER_DATA,
        payload: landingPage
    };
}
export const addPrimaryInsurance = (landingPage: AddPrimaryInsurance): AddPrimaryInsuranceAction => {
    return {
        type: LANDING_PAGE.ADD_PRIMARY_INSURANCE,
        payload: landingPage
    };
}
export const addSecondaryInsurance = (landingPage: AddSecondaryInsurance): AddSecondaryInsuranceAction => {
    return {
        type: LANDING_PAGE.ADD_SECONDARY_INSURANCE,
        payload: landingPage
    };
}
export const addIsIframe = (landingPage: AddIsIframe): AddIsIframeAction => {
    return {
        type: LANDING_PAGE.ADD_IS_IFRAME,
        payload: landingPage.isIframe
    };
}
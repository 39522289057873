import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "../AppointmentCalender/AppointmentCalender.Module.css";

const MyCalendar = ({
  slots,
  calenderSelectedDate,
  setCalenderSelectedDate,
  handleConsultationTypeChange,
  selectedConsultation,
  selectedSlot,
  setSelectedSlot,
  setSelectedDays,
  setSelectedFlexiSlot,
}: any) => {
  const [highlightedDates, setHighlightedDates] = useState<Set<string>>(
    new Set()
  );
  const [selectedDate, setSelectedDate] = useState<any>(null);
  const [groupedSlots, setGroupedSlots] = useState<any>({});

  const isTileDisabled = ({ date, view }) => {
    if (view === "month") {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return date < today;
    }
    return false;
  };

  const highlightDates = () => {
    // Step 1: Filter slots with availability true
    const availableSlots = slots.filter((slot) => slot.avability === "yes");

    // Step 2: Group filtered slots by startdate
    const startDateGroup = _.groupBy(availableSlots, "startdate");
    console.log("startDateGroup", startDateGroup);

    // Step 3: Extract dates from the grouped data
    const availableDates = Object.keys(startDateGroup);

    // Step 4: Use a Set to avoid duplicate dates and set highlighted dates
    setHighlightedDates(new Set(availableDates));
    setGroupedSlots(_.groupBy(slots, "startdate"));
  };

  const tileClassName = ({ date, view }) => {
    if (view === "month") {
      const dateString = moment(date).format("YYYY-MM-DD");
      const today = moment().startOf("day").format("YYYY-MM-DD");

      // Check if the date is not today and is highlighted
      if (highlightedDates.has(dateString) && dateString !== today) {
        return "highlighted"; // Apply the custom class for highlighting
      }
    }
    return null;
  };

  useEffect(() => {
    highlightDates();
  }, [slots]);

  useEffect(() => {
    console.log("highlightedDates", highlightedDates);
  }, [highlightedDates]);

  useEffect(() => {
    console.log("slot", slots);
    console.log("selectedConsultation", selectedConsultation);
    console.log("calenderSelectedDate", calenderSelectedDate);
  }, [slots, selectedConsultation, calenderSelectedDate]);

  const handleActiveStartDateChange = ({ activeStartDate }) => {
    const startOfMonth = moment(activeStartDate)
      .startOf("month")
      .format("YYYY-MM-DD");
    const endOfMonth = moment(activeStartDate)
      .endOf("month")
      .format("YYYY-MM-DD");

    handleConsultationTypeChange(selectedConsultation, {
      consultationTypeId: selectedConsultation,
      start_date: moment(startOfMonth).format("YYYY-MM-DD"),
      end_date: moment(endOfMonth).add(3, "days").format("YYYY-MM-DD"),
    });
  };

  const handleDateSelection = (date) => {
    const formattedSelectedDate = moment(date).format("YYYY-MM-DD");
    setSelectedDate(formattedSelectedDate);
    console.log("Selected date:", formattedSelectedDate);
    console.log("selected Date Data", groupedSlots[formattedSelectedDate]);
  };

  return (
    <>
      {!selectedDate && (
        <div className="d-flex justify-content-center">
          <div className="shadow-sm p-3 mb-5 rounded w-100">
            <Calendar
              tileDisabled={isTileDisabled}
              tileClassName={tileClassName}
              value={calenderSelectedDate}
              onActiveStartDateChange={handleActiveStartDateChange}
              onChange={handleDateSelection}
            />
          </div>
        </div>
      )}

      {selectedDate && (
        <>
          <ul className="d-flex justify-content-between mb-3">
            <li>
              <button
                className="prev_btn shadow-sm hover-shadow"
                onClick={() => {
                  setSelectedDate(
                    moment(selectedDate)
                      .subtract(1, "day")
                      .format("YYYY-MM-DD")
                      .toString()
                  );

                  const originalDate = moment(selectedDate);
                  const newDate = originalDate.clone().subtract(1, "day");
                  const monthChanged = originalDate.month() !== newDate.month();

                  if (monthChanged) {
                    handleActiveStartDateChange({ activeStartDate: newDate });
                  }
                }}
              >
                Previous
              </button>
            </li>
            <li>
              <button
                className="prev_btn shadow-sm hover-shadow"
                onClick={() => {
                  setSelectedDate(
                    moment(selectedDate)
                      .add(1, "day")
                      .format("YYYY-MM-DD")
                      .toString()
                  );
                  const originalDate = moment(selectedDate);
                  const newDate = originalDate.clone().add(1, "day");
                  const monthChanged = originalDate.month() !== newDate.month();

                  if (monthChanged) {
                    handleActiveStartDateChange({ activeStartDate: newDate });
                  }
                }}
              >
                Next
              </button>
            </li>
          </ul>
          <h6 className="Selected_date text-dark h6 mb-2 mt-4 fs-6">
            {moment(selectedDate).format("MMMM D")}
          </h6>
          {groupedSlots[selectedDate]?.filter(
            (slot) => slot.avability === "yes"
          )?.length > 0 ? (
            <div className="d-grid time-slot">
              {groupedSlots[selectedDate]
                ?.filter((slot) => slot.avability === "yes")
                ?.map((item, index) => {
                  return (
                    <div key={`${item.startdate}-${item.startTime}-${index}`}>
                      <a
                        key={index}
                        href="javascript:void(0);"
                        className={`text-center border rounded p-1 small w-100 ${
                          item.avability === "no"
                            ? "unavailable !important"
                            : "available !important"
                        } ${item.avability === "no" && "disabled !important"} ${
                          selectedSlot?.startdate === item?.startdate &&
                          selectedSlot?.startTime === item?.startTime &&
                          "active text-white"
                        }`}
                        onClick={() => {
                          if (item.avability === "no") return;
                          setSelectedSlot(item);
                          setSelectedDays([]);
                          setSelectedFlexiSlot("");
                        }}
                      >
                        {item.startTime}
                      </a>
                    </div>
                  );
                })}
            </div>
          ) : (
            <div className="d-flex justify-content-center">
              <p>No slots available for the selected date.</p>{" "}
            </div>
          )}
          <div></div>
          <div className="d-flex justify-content-center">
            <button
              className="backto_btn btn py-2 mt-4 shadow-sm hover-shadow"
              onClick={() => {
                setSelectedDate(null);
              }}
            >
              Back to Month
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default MyCalendar;

import React, { useEffect, useState, useRef } from "react";
import { IAppState } from "../../../../store";
import configs from "../../../../configs/apiConfigs";
import { addFilterItems, addLanguage } from "../../../../store/filters/actions";
import { connect, useSelector, useDispatch, shallowEqual } from "react-redux";
import { useHistory } from "react-router-dom";
import { prepareAddress } from "../../../../utils/googleFunction";
import {
  fetchLoadingPending,
  fetchLoadingSuccess,
} from "../../../../store/loadingIndicator/actions";
import { searchFilter } from "../../../../store/v2/searchFilter/actions";
import { addClinicProviderList } from "../../../../store/clinicAndProviderList/actions";
import { addSlotData } from "../../../../store/slot/actions";
import { clinicAndProviderList } from "../../../../services/landingPageSearch";
import {
  patientblocksearch,
  appointmentSlot,
} from "../../../../services/landingPageSearch";
import * as _ from "lodash";
import moment from "moment";
import { toast } from "react-toastify";
import { fetchClinicList } from "../../services/AppointmentBook";
function Map(props: any) {
  let googleMapsPromise: any;
  let googleMap: any;
  let maps: any = "";
  let marker: any;
  let storeMarker: any = [];
  let autocomplete: any;
  const [location, setLocation] = useState("" as any);
  const [selectedMap, setSelectedMap] = useState("" as any);
  const [map, setMap] = useState("" as any);
  const dispatch = useDispatch();
  const prevMarkersRef: any = useRef([]);
  const [iFram, setiFram] = useState(false);
  const history: any = useHistory();
  const autcompleteRef: any = useRef();
  const [place, setPlace] = useState(undefined as any);
  const [currentMarker, setCurrentMarker]: any = useState([]);
  const searchFilterData = useSelector(
    (state: IAppState) => state.searchFilter.searchFilterData,
    shallowEqual
  );
  const clinicProviderList: any = useSelector(
    (state: IAppState | any) => state.clinicProviderList.clinicProviderList
  );
  const [apiAppointmentSlotRequest, setAppointmentSlotRequest]: any =
    useState();
  const [apiClinicProviderRequest, setApiClinicProviderRequest]: any =
    useState();
  const [totalCountProvider, setTotalCountProvider] = useState(0);
  const [apiClinicListRequest, setApiClinicListRequest]: any = useState();
  const [totalCount, setTotalCount] = useState(0);
  let selectedTabRef = useRef(props.selectedTab);
  useEffect(() => {
    if (props.selectedTab !== selectedTabRef.current) {
      selectedTabRef.current = props.selectedTab;
    }
  }, [props.selectedTab]);
  useEffect(() => {
    // getGoogleMaps();
    if (new URLSearchParams(history.location.search).get("iframe") === "yes") {
      setiFram(true);
    }
    // from clinic or provider profile
    if (
      history &&
      history.location &&
      history.location.state &&
      history.location.state.iFrame
    ) {
      setiFram(true);
    }
    getGoogleMaps().then(async (google: any) => {
      const center = { lat: 43.3255196, lng: -79.7990319 };
      setMap(
        new google.maps.Map(document.getElementById("gmap_canvas"), {
          zoom: 10,
          center: center,
          streetViewControl: !1,
          scrollwheel: !1,
          draggable: 1,
          disableDoubleClickZoom: !0,
          disableDefaultUI: !0,
          gestureHandling: "cooperative",
          zoomControl: true,
          maxZoom: 15,
          minZoom: 3,
          name: "xcare",
          styles: [
            {
              featureType: "poi",
              elementType: "labels",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "transit.station",
              elementType: "labels",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
          ],
          //@ts-ignore
          mapTypeId: google.maps.MapTypeId.ROADMAP,
        })
      );
      googleMap = map;
      setSelectedMap(google);
      maps = await google;
    });
  }, []);
  useEffect(() => {
    if (searchFilterData.location) {
      // let oldMarkers: any = prevMarkersRef.current;
      // if (oldMarkers && oldMarkers.length > 0) {
      //     for (let m of oldMarkers) {
      //         m.setMap(null);
      //     }
      // }
      if (currentMarker && currentMarker.length > 0) {
        for (let m of currentMarker) {
          m.setMap(null);
        }
        setCurrentMarker([]);
      }
      if (storeMarker && storeMarker.length > 0) {
        for (let m of storeMarker) {
          m.setMap(null);
        }
      }
      if (
        selectedMap &&
        searchFilterData.location.latitude &&
        searchFilterData.location.longitude
      ) {
        const center = {
          lat: searchFilterData.location.latitude,
          lng: searchFilterData.location.longitude,
        };
        //@ts-ignore
        marker = new selectedMap.maps.Marker({
          position: {
            lat: searchFilterData.location.latitude,
            lng: searchFilterData.location.longitude,
          },
          map: map,
          // title: 'You',
          icon: "http://maps.google.com/mapfiles/ms/icons/green-dot.png",
        });
        map.setCenter(center);
        // marker.setMap(map);
        setCurrentMarker([...currentMarker, marker]);
        storeMarker.push(marker);
        // currentMarker.push(marker);
      }

      setPlace(searchFilterData.location.formatted_address);
    } else {
      setPlace("");
    }
  }, [searchFilterData.location, selectedMap]);
  useEffect(() => {
    if (
      location &&
      location.latiude !== searchFilterData.location.latitude &&
      location.longitude !== searchFilterData.location.longitude
    ) {
      if (storeMarker && storeMarker.length > 0) {
        for (let m of storeMarker) {
          m.setMap(null);
        }
      }
      dispatch(
        searchFilter({
          ...searchFilterData,
          location: location,
          clinicId: "",
          providerId: "",
        })
      );
      // API CaLl
      if (selectedTabRef.current === "provider") {
        getClinicProviderList(location);
      } else if (selectedTabRef.current === "clinic") {
        fetchClinicListApi(location);
      }
      //  dispatch(addFilterItems({
      //     ...filter,
      //      location: location,
      //      selectedClinic: '',
      //      clinicId:''
      //     // when map drag previous clinic unselecte so show new clinic list
      //  }));
      // dispatch(fetchLoadingPending());
    }
  }, [location]);
  useEffect(() => {
    let locations: any = "";
    if (searchFilterData.location !== "") {
      setLocation(searchFilterData.location);
      locations = searchFilterData.location;
    }
    if (locations !== "" && locations.latitude && locations.longitude) {
      if (selectedMap !== "") {
        const center = { lat: locations.latitude, lng: locations.longitude };
        marker = new selectedMap.maps.Marker({
          position: { lat: locations.latitude, lng: locations.longitude },
          map: map,
          // title: 'You',
          icon: "http://maps.google.com/mapfiles/ms/icons/green-dot.png",
        });
        map.setCenter(center);
        // marker.setMap(map);
        setCurrentMarker([...currentMarker, marker]);
        storeMarker.push(marker);
        // currentMarker.push(marker);
        //@ts-ignore
        google.maps.event.addListener(map, "dragend", () => {
          let oldMarkers: any = prevMarkersRef.current;
          if (oldMarkers && oldMarkers.length > 0) {
            for (let m of oldMarkers) {
              m.setMap(null);
            }
          }
          if (storeMarker && storeMarker.length > 0) {
            for (let m of storeMarker) {
              m.setMap(null);
            }
            storeMarker = [];
          }
          let latLang = map.getCenter();
          //@ts-ignore
          // var geocoder = new google.maps.Geocoder;
          // geocoder.geocode({'location': center}, function(results:any, status:any) {
          // if (status === 'OK') {
          //     if (results[0]) {
          //         let value = results[0].formatted_address;
          //         console.log('value', value);
          //     } else {
          //     window.alert('No results found');
          //     }
          // } else {
          //     window.alert('Geocoder failed due to: ' + status);
          // }
          // });
          setLocation({ latitude: latLang.lat(), longitude: latLang.lng() });
        });
      }
    }
  }, [selectedMap, map]);

  useEffect(() => {
    if (selectedMap && map) {
      //@ts-ignore
      google.maps.event.addListener(map, "dragend", () => {
        console.log("dragged map");
        // Clear previous markers
        prevMarkersRef.current.forEach((m: any) => m.setMap(null));
        prevMarkersRef.current = [];

        // Fetch new data based on the new map center
        const center = map.getCenter();
        const newLocation = {
          latitude: center.lat(),
          longitude: center.lng(),
        };
        setLocation(newLocation);

        // Dispatch searchFilter action to update data based on new location
        dispatch(
          searchFilter({
            ...searchFilterData,
            location: newLocation,
            clinicId: "",
            providerId: "",
          })
        );

        // Fetch and update new markers
        if (selectedTabRef.current === "provider") {
          getClinicProviderList(newLocation);
        } else if (selectedTabRef.current === "clinic") {
          fetchClinicListApi(newLocation);
        }
      });
    }
  }, [selectedMap, map, searchFilterData]);

  useEffect(() => {
    if (selectedMap) {
      try {
        //@ts-ignore
        autocomplete = new window.google.maps.places.Autocomplete(
          autcompleteRef.current,
          {
            types: ["(regions)"],
          }
        );
        autocomplete.addListener("place_changed", () => {
          let placeChnage = autocomplete.getPlace();
          placeChnage = prepareAddress(placeChnage);
          if (JSON.stringify(place) !== JSON.stringify(placeChnage)) {
            setPlace(placeChnage.formatted_address);
            dispatch(
              searchFilter({
                ...searchFilterData,
                location: placeChnage,
                clinicId: "",
                providerId: "",
              })
            );
            let location = {
              latitude: placeChnage.latitude,
              longitude: placeChnage.longitude,
            };
            if (selectedTabRef.current === "provider") {
              getClinicProviderList(location);
            } else if (selectedTabRef.current === "clinic") {
              fetchClinicListApi(location);
            }
          }
        });
      } catch (err) {
        console.log(err);
      }
    }
  }, [
    selectedMap,
    searchFilterData.date,
    searchFilterData.newSpeciality,
    searchFilterData.languages,
    searchFilterData.gender,
    searchFilterData.distance,
    searchFilterData.clinicId,
    searchFilterData.patientUniqueId,
  ]);

  const fetchClinicListApi = (loc: any) => {
    const {
      oldSpeciality,
      newSpeciality,
      date,
      howToPay,
      gender,
      distance,
      languages,
      clinicId,
      patientUniqueId,
      clinicPage,
      results,
    } = searchFilterData;
    let latitude: any = "",
      longitude: any = "";
    if (loc !== "") {
      latitude = loc.latitude;
      longitude = loc.longitude;
    }
    let langIds: any = [];
    if (languages && languages.length > 0) {
      langIds = languages.map((item: any) => {
        return item.value;
      });
    }
    let request: any = {};
    request = {
      location: {
        latitude: latitude,
        longitude: longitude,
        specialityId: newSpeciality,
        searchdate: date !== null ? moment(date).format("YYYY-MM-DD") : "",
        radiuskm: distance,
        insuranceProviderId:
          howToPay.type === "insurance" ? howToPay.value : "",
        acceptAssignment: howToPay.type === "cash" ? "no" : "",
        socialAssistTypeID:
          howToPay.type === "socialAssistance" ? howToPay.value : "",
        clinicId: "",
        patientUniqueId: patientUniqueId,
        page: 1,
        results: 25,
      },
    };
    let isApiCall: boolean = false;
    if (JSON.stringify(apiClinicListRequest) !== JSON.stringify(request)) {
      setApiClinicListRequest(request);
      isApiCall = true;
    }
    if (isApiCall) {
      dispatch(fetchLoadingPending());
      fetchClinicList(request)
        .then((success) => {
          dispatch(fetchLoadingSuccess(false));
          if (success && success.status && success.status.error === false) {
            if (success && success.total) {
              setTotalCount(success.total);
            }
            dispatch(
              addClinicProviderList({
                ...clinicProviderList,
                clinicList: _.uniqBy(success.clinic, "id"),
                clinicTotalCount: success.total ? success.total : totalCount,
              })
            );
          }
        })
        .catch((err) => {
          dispatch(fetchLoadingSuccess(false));
          dispatch(
            addClinicProviderList({
              ...clinicProviderList,
              clinicList: [],
              clinicTotalCount: 0,
            })
          );
          dispatch(
            searchFilter({
              ...searchFilterData,
              clinicPage: 1,
            })
          );
          console.log(err);
        });
    }
  };
  useEffect(() => {
    let oldMarkers: any = prevMarkersRef.current;
    if (oldMarkers && oldMarkers.length > 0) {
      for (let m of oldMarkers) {
        m.setMap(null);
      }
    }
    if (map !== "") {
      placeClinicMarker(clinicProviderList.cliniclist);
    }
  }, [clinicProviderList.cliniclist]);

  const placeClinicMarker = (clinic: any) => {
    if (clinic && clinic.length > 0) {
      clinic.forEach((element: any, index: number) => {
        if (element && element.latitude && element.longitude) {
          //@ts-ignore
          var cMarker = new google.maps.LatLng(
            element.latitude,
            element.longitude
          );
          putMarkerOnMap(cMarker, element);
          if (
            index === 0 &&
            map !== "" &&
            element.latitude &&
            element.longitude
          ) {
            let center = {
              lat: parseFloat(element.latitude),
              lng: parseFloat(element.longitude),
            };
            map.setCenter(center);
          }
        }
      });
    }
  };
  const putMarkerOnMap = (latLng: any, value: any) => {
    // var image = 'http://maps.google.com/mapfiles/ms/icons/red-dot.png';
    //@ts-ignore
    var mark: any = new google.maps.Marker({
      position: latLng,
      // icon: image
    });
    let contentString: any;
    console.log("value", value);
    if (
      value.doctors &&
      value.doctors.length > 0 &&
      value.doctors[0] &&
      value.doctors[0].photo
    ) {
      contentString = contentString =
        '<div className="info_custom" style="width:200px"><div className="info_img">' +
        '<IMG BORDER="0" style="border-radius:0%; width:100%;" SRC="' +
        configs.IMAGE_URL +
        "doctor/" +
        value.doctors[0].photo.name +
        "-200x200." +
        value.doctors[0].photo.type +
        '"> </div>' +
        '<div className="info_cnt" style="float:left; width:100%; font-size:14px; font-weight:700; margin-top:10px;margin-bottom:5px; display:flex;justify-content: space-between; text-align:left;">' +
        value.name +
        '<div className="info_rating" style="float: right; width:48%; text-align:right; font-size:14px; font-weight:400;"><i class="bi bi-star-fill text-danger"></i>' +
        (value.rating ? value.rating : "") +
        " " +
        (value.reviewCount && value.reviewCount !== ""
          ? "(" + value.reviewCount + ")"
          : "") +
        `</div></div><div className="info_adr" style="color:#000; text-align:left;">${value?.address_level_1} ${value?.country}</div></div>`;
    } else {
      contentString = value.name;
    }
    //@ts-ignore
    var infowindow = new google.maps.InfoWindow({
      content: contentString,
    });
    //start route on map.
    mark.addListener("mouseover", () => {
      // this.drawRouteOnHover(value);
      infowindow.open(maps, mark);
    });

    // remove route on map.
    mark.addListener("mouseout", () => {
      // this.currentDirectionRenderer.setMap(null);
      infowindow.close();
    });
    mark.addListener("click", () => {
      console.log("Marker clicked");

      history.push({
        pathname:
          "/" +
          value.locality +
          "/" +
          value.doctors[0].fullname
            .trim()
            .toLowerCase()
            .replace(/[,._+]/g, "")
            .replace(/\s+/g, "-") +
          "/" +
          value.doctors[0].provideruniqueid +
          "/d",
        state: {
          isInternal: true,
        },
      });
    });
    mark.setMap(map);
    // storeMarker.push(mark);
    prevMarkersRef.current.push(mark);
  };

  const getClinicProviderList = (location?: any) => {
    const {
      newSpeciality,
      date,
      howToPay,
      patientUniqueId,
      page,
      results,
      languages,
      distance,
      gender,
      clinicId,
      providerId,
    } = searchFilterData;
    let latitude: any = "",
      longitude: any = "",
      langIds: any = [],
      clinic: any = "";
    if (location !== "") {
      latitude = location.latitude;
      longitude = location.longitude;
    }
    if (languages && languages.length > 0) {
      langIds = languages.map((item: any) => {
        return item.value;
      });
    }
    let request = {
      location: {
        latitude: latitude,
        longitude: longitude,
        specialityId: newSpeciality,
        searchdate: date !== null ? date : "",
        radiuskm: distance,
        gender: gender ? gender.toString() : "",
        languages: langIds.toString(),
        insuranceProviderId:
          howToPay.type === "insurance" ? howToPay.value : "",
        acceptAssignment: howToPay.type === "cash" ? "no" : "",
        socialAssistTypeID:
          howToPay.type === "socialAssistance" ? howToPay.value : "",
        clinicId: "",
        providerId: "",
        patientUniqueId: patientUniqueId,
        page: 1,
        results: results,
      },
    };
    let isApiCall: boolean = false;
    if (JSON.stringify(apiClinicProviderRequest) !== JSON.stringify(request)) {
      setApiClinicProviderRequest(request);
      isApiCall = true;
    }
    if (isApiCall) {
      dispatch(fetchLoadingPending());
      try {
        clinicAndProviderList(request).then((success: any) => {
          if (
            success &&
            success.response &&
            success.response.data &&
            success.response.status === 200
          ) {
            if (
              success.response.data &&
              success.response.data.status.error === false
            ) {
              setTimeout(() => {
                dispatch(fetchLoadingSuccess(false));
              }, 2000);
              if (success.response.data && success.response.data.total) {
                setTotalCountProvider(success.response.data.total);
              }
              dispatch(
                addClinicProviderList({
                  ...clinicProviderList,
                  clinicList: _.uniqBy(success.response.data.clinic, "id"),
                  providerList: success.response.data.doctors,
                  providerTotalCount: success.response.data.total
                    ? success.response.data.total
                    : totalCountProvider,
                })
              );
              // appointmentSlotApi(success.response.data.doctors);
            } else if (
              success.response.data &&
              success.response.data.status.error === true
            ) {
              dispatch(
                addClinicProviderList({
                  ...clinicProviderList,
                  clinicList: [],
                  providerList: [],
                  providerTotalCount: 0,
                  page: 1,
                })
              );
              console.log("error");
              dispatch(fetchLoadingSuccess(false));
            }
          }
        });
      } catch (error) {
        toast.error("Something went wrong.");
        console.log("error", error);
        dispatch(fetchLoadingSuccess(false));
      }
    } else {
      // dispatch(fetchLoadingSuccess(false));
    }
  };

  const appointmentSlotApi = (providerList?: any, currentWeek?: any) => {
    var request: any = [];
    let startDate: any = searchFilterData.date
      ? searchFilterData.date
      : moment().format("YYYY-MM-DD");
    let endDate: any = searchFilterData.date
      ? moment(searchFilterData.date).add("days", 4).format("YYYY-MM-DD")
      : moment().add("days", 4).format("YYYY-MM-DD");
    if (providerList.length > 0) {
      providerList.map((item: any) => {
        if (item.sharecalendar && item.sharecalendar.calender) {
          request.push({
            doctorId: item.id,
            clinicId: item.clinic_id,
            start_date: startDate,
            end_date: endDate,
          });
        }
      });
      if (request.length > 0) {
        let data = { appointmentData: request };
        let isApiCall: boolean = false;
        if (
          JSON.stringify(apiAppointmentSlotRequest) !== JSON.stringify(data)
        ) {
          setAppointmentSlotRequest(data);
          isApiCall = true;
        }
        if (isApiCall) {
          dispatch(fetchLoadingPending());
          appointmentSlot(data).then((success: any) => {
            try {
              if (
                success &&
                success.response &&
                success.response.data &&
                success.response.status === 200
              ) {
                if (
                  success.response.data &&
                  success.response.data.status.error === false
                ) {
                  dispatch(fetchLoadingSuccess(false));
                  var result = groupBy(
                    success.response.data.appointments,
                    function (item: any) {
                      return [item.doctorid, item.clinicid];
                    }
                  );
                  let data: any = [];
                  result.map((item: any) => {
                    let groupData = _.groupBy(item, "startdate");
                    data.push(groupData);
                  });
                  // setGroupByDate(data);
                  dispatch(addSlotData({ slot: data }));
                  // this.setState({ appointmentSlotData: result,isAppointmentBook:false,isLoading:false });
                } else {
                  if (
                    success.response.data &&
                    success.response.data.status.error === true
                  ) {
                    toast.error(success.response.data.status.msg);
                  }
                  dispatch(fetchLoadingSuccess(false));
                }
              }
            } catch (error) {
              toast.error("Something went wrong.");
              console.log("error", error);
              dispatch(fetchLoadingSuccess(false));
            }
          });
        } else {
          dispatch(fetchLoadingSuccess(false));
        }
      }
    }
  };
  const groupBy = (array: any, f: any) => {
    var groups: any = {};
    array.forEach(function (o: any) {
      var group = JSON.stringify(f(o));
      groups[group] = groups[group] || [];
      groups[group].push(o);
    });
    return Object.keys(groups).map(function (group) {
      return groups[group];
    });
  };

  const getGoogleMaps = async () => {
    // If we haven't already defined the promise, define it
    if (!googleMapsPromise) {
      googleMapsPromise = await new Promise((resolve) => {
        // Add a global handler for when the API finishes loading
        //@ts-ignore
        window.resolveGoogleMapsPromise = () => {
          // Resolve the promise
          //@ts-ignore
          resolve(google);

          // Tidy up
          //@ts-ignore
          delete window.resolveGoogleMapsPromise;
        };

        // Load the Google Maps API
        const script = document.createElement("script");
        const API = "AIzaSyDIDMklRxHmFYWU8Vvo1P-dVkB_nbHARj8";
        script.src = `https://maps.googleapis.com/maps/api/js?key=${API}&libraries=places&callback=resolveGoogleMapsPromise`;
        script.async = true;
        document.body.appendChild(script);
      });
    }

    // Return a promise for the Google Maps API
    return googleMapsPromise;
  };
  return (
    <div className="map-view">
      <div className="filterSec">
        <div className="form-check">
          <input
            type="text"
            value={place}
            id="autocomplete"
            ref={autcompleteRef}
            placeholder="City or postal code"
            className="form-control"
            onChange={(e) => {
              setPlace(e.target.value);
            }}
          />
        </div>
      </div>
      <div id="gmap_canvas"></div>
    </div>
  );
}

export default Map;

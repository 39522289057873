export const FEATURES = [
    { id: 2, name: 'Massage chair', src: '/images/pre-massage-chair.svg' },
    { id: 3, name: 'Noise cancelling headphones', src: '/images/pre-noise-cancelling-headphones.svg' },
    { id: 4, name: 'Wifi', src: '/images/pre-wifi.svg' },
    { id: 5, name: 'Aromatherapy', src: '/images/pre-aromatherapy.svg' },
    { id: 6, name: 'Hot towel service', src: '/images/pre-hot-towel-service.svg' },
    { id: 7, name: 'Public transit accessible', src: '/images/pre-public-transit-accessible.svg' },
    { id: 8, name: 'Sedation & moderate IV sedation facility', src: '/images/pre-sedation-moderate-iv-sedation-facility.svg' },
    { id: 9, name: 'Laser dentistry', src: '/images/pre-laser-dentistry.svg' },
    { id: 11, name: 'Digital X-rays', src: '/images/pre-digital-x-rays.svg' },
    { id: 12, name: 'Intra-oral cameras', src: '/images/pre-intra-oral-cameras.svg' },
    { id: 14, name: 'Mounted TVs in ops', src: '/images/pre-mounted-tvs-in-ops.svg' },
    { id: 15, name: 'Complimentary coffee/tea', src: '/images/pre-complimentary-coffee-tea.svg' },
    { id: 16, name: 'Free Uber/Lyft rides', src: '/images/pre-free-uber-lyft-rides.svg' },
    { id: 17, name: 'Kids friendly', src: '/images/pre-kids-friendly.svg' },
    { id: 18, name: 'Flexible hours', src: '/images/pre-flexible-hours.svg' },
    { id: 19, name: 'Free parking', src: '/images/pre-free-parking.svg' },
    { id: 20, name: 'Dental anxiety friendly', src: '/images/pre-free-parking.svg' },
    { id: 21, name: 'Financing plans available', src: '/images/pre-financing-plans-available.svg' },
    { id: 23, name: 'All Insurances accepted', src: '/images/pre-all-insurances-accepted.svg' },
    { id: 24, name: 'Handicap accessible', src: '/images/pre-handicap-accessible.svg' },
    { id: 25, name: '100% bacteria free air', src: '/images/pre-bacteria-free-air.svg' }
]
export const QUIZ = {
    attitude: [{
        value: "Committed",
        label: "I proactively take care of my mouth.",
        link: "/quiz/reason",
        img: "images/committed.svg"
    }, {
        value: "Neutral",
        label: "It's a habit I maintain.",
        link: "/quiz/reason",
        img: "images/neutral.svg"
    }, {
        value: "Uninspired",
        label: "I have some work to do.",
        link: "/quiz/reason",
        img: "images/uninspired.svg"
    }],
    dentistReasons: [{
        link: "/quiz/criteria", //quiz/goals
        value: "Checkup & Cleaning",
        label: "Routine visit",
        img: "images/toothbrush.svg"
    }, {
        link: "/quiz/dentist-services",
        value: "Specific Treatment",
        label: "E.g. Crown, root canal",
        img: "images/guy-in-dentist-chair.svg"
    }, {
        link: "/quiz/dentist-urgent",
        value: "Urgent Issue",
        label: "E.g. Damaged tooth, pain",
        img: "images/urgent.svg"
    }],
    goals: [{
        value: "Cleaner mouth",
        selected: false,
        img: "images/cleaner-mouth.svg"
    }, {
        value: "Whiter teeth",
        selected: false,
        img: "images/whiter-teeth.svg"
    }, {
        value: "Fresher breath",
        selected: false,
        img: "images/fresh-breath.svg"
    }, {
        value: "Cavity-free teeth",
        selected: false,
        img: "images/cavity-free.svg"
    }, {
        value: "Healthier gums",
        selected: false,
        img: "images/healthy-gums.svg"
    }, {
        value: "Better smile",
        selected: false,
        img: "images/better-smile.svg"
    }],
    /* services: [{
        value: "Braces",
        selected: false,
        label: "Orthodontic treatment uses braces, Invisalign, or other appliances to put gentle pressure on your teeth and eventually move them into the right position."
    }, {
        value: "Bridges / Dentures",
        selected: false,
        label: "Bridges and dentures are used to replace missing or badly damaged teeth."
    },
    {
        value: "Crown / Cap",
        selected: false,
        label: "A crown (also called a cap) is used to reinforce the damaged part of your tooth protecting it from further damage."
    }, {
        value: "Filling",
        selected: false,
        label: "Dental fillings use restorative materials to repair teeth that have been compromised due to cavities or trauma."
    }, {
        value: "Implants",
        selected: false,
        label: "Dental implants are used to replace missing roots and support replacement teeth."
    }, {
        value: "Nitrous Sedation",
        selected: false,
        label: "Sedation dentistry uses medication to help patients relax during dental procedures."
    }, {
        value: "Root Canal",
        selected: false,
        label: "Root canals treat diseases or abscessed teeth. Once a tooth is injured, cracked, or decayed, it is necessary to open the tooth and clean out the infected tissue in the centre. This space is then filled and the opening sealed."
    }, {
        value: "Tooth Extraction",
        selected: false,
        label: "A severely damaged tooth may need to be extracted."
    }, {
        value: "Veneers",
        selected: false,
        label: "Veneers and bonding make your teeth look better by changing their colour, shape, or spacing."
    }, {
        value: "Whitenings",
        selected: false,
        label: "Tooth whitening lightens teeth and helps to remove stains and discolouration."
    }, {
        value: "Wisdom Teeth Removal",
        selected: false,
        label: "When the jaw isn't large enough to accommodate wisdom teeth, they can become impacted. Wisdom teeth may grow sideways, emerge only part way from the gum, or remain trapped beneath the gum and bone."
    }, {
        value: "Other",
        selected: false,
        label: "Dental fillings use restorative materials to repair teeth that have been compromised due to cavities or trauma."
    }], */
    services: [
        {
            "id": "1",
            "name": "Bonding",
            "value": "$$"
        },
        {
            "id": "2",
            "name": "Braces/Invisalign",
            "value": "$$$"
        },
        {
            "id": "3",
            "name": "Bridges / Dentures",
            "value": "$$$"
        },
        {
            "id": "5",
            "name": "Crown / Cap",
            "value": "$$"
        },
        {
            "id": "6",
            "name": "Filling",
            "value": "$$"
        },
        {
            "id": "7",
            "name": "Gum Surgery",
            "value": "$$$"
        },
        {
            "id": "8",
            "name": "Implants",
            "value": "$$$"
        },
        {
            "id": "9",
            "name": "Nitrous Sedation",
            "value": "$"
        },
        {
            "id": "10",
            "name": "IV sedation",
            "value": "$$"
        },
        {
            "id": "11",
            "name": "Root Canal",
            "value": "$$"
        },
        {
            "id": "12",
            "name": "Sleep apnea",
            "value": "$$"
        },
        {
            "id": "13",
            "name": "TMJ",
            "value": "$"
        },
        {
            "id": "14",
            "name": "Veneers",
            "value": "$$$"
        },
        {
            "id": "15",
            "name": "Whitening",
            "value": "$"
        },
        {
            "id": "16",
            "name": "Wisdom Teeth Removal",
            "value": "$$$"
        },
        {
            "id": "17",
            "name": "Kids dentistry",
            "value": "$$"
        }, {
            "id": "18",
            name: "Other",
            value: ""
        }
    ],
    issues: [{
        id: "pain",
        value: "Pain",
        selected: false,
        img: "images/tooth-pain.svg",
        descriptionTitle: "Please describe the pain you’re experiencing",
        descriptionPlaceholder: "Example: My bottom back right molar is giving me a sharp pain in my gums."
    }, {
        id: "damage",
        value: "Damaged tooth",
        selected: false,
        img: "images/tooth-damage.svg",
        descriptionTitle: "Please describe the damage to the tooth",
        descriptionPlaceholder: "Example: I chipped my bottom front tooth on the left side of my mouth."
    }, {
        id: "other",
        value: "Other",
        selected: false,
        img: "images/other.svg",
        descriptionTitle: "Please describe your issue",
        descriptionPlaceholder: ""
    }],
    criterias: [{
        value: "Modern spa-like practice",
        selected: false,
        img: "images/modern-facilities.svg"
    }, {
        value: "Fits my schedule",
        selected: false,
        img: "images/schedule.svg"
    }, {
        value: "A high patient rating",
        selected: false,
        img: "images/high-rating.svg"
    }, {
        value: "Accepts my insurance",
        selected: false,
        img: "images/insurance.svg"
    }, {
        value: "A location close by",
        selected: false,
        img: "images/nearby.svg"
    }, {
        value: "Highly experienced",
        selected: false,
        img: "images/experienced.svg"
    }],
    lastVisited: [{
        value: "< 1",
        label: "year",
        link: "/quiz/dental-anxiety"
    }, {
        value: "1 - 2",
        label: "years",
        link: "/quiz/dental-anxiety"
    }, {
        value: "2+",
        label: "years",
        link: "/quiz/dental-anxiety"
    }, {
        value: 'No',
        label: "I've never visited",
        link: "/quiz/dental-anxiety"
    }],
    dentalAnxiety: [{
        value: "Not at all",
        label: "nervous",
        link: "/quiz/availability"
    }, {
        value: "A little",
        label: "nervous",
        link: "/quiz/availability"
    }, {
        value: "Moderately",
        label: "nervous",
        link: "/quiz/availability"
    }, {
        value: 'Very',
        label: "nervous",
        link: "/quiz/availability"
    }, {
        value: "Extremely",
        label: "nervous",
        link: "/quiz/availability"
    }],
    availabilities: [{
        time: "Before 9am",
        img: "images/early.svg",
        selected: false,
        value: "Early"
    }, {
        time: "9am - 12pm",
        img: "images/morning.svg",
        selected: false,
        value: "Morning"
    },/*  {
        time: "12pm - 2pm",
        img: "images/noon.svg",
        selected: false,
        value: "noon"
    }, */ {
        time: "12 - 4pm",
        img: "images/afternoon.svg",
        selected: false,
        value: "afternoon"
    }, {
        time: "After 4pm",
        img: "images/evening.svg",
        value: "evening"
    }, {
        time: "Sat - Sun",
        img: "images/weekends.svg",
        selected: false,
        value: "weekend"
    }],
    howSoon: [{
        value: "As soon as possible",
        link: "/quiz/insurance"
    }, {
        value: "Within a week",
        link: "/quiz/insurance"
    }, {
        value: "Within 2 weeks",
        link: "/quiz/insurance"
    }, {
        value: "In more than 2 weeks",
        link: "/quiz/insurance"
    }, {
        value: "No preferance",
        link: "/quiz/insurance"
    }],
    isInsured: [{
        value: "Yes, I have insurance",
        img: "images/icon-check.svg",
        link: "/quiz/insurance-details"
    }, {
        value: "No, I don't have insurance",
        img: "images/icon-none.svg",
        link: "/quiz/social-assistance"
    }],
    socialAssistance: [{
        value: "Yes",
        label: "I’ll use a social assistance program"
    }, {
        value: "No",
        label: "I won't use a social assistance program"
    }],
    gender: [{
        value: "I prefer a male",
        label: "male",
        link: '/quiz/ready-to-book',
        img: "images/male-doctor.svg"
    }, {
        value: "I prefer a female",
        label: "female",
        link: '/quiz/ready-to-book',
        img: "images/female-doctor.svg"
    }, {
        value: "Not important to me",
        label: "null",
        link: '/quiz/ready-to-book',
        img: "images/icon-none.svg"
    }],
    providers: [{
        name: "Manulife Financial",
        img: "images/provider-manulife.svg",
        link: "/quiz/ready-to-book" //quiz/gender
    }, {
        name: "Sun Life Financial",
        img: "images/provider-sunlife.svg",
        link: "/quiz/ready-to-book"
    }, {
        name: "Great-West Life",
        img: "images/provider-greatwest.svg",
        link: "/quiz/ready-to-book"
    }, {
        name: "Green Shield Canada",
        img: "images/provider-greenshield.svg",
        link: "/quiz/ready-to-book"
    }, {
        name: "Blue Cross",
        img: "images/provider-bluecross.svg",
        link: "/quiz/ready-to-book"
    }, {
        name: "Desjardins Group",
        img: "images/provider-desjardins.svg",
        link: "/quiz/ready-to-book"
    }],
    providerOptions: [{
        value: "I am unsure",
        img: "images/insurance.svg",
        link: "/quiz/social-assistance"
    }, {
        value: "Other provider",
        img: "images/insurance.svg",
        link: "/quiz/gender"
    }],
    otherPrvoviders: ["Benecaid", "Chambers of Commerce Group Insurance Plan", "ClaimSecure", "Cowan Insurance Group", "Empire Life", "Encon", "Equitable Life of Canada", "Global Benefits", "GroupSource", "Guard.me", "HealthSource Plus", "Industrial Alliance", "Johnston Group", "League", "MDM Insurance Services", "Manion Wilkins & Associates", "Morcare", "University / College / Student Plan", "NexgenRx", "OTIP - Ontario Teachers Insurance Plan", "RBC Insurance", "RWAM", "SSQ Financial Group", "Standard Life", "The Benefits Trust", "The Co - operators"],
    isReady: [{
        link: "/quiz/email",
        value: "Yes, I'm ready",
        label: "Yes",
        img: "images/yes.svg"
    }, {
        link: "/quiz/not-ready-reason",
        value: "No, I'm not ready",
        label: "No",
        img: "images/no.svg"
    }],
    readyOptions: ["I need to check my insurance or wait for it to kick in", "I'm busy and don't have time", "I need to do more research first", "I need to check with my partner first", "I'm not looking for a dentist right now", "Other"],
}
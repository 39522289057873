import React,{useEffect,useState} from 'react';
import { Modal } from 'react-bootstrap';
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import moment from 'moment';
import {allowOnlyAlpha,emailValidation,passwordValidator, takeOnlyDigitAndPlus,allowOnlyDigit} from '../../utils/validators';
import { toast } from 'react-toastify';
import { getInsuranceProviderNew, getInsurancePlan } from '../../services/landingPageSearch';

function GuarantorInfoModal(props:any) {
    let msg:any=''
    const [state, setState] = useState({
        gurantorFormData: {
            email: '',
            firstName: '',
            lastName: '',
            phone: '',
            dob: moment().subtract(1, 'days'),
            gender:'Male',
            emailValidation: { valid: false, msg: '' },
            firstNameValidation: { valid: false, msg: '' },
            lastNameValidation: { valid: false, msg: '' },
            dobValidation: { valid: false, msg: '' },
            phoneValidation: { valid: false, msg: '' },
        },
        isLoading:false,loadingVerification: false,emailInvalid: false,
        insuranceForm:{
            insurance: '',
            insuranceName:'',
            insurancePlan: '',
            insurancePlanName:'',
            insuaranceValidation: { valid: false, msg: '' },
            insuarancePlanValidation: { valid: false, msg: '' },
        },
        insuranceList:[] as any,insurancePlanList:[],
        addAsPrimary:'',addAsSecondaryIndividual:''as any,isAddSecondary:props.isAddPrimaryGuarantor?true: false,
        guarantorList:[] as any,isSubmitDependant:false,
    });
    useEffect(()=>{
        getInsuranceProviderApi();

    },[]);

    const getInsuranceProviderApi = () => {
        getInsuranceProviderNew().then((success: any) => {
            try {
                if (success && success.response && success.response.data && success.response.status === 200) {
                    if (success.response.data && success.response.data.status.error === false) {
                        setState({ ...state, insuranceList: [...success.response.data.insurance,{id:'other',name:'Other',plan:[{id:'other',name:'Other'}]}] });
                        return;
                        // props.loadingEnd(false);
                    } else {
                        if (success.response.data && success.response.data.status.error === true) {
                            toast.error(success.response.data.status.msg);
                        }
                        return;
                        // props.loadingEnd(false);
                    }
                }
            } catch (error) {
                console.log('error', error);
            }
        });
    }
    const handleInsuranceName=(value:any)=>{
        let insurance = state.insuranceList.find((item:any)=>item.id === value);
        console.log('insurance',insurance)
        if(value === 'other'){
            insurance = {
                ...insurance,
                plan: [{id: 'other', name: 'Other'}]
            }
        }else{
            insurance = {
                ...insurance,
                plan: [...insurance.plan,{id: 'other', name: 'Other'}]
            }
        }
        if(insurance && insurance.plan.length > 0){
            setState({ ...state, insuranceForm: { ...state.insuranceForm, 
                insurance: value, 
                insurancePlan:insurance.plan[0].id,
                insuaranceValidation: { valid: false, msg: '' } },
            insurancePlanList:insurance.plan });
        }else{
            setState({ ...state, insuranceForm: { ...state.insuranceForm, 
                insurance: value, 
                insuaranceValidation: { valid: false, msg: '' } },
            insurancePlanList:insurance.plan });
        }
        
    }
    const handleInsurancePlanName=(value:any)=>{
        setState({...state,insuranceForm:{...state.insuranceForm,insurancePlan:value}});
    }
    const handleChangeDate = (date: any) => {
        setState({...state,gurantorFormData:{
            ...state.gurantorFormData,
            dob:date,
            dobValidation:{valid:false,msg:''}
        } })
    }
    const handleResetBtn=()=>{
        setState({...state,
            gurantorFormData:{
                email: '',
                firstName: '',
                lastName: '',
                phone: '',
                dob: moment().subtract(1, 'days'),
                gender:'Male',
                emailValidation: { valid: false, msg: '' },
                firstNameValidation: { valid: false, msg: '' },
                lastNameValidation: { valid: false, msg: '' },
                dobValidation: { valid: false, msg: '' },
                phoneValidation: { valid: false, msg: '' },
            }})
    }
    const handleAddSecondary =()=>{
        let checkAllField = validateAllField();
        if(checkAllField){
            let selectedInsurance:any = state.insuranceList.find((item:any)=>item.id === state.insuranceForm.insurance);
            let selectedInsurancePlan:any = state.insurancePlanList.find((item:any)=>item.id === state.insuranceForm.insurancePlan);
            let data={
                email: state.gurantorFormData.email,
                firstName: state.gurantorFormData.firstName,
                lastName: state.gurantorFormData.lastName,
                phone: state.gurantorFormData.phone,
                dob: state.gurantorFormData.dob,
                gender:state.gurantorFormData.gender,
                // insurance:state.insuranceForm.insurance,
                // insurancePlan:state.insuranceForm.insurancePlan,
                insurance:{
                    id: selectedInsurance ? selectedInsurance.id :'',
                    name:selectedInsurance && selectedInsurance.id === 'other' ?state.insuranceForm.insuranceName:selectedInsurance.name,
                    plan:selectedInsurancePlan ? selectedInsurancePlan.id:'',
                    planName: selectedInsurancePlan && selectedInsurancePlan.id === 'other'?state.insuranceForm.insurancePlanName: selectedInsurancePlan.name
                }
            }
            setState({...state,addAsSecondaryIndividual:data});
        }
        // props.addAsSecondary(data);
    }
    const handleAddSecondaryBtnSubmit =()=>{
        props.addAsSecondary(state.addAsSecondaryIndividual);
    }

    const validateAllField =()=>{
        if (state.gurantorFormData.firstName === "") {
            setState({...state,
                gurantorFormData: {
                    ...state.gurantorFormData,
                    firstNameValidation: { valid: true, msg: 'First Name required' },
                    lastNameValidation: { valid: false, msg: '' },
                    emailValidation: { valid: false, msg: '' },
                    dobValidation: { valid: false, msg: '' },
                    phoneValidation: { valid: false, msg: '' },
                }
            });
            return false;
        } else if (state.gurantorFormData.lastName === "") {
            setState({...state,
                gurantorFormData: {
                    ...state.gurantorFormData,
                    firstNameValidation: { valid: false, msg: '' },
                    lastNameValidation: { valid: true, msg: 'Last name required' },
                    emailValidation: { valid: false, msg: '' },
                    dobValidation: { valid: false, msg: '' },
                    phoneValidation: { valid: false, msg: '' },
                }
            });
            return false;
        }  if(state.gurantorFormData.email === "" && state.gurantorFormData.phone?.length !== 10){
             if (state.gurantorFormData.email === "" || (state.gurantorFormData.email && !emailValidation(state.gurantorFormData.email))) {
                setState({...state,
                    gurantorFormData: {
                        ...state.gurantorFormData,
                        firstNameValidation: { valid: false, msg: '' },
                        lastNameValidation: { valid: false, msg: '' },
                        emailValidation: { valid: true, msg: 'Email required' },
                        dobValidation: { valid: false, msg: '' },
                        phoneValidation: { valid: false, msg: '' },
                    }
                });
                return false;
            } else if (state.gurantorFormData.phone === "" || state.gurantorFormData.phone?.length !== 10) {
                setState({...state,
                    gurantorFormData: {
                        ...state.gurantorFormData,
                        firstNameValidation: { valid: false, msg: '' },
                        lastNameValidation: { valid: false, msg: '' },
                        emailValidation: { valid: false, msg: '' },
                        dobValidation: { valid: false, msg: '' },
                        phoneValidation: { valid: true, msg: 'Invalid Phone' },
                    }
                });
                return false;
            }
        } else if (state.gurantorFormData.email === "" || (state.gurantorFormData.email && !emailValidation(state.gurantorFormData.email))) {
            setState({...state,
                gurantorFormData: {
                    ...state.gurantorFormData,
                    firstNameValidation: { valid: false, msg: '' },
                    lastNameValidation: { valid: false, msg: '' },
                    emailValidation: { valid: true, msg: 'Email required' },
                    dobValidation: { valid: false, msg: '' },
                    phoneValidation: { valid: false, msg: '' },
                }
            });
            return false;
        } else if (state.gurantorFormData.phone === "" || state.gurantorFormData.phone?.length !== 10) {
            setState({...state,
                gurantorFormData: {
                    ...state.gurantorFormData,
                    firstNameValidation: { valid: false, msg: '' },
                    lastNameValidation: { valid: false, msg: '' },
                    emailValidation: { valid: false, msg: '' },
                    dobValidation: { valid: false, msg: '' },
                    phoneValidation: { valid: true, msg: 'Invalid Phone' },
                }
            });
            return false;
        }
         else if (state.gurantorFormData.dob && moment().diff(state.gurantorFormData.dob, "years") < 18) {
            setState({...state,
                gurantorFormData: {
                    ...state.gurantorFormData,
                    firstNameValidation: { valid: false, msg: '' },
                    lastNameValidation: { valid: false, msg: '' },
                    emailValidation: { valid: false, msg: '' },
                    dobValidation: { valid: true, msg: 'Should be greater than 18 years' },
                    phoneValidation: { valid: false, msg: '' },
                }
            });
            return false;
        }else  if (state.insuranceForm.insurance === "") {
            setState({...state,
                insuranceForm: {
                    ...state.insuranceForm,
                    insuaranceValidation: { valid: true, msg: 'Please select insurance.' },
                    insuarancePlanValidation: { valid: false, msg: '' },
                }
            });
            return false;
        }else if(state.insuranceForm.insurance === "other" && state.insuranceForm.insuranceName === ""){
            setState({
                ...state,
                insuranceForm: {
                    ...state.insuranceForm,
                    insuaranceValidation: { valid: true, msg: '' },
                    insuarancePlanValidation: { valid: false, msg: '' },
                }
            })
            return false;
        }else  if (state.insuranceForm.insurancePlan === "") {
            setState({...state,
                insuranceForm: {
                    ...state.insuranceForm,
                    insuaranceValidation: { valid: false, msg: '' },
                    insuarancePlanValidation: { valid: true, msg: 'Please select insurance plan.' },
                }
            });
            return false;
        }else if(state.insuranceForm.insurancePlan === "other" && state.insuranceForm.insurancePlanName === ""){
            setState({
                ...state,
                insuranceForm: {
                    ...state.insuranceForm,
                    insuaranceValidation: { valid: false, msg: '' },
                    insuarancePlanValidation: { valid: true, msg: '' },
                }
            })
            return false;
        }
        return true;
    }

    const handleAsPrimary = ()=>{
        let allValid = validateAllField();
        if(allValid){
            let selectedInsurance:any = state.insuranceList.find((item:any)=>item.id === state.insuranceForm.insurance);
            let selectedInsurancePlan:any = state.insurancePlanList.find((item:any)=>item.id === state.insuranceForm.insurancePlan);
            let data={
                email: state.gurantorFormData.email,
                firstName: state.gurantorFormData.firstName,
                lastName: state.gurantorFormData.lastName,
                phone: state.gurantorFormData.phone,
                dob: state.gurantorFormData.dob,
                gender:state.gurantorFormData.gender,
                // insurance:state.insuranceForm.insurance,
                // insurancePlan:state.insuranceForm.insurancePlan,
                insurance:{
                    id: selectedInsurance ? selectedInsurance.id :'',
                    name:selectedInsurance && selectedInsurance.id === 'other' ?state.insuranceForm.insuranceName:selectedInsurance.name,
                    plan:selectedInsurancePlan ? selectedInsurancePlan.id:'',
                    planName: selectedInsurancePlan && selectedInsurancePlan.id === 'other'?state.insuranceForm.insurancePlanName: selectedInsurancePlan.name
                }
            }
            let ans:any = state.guarantorList;
            ans.push(data);
            setState({...state,
                guarantorList:ans,
                isAddSecondary:props.isSecondaryGurantor?true:false,
                isSubmitDependant:props.isSecondaryGurantor?false:true,});
            // handleResetBtn();
        }
    }
    const handleAsSecondary = ()=>{
        let allValid = validateAllField();
        if(allValid){
            let selectedInsurance:any = state.insuranceList.find((item:any)=>item.id === state.insuranceForm.insurance);
            let selectedInsurancePlan:any = state.insurancePlanList.find((item:any)=>item.id === state.insuranceForm.insurancePlan);
            let data={
                email: state.gurantorFormData.email,
                firstName: state.gurantorFormData.firstName,
                lastName: state.gurantorFormData.lastName,
                phone: state.gurantorFormData.phone,
                dob: state.gurantorFormData.dob,
                gender:state.gurantorFormData.gender,
                // insurance:state.insuranceForm.insurance,
                // insurancePlan:state.insuranceForm.insurancePlan,
                insurance:{
                    id: selectedInsurance ? selectedInsurance.id :'',
                    name:selectedInsurance && selectedInsurance.id === 'other' ?state.insuranceForm.insuranceName:selectedInsurance.name,
                    plan:selectedInsurancePlan ? selectedInsurancePlan.id:'',
                    planName: selectedInsurancePlan && selectedInsurancePlan.id === 'other'?state.insuranceForm.insurancePlanName: selectedInsurancePlan.name
                }
            }
            let ans:any = state.guarantorList;
            ans.push(data);
            setState({...state,
                guarantorList:ans,
                isAddSecondary:false,
                isSubmitDependant:true,});
            // handleResetBtn();
        }
    }

    const handleDependentBtnSubmit =()=>{
        if(props.isAddPrimaryGuarantor){
            props.addSecondaryGuarantorList(state.guarantorList[0]);
        }else{
            props.guarantorList(state.guarantorList);
        }
    }

    return (
        <Modal className="master_popup public_popup regPopup" tabIndex={1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" show={props.isGuarantorInfo} onHide={props.hideModal}>
            <Modal.Header closeButton>
                <Modal.Title>Add guarantor info</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className="form-row">
                {
                    (!props.isDependent) &&
                    <div className="alert alert-warning py-2">
                        You indicated that patient will have self guarator and secondary guarantor
                    </div>
                }
                {
                    (props.isDependent && !state.isAddSecondary) &&
                        <div className="alert alert-warning py-2">
                            You indicated that patient will have primary guarator and secondary guarantor.
                        </div>
                }  
                {
                    (props.isDependent && state.isAddSecondary) &&
                        <div className="alert alert-warning py-2">
                            You indicated that patient will have primary guarator.
                        </div>
                }
                 {
                    (!props.isDependent && state.addAsSecondaryIndividual) &&
                    <div className="alert alert-success alert-dismissible">Secondary Guarantor : {
                        state.addAsSecondaryIndividual.firstName + " " + state.addAsSecondaryIndividual.lastName + " (" +
                        state.addAsSecondaryIndividual.email+ ")"
                    }
                    <button className="btn-close" onClick={() => {
                            setState({...state,addAsSecondaryIndividual:''});
                        }}></button></div>
                }
                {
                        props.isDependent && state.guarantorList && state.guarantorList.length > 0 &&
                        <div className="alert alert-success alert-dismissible">Primary Guarantor : {
                            state.guarantorList[0].firstName + " " + state.guarantorList[0].lastName + " (" +
                            state.guarantorList[0].email + ")"
                        } <button className="btn-close" onClick={() => {
                            setState({...state,guarantorList:[],isAddSecondary:false});
                        }}></button></div>
                    }
                     {
                        props.isDependent && state.guarantorList && state.guarantorList.length > 1 && 
                        <div className="alert alert-success alert-dismissible">Secondary Guarantor : {
                            state.guarantorList[1].firstName + " " + state.guarantorList[1].lastName + " (" +
                            state.guarantorList[1].email + ")"
                        } <button className="btn-close" onClick={() => {
                            setState({...state,guarantorList:[],isAddSecondary:false});
                        }}></button></div>
                    }
                        <div className="col-md-6 mb-3">
                            <label>First name</label>
                            <input type="text" className="form-control" id="firstname" 
                            placeholder="Enter first name"
                            onInput={(e: any) => {
                                if (allowOnlyAlpha(e.target.value)) {
                                    setState({...state,
                                        gurantorFormData: {
                                            ...state.gurantorFormData,
                                            firstName: e.target.value
                                        }
                                    });
                                } else if (e.target.value === "") {
                                    setState({...state,
                                        gurantorFormData: {
                                            ...state.gurantorFormData,
                                            firstName: e.target.value
                                        }
                                    });
                                }
                            }}
                            onChange={(e) => {
                                if (allowOnlyAlpha(e.target.value)) {
                                    setState({...state,
                                        gurantorFormData: {
                                            ...state.gurantorFormData,
                                            firstName: e.target.value,
                                            firstNameValidation:{valid:false,msg:''}
                                        }
                                    });
                                } else if (e.target.value === "") {
                                    setState({...state,
                                        gurantorFormData: {
                                            ...state.gurantorFormData,
                                            firstName: e.target.value
                                        }
                                    });
                                }
                            }} value={state.gurantorFormData.firstName} />
                            {
                                (state.gurantorFormData.firstNameValidation && state.gurantorFormData.firstNameValidation.valid) &&
                                <div className="is-invalid">{state.gurantorFormData.firstNameValidation.msg}</div>
                            }
                        </div>
                        <div className="col-md-6 mb-3">
                            <label>Last name</label>
                            <input type="text" className="form-control" id="lastname"
                            placeholder="Enter last name"
                            onInput={(e: any) => {
                                if (allowOnlyAlpha(e.target.value)) {
                                    setState({...state,
                                        gurantorFormData: {
                                            ...state.gurantorFormData,
                                            lastName: e.target.value
                                        }
                                    });
                                } else if (e.target.value === "") {
                                    setState({...state,
                                        gurantorFormData: {
                                            ...state.gurantorFormData,
                                            lastName: e.target.value
                                        }
                                    });
                                }
                            }}
                            onChange={(e) => {
                                if (allowOnlyAlpha(e.target.value)) {
                                    setState({...state,
                                        gurantorFormData: {
                                            ...state.gurantorFormData,
                                            lastName: e.target.value,
                                            lastNameValidation:{valid:false,msg:''}
                                        }
                                    });
                                } else if (e.target.value === "") {
                                    setState({...state,
                                        gurantorFormData: {
                                            ...state.gurantorFormData,
                                            lastName: e.target.value
                                        }
                                    });
                                } 
                                // else {
                                //     setState({...state,
                                //         gurantorFormData: {
                                //             ...state.gurantorFormData,
                                //             lastName: ''
                                //         }
                                //     });
                                // }
                            }}
                            value={state.gurantorFormData.lastName} />
                             {
                                (state.gurantorFormData.lastNameValidation && state.gurantorFormData.lastNameValidation.valid) &&
                                <div className="is-invalid">{state.gurantorFormData.lastNameValidation.msg}</div>
                            }
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="col-md-6 mb-3">
                            <label>Email address</label>
                            <input type="email" className="form-control" id="email"
                            placeholder="Enter email"
                            onChange={(e) => {
                                setState({...state,
                                    gurantorFormData: {
                                        ...state.gurantorFormData,
                                        email: e.target.value,
                                        emailValidation:{valid:false,msg:''}
                                    }
                                });
                            }}
                            value={state.gurantorFormData.email} />
                            {
                                (state.gurantorFormData.emailValidation && state.gurantorFormData.emailValidation.valid) &&
                                <div className="is-invalid">{state.gurantorFormData.emailValidation.msg}</div>
                            }
                        </div>
                        <div className="col-md-6 mb-3">
                            <label>Mobile number</label>
                            <input type="text" className="form-control" id="phone" maxLength={10} value={state.gurantorFormData.phone}
                            placeholder="Enter mobile"
                            onInput={(e: any) => {
                                if (allowOnlyDigit(e.target.value)) {
                                    setState({...state,
                                        gurantorFormData: {
                                            ...state.gurantorFormData,
                                            phone: e.target.value
                                        }
                                    });
                                } else if (e.target.value === "") {
                                    setState({...state,
                                        gurantorFormData: {
                                            ...state.gurantorFormData,
                                            phone: e.target.value
                                        }
                                    });
                                }
                            }}
                            onChange={(e) => {
                                if(e.target.value.length === 10 && allowOnlyDigit(e.target.value)){
                                    setState({...state,
                                        gurantorFormData: {
                                            ...state.gurantorFormData,
                                            phone: e.target.value,
                                            phoneValidation:{valid:false,msg:''}
                                        }
                                    });
                                }else
                                if (allowOnlyDigit(e.target.value)) {
                                    setState({...state,
                                        gurantorFormData: {
                                            ...state.gurantorFormData,
                                            phone: e.target.value
                                        }
                                    });
                                } else if (e.target.value === "") {
                                    setState({...state,
                                        gurantorFormData: {
                                            ...state.gurantorFormData,
                                            phone: e.target.value
                                        }
                                    });
                                }
                              }}  />
                            {
                                (state.gurantorFormData.phoneValidation && state.gurantorFormData.phoneValidation.valid) &&
                                <div className="is-invalid">{state.gurantorFormData.phoneValidation.msg}</div>
                            }
                        </div>
                        <div className="col-md-6 mb-3">
                            <label className="form-label">Gender</label>
                            <select className="form-control" name="gender" value={state.gurantorFormData.gender}
                            onChange={(e)=>{setState({...state,gurantorFormData:{...state.gurantorFormData,gender:e.target.value}})}}>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                            </select>
                        </div>
                        <div className="col-md-6 mb-3">
                        <label className="d-block">Date of Birth</label>
                        <KeyboardDatePicker
                            margin="none"
                            id={"date-picker-dialog"}
                            name={"date-picker-dialog"}
                            // label="Date of Birth"
                            format="YYYY/MM/DD"
                            maxDate={moment().subtract(1, 'days')}
                            value={moment(state.gurantorFormData.dob)}
                            onChange={(date: any) => { handleChangeDate(date) }}
                            KeyboardButtonProps={{
                            'aria-label': 'change date',
                            }}
                        />
                         {
                                (state.gurantorFormData.dobValidation && state.gurantorFormData.dobValidation.valid) &&
                                <div className="is-invalid">{state.gurantorFormData.dobValidation.msg}</div>
                            }
                        </div>
                        <div className="col-md-6 mt-2 mt-md-0">
                                <label className="form-label">Insurance name:</label>
                                <select className="form-control" name="insurancename"  value={state.insuranceForm.insurance}
                                    onChange={(e) => { handleInsuranceName(e.target.value) }}>
                                        <option value="">Select Insurance</option>
                                {
                                    state.insuranceList && state.insuranceList.length > 0 &&
                                    state.insuranceList.map((item: any) => {
                                        return (
                                            <option value={item.id}>{item.name}</option>
                                        )
                                    })
                                }
                                </select>
                                {
                                    state.insuranceForm.insurance === 'other' &&
                                <input type="text" className="form-control" placeholder="Enter insurance name" name="insurancename"
                                    value={state.insuranceForm.insuranceName}
                                    onChange={(e) => { setState({ ...state, insuranceForm: { ...state.insuranceForm, insuranceName: e.target.value, insuaranceValidation: { valid: false, msg: '' } } }) }} />
                                }
                                {/* <input type="text" className="form-control" placeholder="Enter insurance name" name="insurancename" ref={register({ required: true })} /> */}
                                { state.insuranceForm.insuaranceValidation.valid &&
                                    <div className="is-invalid">Insurance name is required</div>
                                }
                            </div>
                            <div className="col-md-6">
                                <label className="form-label">Insurance plan name:</label>
                                <select className="form-control" name="insurancename" value={state.insuranceForm.insurancePlan}
                                    onChange={(e) => { handleInsurancePlanName(e.target.value) }}>
                                    <option value="">Select insurance plan</option>
                                    {
                                        state.insurancePlanList && state.insurancePlanList.length > 0 &&
                                        state.insurancePlanList.map((item: any, index: any) => {
                                            return (
                                                <option key={index + Math.random() + 'insurance'} value={item.id}>{item.name}</option>
                                            )
                                        })
                                    }
                                </select>
                                {
                                    state.insuranceForm.insurancePlan === 'other' &&
                                <input type="text" className="form-control" placeholder="Enter insurance plan name" name="insuranceplanname"
                                    value={state.insuranceForm.insurancePlanName}
                                    onChange={(e) => { setState({ ...state, insuranceForm: { ...state.insuranceForm, insurancePlanName: e.target.value, insuarancePlanValidation: { valid: false, msg: '' } } }) }} />
                                }
                                {
                                    state.insuranceForm.insuarancePlanValidation.valid &&
                                    <div className="is-invalid">Insurance plan name is required</div>
                                }
                            </div>
                    </div>          
            </Modal.Body>
            <Modal.Footer>
            <button className="btn btn-outline-secondary mr-2" onClick={() => {handleResetBtn()}} >Reset</button>
            {
                //Individual
                (!props.isDependent) &&
                <>
                    {!state.addAsSecondaryIndividual && <button className={"btn btn-primary"} onClick={()=>{handleAddSecondary()}} type="submit" >Add as secondary</button>}
                    {
                        (state.addAsSecondaryIndividual) &&
                        <button className={"btn btn-primary"} onClick={()=>{handleAddSecondaryBtnSubmit()}} type="submit" >Submit</button>

                    }
                </>
                    
            }
            {
                //dependant Primary
                (props.isDependent && !state.isAddSecondary && !state.isSubmitDependant) &&
                    <button className={"btn btn-primary"} onClick={()=>{handleAsPrimary()}} type="submit" >Add as Primary</button>
            }
            {
                //dependant Secondary
                (props.isDependent && state.isAddSecondary && props.isSecondaryGurantor) &&
                    <button className={"btn btn-primary"} onClick={()=>{handleAsSecondary()}} type="submit" >Add as secondary</button>
            }
             {
                        (props.isDependent && state.isSubmitDependant) &&
                        <button className={"btn btn-primary"} onClick={()=>{handleDependentBtnSubmit()}} type="submit" >Submit</button>

            }
            </Modal.Footer>
        </Modal>
    )
}

export default GuarantorInfoModal

import React from "react";
import Header from "../Header/Header";
import { IAppState } from "../../store";
import { connect } from "react-redux";
import {
  addLocation,
  setToInital,
  setPercentage,
} from "../../store/formwizard/actions";
import { prepareAddress } from "../../utils/googleFunction";
import { FormWizardState } from "../../store/formwizard/types";
import $ from "jquery";
import Slider from "react-slick";
import { Redirect } from "react-router-dom";
import { getInviteCodeDetails } from "../../services/results";
interface Props {
  addLocation: typeof addLocation;
  history: any;
  formwizardState: FormWizardState;
  setToInitial: typeof setToInital;
  setPercentage: typeof setPercentage;
  match: any;
}
interface State {
  latitude: any;
  longitude: any;
  place: any;
  redirectToQuiz: boolean;
  redirectToHome: boolean;
  patient_fname: string;
  patient_lname: string;
  loading: boolean;
}
class Main extends React.Component<Props, State> {
  autocomplete: any;
  autcompleteRef: any;
  constructor(props: any) {
    super(props);
    this.state = {
      latitude: "",
      longitude: "",
      place: "",
      redirectToQuiz: false,
      patient_fname: "",
      patient_lname: "",
      redirectToHome: false,
      loading: false,
    };
    this.autcompleteRef = React.createRef();
  }
  componentDidMount() {
    this.props.setToInitial();
    if (!document.getElementById("googlePlaceScript")) {
      const googleScript = document.createElement("script");
      googleScript.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDIDMklRxHmFYWU8Vvo1P-dVkB_nbHARj8&libraries=places`;
      googleScript.id = "googlePlaceScript";
      window.document.body.appendChild(googleScript);
      googleScript.addEventListener("load", () => {
        try {
          //@ts-ignore
          this.autocomplete = new window.google.maps.places.Autocomplete(
            this.autcompleteRef.current,
            {
              types: ["(regions)"],
            }
          );
          this.autocomplete.addListener("place_changed", () => {
            const place = this.autocomplete.getPlace();
            this.setState({ place: place });
          });
        } catch (err) {
          console.log(err);
        }
      });
    } else {
      try {
        //@ts-ignore
        this.autocomplete = new window.google.maps.places.Autocomplete(
          this.autcompleteRef.current,
          {
            types: ["(regions)"],
          }
        );
        this.autocomplete.addListener("place_changed", () => {
          const place = this.autocomplete.getPlace();
          this.setState({ place: place });
        });
      } catch (err) {
        console.log(err);
      }
    }
    this.getInviteDetail();
  }
  getInviteDetail() {
    this.setState({ loading: true });
    getInviteCodeDetails(this.props.match.params.inviteCode).then(
      (success: any) => {
        this.setState({ loading: false });
        this.setState({
          patient_fname: success.fname,
          patient_lname: success.lname,
        });
        if (
          !localStorage.getItem("referrer") ||
          localStorage.getItem("referrer") === ""
        ) {
          localStorage.setItem("referrer", this.props.match.params.inviteCode);
        }
      },
      (error) => {
        this.setState({ redirectToHome: true });
      }
    );
  }
  getMyLocation() {
    const location = window.navigator && window.navigator.geolocation;
    if (location) {
      location.getCurrentPosition(
        (position) => {
          /*  this.setState({
                     latitude: position.coords.latitude,
                     longitude: position.coords.longitude,
                 }); */
          this.props.setToInitial();
          this.props.addLocation({
            location: {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            },
          });
          this.props.setPercentage(10);
          this.setState({ redirectToQuiz: true });
          // this.props.history.push('/quiz');
        },
        (error) => {
          /* this.setState({ latitude: '', longitude: '' }); */
        }
      );
    }
  }
  shouldComponentUpdate(nextProps: Props, nextState: any) {
    if (this.props !== nextProps) {
      console.log(this.props);
    }
    return true;
  }
  render() {
    if (this.state.redirectToQuiz) {
      return <Redirect to="/quiz/attitude"></Redirect>;
    }
    if (this.state.redirectToHome) {
      return <Redirect to="/"></Redirect>;
    }
    const homeTesti = {
      slidesToShow: 1,
      arrows: false,
      autoplay: true,
      fade: true,
      cssEase: "linear",
      dots: false,
      infinite: true,
      Speed: 1000,
    };
    return (
      <>
        <Header></Header>
        <section className="banner-section master-banner">
          <div className="container h-100">
            <div className="row h-100">
              <div className="col-12 align-self-center mx-auto text-center">
                <div className="txt-section">
                  <h1>
                    {this.state.patient_fname + " " + this.state.patient_lname}{" "}
                    just treated you to <strong>5000 XcarePro points</strong>
                  </h1>
                  <p>Visit a dentist & get a 5000 points.</p>

                  <div className="address-section">
                    <input
                      type="text"
                      id="autocomplete"
                      ref={this.autcompleteRef}
                      placeholder="Address or Postal Code (Ex. Mississauga OR L4T 0A4)"
                      className="location-input"
                      autoFocus
                    />
                    <button
                      className="location-btn"
                      disabled={this.state.loading}
                      onClick={() => {
                        if (this.state.place !== "") {
                          this.props.setToInitial();
                          this.props.addLocation({
                            location: prepareAddress(this.state.place),
                          });
                          this.props.setPercentage(10);
                          this.setState({ redirectToQuiz: true });
                          // this.props.history.push('/quiz');
                        } else {
                          $("#autocomplete").focus();
                        }
                      }}
                    >
                      <img
                        src={process.env.PUBLIC_URL + "/images/location.svg"}
                      />
                    </button>
                  </div>
                  <a
                    className="location-title"
                    href="javascript:void(0);"
                    onClick={(e) => {
                      this.getMyLocation();
                    }}
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/current-location.svg"
                      }
                      alt="image"
                    />
                    Use my current location
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="feature-section">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6">
                <h2 className="like-h1">
                  <strong>Quality healthcare providers</strong> At Your
                  Fingertips
                </h2>
                <ul className="feature-list">
                  <li>
                    <div className="list-title">
                      Only vetted healthcare providers
                    </div>
                    <div className="list-desc">
                      All of the healthcare providers we recommend meet your
                      standards. We visit, interview, and background check every
                      single one.
                    </div>
                  </li>
                  <li>
                    <div className="list-title">Nearby and convenient</div>
                    <div className="list-desc">
                      Our wide network ensures that you’ll find a dentist nearby
                      with availability that works with your schedule.
                    </div>
                  </li>
                  <li>
                    <div className="list-title">Read reviews & book online</div>
                    <div className="list-desc">
                      Easily browse detailed reviews from real patients and book
                      your first appointment online.
                    </div>
                  </li>
                </ul>
              </div>
              <div className="col-12 col-md-6 align-self-center">
                <div className="client-review-img">
                  <img
                    src={process.env.PUBLIC_URL + "/images/feature-img.svg"}
                    className="img-fluid"
                    alt="image"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="feature-icon">
          <div className="container">
            <div className="row">
              <div className="col-6 col-md-3 col-lg-3">
                <div className="feature-icon-section">
                  <div className="f-img">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/images/satisfaction-guarantee.svg"
                      }
                      className=""
                      alt="image"
                    />
                  </div>
                  <div className="f-title">Satisfaction Guarantee</div>
                </div>
              </div>
              <div className="col-6 col-md-3 col-lg-3">
                <div className="feature-icon-section">
                  <div className="f-img">
                    <img
                      src={process.env.PUBLIC_URL + "/images/time-saving.svg"}
                      className=""
                      alt="image"
                    />
                  </div>
                  <div className="f-title">
                    Save Money <br></br>& Time
                  </div>
                </div>
              </div>
              <div className="col-6 col-md-3 col-lg-3">
                <div className="feature-icon-section">
                  <div className="f-img">
                    <img
                      src={process.env.PUBLIC_URL + "/images/separate-user.svg"}
                      className="user-card"
                      alt="image"
                    />
                  </div>
                  <div className="f-title">separate user panel</div>
                </div>
              </div>
              <div className="col-6 col-md-3 col-lg-3">
                <div className="feature-icon-section">
                  <div className="f-img">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/managed-patient.svg"
                      }
                      className="time"
                      alt="image"
                    />
                  </div>
                  <div className="f-title">Managed Patient History</div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="provider-section">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-6 col-md-3 col-lg-3 provider-box">
                <div className="provider-name">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/images/insurance-manulife.svg"
                    }
                    className="m-auto"
                    alt="image"
                  />
                </div>
              </div>
              <div className="col-6 col-md-3 col-lg-3 provider-box">
                <div className="provider-name">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/images/insurance-bluecross.svg"
                    }
                    className="m-auto"
                    alt="image"
                  />
                </div>
              </div>
              <div className="col-12 col-md-3 col-lg-3 provider-box">
                <div className="provider-name txt-box">
                  <div className="que-txt">
                    Who is your dental insurance provider?
                  </div>
                  <div className="ans-txt">All insurances are accepted</div>
                </div>
              </div>
              <div className="col-6 col-md-3 col-lg-3 provider-box">
                <div className="provider-name">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/images/insurance-greatwest.svg"
                    }
                    className="m-auto"
                    alt="image"
                  />
                </div>
              </div>
              <div className="col-6 col-md-3 col-lg-3 provider-box">
                <div className="provider-name">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/images/insurance-sunlife.svg"
                    }
                    className="m-auto"
                    alt="image"
                  />
                </div>
              </div>
              <div className="col-6 col-md-3 col-lg-3 provider-box">
                <div className="provider-name">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/images/insurance-desjardins.svg"
                    }
                    className="m-auto"
                    alt="image"
                  />
                </div>
              </div>
              <div className="col-6 col-md-3 col-lg-3 provider-box">
                <div className="provider-name">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/images/insurance-greenshield.svg"
                    }
                    className="m-auto"
                    alt="image"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="testimonials wrap">
          <div className="bg-img"></div>
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <h2 className="like-h1">
                  Patient <strong>Success</strong> Stories
                </h2>
                <p>
                  Over 3000 patients have found the right healthcare providers
                  in their locality for their dental needs.
                </p>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-7 col-md-6 col-sm-12">
                <img
                  src={
                    process.env.PUBLIC_URL + "/images/testimonials_banner.svg"
                  }
                  className="testi_img img-fluid"
                />
                <div className="right_coma">
                  <img
                    src={process.env.PUBLIC_URL + "/images/quote-white.svg"}
                  />
                </div>
              </div>
              <div className="col-lg-5 col-md-6 col-sm-12">
                <div className="side_section">
                  <div className="carousel_slide">
                    <div className="slicky_slide">
                      <Slider {...homeTesti}>
                        <div>
                          <p>
                            XcarePro is very simple to navigate and use. They
                            have detailed descriptions of various healthcare
                            providers along with their reviews. This helped me
                            to find a good orthodontist in my neighborhood for
                            my child.
                          </p>
                          <h3>
                            Jenifer <span>Toronto</span>
                          </h3>
                          <div className="bg_coma">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/images/quote-white.svg"
                              }
                            />
                          </div>
                        </div>

                        <div>
                          <p>
                            I love XcarePro. I was able to find a new dentist in
                            my area and book an appointment with him in minutes.
                          </p>
                          <h3>
                            Robert <span>Oakville</span>
                          </h3>
                          <div className="bg_coma">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/images/quote-white.svg"
                              }
                            />
                          </div>
                        </div>
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-md-center">
              <div className="col col-lg-3 col-md-4 col-sm-4">
                <div className="text-center rattings">
                  <div className="rateing_star">
                    <img
                      src={process.env.PUBLIC_URL + "/images/ratting-star.svg"}
                    />
                    <img
                      src={process.env.PUBLIC_URL + "/images/ratting-star.svg"}
                    />
                    <img
                      src={process.env.PUBLIC_URL + "/images/ratting-star.svg"}
                    />
                    <img
                      src={process.env.PUBLIC_URL + "/images/ratting-star.svg"}
                    />
                    <img
                      src={process.env.PUBLIC_URL + "/images/ratting-star.svg"}
                    />
                  </div>
                  <p>My experience was great from the beginning.</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-4">
                <div className="text-center rattings">
                  <div className="rateing_star">
                    <img
                      src={process.env.PUBLIC_URL + "/images/ratting-star.svg"}
                    />
                    <img
                      src={process.env.PUBLIC_URL + "/images/ratting-star.svg"}
                    />
                    <img
                      src={process.env.PUBLIC_URL + "/images/ratting-star.svg"}
                    />
                    <img
                      src={process.env.PUBLIC_URL + "/images/ratting-star.svg"}
                    />
                    <img
                      src={process.env.PUBLIC_URL + "/images/ratting-star.svg"}
                    />
                  </div>
                  <p>Could book an appointment in minutes.</p>
                </div>
              </div>
              <div className="col col-lg-3 col-md-4 col-sm-4">
                <div className="text-center rattings">
                  <div className="rateing_star">
                    <img
                      src={process.env.PUBLIC_URL + "/images/ratting-star.svg"}
                    />
                    <img
                      src={process.env.PUBLIC_URL + "/images/ratting-star.svg"}
                    />
                    <img
                      src={process.env.PUBLIC_URL + "/images/ratting-star.svg"}
                    />
                    <img
                      src={process.env.PUBLIC_URL + "/images/ratting-star.svg"}
                    />
                    <img
                      src={process.env.PUBLIC_URL + "/images/ratting-star.svg"}
                    />
                  </div>
                  <p>
                    Great experience! I found a dentist that I absolutely love!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
const mapStateToProps = (state: IAppState) => ({
  formwizardState: state.formwizard,
});
const mapDispatchToProps = (dispatch: any) => ({
  addLocation: (location: any) => {
    dispatch(addLocation(location));
  },
  setToInitial: () => {
    dispatch(setToInital());
  },
  setPercentage: (percent: number) => {
    dispatch(setPercentage(percent));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(Main);

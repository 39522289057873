import React from 'react';
import './PopUpStyle.css';
import apiConfigs from '../../configs/apiConfigs';
import Select from 'react-select';
interface Props {
    showNextPopUp: (selectedAppointment: any, noteForOffice: any) => void;
    closePopUp: () => void;
    data: any;
    doctor: any;
}
interface State {
    selectedAppointment: any;
    selectedAppointmentValue: any;
    noteForOffice: any;
}
class PopUpTwo extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            selectedAppointment: props.doctor.potentialAppointmentTypes[0] ? props.doctor.potentialAppointmentTypes[0].id : '',
            selectedAppointmentValue: props.doctor.potentialAppointmentTypes[0] ? props.doctor.potentialAppointmentTypes[0] : '',
            noteForOffice: ''
        }
    }
    continueClick = (selectedAppointment: any, noteForOffice: any) => {
        this.props.showNextPopUp(selectedAppointment, noteForOffice);
    }
    closePopUp = () => {
        this.props.closePopUp();
    }
    render() {
        console.log(this.props.doctor);
        return (
            <div className="step-2">
                <div className="modal-header">
                    <button className="btn book-close-btn" onClick={(e) => { this.closePopUp(); }}>
                        <img src={process.env.PUBLIC_URL + "/images/close-icon-white.svg"} className="img-fluid" />
                    </button>

                    <div className="media">
                        <div className="media-img mr-3 img-box">
                        {
                            (this.props.doctor.photo && this.props.doctor.photo.name && this.props.doctor.photo.type) ?
                                <img src={apiConfigs.IMAGE_URL + '/doctor/' + this.props.doctor.photo.name + '-200x200.' + this.props.doctor.photo.type} className="img-fluid" /> :
                                <img src={apiConfigs.DEFAULT_IMAGE_URL_DOCTOR} className="img-fluid" />
                        }
                        </div>
                        <div className="media-body align-self-end">
                            <div>Book your appointment with</div>
                            <div className="p-name">{this.props.doctor.fullname}</div>
                        </div>
                    </div>
                    
                </div>
                
                <div className="modal-body">
                    <div className="publp_title">
                        <h6>What type of appointment are you seeking?</h6>
                    </div>
                    <div className="publp_form">
                        
                            <div className="form-group">
                                <select value={this.state.selectedAppointment} onChange={(e) => {
                                    const potentialAppointment = this.props.doctor.potentialAppointmentTypes.find((value: any, index: number) => {
                                        return value.id === e.target.value;
                                    });
                                    this.setState({
                                        selectedAppointment: e.target.value,
                                        selectedAppointmentValue: potentialAppointment
                                    });
                                }}>
                                    {
                                        this.props.doctor.potentialAppointmentTypes.map((pat: any, index: number) => {
                                            return (
                                                <option value={pat.id} key={index + Math.random()}>{pat.name} ({pat.duration} mins)</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className="form-group">    
                                <textarea placeholder="Notes for the office (Optional)" value={this.state.noteForOffice}
                                    onInput={(e: any) => {
                                        this.setState({ noteForOffice: e.target.value });
                                    }}
                                    onChange={(e: any) => {
                                        this.setState({ noteForOffice: e.target.value });
                                    }}
                                ></textarea>
                            </div>                          
                            <div className="form-group pop_btnsec">
                                <button disabled={this.state.selectedAppointment === ''} className="btn btn-primary w-100" onClick={(e) => {
                                    this.continueClick(this.state.selectedAppointmentValue, this.state.noteForOffice);
                                }}>Continue</button>
                            </div>
                    </div>
                    
                </div>
                
                 
            </div>

           
        )
    }
}
export default PopUpTwo;
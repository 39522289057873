import React from 'react';
import { CONSTANT } from '../../constants';
import { connect } from 'react-redux';
import { IAppState } from '../../store';
import { NavLink } from 'react-router-dom';
import { FormWizardState } from '../../store/formwizard/types';
import { addLastVisited, setPercentage } from '../../store/formwizard/actions';
interface Props {
    formwizardState: FormWizardState;
    addLastVisited: typeof addLastVisited;
    history: any;
    setPercentage: typeof setPercentage;
}
class LastVisited extends React.Component<Props> {
    constructor(props: any) {
        super(props);
    }
    selectLastVisited(lastVisited: any) {
        this.props.addLastVisited({ lastVisited: lastVisited });
        this.props.setPercentage(40);
        this.props.history.push(lastVisited.link);
    }
    render() {
        const selectedLastVisited: any = this.props.formwizardState.formwizard.lastVisited;
        return (
            <div className="col-12 col-md-9 wizard last-visited">
                <div className="row justify-content-center gutter-5">
                    <div className="col-12">
                        <div className="txt-box">
                            <h4 className="title mb-15">When was your last visit to the dentist?</h4>
                            <div className="sub-title">We’re not judging! This question helps us determine your appointment length.</div>
                        </div>
                    </div>
                    {
                        CONSTANT.QUIZ.lastVisited.map((visited) => {
                            return (
                                <div className="col-lg-3 list-box" key={Math.random()}>
                                    <button className={"custom-click btn " + ((selectedLastVisited && selectedLastVisited !== "" && selectedLastVisited.value === visited.value) ? 'active' : '')}
                                        onClick={(e) => { this.selectLastVisited(visited) }}>
                                        <div className="content-container">
                                            <h5 className="main-text">{visited.value}</h5>
                                            <div className="sub-text">{visited.label}</div>
                                        </div>
                                    </button>
                                </div>
                            );
                        })
                    }
                </div>
            </div>

        );
    }
}
const mapStateToProps = (state: IAppState) => ({
    formwizardState: state.formwizard
});
const mapDispatchToProps = (dispatch: any) => ({
    addLastVisited: (lastVisited: any) => {
        dispatch(addLastVisited(lastVisited));
    },
    setPercentage: (percent: number) => {
        dispatch(setPercentage(percent));
    }
});
export default connect(mapStateToProps, mapDispatchToProps)(LastVisited);
import {CLINIC_PROVIDER_LIST, ClinicProviderState, ClinicProviderType} from './types';

export const initialState: ClinicProviderState = {
    clinicProviderList:{
        clinicList:[],
        providerList: [],
        providerTotalCount: 0,
        clinicTotalCount: 0,
        primaryInsurance: ''
    }
}

export const clinicProviderListReducer = (state = initialState, action: ClinicProviderType) => {
    switch (action.type) {
        case CLINIC_PROVIDER_LIST.ADD_CLINIC_PROVIDER:
            return {
                ...state,
                clinicProviderList: {
                    cliniclist: action.payload.clinicList,
                    providerList: action.payload.providerList,
                    providerTotalCount: action.payload.providerTotalCount,
                    clinicTotalCount: action.payload.clinicTotalCount,
                    primaryInsurance: action.payload.primaryInsurance
                },
            }
        default:
            return state;
    }
}
import {
    FORM_WIZARD, AddLocationAction, AddLocation, AddAttitude,
    AddAttitudeAction, AddReason, AddReasonAction, RemoveGoalAction, RemoveGoal, AddGoal,
    AddGoalAction,
    AddCriteria,
    AddCriteriaAction,
    RemoveCriteria,
    RemoveCriteriaAction,
    AddLastVisited,
    AddLastVisitedAction,
    AddDentalAnxiety,
    AddDentalAnxietyAction,
    AddAvailability,
    AddAvailabilityAction,
    RemoveAvailability,
    RemoveAvailabilityAction,
    AddHowSoonAction,
    AddHowSoon,
    AddInsurance,
    AddInsuranceAction,
    AddInsuranceDetail,
    AddInsuranceDetailAction,
    AddGender,
    AddGenderAction,
    AddSocialAssistance,
    AddSocialAssistanceAction,
    AddReadyToBook,
    AddReadyToBookAction,
    AddInfo,
    AddInfoAction,
    AddDentistService,
    AddDentistServiceAction,
    RemoveDentistService,
    RemoveDentistServiceAction,
    AddDentistUrgent,
    AddDentistUrgentAction,
    AddNotReadyReason,
    AddNotReadyReasonAction,
    SetToInitialAction,
    SetPercentageAction
} from './types';
export const addLocation = (formwizard: AddLocation): AddLocationAction => {
    return {
        type: FORM_WIZARD.ADD_LOCATION,
        payload: formwizard.location
    };
}
export const addAttitude = (formwizard: AddAttitude): AddAttitudeAction => {
    return {
        type: FORM_WIZARD.ADD_ATTITUDE,
        payload: formwizard.attitude
    };
}
export const addReason = (formwizard: AddReason): AddReasonAction => {
    return {
        type: FORM_WIZARD.ADD_REASAON,
        payload: formwizard.reason
    };
}
export const addGoal = (formwizard: AddGoal): AddGoalAction => {
    return {
        type: FORM_WIZARD.ADD_GOAL,
        payload: formwizard.goal
    };
}
export const removeGoal = (formwizard: RemoveGoal): RemoveGoalAction => {
    return {
        type: FORM_WIZARD.REMOVE_GOAL,
        payload: formwizard.goal
    };
}
export const addCriteria = (formwizard: AddCriteria): AddCriteriaAction => {
    return {
        type: FORM_WIZARD.ADD_CRITERIA,
        payload: formwizard.criteria
    };
}
export const removeCriteria = (formwizard: RemoveCriteria): RemoveCriteriaAction => {
    return {
        type: FORM_WIZARD.REMOVE_CRITERIA,
        payload: formwizard.criteria
    };
}
export const addLastVisited = (formwizard: AddLastVisited): AddLastVisitedAction => {
    return {
        type: FORM_WIZARD.ADD_LAST_VISITED,
        payload: formwizard.lastVisited
    };
}
export const addDentalAnxiety = (formwizard: AddDentalAnxiety): AddDentalAnxietyAction => {
    return {
        type: FORM_WIZARD.ADD_DENTAL_ANXIETY,
        payload: formwizard.dentalAnxiety
    };
}
export const addAvailability = (formwizard: AddAvailability): AddAvailabilityAction => {
    return {
        type: FORM_WIZARD.ADD_AVAILABILITY,
        payload: formwizard.availability
    };
}
export const removeAvailability = (formwizard: RemoveAvailability): RemoveAvailabilityAction => {
    return {
        type: FORM_WIZARD.REMOVE_AVAILABILITY,
        payload: formwizard.availability
    };
}
export const addHowSoon = (formwizard: AddHowSoon): AddHowSoonAction => {
    return {
        type: FORM_WIZARD.ADD_HOW_SOON,
        payload: formwizard.howsoon
    };
}
export const addInsurance = (formwizard: AddInsurance): AddInsuranceAction => {
    return {
        type: FORM_WIZARD.ADD_INSURANCE,
        payload: formwizard.insurance
    };
}
export const addInsuranceDetail = (formwizard: AddInsuranceDetail): AddInsuranceDetailAction => {
    return {
        type: FORM_WIZARD.ADD_INSURANCE_DETAIL,
        payload: formwizard.insuranceDetail
    };
}
export const addGender = (formwizard: AddGender): AddGenderAction => {
    return {
        type: FORM_WIZARD.ADD_GENDER,
        payload: formwizard.gender
    };
}
export const addSocialAssistance = (formwizard: AddSocialAssistance): AddSocialAssistanceAction => {
    return {
        type: FORM_WIZARD.ADD_SOCIAL_ASSISTANCE,
        payload: formwizard.socialAssistance
    };
}
export const addReadyToBook = (formwizard: AddReadyToBook): AddReadyToBookAction => {
    return {
        type: FORM_WIZARD.ADD_READY_TO_BOOK,
        payload: formwizard.readyToBook
    };
}
export const addInfo = (formwizard: AddInfo): AddInfoAction => {
    return {
        type: FORM_WIZARD.ADD_INFO,
        payload: formwizard
    };
}
export const addDentistService = (formwizard: AddDentistService): AddDentistServiceAction => {
    return {
        type: FORM_WIZARD.ADD_DENTIST_SERVICE,
        payload: formwizard.dentistService
    };
}
export const removeDentistService = (formwizard: RemoveDentistService): RemoveDentistServiceAction => {
    return {
        type: FORM_WIZARD.REMOVE_DENTIST_SERVICE,
        payload: formwizard.dentistService
    };
}
export const addDentistUrgent = (formwizard: AddDentistUrgent): AddDentistUrgentAction => {
    return {
        type: FORM_WIZARD.ADD_DENTIST_URGENT,
        payload: formwizard.dentistUrgent
    };
}
export const addNotReadyReason = (formwizard: AddNotReadyReason): AddNotReadyReasonAction => {
    return {
        type: FORM_WIZARD.ADD_NOT_READY_REASON,
        payload: formwizard.notreadytobook
    };
}
export const setToInital = (): SetToInitialAction => {
    return {
        type: FORM_WIZARD.SET_TO_INITIAL,
    };
}
export const setPercentage = (percentage: number): SetPercentageAction => {
    return {
        type: FORM_WIZARD.ADD_PERCENTAGE,
        percent: percentage
    };
}
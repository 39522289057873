import React, { useEffect, useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import moment from 'moment';
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import { toast } from 'react-toastify';
import { Controller, useForm } from 'react-hook-form';
import { shallowEqual, useSelector } from 'react-redux';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { IAppState } from '../../../../store';
import configs from '../../../../configs/apiConfigs';
import { bookappointmentSlotCheck, appointmentRequest } from '../../services/AppointmentBook';
import { getCookie } from '../../../../utils/global';
import Select from 'react-select';
export const Book = (props: any) => {
    const [doctor, setDoctor] = useState('' as any);
    const [selectedSlot, setSelectedSlot] = useState('' as any);
    const [blockSlotData, setBlockSlotData] = useState('' as any);
    const [guarantorId, setGuarantorId] = useState('');
    const [dependant, setDependant] = useState('' as any);
    const [isLoading, setIsLoading] = useState(false);
    const landingPageData: any = useSelector((state: IAppState | any) => state.landingPage.landingPage);
    const clinicProviderList: any = useSelector((state: IAppState | any) => state.clinicProviderList.clinicProviderList);
    const searchFilterData = useSelector((state: IAppState) => state.searchFilter.searchFilterData, shallowEqual);
    const { register, setError, handleSubmit, setValue, getValues, errors, trigger, control, watch } = useForm({
        defaultValues: {
            appointmenttype: '',
            date: props.selectedSlot && props.selectedSlot.startdate ? props.selectedSlot.startdate : moment(),
            starttime: props.selectedSlot && props.selectedSlot.startTime ? moment(props.selectedSlot.startTime, 'LT') : moment(),
            endtime: props.selectedSlot && props.selectedSlot.startTime ? moment(props.selectedSlot.startTime, 'LT').add('minutes', 30) : moment().add('minutes', 30),
            isVisited: 'no',
            appointmentSlot: ''
        }
    });
    useEffect(() => {
        console.log('propsBook', props);
        setSelectedSlot(props.selectedSlot);
        setDoctor(props.doctor);
        setBlockSlotData(props.blockSlotData);
        setGuarantorId(props.guarantorId);
        setDependant(props.dependant);
    }, [props]);

   /*  useEffect(() => {
        if (watch('appointmenttype') !== '') {
            let selectedAppointment: any = doctor?.potentialAppointmentTypes?.find((item: any) => { return item.id === getValues('appointmenttype') });
            if (selectedAppointment) {
                setValue('endtime', moment(getValues('starttime')).add('minutes', selectedAppointment.duration));
            }
        }
    }, [watch('appointmenttype'), watch('starttime')]); */

    const checkBlockSlot = (data: any) => {
        let endTime: any = moment(data.endtime).format('LT');
        let startDate: any = moment(data.date).format('YYYY-MM-DD');
        let startTime: any = moment(data.starttime).format('LT');
        let isBlocked: any = undefined;
        isBlocked = blockSlotData.find((item: any) => {
            if (item.blockslot_type === 'full') {
                return doctor.id === item.doctorId && doctor.clinic_Id === item.clinicId && moment(startDate).isSame(moment(item.startdate));
            } else if (item.blockslot_type === 'partial') {
                return (doctor.id === item.doctorId &&
                    doctor.clinic_id === item.clinicId &&
                    (moment(startDate + " " + startTime, 'YYYY-MM-DD LT').isSame(moment(item.startdate + " " + item.starttime, 'YYYY-MM-DD LT')) ||
                        moment(startDate + " " + startTime, 'YYYY-MM-DD LT').isSame(moment(item.enddate + " " + item.endtime, 'YYYY-MM-DD LT')) ||
                        moment(item.enddate + " " + item.endtime, 'YYYY-MM-DD LT').isBetween(moment(startDate + " " + startTime, 'YYYY-MM-DD LT'),
                            moment(startDate + " " + endTime, 'YYYY-MM-DD LT')) ||
                        moment(item.startdate + " " + item.starttime, 'YYYY-MM-DD LT')
                            .isBetween(moment(startDate + " " + startTime, 'YYYY-MM-DD LT'),
                                moment(startDate + " " + endTime, 'YYYY-MM-DD LT')) ||
                        moment(startDate + " " + startTime, 'YYYY-MM-DD LT').isBetween(moment(item.startdate + " " + item.starttime, 'YYYY-MM-DD LT'),
                            moment(item.enddate + " " + item.endtime, 'YYYY-MM-DD LT'))
                    ))
            }
            return (doctor.id === item.doctorId &&
                doctor.clinic_id === item.clinicId &&
                (moment(startDate + " " + startTime, 'YYYY-MM-DD LT').isSame(moment(item.startdate + " " + item.starttime, 'YYYY-MM-DD LT')) ||
                    moment(startDate + " " + startTime, 'YYYY-MM-DD LT').isSame(moment(item.enddate + " " + item.endtime, 'YYYY-MM-DD LT')) ||
                    moment(item.enddate + " " + item.endtime, 'YYYY-MM-DD LT').isBetween(moment(startDate + " " + startTime, 'YYYY-MM-DD LT'),
                        moment(startDate + " " + endTime, 'YYYY-MM-DD LT')) ||
                    moment(item.startdate + " " + item.starttime, 'YYYY-MM-DD LT')
                        .isBetween(moment(startDate + " " + startTime, 'YYYY-MM-DD LT'),
                            moment(startDate + " " + endTime, 'YYYY-MM-DD LT')) ||
                    moment(startDate + " " + startTime, 'YYYY-MM-DD LT').isBetween(moment(item.startdate + " " + item.starttime, 'YYYY-MM-DD LT'),
                        moment(item.enddate + " " + item.endtime, 'YYYY-MM-DD LT'))
                ))
        });
        return isBlocked;
    }

    const OnSubmitRequest = (data: any) => {
        if (moment(data.startdate + " " + data.startTime, 'YYYY-MM-DD LT').isBefore(moment().format('YYYY-MM-DD LT'))) {
            toast.warn('Invalid starttime');
            return;
        }
        if (firebase.auth().currentUser?.uid) {
            setIsLoading(true);
            let isBlocked = checkBlockSlot(data);
            if (isBlocked === undefined) {
                checkTimeSlotAvailability()
            } else {
                toast.warn('This time slot is not available.');
                setIsLoading(false);
                return;
            }
        } else {
            setIsLoading(false);
            toast.error('Please signin again. after logout.')
            return;
        }
    }
    const checkTimeSlotAvailability = () => {
        let request = {
            "clinicId": doctor.clinic_id,
            "doctorId": doctor.id,
            "start_date": moment(getValues('date')).format('YYYY-MM-DD'),
            "start_time": moment(getValues('starttime')).format('hh:mm A'),
            "end_time": moment(getValues('endtime')).format('hh:mm A')
        }
        console.log('request', request);
        bookappointmentSlotCheck(request).then((success) => {
            if (success && success.status && success.status.error === false) {
                console.log('success', success);
                if (success.avability !== "no") {
                    bookAppointmentRequest();
                } else {
                    toast.warn('This time slot is not available.');
                    setIsLoading(false);
                    return;
                }
            } else if (success && success.status && success.status.error === true) {
                setIsLoading(false);
                if (success.status.msg) {
                    toast.error(success.status.msg);
                } else {
                    toast.error('Something went wrong.');
                }
            }
        }).catch((err) => {
            setIsLoading(false);
            console.log(err);
        })
    }
    const bookAppointmentRequest = () => {
        const clinic = {
            id: doctor.clinic_id,
            doctor: {
                id: doctor.id
            }
        };
        const slots: any = getValues('appointmentSlot');

        const request = {
            isNewPatient: true,
            isExistingClinic: getValues('isvisited') === "yes" ? true : false,
            isFromApp: true,
            patient: {
                id: guarantorId,
                // uniqueId: props.guarantor.uniqueId
            },
            clinic: clinic,
            appointmenttype: {
                id: getValues('appointmenttype')
            },
            dependant: {
                id: dependant.id,
                etime: moment(getValues('endtime')).format('LT'),
                slot: slots?.map((item: any) => item.value).toString() ?? '',
            },
            request: {
                isNewPatient: true,
                additionalNote: "",
            },
        }
        appointmentRequest(request).then((success) => {
            if (success && success.status && success.status.error === false) {
                console.log('success', success);
                addStatusToFirebase(success.appopintment, success.dependant, success.patient, success.status.msg);
            } else if (success && success.status && success.status.error === true) {
                setIsLoading(false);
                if (success.status.msg) {
                    toast.error(success.status.msg);
                } else {
                    toast.error('Something went wrong.');
                }
            }
        }).catch((err) => {
            setIsLoading(false);
            console.log(err);
        })
    }
    const addStatusToFirebase = (appointmentId: any, dependantId: any, patientId: any, successMessage: any) => {
        var db = firebase.firestore();
        let dateTimeObj: any = {};
        dateTimeObj = {
            "date": moment(getValues('date')).format('YYYY-MM-DD'),
            "time": moment(getValues('starttime')).format('LT'),
            "startTime": moment(getValues('starttime')).format('LT'),
            "endTime": moment(getValues('endtime')).format('LT')
        }
        console.log('dateTimeObj', dateTimeObj);
        const obj = {
            "appointmentId": Number(appointmentId),
            "senderId": Number(patientId),
            "sender": 'patient',
            "action": 'Changed status from CREATED to REQUESTED.',
            "actionType": 'STATUS',
            "createdAt": firebase.firestore.FieldValue.serverTimestamp(),
            "updatedAt": firebase.firestore.FieldValue.serverTimestamp(),
            "fromStatus": 'CREATED',
            "toStatus": 'REQUESTED',
            "sendToCId": doctor.clinic_uniqueId,
            "dependantId": Number(dependantId),
            "dateTime": {
                ...dateTimeObj
            },
        };
        db.collection(configs.CHAT_COLLECTION_NAME).doc().set(obj)
            .then(() => {
                console.log("Document successfully written!");
                const login: any = getCookie();
                if (login) {
                    setTimeout(() => {
                        setIsLoading(false);
                        toast.success(successMessage);
                        props.bookSlotSuccess();
                    }, 1000);
                } else {
                    toast.error('Something went wrong.');
                    console.log('else do logoout');
                    setIsLoading(false);
                }
            })
            .catch((error: any) => {
                toast.error('Something went wrong.');
                console.error("Error writing document: ", error);
                setIsLoading(false);
            });
    }

    useEffect(() => {
        if (getValues('appointmentSlot')) {
            const value: any = getValues('appointmentSlot');
            if (value.find((item: any) => item.value === "morning")){
                setValue('starttime', moment(moment().format("DD MMM YYY") + " 7:00 AM"))
                if (value.find((item: any) => item.value === "evening")) {
                    setTimeout(() => {
                        setValue('endtime', moment(moment().format("DD MMM YYY") + " 07:00 PM"))
                    }, 500);
                } else if (value.find((item: any) => item.value === "afternoon")) {
                    setTimeout(() => {
                        setValue('endtime', moment(moment().format("DD MMM YYY") + " 03:00 PM"))
                    }, 500);
                }  else {
                    setTimeout(() => {
                        setValue('endtime', moment(moment().format("DD MMM YYY") + " 11:00 AM"))
                    }, 500);
                }
            } else if (value.find((item: any) => item.value === "afternoon")) {
                setValue('starttime', moment(moment().format("DD MMM YYY") + " 11:00 AM"))
                if (value.find((item: any) => item.value === "evening")) {
                    setTimeout(() => {
                        setValue('endtime', moment(moment().format("DD MMM YYY") + " 07:00 PM"))
                    }, 500);
                } else {
                    setTimeout(() => {
                        setValue('endtime', moment(moment().format("DD MMM YYY") +" 03:00 PM"))
                    }, 500);
                }
            } else if (value.find((item: any) => item.value === "evening")){
                setValue('starttime', moment(moment().format("DD MMM YYY") + " 03:00 PM"))
                setTimeout(() => {
                    setValue('endtime', moment(moment().format("DD MMM YYY") + " 07:00 PM"))
                }, 500);
            } else {
                setValue('starttime', '')
                setTimeout(() => {
                    setValue('endtime', '')
                }, 500);
            }
            setTimeout(() => {
                setValue('appointmentSlot', value);
            }, 500);
        }
    }, [watch('appointmentSlot')]);

    return (
        <Modal className="master_popup public_popup book_popup_nw lastStep" tabIndex={1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" show={props.isVisible} onHide={props.hideModal}>
            <Modal.Header closeButton>
                <Modal.Title><span className="subtxt-box">Book appointment for <strong>{"(" + dependant.firstName + " " + dependant.lastName + ")"}</strong></span></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-12">
                        <label className="form-label d-block fw-bold">Your appointment with:</label>
                        <div className="publp_form">
                            <div className="row d-flex justify-content-start">
                                {doctor &&
                                    <div className="col-md-12">
                                        <input type="radio" name="dr-profile" className="dr-selected" checked={true} />
                                        <div className="media">
                                            <div className="media-img mr-3">
                                                <img src={configs.IMAGE_URL + 'doctor/' + doctor.photo.name + '-200x200.' + doctor.photo.type} className="img-fluid" />
                                            </div>
                                            <div className="media-body align-self-center">
                                                <div className="dr-name">{doctor.fullname}</div>
                                                <div className="dr-desg">{doctor.speciality_name}</div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <hr className="my-3" />
                    </div>

                    <div className="col-12">
                        <label className="form-label d-block fw-bold">Appointment details:</label>
                        <select className="custom-select mr-sm-2 mb-3" name="appointmenttype" ref={register({ required: true })}>
                            <option value="" disabled>Select Appointment type</option>
                            {
                                (doctor && doctor.potentialAppointmentTypes && doctor.potentialAppointmentTypes.length > 0) &&
                                doctor.potentialAppointmentTypes.map((item: any, index: number) => {
                                    return (
                                        <option key={index} value={item.id}>{item.name}</option>
                                    )
                                })
                            }
                        </select>
                        {
                            errors.appointmenttype && errors.appointmenttype.type === "required" &&
                            <label className='text-danger'>Appointment type is required</label>
                        }
                    </div>
                    <div className="col-12">
                        <label className="form-label d-block fw-bold">Appointment slots:</label>
                        <Controller
                            name="appointmentSlot"
                            control={control}
                            rules={{
                                required: {
                                    value: true, message: 'Appointment slot is required'
                                }
                            }}
                            render={(props) => (
                                <Select
                                    options={[
                                        { value: 'morning', label: 'Morning' },
                                        { value: 'afternoon', label: 'Afternoon' },
                                        { value: 'evening', label: 'Evening' },
                                    ]}
                                    name="appointmentSlot"
                                    className="w-100" isMulti
                                    value={props.value}
                                    onChange={props.onChange}
                                ></Select>
                            )}
                        ></Controller>
                        {
                            (errors && errors.appointmentSlot && errors.appointmentSlot.type === 'required') &&
                            <div className="is-invalid">{errors.appointmentSlot.message}</div>
                        }
                    </div>
                    <div className="col-12">
                        <div className="row miuif_margin">
                            <div className="col-md-4 col-12 mt-3 mt-md-0">
                                <label className="d-block fw-bold">Date</label>
                                <Controller
                                    name="date"
                                    control={control}
                                    defaultValue={moment()}
                                    render={(prop) => (
                                        <KeyboardDatePicker
                                            id="date-picker-dialog"
                                            format="YYYY/MM/DD"
                                            readOnly={selectedSlot !== ""}
                                            name="date"
                                            value={prop.value}
                                            minDate={moment()}
                                            onChange={(date: any) => prop.onChange(date)}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                            disabled={props.asapAppt === "Hide"}
                                        />
                                    )}
                                ></Controller>
                            </div>
                            <div className="col-md-4 col-12 mt-3 mt-md-0">
                                <label className="d-block fw-bold">Start time</label>
                                <Controller
                                    name="starttime"
                                    control={control}
                                    defaultValue={moment()}
                                    render={(prop) => (
                                        <KeyboardTimePicker
                                            readOnly={selectedSlot !== ""}
                                            id="date-picker-dialog2"
                                            name="starttime"
                                            value={prop.value}
                                            onChange={(date: any) => prop.onChange(date)}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                            disabled={props.asapAppt === "Hide"}
                                        />
                                    )}
                                ></Controller>
                            </div>
                            <div className="col-md-4 col-12 mt-3 mt-md-0">
                                <label className="d-block fw-bold">End time</label>
                                <Controller
                                    name="endtime"
                                    control={control}
                                    defaultValue={moment()}
                                    render={(prop) => (
                                        <KeyboardTimePicker
                                            readOnly={selectedSlot !== ""}
                                            id="date-picker-dialog3"
                                            name="endtime"
                                            value={prop.value}
                                            onChange={(date: any) => prop.onChange(date)}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                            disabled={props.asapAppt === "Hide"}
                                        />
                                    )}
                                ></Controller>
                                {/* <div className="likeInputBox">{selectedEndTime ? moment(selectedEndTime).format('LT') : props.selectedSlot.endTime}</div> */}
                            </div>
                        </div>
                        <hr className="my-3" />
                    </div>
                </div>
                {
                    (clinicProviderList && clinicProviderList.primaryInsurance !== "" && searchFilterData.howToPay.type === "insurance") &&
                    <div className="mb-3">
                        <label className="form-label d-block fw-bold">You have selected to pay by insurance: </label>
                        {/* <label className="form-label d-block fw-bold">Primary insurance</label> */}
                        <div className="d-flex">
                            <div className="mr-5">
                                <span className="text-muted small">Insurance Provider</span>
                                <div>{clinicProviderList.primaryInsurance.insurancePlan}</div>
                            </div>
                            <div className="mr-5">
                                <span className="text-muted small">Insurance Plan</span>
                                <div>{clinicProviderList.primaryInsurance.insurencePlanName}</div>
                            </div>
                        </div>
                        <hr className="my-3" />
                    </div>
                }
                {
                    (searchFilterData && searchFilterData.howToPay.type === "insurance" && clinicProviderList.primaryInsurance === "") &&
                    <div className="mb-3">
                        <label className="form-label d-block fw-bold">You have selected insurance but we found that this account not have any insurance.</label>
                        <hr className="my-3" />
                    </div>
                }
                {
                    (searchFilterData && searchFilterData.howToPay.type === "cash") &&
                    <div className="mb-3">
                        <label className="form-label d-block fw-bold">Your selected payment method:</label>
                        {searchFilterData.howToPay.value &&
                            searchFilterData.howToPay.value.map((item: any, index: number) => {
                                return (
                                    <span key={index + Math.random()} className="badge badge-pill badge-primary mr-2 py-2">{item}</span>
                                )
                            })
                        }
                        <hr className="my-3" />
                    </div>

                }
                <div className="mb-3">
                    <label className="form-label d-block fw-bold">Have you visited this clinic before ?</label>
                    <div className="d-flex">
                        <div className="custom-control custom-checkbox mr-3">
                            <input type="radio" className="custom-control-input" id="visited1" name="isVisited" value="yes" ref={register({ required: true })} />
                            <label className="custom-control-label" htmlFor="visited1">Yes</label>
                        </div>

                        <div className="custom-control custom-checkbox">
                            <input type="radio" className="custom-control-input" id="visited2" value="no" name="isVisited" ref={register({ required: true })} />
                            <label className="custom-control-label" htmlFor="visited2">No</label>
                        </div>
                    </div>

                </div>
                <button onClick={handleSubmit(OnSubmitRequest)}
                    className="mt-3 btn btn-dark float-right">
                    {
                        isLoading &&
                        <span className="aft_load right">
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        </span>
                    }
                    Submit request</button>
            </Modal.Body>
        </Modal>
    )
}
import React from 'react';
import { CONSTANT } from '../../constants';
import { IAppState } from '../../store';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { addDentistUrgent } from '../../store/formwizard/actions';
import { FormWizardState } from '../../store/formwizard/types';
import { toast } from 'react-toastify';
interface Props {
    addDentistUrgent: typeof addDentistUrgent;
    formwizardState: FormWizardState;
    history: any;
}
interface State {
    reason: string;
}
class DentistUrgent extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            reason: ''
        }
    }
    componentDidMount() {
        if (this.props.formwizardState.formwizard.dentistUrgent !== '') {
            this.setState({ reason: this.props.formwizardState.formwizard.dentistUrgent.reason });
        }
    }
    shouldComponentUpdate(nextProps: Props, nextState: any) {
        if (this.props !== nextProps) {
            if (nextProps.formwizardState.formwizard.dentistUrgent !== '') {
                this.setState({ reason: nextProps.formwizardState.formwizard.dentistUrgent.reason });
            }
        }
        return true;
    }
    selectAttitude(attitude: any) {
        if (this.props.formwizardState.formwizard.dentistUrgent.value === attitude.value) {
            return;
        }
        this.setState({ reason: '' });
        this.props.addDentistUrgent({ dentistUrgent: attitude });
    }
    render() {
        const selectedUrgent: any = this.props.formwizardState.formwizard.dentistUrgent;
        return (
            <div className="col-12 col-md-10 wizard">
                <div className="row justify-content-center gutter-5">
                    <div className="col-12">
                        <div className="txt-box">
                            <h4 className="title">What issue are you experiencing?</h4>
                            <div className="sub-title">Make a choice and we'll connect you with the right dentist, pronto.</div>
                        </div>
                    </div>
                    {
                        CONSTANT.QUIZ.issues.map((issue, index) => {
                            return (
                                <div className="col-lg-4 list-box" ng-repeat="issue in issues">
                                    <button className={"custom-click btn " + ((selectedUrgent && selectedUrgent !== "" && selectedUrgent.id === issue.id) ? 'active' : '')}
                                        onClick={(e) => { this.selectAttitude(issue) }} >
                                        <div className="img-container">
                                            <img src={process.env.PUBLIC_URL + '/' + issue.img} />
                                        </div>
                                        <div className="content-container">
                                            <h5 className="main-text">{issue.value}</h5>
                                        </div>
                                    </button>
                                </div>

                            )
                        })
                    }
                    {
                        (selectedUrgent && selectedUrgent !== '') ?
                            <div className="col-12 text-center issue-box">
                                <p className="form-title">{selectedUrgent.descriptionTitle}</p>
                                <textarea className="text-box"
                                    value={this.state.reason}
                                    onInput={(e: any) => {
                                        this.setState({ reason: e.target.value });
                                    }}
                                    onChange={(e: any) => {
                                        this.setState({ reason: e.target.value });
                                    }}
                                    placeholder={selectedUrgent.descriptionPlaceholder} autoFocus></textarea>
                            </div>
                            : null
                    }
                    <div className="col-12 text-center">
                        <div className="bottom_bar">
                            <button className={"" + (selectedUrgent && selectedUrgent !== '') ? 'btn btn-toggle activeBtn' : 'btn btn-toggle unactiveBtn'}
                                onClick={(e) => {
                                    if (this.state.reason && this.state.reason !== '') {
                                        Object.assign(selectedUrgent, { reason: this.state.reason });
                                        this.props.addDentistUrgent({ dentistUrgent: selectedUrgent });
                                        //quiz/goals
                                        this.props.history.push('/quiz/criteria');
                                    } else {
                                        toast.warn('Please describe urgency.');
                                    }
                                }}
                            >Contitue</button>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}
const mapStateToProps = (state: IAppState) => ({
    formwizardState: state.formwizard
});
const mapDispatchToProps = (dispatch: any) => ({
    addDentistUrgent: (goal: any) => {
        dispatch(addDentistUrgent(goal));
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(DentistUrgent);
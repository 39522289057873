import React from 'react';
import { IAppState } from '../../store';
import { connect } from 'react-redux';
import { CONSTANT } from '../../constants';
import { NavLink } from 'react-router-dom';
import { FormWizardState } from '../../store/formwizard/types';
import { addReadyToBook, setPercentage } from '../../store/formwizard/actions';
interface Props {
    formwizardState: FormWizardState;
    addReadyToBook: typeof addReadyToBook;
    history: any;
    setPercentage: typeof setPercentage;
}
class ReadyToBook extends React.Component<Props> {
    constructor(props: any) {
        super(props);
    }
    selectReadyToBook(readyToBook: any) {
        this.props.addReadyToBook({ readyToBook: readyToBook });
        this.props.setPercentage(94);
        this.props.history.push(readyToBook.link);
    }
    render() {
        const selectedReady: any = this.props.formwizardState.formwizard.readyToBook;
        return (
            <div className="col-12 col-md-10 wizard">
                <div className="row justify-content-start gutter-5">
                    <div className="col-12 ">                       
                        <div className="txt-box">
                            <h4 className="title">If we can connect you with a dentist who matches your criteria, are you ready to book now?</h4>
                            <div className="sub-title">This helps us measure our ability to match patients with a dentist who meets their needs.</div>
                        </div>
                    </div>
                    {
                        CONSTANT.QUIZ.isReady.map((ready) => {
                            return (
                                <div className="col-lg-5 list-box" key={Math.random()}>
                                    <button className={"custom-click btn " + ((selectedReady && selectedReady !== "" && selectedReady.value === ready.value) ? 'active' : '')}
                                        onClick={(e) => { this.selectReadyToBook(ready) }} >

                                        <div className="img-container">
                                            <img src={process.env.PUBLIC_URL + '/' + ready.img} />
                                        </div>

                                        <div className="content-container">
                                            <h5 className="main-text">{ready.value}</h5>
                                            {/* <div className="sub-text">{ready.label}</div> */}
                                        </div>
                                    </button>
                                </div>
                            )
                        })
                    }
                </div>
            </div>

        )
    }
}
const mapStateToProps = (state: IAppState) => ({
    formwizardState: state.formwizard
});
const mapDispatchToProps = (dispatch: any) => ({
    addReadyToBook: (readyToBook: any) => {
        dispatch(addReadyToBook(readyToBook));
    },
    setPercentage: (percent: number) => {
        dispatch(setPercentage(percent));
    }
});
export default connect(mapStateToProps, mapDispatchToProps)(ReadyToBook);
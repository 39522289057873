import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import HeaderWhite from "../HeaderWhite/HeaderWhite";
import { isLogin } from "../../../../utils/global";

export const RewardTNCPage = () => {
    const location = useLocation();
    const history = useHistory();
    useEffect(() => {
        if (isLogin('patient_cookie')) {
            history.replace('/');
        }
    }, []);
    return (
        <>
            <HeaderWhite location={location}></HeaderWhite>
            <section>
                <div className="main-grid-sec" style={{ marginTop: 80 }}>
                    <div className="container">
                        <h2 className="like-h1 text-center fw-bold">Xcare Rewards Program Terms and Conditions</h2>
                        <p className="text-center">Last updated and effective: 1st January 2024</p>
                        <p className="text-center">Please read these Xcare Rewards Program Terms and Conditions ("Agreement") carefully</p>
                        <div>
                            <p className="fw-bold">1. Description of the Rewards Program</p>
                            <p className="ps-4">1.1. The Xcare Rewards Program enables you to earn rewards for interacting with your providers through Xcare. These rewards, determined by Xcare, may encompass both non-monetary and monetary benefits.</p>
                            <p className="ps-4">1.2. To participate in the Rewards Program, you must meet the requirements specified for each program, including but not 
limited to booking and managing appointments, referring friends and family, following us on social media, participating in 
special promotions and providing feedback.</p>
                        </div>
                        <div>
                            <p className="fw-bold">2. Eligibility and Availability:</p>
                            <p className="ps-4">2.1.  You must be a registered user of Xcare in good standing to participate in the Rewards Program.</p>
                            <p className="ps-4">2.2. The Program is open to individuals who are 18 years of age or older.</p>
                            <p className="ps-4">2.3.  The Rewards Program may not be available in all locations. Participation is prohibited in jurisdictions where it may violate 
legal requirements.</p>
                        </div>
                        <div>
                            <p className="fw-bold">3. Prohibited Uses:</p>
                            <p className="ps-4">3.1.  You may only use the Rewards Program for lawful purposes and in good faith.</p>
                            <p className="ps-4">3.2.  Prohibited uses include creating multiple accounts, inviting duplicate accounts, inviting individuals who have been Xcare 
customers for more than 30 days, and engaging in deceptive practices.</p>
                            <p className="ps-4">3.3.  You may not use the Rewards Program in any way that breaches applicable laws or regulations, damages Xcare's brand or 
reputation, or violates other agreements with Xcare.</p>
                        </div>
                        <div>
                            <p className="fw-bold">4. Additional Documents:</p>
                            <p className="ps-4">4.1.  Xcare may require additional documents, including identification information, before providing Rewards.</p>
                            <p className="ps-4">4.2. Failure to provide requested documents may result in forfeiture of Rewards.</p>
                        </div>
                        <div>
                            <p className="fw-bold">5. Data Protection:</p>
                            <p className="ps-4">You represent and warrant that you have obtained express consent from individuals whose data you provide to Xcare.</p>
                        </div>
                        <div>
                            <p className="fw-bold">6. Redemption of Rewards:</p>
                            <p className="ps-4">6.1. Participants may redeem points for rewards as specified by Xcare.</p>
                            <p className="ps-4">6.2. Rewards may be subject to availability and may be changed or discontinued at any time at the discretion of Xcare.</p>
                            <p className="ps-4">6.3. Xcare reserves the right to modify the redemption process and requirements for redemption at its sole discretion.</p>
                        </div>
                        <div>
                            <p className="fw-bold">7. Abuse and Termination:</p>
                            <p className="ps-4">7.1.  Xcare reserves the right to refuse Rewards, suspend or terminate participation, or terminate access to services for 
suspected abuse or breach of this Agreement.</p>
                            <p className="ps-4">7.2. Termination may result in forfeiture of Rewards.</p>
                        </div>
                        <div>
                            <p className="fw-bold">8. Limitation of Liability:</p>
                            <p className="ps-4">8.1.  Xcare accepts no liability for unauthorized use of the Rewards Program.</p>
                            <p className="ps-4">8.2. You are liable for breaches of this Agreement or applicable laws.</p>
                        </div>
                        <div>
                            <p className="fw-bold">9. Changes to this Agreement:</p>
                            <p className="ps-4">Xcare may amend this Agreement or Program Requirements at any time. By continuing to use the Rewards Program, you agree 
to be bound by changes.</p>
                        </div>
                        <div>
                            <p className="fw-bold">10. Changes and Termination of the Rewards Program:</p>
                            <p className="ps-4">10.1.  Xcare reserves the right to amend, cancel, or terminate the Rewards Program at any time without notice.</p>
                            <p className="ps-4">10.2.  Termination of the Rewards Program may result in forfeiture of Rewards.</p>
                        </div>
                        <div>
                            <p className="fw-bold">11. Contact Us:</p>
                            <p className="ps-4">If you need support, please contact us at support@xcarepro.com.</p>
                        </div>
                        <p>By using the Xcare Rewards Program, you agree to abide by this Agreement. If you do not agree, do not use the Rewards Program.</p>
                    </div>
                </div>
            </section>
        </>
    );
};
import React from 'react';
import './PopUpStyle.css';
import apiConfigs from '../../configs/apiConfigs';
interface Props {
    showNextPopUp: (isExisting: boolean) => void;
    closePopUp: () => void;
    clinic: any;
}
interface State {
    emailAddress: string;
    btnDisabledContinue: boolean;
    alreadyPresent: boolean;
    isLoginData: any;
    loading: boolean;
}
class PopUpAlreadyClinic extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            emailAddress: '',
            btnDisabledContinue: true,
            alreadyPresent: false,
            isLoginData: '',
            loading: false
        }
    }
    componentDidMount() {
    }
    closePopUp = () => {
        this.props.closePopUp();
    }
    onYesClick() {
        this.props.showNextPopUp(true);
    }
    onNoClick() {
        this.props.showNextPopUp(false);
    }
    render() {
        return (

            <div className="step-3">
                <div className="modal-header">
                    <button className="btn book-close-btn" onClick={(e) => { this.closePopUp(); }}>
                        <img src={process.env.PUBLIC_URL + "/images/close-icon-white.svg"} className="img-fluid" alt="" />
                    </button>

                    <div className="media">
                        <div className="media-img mr-3 img-box">
                            {
                                (this.props.clinic.logo && this.props.clinic.logo.name && this.props.clinic.logo.type) ?
                                    <img src={apiConfigs.IMAGE_URL + '/clinic/' + this.props.clinic.logo.name + '-200x200.' + this.props.clinic.logo.type} className="img-fluid" alt="" /> :
                                    <img src={apiConfigs.DEFAULT_IMAGE_URL_CLINIC} className="img-fluid" alt="" />
                            }
                        </div>
                    </div>
                </div>

                <div className="modal-body">
                    <div className="publp_form">
                        <div className="publp_title">
                            <h6>{this.props.clinic.name}</h6>
                        </div>

                        <div className="row justify-content-center">
                            <div className="col-8">
                                <div className="lginfo_dom text-center">
                                    <p>Is this clinic your existing clinic or new?</p>
                                </div>
                            </div>
                        </div>
                        <div className="form-group d-flex pop_btnsec">
                            <button className="btn btn-primary" onClick={() => { this.onYesClick(); }}>Yes, Existing</button>
                            <button className="btn btn-primary" onClick={() => { this.onNoClick(); }}>No, It's new</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default PopUpAlreadyClinic;
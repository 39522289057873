import React from 'react';
import { CONSTANT } from '../../constants';
import { IAppState } from '../../store';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { addCriteria, removeCriteria } from '../../store/formwizard/actions';
import { FormWizardState } from '../../store/formwizard/types';
interface Props {
    addCriteria: typeof addCriteria;
    removeCriteria: typeof removeCriteria;
    formwizardState: FormWizardState;
    history: any;
}
class Criteria extends React.Component<Props> {
    constructor(props: any) {
        super(props);
    }
    selectCriteria(criteria: any) {
        const criteriaFound = this.props.formwizardState.formwizard.criterias.findIndex((value) => {
            return value.value === criteria.value;
        });
        if (criteriaFound !== -1) {
            this.props.removeCriteria({ criteria: criteria });
        } else {
            this.props.addCriteria({ criteria: criteria });
        }
    }
    render() {
        return (
            <div className="col-12 col-md-10 criteria wizard">
                <div className="row  gutter-5">
                    <div className="col-12">
                        <div className="txt-box">
                            <h4 className="title">The most important factors in choosing a dentist are...</h4>
                            <div className="sub-title">Select all that apply</div>
                        </div>
                    </div>
                    {
                        CONSTANT.QUIZ.criterias.map((criteria, index) => {
                            return (
                                <div className="col-lg-3 list-box" key={index + Math.random()}>
                                    <a href="javascript:void(0);"
                                        className={"custom-click btn " + (this.props.formwizardState.formwizard.criterias.findIndex((value) => { return value.value === criteria.value }) !== -1 ? 'active' : '')}
                                        onClick={(e) => { this.selectCriteria(criteria) }}>

                                        { /*
										<div className="check_corner">
                                            <i className={"far " + (this.props.formwizardState.formwizard.criterias.findIndex((value) => { return value.value === criteria.value }) !== -1 ? 'fa-check-circle' : 'fa-circle')}></i>
                                        </div>
										*/}
                                        <div className="img-container">
                                            <img src={process.env.PUBLIC_URL + '/' + criteria.img} />
                                        </div>
                                        <div className="content-container">
                                            <h5 className="main-text">{criteria.value}</h5>
                                        </div>
                                    </a>
                                </div>
                            )
                        })
                    }
                    <div className="col-12">
						<div className="bottom_bar justify-content-between d-flex">
							<button className="btn isvg btn-back" onClick={(e) => {
								this.props.history.goBack();
							}}>back</button>
							<Link to="/quiz/last-visited" className={"btn btn-toggle selected-none btn-next " + (this.props.formwizardState.formwizard.criterias.length !== 0 ? 'activeBtn' : 'changeTxt')}>{
								(this.props.formwizardState.formwizard.criterias.length === 0 ? "None of the above" : "Continue")
							}</Link>
						</div>                       
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state: IAppState) => ({
    formwizardState: state.formwizard
});
const mapDispatchToProps = (dispatch: any) => ({
    addCriteria: (criteria: any) => {
        dispatch(addCriteria(criteria));
    },
    removeCriteria: (criteria: any) => {
        dispatch(removeCriteria(criteria));
    }
});
export default connect(mapStateToProps, mapDispatchToProps)(Criteria);
import React, { useEffect, useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import PinInput from 'react-pin-input';
import Axios from 'axios';
import apiConfigs from '../../configs/apiConfigs';
import { toast } from 'react-toastify';
import { signInAnonymously, signInWithCustomToken, getCurrentUser } from '../../utils/firebaseUtils';
import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import {addPrimaryInsurance,addSecondaryInsurance} from '../../store/landingPageSearch/actions';
function OTPModal(props: any) {
    let interval: any;
    const [state, setState] = useState({
        verificationCode: '', verificationCodeFalse: false,
        intervalTime: 30, loadingVerification: false
    });
    const [seconds, setSeconds] = React.useState(30);
    const dispatch = useDispatch();


    useEffect(() => {
        // intervalChange();
        if (seconds > 0) {
            setTimeout(() => setSeconds(seconds - 1), 1000);
          } else {
            setSeconds(0);
          }
    });
    const intervalChange = () => {
        interval = setInterval(() => {
            if (state.intervalTime === 0) {
                clearInterval(interval);
            } else {
                setState({ ...state, intervalTime: state.intervalTime - 1 });
            }
        }, 1000)
    }

    const handleVerify = () => {
        if (props.userId !== "") {
            setState({ ...state, loadingVerification: true });
            Axios.post(apiConfigs.API_URL + 'register1/' + props.userId + '/', {
                // phone: props.patientMobile,
                code: state.verificationCode
            }).then((success: any) => {
                setState({ ...state, loadingVerification: false });
                if (success.status === 200) {
                    if (success.data.status.error === false) {
                        toast(success.data.status.msg);
                        console.log('CHeckResponse',success.data);
                        setState({ ...state, verificationCodeFalse: false });
                        if(success.data.insurance && success.data.iPlan){
                            dispatch(addPrimaryInsurance({
                                insurancePlan:success.data.insurance.name,
                                insurencePlanName:success.data.iPlan.name
                            }));
                            dispatch(addSecondaryInsurance({
                                insurancePlan:success.data.insurance.name,
                                insurencePlanName:success.data.iPlan.name
                            }));
                        }
                        signInWithCustomToken(success.data.jwtToken).then((response) => {
                            if (response) {
                                const storeObject = {
                                    id: success.data.id,
                                    uniqueId: success.data.uniqueId,
                                    authParam: success.data.authParam
                                }
                                // localStorage.setItem('login:Patient', JSON.stringify(storeObject));
                                const key1 = 'xprUser.patient-' + success.data.id;
                                const key2 = 'xpr_user_id';
                                const key3 = 'xprAuthUser';
                                const key4 = 'xprUserName';
                                const key5 = 'jwtToken';
                                const key6 = 'xprIsGuarantor';
                                var json: any = {};
                                json[key1] = true;
                                json[key2] = success.data.uniqueId;
                                json[key3] = success.data.authParam;
                                json[key4] = success.data.firstName + " " + success.data.lastName;
                                json[key5] = success.data.jwtToken;
                                json[key6] = success.data.isGuarantor;
                                Cookies.set('patient_cookie', json, { expires: 30, domain: apiConfigs.COOKIE_DOMAIN });
                                Cookies.set('patient_cookie', json, { expires: 30 });
                                // setState({...state, });
                                props.otpSuccess(success.data.uniqueId);
                                
                            } else {
                                toast.error('Something went wrong');
                            }
                        }).catch((error) => {
                            toast.error('Something went wrong');
                        });
                    } else {
                        setState({ ...state, verificationCodeFalse: true });
                    }
                }
            }, (error) => {
                setState({ ...state, loadingVerification: false });
                toast.error('Something went wrong');
            });
        }
    }
    const sendVerificationCode = () => {
        if (props.patientMobile && props.userId !== "") {
            setState({ ...state, loadingVerification: true });
            setSeconds(30);
            Axios.post(apiConfigs.API_URL + 'signup/' + props.userId + '/resend/', {
                phone: props.patientMobile
            }).then((success) => {
                setState({ ...state, loadingVerification: false });
                if (success.status === 200) {
                    if (success.data.status.error === false) {
                        toast.success(success.data.status.msg);
                        setState({ ...state, verificationCodeFalse: false });
                        if (seconds > 0) {
                            setTimeout(() => setSeconds(seconds - 1), 1000);
                          } else {
                            setSeconds(0);
                          }
                    } else {
                        toast.error(success.data.status.msg);
                    }
                }
            }, (error) => {
                setState({ ...state, loadingVerification: false });
                toast.error('Something went wrong');
            });
        }
    }
    return (
        <>
            <Modal className="master_popup public_popup loginPopup" tabIndex={1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" show={props.isOTP} onHide={props.hideModal}>
                <Modal.Header closeButton>
                    <Modal.Title>OTP</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="popup-form text-center">
                        <p>We have sent a verification code to <span>{props.patientMobile && props.patientMobile}</span></p>
                        <div className="form-txt">Enter code here</div>
                        <PinInput
                            length={4}
                            initialValue={""}
                            onChange={(value, index) => {
                                setState({ ...state, verificationCode: value });
                            }}
                            type="numeric"
                            style={{ padding: '10px'}}
                            inputStyle={{}}
                            inputFocusStyle={{ borderColor: 'blue' }}
                            onComplete={(value, index) => {
                                setState({ ...state, verificationCode: value });
                            }}
                        />
                        {
                            state.verificationCodeFalse &&
                            <div className="text-danger error">Verification code entered is incorrect.</div>
                        }
                        <button disabled={state.verificationCode.length !== 4 || state.loadingVerification} className="btn btn-primary" onClick={() => { handleVerify() }}>Verify</button>
                        {
                            seconds !== 0 &&
                            <div className="resend-txt">Didn't received code? Resend after {seconds} seconds.</div>
                        }
                        {
                            seconds === 0 &&
                            <button className="btn resend-btn" onClick={(e) => { sendVerificationCode() }}>
                                Resend Code
                            {
                                    state.loadingVerification &&
                                    <span className="aft_load right">
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    </span>
                                }
                            </button>
                        }
                    </div>
                </Modal.Body>
            </Modal>

        </>
    )
}

export default OTPModal

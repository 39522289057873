import React from 'react';
import HeaderWhite from '../HeaderWhite/HeaderWhite';
import TermsOfUseNew from '../TermsOfUseNew';
class TermsAndCondition extends React.Component {
    render() {
        return (
            <>
                <HeaderWhite></HeaderWhite>
                <div className="no_banner"></div>
                <div className="policyParent">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="policy_cnt">
                                    <TermsOfUseNew></TermsOfUseNew>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default TermsAndCondition;
import React from 'react';
import { connect } from 'react-redux';
import { IAppState } from '../../store';
import { NavLink } from 'react-router-dom';
import { CONSTANT } from '../../constants';
import { FormWizardState } from '../../store/formwizard/types';
import { addDentalAnxiety } from '../../store/formwizard/actions';
interface Props {
    formwizardState: FormWizardState;
    addDentalAnxiety: typeof addDentalAnxiety;
    history: any;
}
class DentalAnxiety extends React.Component<Props> {
    constructor(props: any) {
        super(props);
    }
    selectDentalAnxiety(anxiety: any) {
        this.props.addDentalAnxiety({ dentalAnxiety: anxiety });
        this.props.history.push(anxiety.link);
    }
    render() {
        const selectedAnxiety: any = this.props.formwizardState.formwizard.dentalAnxiety;
        return (
            <div className="col-12 col-md-10 wizard last-visited anxiety">
                <div className="row  gutter-5">
                    <div className="col-12">
                        <div className="txt-box">
                            <h4 className="title mb-15">How do you feel about visiting the dentist?</h4>
                            <div className="sub-title">If needed, we have dentists trained specifically in dental anxiety.</div>
                        </div>
                    </div>
                    {
                        CONSTANT.QUIZ.dentalAnxiety.map((anxiety) => {
                            return (<div className="col-lg-3 list-box" key={Math.random()}>
                                <button className={"custom-click btn " + ((selectedAnxiety && selectedAnxiety !== "" && selectedAnxiety.value === anxiety.value) ? 'active' : '')}
                                    onClick={(e) => { this.selectDentalAnxiety(anxiety) }}>
                                    <div className="content-container">
                                        <h5 className="main-text">{anxiety.value}</h5>
                                        <div className="sub-text">{anxiety.label}</div>
                                    </div>
                                </button>
                            </div>);
                        })
                    }
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state: IAppState) => ({
    formwizardState: state.formwizard
});
const mapDispatchToProps = (dispatch: any) => ({
    addDentalAnxiety: (anxiety: any) => {
        dispatch(addDentalAnxiety(anxiety));
    }
});
export default connect(mapStateToProps, mapDispatchToProps)(DentalAnxiety);
import React, { useState, useEffect } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import ReactInputMask from 'react-input-mask';
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Controller, useForm } from 'react-hook-form';
import { CONTACTMETHODS } from '../../constants/contactMethods';
import { allowOnlyAlpha, emailValidation, allowDigit } from '../../../../utils/validators';
import { addDependant } from '../../services/AppointmentBook';

export const AddMember = (props: any) => {
    const [isLoading, setIsLoading] = useState(false);
    const { register, setError, handleSubmit, setValue, getValues, errors, trigger, control, watch } = useForm({
        defaultValues: {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            dob: moment().subtract(1, 'day'),
            gender: 'Male',
            automatedreminder: 'yes',
            pcontactmethod: 'email,text',
        }
    });
    const handleAddDependant = (data: any) => {
        let requestedData = {
            fname: getValues('firstName'),
            lname: getValues('lastName'),
            email: getValues('email'),
            phone: getValues('phone'),
            dob: moment(getValues('dob')).format('YYYY-MM-DD'),
            gender: getValues('gender'),
            contactMethod: getValues('pcontactmethod') ? getValues('pcontactmethod') : '',
            reminder: getValues('automatedreminder') === "yes" ? true : false,
        }
        if (props.patientUniqueId) {
            setIsLoading(true);
            addDependant(props.patientUniqueId, requestedData).then((success) => {
                setIsLoading(false);
                if (success && success.status && success.status.error === false) {
                    toast.success('Member Added.');
                    props.addDependantSuccess();
                }
            }).catch((err) => {
                setIsLoading(false);
                console.log(err);
            })
        }
    }
    return (
        <>
            <Modal className="master_popup public_popup book_popup_nw addMemberNw" tabIndex={1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" show={props.isVisible} onHide={props.hideModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Family Member</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="cal_pop">
                        <div className="mb-3">
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <label className="form-label">First Name:</label>
                                    <input type="text" className="form-control" placeholder="Patient first name" name="firstName" ref={register({ required: true })} />
                                    {
                                        (errors && errors.firstName && errors.firstName.type === 'required') &&
                                        <div className="is-invalid">First name is required</div>
                                    }
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label className="form-label">Last Name:</label>
                                    <input type="text" className="form-control" placeholder="Patient last name" name="lastName" ref={register({ required: true })} />
                                    {
                                        (errors && errors.lastName && errors.lastName.type === 'required') &&
                                        <div className="is-invalid">Last name is required</div>
                                    }
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label className="form-label">Communication email:</label>
                                    <input type="email" className="form-control" placeholder="Enter email" name="email" ref={register({
                                        required: true,
                                        pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                    })} />
                                    {
                                        (errors && errors.email && errors.email.type === 'required') &&
                                        <div className="is-invalid">Email is required</div>
                                    }
                                </div>

                                <div className="col-md-6 mb-3">
                                    <label className="form-label">Communication mobile #</label>
                                    <input type="text" className="form-control" name="phone" maxLength={10} placeholder="Enter mobile #"
                                        ref={register({
                                            required: true,
                                            minLength: 10, maxLength: 10,
                                            pattern: /^[0-9]*$/
                                        })}
                                    />
                                    {console.log('error', errors.phone)}
                                    {
                                        (errors && errors.phone && errors.phone.type === 'required') &&
                                        <div className="is-invalid">Mobile number is required</div>
                                    }
                                    {
                                        (errors && errors.phone && errors.phone.type === 'minLength') &&
                                        <div className="is-invalid">Mobile number is invalid</div>
                                    }
                                    {
                                        (errors && errors.phone && (errors.phone.type === 'maxLength' || errors.phone.type === "pattern")) &&
                                        <div className="is-invalid">Mobile number is invalid</div>
                                    }
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label className="form-label">Gender</label>
                                    <select className="form-select form-control" name="gender" ref={register({ required: true })}>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                    </select>
                                </div>
                                <div className="col-md-6 mb-3 miuif_margin">
                                    <label className="form-label">Date of birth</label>
                                    {/* <input type="text" className="form-control" placeholder="Date of birth" /> */}
                                    <Controller
                                        name="dob"
                                        control={control}
                                        defaultValue={moment().subtract(1, 'day')}
                                        render={(props) => (
                                            <KeyboardDatePicker
                                                id="date-picker-dialog"
                                                format="YYYY/MM/DD"
                                                name="dob"
                                                value={props.value}
                                                maxDate={moment().subtract(1, 'day')}
                                                onChange={(date: any) => props.onChange(date)}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                        )}
                                    ></Controller>
                                </div>
                                <div className="col-md-5">
                                    <label className="form-label">Automated Reminder?</label>
                                    <div className="d-flex align-items-center">
                                        <div className="form-check me-3">
                                            <input className="form-check-input" type="radio" name="automatedreminder" id="a-yes" value="yes" ref={register({ required: true })} />
                                            <label className="form-check-label" htmlFor="a-yes">Yes</label>
                                        </div>
                                        <div className="form-check me-3">
                                            <input className="form-check-input" type="radio" name="automatedreminder" id="a-no" value="no" ref={register({ required: true })} />
                                            <label className="form-check-label" htmlFor="a-no">No</label>
                                        </div>
                                    </div>
                                    {
                                        (errors && errors.automatedreminder && errors.automatedreminder.type === 'required') &&
                                        <div className="text-danger error">Automated reminder is required.</div>
                                    }
                                </div>
                                <div className="col-md-7 mt-3 mt-md-0">
                                    {
                                        watch('automatedreminder') === 'yes' ?
                                            <>
                                                <label className="form-label">Preferred contact method?</label>
                                                <div className="d-flex align-items-center">
                                                    <div className="form-check me-3">
                                                        <input className="form-check-input" type="radio" name="pcontactmethod" id="email-only" value={"email"} ref={register({ required: true })} />
                                                        <label className="form-check-label" htmlFor="email-only">Email</label>
                                                    </div>
                                                    <div className="form-check me-3">
                                                        <input className="form-check-input" type="radio" name="pcontactmethod" id="text-only" value="text" ref={register({ required: true })} />
                                                        <label className="form-check-label" htmlFor="text-only">Text</label>
                                                    </div>
                                                    <div className="form-check me-3">
                                                        <input className="form-check-input" type="radio" name="pcontactmethod" id="et-both" value="email,text" ref={register({ required: true })} />
                                                        <label className="form-check-label" htmlFor="et-both">Email & Text</label>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <p className="form-label">This patient will not receive notification.</p>
                                    }
                                </div>
                                {/* <div className="col-12 small text-muted">This patient will not receive notification.</div> */}
                            </div>
                        </div>
                        <button onClick={handleSubmit(handleAddDependant)} className="btn btn-dark float-right">
                            {isLoading &&
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            }
                            Submit</button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
export enum SEARCHFILTER {
    SEARCH_FILTER_DATA = "SEARCH_FILTER_DATA"
}

export interface SearchFilterState {
    searchFilterData: searchFilterData;
}

export interface searchFilterData {
    oldSpeciality: any; // used for clear filter
    newSpeciality: any;
    location: any; // search location or use current location
    date: any;
    howToPay: any; // insurance,cash/Credit/debit/,social assistance {type:insurance , value: 1}
    gender: any;
    distance: any;
    languages: any;
    clinicId: any;
    providerId: any;
    patientUniqueId: any;
    page: any;
    clinicPage: any;
    results: any;
    insuranceDetail?: any;
}
export interface SearchFilterDataAction {
    type: SEARCHFILTER.SEARCH_FILTER_DATA,
    payload: searchFilterData;
}

export type SearchFilterType = SearchFilterDataAction;
import React, { useEffect } from "react";
import HeaderWhite from "../HeaderWhite/HeaderWhite";
import { isLogin } from "../../../../utils/global";
import { Accordion, Button, Card } from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";

export const RewardPage = () => {
    const location = useLocation();
    const history = useHistory();
    useEffect(() => {
        if (isLogin('patient_cookie')) {
            history.replace('/');
        }
    }, []);
    return (
        <>
            <HeaderWhite location={location}></HeaderWhite>
            <section>
                <div className="main-grid-sec" style={{ marginTop: 80 }}>
                    <div className="container">
                    <div className="">
                            <h2 className="like-h1 text-center fw-bold">Refer and Earn</h2>
                            <h5 className="fst-normal my-3 text-center">Our referral program is designed to reward you for sharing the benefits of Xcare Rewards with your friends and family. It's our way of saying thank you for being a loyal member of our Xcare community.</h5>
                            <ul className="my-3 custom-list mt-4">
                                <li>
                                    <span className="fw-bold">Refer Your Friends:</span>
                                    Share your love for Xcare Rewards with your friends and family by inviting them to join our program. You can refer as many people as you'd like!
                                </li>
                                <li>
                                    <span className="fw-bold">They Join Xcare Rewards:</span>
                                    When your friends sign up for Xcare Rewards using your unique referral link, they'll become members of our dental family and start earning points for their dental visits.
                                </li>
                                <li>
                                    <span className="fw-bold">You Both Earn Rewards:</span>
                                    Once your friends complete their first dental appointment as a member of Xcare Rewards, you'll both earn bonus points as a thank you for your referral!
                                </li>
                            </ul>
                            <p className="fw-bold">Benefits of Xcare Referral points:</p>
                            <ul className="custom-list my-3">
                                <li>
                                    <span className="fw-bold">Earn Bonus Points:</span>
                                    Receive bonus points for every friend who joins Xcare Rewards using your referral link and completes their first appointment.
                                </li>
                                <li>
                                    <span className="fw-bold">Share the Love:</span>
                                    Help your friends and family save on dental care and enjoy the benefits of being a member of Xcare Rewards.
                                </li>
                                <li>
                                    <span className="fw-bold">Join Our Ambassador Program:</span>
                                    Become a beacon of smiles and aid us in expanding our community by advocating for more members to join the Xcare Rewards program.
                                </li>
                                <p className="fw-bold">How to Refer Your Friends:</p>
                                <li>
                                    <span className="fw-bold">Get Your Unique Referral Link:</span>
                                    Log in to your Xcare Rewards account and find your unique referral link in the "Refer a Friend" section.
                                </li>
                                <li>
                                    <span className="fw-bold">Share Your Link:</span>
                                    Share your referral link with your friends and family via email, social media, or word of mouth. Encourage them to sign up for Xcare Rewards using your link to ensure you both receive credit for the referral.
                                </li>
                                <li>
                                    <span className="fw-bold">Track Your Referrals:</span>
                                    Monitor the status of your referrals and see how many bonus points you've earned for spreading smiles!
                                </li>
                            </ul>
                            <p className="text-center">
                                Ready to start earning rewards for spreading smiles? Log in to your Xcare Rewards account now to access your unique referral link and start referring your friends and family. Together, we can make health care more rewarding for everyone!
                            </p>
                        </div>
                        <section className="faq">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12  col-md-10 col-lg-8 mx-auto">
                                        <h2 className="like-h1 text-center fw-bold">
                                            Frequently Asked questions
                                        </h2>
                                        <Accordion className="faq-content">
                                            <Card>
                                                <Card.Header>
                                                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                                        <span className="">I want to join the Xcare community. How can I start using a referral link?</span>
                                                    </Accordion.Toggle>
                                                </Card.Header>
                                                <Accordion.Collapse eventKey="0">
                                                    <Card.Body>
                                                        <p>
                                                            Sign-up with Xcare as a patient and find your referral link in your patient portal or Mobile app. Have your friend send you their referral link using the Xcare app.
                                                        </p>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>

                                            <Card>
                                                <Card.Header>
                                                    <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                                        What are the qualifying criteria to earn referral points via a referral link?
                                                    </Accordion.Toggle>
                                                </Card.Header>
                                                <Accordion.Collapse eventKey="1">
                                                    <Card.Body>
                                                        <ul>
                                                            <li>Bonus points will be awarded to both the referrer and the referred friend upon completion of the referred friend's first dental appointment as a member of Xcare Rewards.</li>
                                                            <li>Referred friends must use the referrer's unique referral link to sign up for Xcare Rewards, for both parties to receive referral bonus points.</li>
                                                            <li>Bonus points will be credited to the referrer's and referred friend's Xcare Rewards accounts within 5 days of the referred friend's first dental appointment.</li>
                                                        </ul>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>

                                            <Card>
                                                <Card.Header>
                                                    <Accordion.Toggle as={Button} variant="link" eventKey="2">
                                                        How can I check if I am an existing customer and am already part of the Xcare community?
                                                    </Accordion.Toggle>
                                                </Card.Header>
                                                <Accordion.Collapse eventKey="2">
                                                    <Card.Body>
                                                        <p>
                                                            If you are already a patient of one of the providers listed on Xcare, you have already joined the community:
                                                            <Link to="/">Xcarepro Search portal by selecting Domain | Provider Type | City</Link>
                                                            Note: If you did not join the referral program via your provider, you will not receive bonus welcome points.
                                                        </p>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        </Accordion>
                                    </div>
                                </div>
                                <div className="text-center mt-5">
                                    <a
                                        className="btn btn-primary btn-lg"
                                        href="/clinic-registration/#gotodentist-form"
                                    >
                                        Get Started With XcarePro
                                    </a>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </section>
        </>
    );
};
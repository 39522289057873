import React from 'react';
import HeaderWhite from '../HeaderWhite/HeaderWhite';
import apiConfigs from '../../configs/apiConfigs';
import { FormWizardState } from '../../store/formwizard/types';
import { IAppState } from '../../store';
import { connect } from 'react-redux';
import { isArray } from 'util';
import { FEATURES } from '../../constants/features';
import * as _ from 'lodash';
import { findDistanceBetweenTwolatLng } from '../../utils/googleFunction';
import { Link, Redirect } from 'react-router-dom';
import { RatingComp } from '../RatingComponent';
import { showAddress } from '../renderAddress';
import { HygieneRating } from '../hygieneRating';
import { getHygieneFeatures } from '../../services/results';
import { Avatar } from '@material-ui/core';
interface Props {
    formwizardState: FormWizardState;
}
interface State {
    result: any[];
    actualResult: any[];
    request: any;
    expandedFeatureId: any;
    selectedFilter: any;
    featureList: any[];
    redirectToHome: boolean;
    hygieneList: { status: boolean, list: any[] };
}
class Listing extends React.Component<Props, State> {
    map: any;
    currentDirectionRenderer: any;
    constructor(props: any) {
        super(props);
        this.state = {
            result: [], request: '', expandedFeatureId: '', actualResult: [],
            selectedFilter: '', featureList: [],
            redirectToHome: false, hygieneList: { status: false, list: [] },
        }
    }
    componentWillMount() {

    }
    componentDidMount() {
        if (!document.getElementById('googlePlaceScript')) {
            const googleScript = document.createElement('script');
            googleScript.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDIDMklRxHmFYWU8Vvo1P-dVkB_nbHARj8&libraries=places`
            googleScript.id = "googlePlaceScript";
            googleScript.async = true;
            window.document.body.appendChild(googleScript);
            googleScript.addEventListener('load', () => {
                this.setMapAndRequest();
            });
        } else {
            try {
                this.setMapAndRequest();
            } catch (err) {
                console.log(err)
            }
        }
        /* try {
            const result: any = localStorage.getItem('ca.xp.wizard.match.results');
            if (result !== undefined && result !== null && result !== "") {
                const resultObj = JSON.parse(result);
                this.setState({ result: resultObj, actualResult: resultObj });
            }
        } catch (err) {
            console.log(err);
        } */

        /* Fetch Hygiene list */
        this.fetchHygieneList();
    }
    async fetchHygieneList() {
        try {
            const results = await getHygieneFeatures();
            if (results.hygieneFeatures) {
                this.setState({ hygieneList: { status: true, list: results.hygieneFeatures } });
            }
        } catch (error) {
            this.setState({ hygieneList: { status: false, list: [] } });
        }
    }
    setMapAndRequest() {
        try {
            //@ts-ignore
            this.map = new google.maps.Map(document.getElementById('map'), {
                center: { lat: -34.397, lng: 150.644 },
                streetViewControl: !1,
                zoom: 10,
                scrollwheel: !1,
                draggable: !1,
                disableDoubleClickZoom: !0,
                disableDefaultUI: !0,
                gestureHandling: 'cooperative',
                zoomControl: false,
                maxZoom: 15,
                name: 'xcare',
                styles: [{
                    featureType: "poi",
                    elementType: "labels",
                    stylers: [{
                        visibility: "off"
                    }]
                }, {
                    featureType: "transit.station",
                    elementType: "labels",
                    stylers: [{
                        visibility: "off"
                    }]
                }],
                //@ts-ignore
                mapTypeId: google.maps.MapTypeId.ROADMAP
            });
            //@ts-ignore
            google.maps.event.addListenerOnce(this.map, 'idle', () => {
                // do something only the first time the map is loaded
                //@ts-ignore
                google.maps.event.trigger(this.map, 'resize');
                this.map.setZoom(this.map.getZoom());
                const result: any = localStorage.getItem('ca.xp.wizard.match.results');
                const request: any = localStorage.getItem('ca.xp.wizard.quiz.request');
                if (result !== undefined && result !== null && result !== "" &&
                    request !== undefined && request !== null && request !== "") {
                    const resultObj = JSON.parse(result);
                    const requestObj = JSON.parse(request);
                    const allFeaturesArray: any[] = [];
                    resultObj.forEach((element: any) => {
                        element.features.forEach((value: any) => {
                            allFeaturesArray.push(value);
                        })
                    });
                    // console.log(allFeaturesArray);
                    // console.log(_.uniqBy(allFeaturesArray, 'id'));
                    this.setState({
                        result: resultObj, request: requestObj,
                        actualResult: resultObj, featureList: _.uniqBy(allFeaturesArray, 'id')
                    }, () => {
                        const currentLocation = { lat: requestObj.location.latitude, lng: requestObj.location.longitude };
                        this.state.result.forEach((value) => {
                            findDistanceBetweenTwolatLng(currentLocation, { lat: value.address.latitude, lng: value.address.longitude }).then((success) => {
                                this.setState({
                                    result: this.state.result.map((res) => {
                                        console.log(JSON.parse(JSON.stringify(res)));
                                        if (res.uniqueId === value.uniqueId) {
                                            Object.assign(res, { distanceKmAway: success });
                                            return res;
                                        } else {
                                            return res;
                                        }
                                    })
                                })
                            }, (error) => {
                            });
                        });
                    });
                } else {
                    this.setState({ redirectToHome: true });
                }
            });
        } catch (err) {
            console.log(err);
            this.setState({ redirectToHome: true });
        }
    }
    placeCenterMarker(state: any) {
        const centerLocation = state.request.location;
        //@ts-ignore
        var myLatlng = new google.maps.LatLng(centerLocation.latitude, centerLocation.longitude);
        var image = 'http://maps.google.com/mapfiles/ms/icons/green-dot.png';
        //@ts-ignore
        var marker = new google.maps.Marker({
            position: myLatlng,
            icon: image,
        });
        this.map.setCenter(myLatlng);
        marker.setMap(this.map);
    }
    putMarkerOnMap(latLng: any, value: any) {
        // var image = 'http://maps.google.com/mapfiles/ms/icons/red-dot.png';
        //@ts-ignore
        var marker = new google.maps.Marker({
            position: latLng,
            // icon: image
        });
        let contentString: any;
        if (value.doctors && value.doctors.length > 0) {
            contentString = contentString = '<div class="info_custom"><div class="info_img">' +
                '<IMG BORDER="0" width="50" style="border-radius:50%;" height="50"  ALIGN="Left" SRC="' + apiConfigs.IMAGE_URL + 'doctor/' + value.doctors[0].photo.name + '-200x200.' + value.doctors[0].photo.type + '"> </div>'
                + '<div class="info_cnt">' + value.name + '<div class="info_rating"><i class="fas fa-star"></i> ' + (value.rating ? value.rating : '') + ' ' + ((value.reviewCount && value.reviewCount !== "") ? '(' + value.reviewCount + ')' : '') + '</div> </div></div>';

        } else {
            contentString = value.name;
        }
        //@ts-ignore
        var infowindow = new google.maps.InfoWindow({
            content: contentString
        });
        //start route on map.
        marker.addListener('mouseover', () => {
            // this.drawRouteOnHover(value);
            infowindow.open(this.map, marker);
        });

        // remove route on map.
        marker.addListener('mouseout', () => {
            // this.currentDirectionRenderer.setMap(null);
            infowindow.close();
        });
        marker.setMap(this.map);
    }
    placeClinicMarker(clinic: any) {
        if (clinic && clinic.length > 0) {
            clinic.forEach((element: any) => {
                if (element.address && element.address.latitude && element.address.longitude) {
                    //@ts-ignore
                    var cMarker = new google.maps.LatLng(element.address.latitude, element.address.longitude);
                    this.putMarkerOnMap(cMarker, element);
                }
            });
        }
    }
    drawRouteOnHover(value: any) {
        if (this.currentDirectionRenderer) {
            this.currentDirectionRenderer.setMap(null);
        }
        //@ts-ignore
        const directionsService = new google.maps.DirectionsService();
        //@ts-ignore
        var directionsDisplay = new google.maps.DirectionsRenderer();
        this.currentDirectionRenderer = directionsDisplay;
        directionsDisplay.setMap(this.map);
        //@ts-ignore
        var originLatLng = this.state.request.location.latitude + ',' + this.state.request.location.longitude;
        //@ts-ignore
        var destinationLatLng = value.address.latitude + ',' + value.address.longitude;

        var request = {
            origin: originLatLng,
            destination: destinationLatLng,
            //@ts-ignore
            travelMode: google.maps.DirectionsTravelMode.DRIVING
        };
        directionsService.route(request, function (response: any, status: any) {
            //@ts-ignore
            if (status === google.maps.DirectionsStatus.OK) {
                directionsDisplay.setDirections(response);
            }
        });
    }
    shouldComponentUpdate(nextProps: Props, nextState: State) {
        if (nextState !== this.state) {
            try {
                if (this.map) {
                    this.placeCenterMarker(nextState);
                    if (nextState.result.length > 0) {
                        this.placeClinicMarker(nextState.result);
                    }
                }
            } catch (err) {
                console.log(err);
            }
        }
        return true;
    }
    render() {
        if (this.state.redirectToHome) {
            return (<Redirect to="/"></Redirect>)
        }
        return (
            <>
                <HeaderWhite></HeaderWhite>
                <section className="list-page">
                    <div id="panel"></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 mt-5">
                                <div className="result-list-page-title text-center text-lg-start">
                                    <div className="row">
                                        <div className="col-sm-8">
                                            <div className="shwrt"><strong>{this.state.result.length} Xcarepro</strong> Trusted dental practices available for bookings.</div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="text-center filter-by-pref">
                                                <div className="filt_lab">Filter by preference</div>
                                                <select className="form-control" value={this.state.selectedFilter} onChange={(e) => {
                                                    this.setState({ selectedFilter: e.target.value });
                                                    if (e.target.value === '') {
                                                        this.setState({ result: this.state.actualResult });
                                                        return;
                                                    }
                                                    const filteredData = _.filter(this.state.actualResult, (result: any) => {
                                                        return _.filter(result.features, (object) => {
                                                            return object.id.toString() === e.target.value.toString()
                                                        }).length !== 0;
                                                    });
                                                    this.setState({ result: filteredData });
                                                }}>
                                                    <option value="">No preference</option>
                                                    {
                                                        this.state.featureList.map((feature, index) => {
                                                            return (
                                                                <option key={index + Math.random()} value={feature.id}>{feature.name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    (this.state.result && this.state.result.length > 0) &&
                                    <>
                                        {
                                            this.state.result.map((value: any, index: number) => {
                                                const features = JSON.parse(JSON.stringify(value.features));
                                                return (
                                                    <div className="list-box-sec" onMouseEnter={(e) => {
                                                        if (this.map) {
                                                            this.drawRouteOnHover(value);
                                                        }
                                                    }} onMouseLeave={(e) => {
                                                        if (this.currentDirectionRenderer) {
                                                            this.currentDirectionRenderer.setMap(null);
                                                            //@ts-ignore
                                                            google.maps.event.trigger(this.map, 'resize');
                                                        }
                                                    }} key={index + Math.random()}>
                                                        <div className="row">
                                                            <div className="col-12 col-md-7 col">
                                                                <div className="clinic-details">
                                                                    <div className="trusted-txt"><img src={process.env.PUBLIC_URL + "/images/trusted-icon.svg"} className="mr-2" />Trusted by Xcarepro</div>
                                                                    <div className="p-name">{value.name}</div>
                                                                    <div className="p-address">
                                                                        {
                                                                            showAddress(value.address)
                                                                        }
                                                                    </div>

                                                                    {
                                                                        value.rating !== 0 ? <div className="ratting-star ">
                                                                            {
                                                                                <RatingComp rating={value.rating}></RatingComp>
                                                                            }
                                                                            <strong>
                                                                                {
                                                                                    console.log(typeof value.rating, typeof value.reviewCount, value.rating > 0, value.reviewCount > 0)
                                                                                }
                                                                                {
                                                                                    (value.rating > 0) &&
                                                                                    <>{value.rating}</>

                                                                                }
                                                                                {
                                                                                    (value.reviewCount > 0) &&
                                                                                    <>
                                                                                        ({value.reviewCount})
                                                                                </>
                                                                                }
                                                                            </strong>
                                                                        </div> : ""
                                                                    }
                                                                    {
                                                                        (this.state.hygieneList.status && value.HygieneFeatures) &&
                                                                        <HygieneRating key={'hrl1'} HygieneFeatures={value.HygieneFeatures} hygieneList={this.state.hygieneList}></HygieneRating>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-5 col align-self-center text-start text-md-end">
                                                                <div className="cd-dr-img">
                                                                    {
                                                                        (value.doctors && value.doctors.length > 0) ?
                                                                            <>
                                                                                {
                                                                                    value.doctors.map((valuePhoto: any, index: number) => {
                                                                                        if (index > 2) {
                                                                                            return null;
                                                                                        }
                                                                                        if (valuePhoto.photo && valuePhoto.photo.name && valuePhoto.photo.type) {
                                                                                            return (
                                                                                                <div key={index + Math.random()} className="img-box"><img src={apiConfigs.IMAGE_URL + 'doctor/' + valuePhoto.photo.name + '-200x200.' + valuePhoto.photo.type} className="img-fluid" /></div>
                                                                                            )
                                                                                        } else {
                                                                                            let doctorName = valuePhoto.fullname.replace("Dr.", "");
                                                                                            doctorName = doctorName.trim();
                                                                                            doctorName = doctorName.split(" ");
                                                                                            const letterOne = doctorName[0] ? doctorName[0].charAt(0) : '';
                                                                                            const letterTwo = doctorName[1] ? doctorName[1].charAt(0) : '';
                                                                                            return (<div key={index + Math.random()} className="img-box">
                                                                                                <Avatar>{letterOne}{letterTwo}</Avatar>
                                                                                                {/* <img src={apiConfigs.DEFAULT_IMAGE_URL_DOCTOR} className="img-fluid" /> */}
                                                                                            </div>);
                                                                                            // return (<div key={index + Math.random()} className="img-box"><img src={apiConfigs.DEFAULT_IMAGE_URL_DOCTOR} className="img-fluid" /></div>);
                                                                                        }
                                                                                    })
                                                                                }
                                                                                {
                                                                                    (value.doctors.length - 3 > 0) &&
                                                                                    <div className="img-box lmo">
                                                                                        +{value.doctors.length - 3}
                                                                                    </div>
                                                                                }
                                                                            </> :
                                                                            <>
                                                                                <div key={index + Math.random()} className="img-box"><img src={apiConfigs.DEFAULT_IMAGE_URL_DOCTOR} className="img-fluid" /></div>
                                                                            </>
                                                                    }
                                                                    {/* <div className="img-box"><img src="images/team_walter-heidary.jpg" className="img-fluid" /></div>
                                                                    <div className="img-box"><img src="images/sandeep-walia.jpg" className="img-fluid" /></div> */}
                                                                </div>
                                                                <div className="patients-no text-md-end"><img src="/images/ic_trending_up_24px.svg" className="mr-2" /> {value.totalPatient ? parseInt(value.totalPatient, 10) !== 0 ? value.totalPatient + '+' : '0' : '0'} new patients last 30 days</div>
                                                                <Link className="btn btn-primary" to={"/quiz/results/" + value.uniqueId} >View Profile</Link>
                                                                {
                                                                    (value.distanceKmAway && value.distanceKmAway !== '') &&
                                                                    <div className="distnc" style={{ textTransform: 'capitalize' }}>{value.distanceKmAway} Away</div>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <ul className="img-list-sec">
                                                                    {
                                                                        (features && isArray(features) && features.length > 0) &&
                                                                        <>
                                                                            {
                                                                                (features.map((feature: any, index: any) => {
                                                                                    if (index > 5) {
                                                                                        return null;
                                                                                    }
                                                                                    const findexFeature = FEATURES.findIndex((featureEach: any, index) => {
                                                                                        return feature.id.toString() === featureEach.id.toString();
                                                                                    });
                                                                                    if (findexFeature !== -1) {
                                                                                        return (
                                                                                            <li key={index + Math.random()}><img alt={FEATURES[findexFeature].name} src={process.env.PUBLIC_URL + FEATURES[findexFeature].src} /></li>
                                                                                        )
                                                                                    } else {
                                                                                        return null;
                                                                                    }
                                                                                }))

                                                                            }
                                                                            {
                                                                                <>
                                                                                    {
                                                                                        (features.length > 6 && this.state.expandedFeatureId !== value.uniqueId) &&
                                                                                        <>
                                                                                            <li onClick={(e) => { this.setState({ expandedFeatureId: value.uniqueId }) }}><span>More</span></li>
                                                                                        </>}
                                                                                </>
                                                                            }
                                                                            {
                                                                                (value.uniqueId === this.state.expandedFeatureId) &&
                                                                                <>
                                                                                    {
                                                                                        (features.map((feature: any, index: any) => {
                                                                                            if (index < 6) {
                                                                                                return null;
                                                                                            }
                                                                                            const findexFeature = FEATURES.findIndex((featureEach: any, index) => {
                                                                                                return feature.id.toString() === featureEach.id.toString();
                                                                                            })
                                                                                            if (findexFeature !== -1) {
                                                                                                return (
                                                                                                    <li key={index + Math.random()}><img alt={FEATURES[findexFeature].name} src={process.env.PUBLIC_URL + FEATURES[findexFeature].src} /></li>
                                                                                                )
                                                                                            }
                                                                                        }))
                                                                                    }
                                                                                </>
                                                                            }
                                                                            {
                                                                                (this.state.expandedFeatureId !== '' && this.state.expandedFeatureId === value.uniqueId) &&
                                                                                <>
                                                                                    <li onClick={(e) => { this.setState({ expandedFeatureId: '' }) }}><span>Less</span></li>
                                                                                </>
                                                                            }
                                                                        </>
                                                                    }
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </>
                                }
                            </div>
                            <div className="col-md-4">
                                <div className="map-sec" id="map">
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}
const mapStateToProps = (state: IAppState) => ({
    formwizardState: state.formwizard
});
const mapDispatchToProps = (dispatch: any) => ({
});
export default connect(mapStateToProps, mapDispatchToProps)(Listing);
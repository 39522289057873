import React from 'react';
import { CONSTANT } from '../../constants';
import { IAppState } from '../../store';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { addDentistService, removeDentistService } from '../../store/formwizard/actions';
import { FormWizardState } from '../../store/formwizard/types';
import { toast } from 'react-toastify';
interface Props {
    addDentistService: typeof addDentistService;
    removeDentistService: typeof removeDentistService;
    formwizardState: FormWizardState;
    history: any;
}
interface State {
    otherReason: string;
}
class DentistService extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            otherReason: '',
        }
    }
    componentDidMount() {
        const otherPresent = this.props.formwizardState.formwizard.dentistServices.findIndex((value) => {
            return value.id === "18";
        });
        if (otherPresent !== -1) {
            this.setState({ otherReason: this.props.formwizardState.formwizard.dentistServices[otherPresent].reason });
        }
    }
    shouldComponentUpdate(nextProps: Props, nextState: any) {
        if (this.props !== nextProps) {
            const otherPresent = nextProps.formwizardState.formwizard.dentistServices.findIndex((value) => {
                return value.id === "18";
            });
            if (otherPresent !== -1) {
                this.setState({ otherReason: nextProps.formwizardState.formwizard.dentistServices[otherPresent].reason });
            }
        }
        return true;
    }
    selectDentistService(dentistService: any) {
        const goalFound = this.props.formwizardState.formwizard.dentistServices.findIndex((value) => {
            return value.id === dentistService.id;
        });
        if (goalFound !== -1) {
            if (dentistService.id === "18") {
                this.setState({ otherReason: '' });
            }
            this.props.removeDentistService({ dentistService: dentistService });
        } else {
            this.props.addDentistService({ dentistService: dentistService });
        }
    }
    render() {
        const otherPresent = this.props.formwizardState.formwizard.dentistServices.findIndex((value) => {
            return value.id === "18";
        });
        return (
            <div className="col-12 col-md-10 dentist-services wizard">
                <div className="row  gutter-5">
                    <div className="col-12">
                        <div className="txt-box">
                            <h4 className="title">Choose all of the treatments you need.</h4>
                            <div className="sub-title">We’ll filter for dentists who specialize in specific treatments and technology.</div>
                        </div>
                    </div>
                    {
                        CONSTANT.QUIZ.services.map((service, index) => {
                            return (
                                <div className="col-lg-3 service-list">
                                    <a href="javascript:void(0)" className={"custom-click btn " + (this.props.formwizardState.formwizard.dentistServices.findIndex((value) => { return value.id === service.id }) !== -1 ? 'active' : '')} onClick={(e) => { this.selectDentistService(service) }}>
                                        <div className="check_corner">
                                            <i className={"" + (this.props.formwizardState.formwizard.dentistServices.findIndex((value) => { return value.id === service.id }) !== -1 ? 'fas fa-check-circle' : 'far fa-circle')}></i>
                                        </div>
                                        <div className="content-container text-start">
                                            <h5 className="main-text">{service.name}</h5>
                                        </div>
                                    </a>
                                </div>
                            )
                        })
                    }
                    {
                        otherPresent !== -1 ?
                            <div className="col-12 text-start form-sec">
                                <label>Other reason for visiting</label>
                                <input type="text" className="input-box"
                                    id="other-reason" value={this.state.otherReason}
                                    onInput={(e: any) => {
                                        this.setState({ otherReason: e.target.value });
                                    }}
                                    onChange={(e) => {
                                        this.setState({ otherReason: e.target.value });
                                    }} />
                            </div>
                            : null
                    }
                    <div className="col-12 text-center">
                        <div className="bottom_bar">
                            <button className={"btn btn-toggle " + (this.props.formwizardState.formwizard.dentistServices.length === 0 ? 'unactiveBtn' : 'activeBtn')}
                                onClick={(e) => {
                                    const selectedDS = this.props.formwizardState.formwizard.dentistServices[otherPresent];
                                    console.log(selectedDS);
                                    if (selectedDS) {
                                        if (this.state.otherReason && this.state.otherReason !== '') {
                                            this.props.removeDentistService({ dentistService: selectedDS });
                                            setTimeout(() => {
                                                Object.assign(selectedDS, { reason: this.state.otherReason });
                                                this.props.addDentistService({ dentistService: selectedDS });
                                                this.props.history.push('/quiz/goals');
                                            }, 100);
                                        } else {
                                            toast.warn('Please input the other reason.');
                                        }
                                    } else {
                                        //quiz/goals
                                        this.props.history.push('/quiz/criteria');
                                    }
                                }}
                            >Continue</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state: IAppState) => ({
    formwizardState: state.formwizard
});
const mapDispatchToProps = (dispatch: any) => ({
    addDentistService: (goal: any) => {
        dispatch(addDentistService(goal));
    },
    removeDentistService: (goal: any) => {
        dispatch(removeDentistService(goal));
    }
});
export default connect(mapStateToProps, mapDispatchToProps)(DentistService);
import { LandingPageState, LandingPageType, LANDING_PAGE } from "./types";
export const initialState: LandingPageState = {
    landingPage: {
        location: '',
        speciality: '',
        date: '',
        isInsurance:true,
        insuranceProvider:'',
        insurancePlan:'',
        paymentMethod:[],
        socialAssistance:'',
        filterData:{
            location: '',
            userLocation:'',
            speciality: '',
            date: null,
            isInsurance:true,
            insuranceProvider:'',
            insurancePlan:'',
            paymentMethod:[],
            socialAssistance:'',
        },
        primaryInsurance:'',
        secondaryInsurance:'',
        isIframe:false
    }
};

export const landingPageReducer = (state = initialState, action: LandingPageType) => {
    switch (action.type) {
        case LANDING_PAGE.ADD_LOCATION:
            return {
                ...state,
                landingPage: {
                    ...state.landingPage,
                    location: action.payload
                },
            }
        case LANDING_PAGE.ADD_SPECIALITY:
            return {
                ...state,
                landingPage: {
                    ...state.landingPage,
                    speciality: action.payload
                },
            }
        case LANDING_PAGE.ADD_DATE:
            return {
                ...state,
                landingPage: {
                    ...state.landingPage,
                    date: action.payload
                },
            }
            case LANDING_PAGE.ADD_ISINSURANCE:
                return {
                    ...state,
                    landingPage: {
                        ...state.landingPage,
                        isInsurance: action.payload
                    },
                }
            case LANDING_PAGE.ADD_ISURANCE_PROVIDER:
                return {
                    ...state,
                    landingPage: {
                        ...state.landingPage,
                        insuranceProvider: action.payload
                    },
                }
            case LANDING_PAGE.ADD_INSURANCE_PLAN:
                return {
                    ...state,
                    landingPage: {
                        ...state.landingPage,
                        insurancePlan: action.payload
                    },
                }
            case LANDING_PAGE.ADD_PAYMENT_METHOD:
                return {
                    ...state,
                    landingPage: {
                        ...state.landingPage,
                        paymentMethod: action.payload
                    },
                }
            case LANDING_PAGE.ADD_SOCIAL_ASSISTANCE:
                return {
                    ...state,
                    landingPage: {
                        ...state.landingPage,
                        socialAssistance: action.payload
                    },
                }
            case LANDING_PAGE.FILTER_DATA:
                return {
                    ...state,
                    landingPage: {
                        ...state.landingPage,
                        filterData: action.payload
                    },
                }
            case LANDING_PAGE.ADD_PRIMARY_INSURANCE:
                return {
                    ...state,
                    landingPage: {
                        ...state.landingPage,
                        primaryInsurance: action.payload
                    },
                }
            case LANDING_PAGE.ADD_SECONDARY_INSURANCE:
                return {
                    ...state,
                    landingPage: {
                        ...state.landingPage,
                        secondaryInsurance: action.payload
                    },
                }
            case LANDING_PAGE.ADD_IS_IFRAME:
                return {
                    ...state,
                    landingPage: {
                        ...state.landingPage,
                        isIframe: action.payload
                    },
                }
        default:
            return state;
    }
};
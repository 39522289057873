import React, { useState, useEffect } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import moment from 'moment';
import { allowOnlyAlpha, emailValidation, passwordValidator, takeOnlyDigitAndPlus, allowOnlyDigit } from '../../utils/validators';
import Axios from 'axios';
import apiConfigs from '../../configs/apiConfigs';
import { toast } from 'react-toastify';
import InputMask from 'react-input-mask';
import { register } from '../../services/auth';
import { setCookie, removeCookie } from '../../utils/global';
import Cookies from 'js-cookie';
import { signInWithCustomToken } from '../../utils/firebaseUtils';
import { getInsuranceProviderNew, getInsurancePlan } from '../../services/landingPageSearch';
import GuarantorInfoModal from './GuarantorInfoModal';
import { FormatAlignJustifyRounded, StarTwoTone } from '@material-ui/icons';
function RegisterModal(props: any) {

    const [state, setState] = useState({
        registerFormData: {
            email: '',
            firstName: '',
            lastName: '',
            phone: '',
            dob: moment().subtract(1, 'days'),
            password: '',
            cpassword: '',
            gender: 'Male',
            emailValidation: { valid: false, msg: '' },
            passwordValidation: { valid: false, msg: '' },
            cpasswordValidation: { valid: false, msg: '' },
            firstNameValidation: { valid: false, msg: '' },
            lastNameValidation: { valid: false, msg: '' },
            dobValidation: { valid: false, msg: '' },
            phoneValidation: { valid: false, msg: '' },
        },
        isLoading: false, loadingVerification: false, emailInvalid: false,
        isDependent: false, isPayThroughInsurance: true,
        insuranceForm: {
            insurance: '',
            insuranceName:'',
            insurancePlan: '',
            insurancePlanName:'',
            insuaranceValidation: { valid: false, msg: '' },
            insuarancePlanValidation: { valid: false, msg: '' },
        },
        isSecondary: false, isSecondaryGurantor: false,
        insuranceList: [] as any,
        insurancePlanList:[],
        isShowGurantorInfo: false,
        addAsSecondary: '' as any,
        guarantorList: [] as any
    });
    useEffect(() => {
        getInsuranceProviderApi();

    }, []);

    const handleInsuranceName=(value:any)=>{
        let insurance = state.insuranceList.find((item:any)=>item.id === value);
        console.log('insurance',insurance)
        if(value === 'other'){
            insurance = {
                ...insurance,
                plan: [{id: 'other', name: 'Other'}]
            }
        }else{
            insurance = {
                ...insurance,
                plan: [...insurance.plan,{id: 'other', name: 'Other'}]
            }
        }
        if(insurance && insurance.plan.length > 0){
            setState({ ...state, insuranceForm: { ...state.insuranceForm, 
                insurance: value, 
                insurancePlan:insurance.plan[0].id,
                insuaranceValidation: { valid: false, msg: '' } },
            insurancePlanList:insurance.plan });
        }else{
            setState({ ...state, insuranceForm: { ...state.insuranceForm, 
                insurance: value, 
                insuaranceValidation: { valid: false, msg: '' } },
            insurancePlanList:insurance.plan });
        }
        
    }
    const handleInsurancePlanName=(value:any)=>{
        setState({...state,insuranceForm:{...state.insuranceForm,insurancePlan:value}});
    }

    const clickBackToLogin = () => {
        props.backToLogin();
    }
    const getInsuranceProviderApi = () => {
        getInsuranceProviderNew().then((success: any) => {
            try {
                if (success && success.response && success.response.data && success.response.status === 200) {
                    if (success.response.data && success.response.data.status.error === false) {
                        console.log('Suucesss',success);
                        setState({ ...state, insuranceList: [...success.response.data.insurance,{id:'other',name:'Other',plan:[{id:'other',name:'Other'}]}] });
                        return;
                        // props.loadingEnd(false);
                    } else {
                        if (success.response.data && success.response.data.status.error === true) {
                            toast.error(success.response.data.status.msg);
                        }
                        return;
                        // props.loadingEnd(false);
                    }
                }
            } catch (error) {
                console.log('error', error);
            }
        });
    }
    const handleRegister = () => {
        if (state.registerFormData.firstName === "") {
            setState({
                ...state,
                registerFormData: {
                    ...state.registerFormData,
                    firstNameValidation: { valid: true, msg: 'First Name required' },
                    lastNameValidation: { valid: false, msg: '' },
                    emailValidation: { valid: false, msg: '' },
                    cpasswordValidation: { valid: false, msg: '' },
                    passwordValidation: { valid: false, msg: '' },
                    dobValidation: { valid: false, msg: '' },
                    phoneValidation: { valid: false, msg: '' },
                }
            });
            return;
        } else if (state.registerFormData.lastName === "") {
            setState({
                ...state,
                registerFormData: {
                    ...state.registerFormData,
                    firstNameValidation: { valid: false, msg: '' },
                    lastNameValidation: { valid: true, msg: 'Last name required' },
                    emailValidation: { valid: false, msg: '' },
                    cpasswordValidation: { valid: false, msg: '' },
                    passwordValidation: { valid: false, msg: '' },
                    dobValidation: { valid: false, msg: '' },
                    phoneValidation: { valid: false, msg: '' },
                }
            });
            return;
        } else if (state.registerFormData.email === "" || (state.registerFormData.email && !emailValidation(state.registerFormData.email))) {
            setState({
                ...state,
                registerFormData: {
                    ...state.registerFormData,
                    firstNameValidation: { valid: false, msg: '' },
                    lastNameValidation: { valid: false, msg: '' },
                    emailValidation: { valid: true, msg: 'Email required' },
                    cpasswordValidation: { valid: false, msg: '' },
                    passwordValidation: { valid: false, msg: '' },
                    dobValidation: { valid: false, msg: '' },
                    phoneValidation: { valid: false, msg: '' },
                }
            });
            return;
        } else if (state.registerFormData.phone === "" || state.registerFormData.phone?.length !== 10) {
            setState({
                ...state,
                registerFormData: {
                    ...state.registerFormData,
                    firstNameValidation: { valid: false, msg: '' },
                    lastNameValidation: { valid: false, msg: '' },
                    emailValidation: { valid: false, msg: '' },
                    cpasswordValidation: { valid: false, msg: '' },
                    passwordValidation: { valid: false, msg: '' },
                    dobValidation: { valid: false, msg: '' },
                    phoneValidation: { valid: true, msg: 'Invalid Phone' },
                }
            });
            return;
        } else if (state.registerFormData.dob && moment().diff(state.registerFormData.dob, "years") < 18) {
            setState({
                ...state,
                registerFormData: {
                    ...state.registerFormData,
                    firstNameValidation: { valid: false, msg: '' },
                    lastNameValidation: { valid: false, msg: '' },
                    emailValidation: { valid: false, msg: '' },
                    cpasswordValidation: { valid: false, msg: '' },
                    passwordValidation: { valid: false, msg: '' },
                    dobValidation: { valid: true, msg: 'Should be greater than 18 years' },
                    phoneValidation: { valid: false, msg: '' },
                }
            });
            return;
        } else if (state.registerFormData.password === "") {
            setState({
                ...state,
                registerFormData: {
                    ...state.registerFormData,
                    firstNameValidation: { valid: false, msg: '' },
                    lastNameValidation: { valid: false, msg: '' },
                    emailValidation: { valid: false, msg: '' },
                    cpasswordValidation: { valid: false, msg: '' },
                    passwordValidation: { valid: true, msg: 'Password required' },
                    dobValidation: { valid: false, msg: '' },
                    phoneValidation: { valid: false, msg: '' },
                }
            });
            return;
        } else if (state.registerFormData.cpassword === "") {
            setState({
                ...state,
                registerFormData: {
                    ...state.registerFormData,
                    firstNameValidation: { valid: false, msg: '' },
                    lastNameValidation: { valid: false, msg: '' },
                    emailValidation: { valid: false, msg: '' },
                    cpasswordValidation: { valid: true, msg: 'Confirm Password Required' },
                    passwordValidation: { valid: false, msg: '' },
                    dobValidation: { valid: false, msg: '' },
                    phoneValidation: { valid: false, msg: '' },
                }
            });
            return;
        } else if (!passwordValidator(state.registerFormData.password ? state.registerFormData.password : '')) {
            setState({
                ...state,
                registerFormData: {
                    ...state.registerFormData,
                    firstNameValidation: { valid: false, msg: '' },
                    lastNameValidation: { valid: false, msg: '' },
                    emailValidation: { valid: false, msg: '' },
                    cpasswordValidation: { valid: false, msg: '' },
                    passwordValidation: { valid: true, msg: 'Password must be at least 6 characters long(contain a number and an uppercase and lowercase).' },
                    dobValidation: { valid: false, msg: '' },
                    phoneValidation: { valid: false, msg: '' },
                }
            });
            return;
        } else if (!passwordValidator(state.registerFormData.cpassword ? state.registerFormData.cpassword : '')) {
            setState({
                ...state,
                registerFormData: {
                    ...state.registerFormData,
                    firstNameValidation: { valid: false, msg: '' },
                    lastNameValidation: { valid: false, msg: '' },
                    emailValidation: { valid: false, msg: '' },
                    passwordValidation: { valid: false, msg: '' },
                    cpasswordValidation: { valid: true, msg: 'Confirm Password must be at least 6 characters long(contain a number and an uppercase and lowercase).' },
                    dobValidation: { valid: false, msg: '' },
                    phoneValidation: { valid: false, msg: '' },
                }
            });
            return;
        } else if (state.registerFormData.password && state.registerFormData.cpassword && state.registerFormData.password !== state.registerFormData.cpassword) {
            setState({
                ...state,
                registerFormData: {
                    ...state.registerFormData,
                    firstNameValidation: { valid: false, msg: '' },
                    lastNameValidation: { valid: false, msg: '' },
                    emailValidation: { valid: false, msg: '' },
                    cpasswordValidation: { valid: true, msg: 'Confirm Password shoule be same as password' },
                    passwordValidation: { valid: false, msg: '' },
                    dobValidation: { valid: false, msg: '' },
                    phoneValidation: { valid: false, msg: '' },
                }
            });
            return;
        }else if(state.isPayThroughInsurance){
            if (state.insuranceForm.insurance === "") {
                setState({
                    ...state,
                    insuranceForm: {
                        ...state.insuranceForm,
                        insuaranceValidation: { valid: true, msg: '' },
                        insuarancePlanValidation: { valid: false, msg: '' },
                    }
                })
                return;
            }else if(state.insuranceForm.insurance === "other" && state.insuranceForm.insuranceName === ""){
                setState({
                    ...state,
                    insuranceForm: {
                        ...state.insuranceForm,
                        insuaranceValidation: { valid: true, msg: '' },
                        insuarancePlanValidation: { valid: false, msg: '' },
                    }
                })
                return;
            } 
            else if (state.insuranceForm.insurancePlan === "") {
                setState({
                    ...state,
                    insuranceForm: {
                        ...state.insuranceForm,
                        insuarancePlanValidation: { valid: true, msg: '' },
                        insuaranceValidation: { valid: false, msg: '' },
                    }
                })
                return;
            }else if(state.insuranceForm.insurancePlan === "other" && state.insuranceForm.insurancePlanName === ""){
                setState({
                    ...state,
                    insuranceForm: {
                        ...state.insuranceForm,
                        insuaranceValidation: { valid: false, msg: '' },
                        insuarancePlanValidation: { valid: true, msg: '' },
                    }
                })
                return;
            } 
            else if (!state.isDependent && state.isSecondary && state.isPayThroughInsurance && !state.addAsSecondary) {
                toast.warn('Please add Secondary guarantor.');
                return;
            } else if (state.isDependent && state.isPayThroughInsurance && state.guarantorList.length <= 0) {
                toast.warn('Please add Primary guarantor.');
                return;
            } else if (state.isDependent && state.isPayThroughInsurance && state.guarantorList.length <= 1 && state.isSecondaryGurantor) {
                toast.warn('Please add Secondary guarantor.');
                return;
            }
        } 
        setState({
            ...state,
            registerFormData: {
                ...state.registerFormData,
                firstNameValidation: { valid: false, msg: '' },
                lastNameValidation: { valid: false, msg: '' },
                emailValidation: { valid: false, msg: '' },
                cpasswordValidation: { valid: false, msg: '' },
                passwordValidation: { valid: false, msg: '' },
                dobValidation: { valid: false, msg: '' },
                phoneValidation: { valid: false, msg: '' },
            }
        });
        if (state.registerFormData.dob &&
            state.registerFormData.password &&
            (state.registerFormData.email || state.registerFormData.phone) && state.registerFormData.firstName &&
            state.registerFormData.lastName && state.registerFormData.lastName) {
            let request: any = ""; let individualGuarantor: any = ""; let patient: any = "";
            let primaryGuarantor: any = ""; let secondaryGuarantor: any = "";
            let selectedInsurance:any = state.insuranceList.find((item:any)=>item.id === state.insuranceForm.insurance);
            let selectedInsurancePlan: any = state.insurancePlanList.find((item: any) => item.id === state.insuranceForm.insurancePlan);
            if (state.isPayThroughInsurance) {
                patient = {
                    dob: moment(state.registerFormData.dob).format("YYYY-DD-MM"),
                    email: state.registerFormData.email,
                    fname: state.registerFormData.firstName,
                    lname: state.registerFormData.lastName,
                    phone: state.registerFormData.phone,
                    registeras: "normal",
                    password: state.registerFormData.password,
                    gender: state.registerFormData.gender,
                    isIndividual: !state.isDependent,
                    insurance: {
                        id: selectedInsurance ? selectedInsurance.id :'',
                        name:selectedInsurance && selectedInsurance.id === 'other' ?state.insuranceForm.insuranceName:selectedInsurance.name,
                        plan:selectedInsurancePlan ? selectedInsurancePlan.id:'',
                        planName: selectedInsurancePlan && selectedInsurancePlan.id === 'other'?state.insuranceForm.insurancePlanName: selectedInsurancePlan.name
                    }
                }
            } else {
                patient = {
                    dob: moment(state.registerFormData.dob).format("YYYY-DD-MM"),
                    email: state.registerFormData.email,
                    fname: state.registerFormData.firstName,
                    lname: state.registerFormData.lastName,
                    phone: state.registerFormData.phone,
                    registeras: "normal",
                    password: state.registerFormData.password,
                    gender: state.registerFormData.gender,
                    isIndividual: !state.isDependent,
                    request: {
                        paymode: "cash,credit,card,cheque"
                    }
                }
            }
            if (state.isPayThroughInsurance === false) {
                request = {
                    ...patient,
                    guarantor: patient
                }
            }
            if (!state.isDependent && !state.addAsSecondary && state.isPayThroughInsurance) {
                request = {
                    ...patient,
                    guarantor: patient
                }
            } else if (!state.isDependent && state.addAsSecondary && state.isPayThroughInsurance) {
                individualGuarantor = {
                    dob: moment(state.addAsSecondary.dob).format("YYYY-DD-MM"),
                    email: state.addAsSecondary.email,
                    fname: state.addAsSecondary.firstName,
                    lname: state.addAsSecondary.lastName,
                    phone: state.addAsSecondary.phone,
                    registeras: "normal",
                    gender: state.addAsSecondary.gender,
                    insurance: state.addAsSecondary.insurance
                }
                request = {
                    ...patient,
                    guarantor: patient,
                    xguarantor: individualGuarantor
                }
            }
            if (state.isDependent && state.isPayThroughInsurance) {
                if (state.guarantorList && state.guarantorList.length > 0) {
                    primaryGuarantor = {
                        dob: moment(state.guarantorList[0].dob).format("YYYY-DD-MM"),
                        email: state.guarantorList[0].email,
                        fname: state.guarantorList[0].firstName,
                        lname: state.guarantorList[0].lastName,
                        phone: state.guarantorList[0].phone,
                        registeras: "normal",
                        gender: state.guarantorList[0].gender,
                        insurance: state.guarantorList[0].insurance
                    }
                }
                if (state.guarantorList && state.guarantorList.length > 1 && state.isSecondaryGurantor) {
                    secondaryGuarantor = {
                        dob: moment(state.guarantorList[1].dob).format("YYYY-DD-MM"),
                        email: state.guarantorList[1].email,
                        fname: state.guarantorList[1].firstName,
                        lname: state.guarantorList[1].lastName,
                        phone: state.guarantorList[1].phone,
                        registeras: "normal",
                        gender: state.guarantorList[1].gender,
                        insurance: state.guarantorList[1].insurance
                    }
                }
                if (primaryGuarantor && secondaryGuarantor) {
                    request = {
                        ...patient,
                        guarantor: primaryGuarantor,
                        xguarantor: secondaryGuarantor
                    }

                } else if (primaryGuarantor && !secondaryGuarantor) {
                    request = {
                        ...patient,
                        guarantor: primaryGuarantor,
                    }
                }
            }
            setState({ ...state, isLoading: true });
            register(request).then((success: any) => {
                try {
                    if (success && success.response && success.response.data && success.response.status === 200) {
                        if (success.response.data && success.response.data.status.error === false) {
                            // if (success.response.data.id && !success.response.data.uniqueId) {
                            //     onHideModal();
                            //     props.userNotVerified(success.response.data.id);
                            // } else
                            //     // if ((success.response.data.phone === null && success.response.data.phoneVerified === null) || (success.response.data.phone !== null && success.response.data.phoneVerified !== null)) {
                            //     //     signInWithCustomToken(success.response.data.jwtToken).then(async (signInSuccess) => {
                            //     //         removeCookie('clinic_cookie');
                            //     //         removeCookie('patient_cookie');
                            //     //         setCookie(success.response.data, 'patient');
                            //     //         setState({ ...state, isLoading: false });
                            //     //         console.log('LoginSuccess', success);
                            //     //         onHideModal();
                            //     //         props.loginSuccess(success.response.data.uniqueId);
                            //     //     })
                            //     //         .catch((error) => {
                            //     //             console.log('error', error);
                            //     //             return;
                            //     //         });
                            //     //         onHideModal();
                            //     //     setState({ ...state, isLoading: false });
                            //     // } 
                            //     onHideModal();
                            // props.userNotVerified(success.response.data.id, success.response.data.phone);
                            // if (success.response.data.phone !== null && success.response.data.phoneVerified === null) {
                            //     // onHideModal();
                            //     props.hideModal();
                            //     props.userNotVerified(success.response.data.id, success.response.data.phone);
                            // } else {
                            //     onHideModal();
                            //     props.userNotVerified(success.response.data.id);
                            // }
                            props.userNotVerified(success.response.data.id, success.response.data.phone);
                            setState({ ...state, isLoading: false });
                        } else {
                            if (success.response.data && success.response.data.status.error === true) {
                                toast.error(success.response.data.status.msg);
                            }
                            setState({ ...state, isLoading: false });
                        }
                    }
                } catch (error) {
                    console.log('error', error);
                    setState({ ...state, isLoading: false });
                }
            });
        }
        // props.registerSuccess();
    }
    const handleChangeDate = (date: any) => {
        setState({
            ...state, registerFormData: {
                ...state.registerFormData,
                dob: date
            }
        })
    }
    const checkPhoneOrEmail = (emailOrPhone: string, type: string) => {
        let obj = {};
        if (type === "email") {
            obj = {
                type: "email",
                email: emailOrPhone
            }
        }
        setState({ ...state, isLoading: true });
        Axios.post(apiConfigs.API_URL + 'signup/validate/', obj).then((success) => {
            setState({ ...state, loadingVerification: false, isLoading: false });
            if (success.status === 200 && success.data !== "") {
                if (success.data.status.error === true) {
                    toast.warn(success.data.status.msg);
                    setState({ ...state, emailInvalid: true });
                } else {
                    setState({ ...state, emailInvalid: false });
                }
            }
        }, (error) => {
            setState({ ...state, loadingVerification: false, isLoading: false });
            toast.error('Something went wrong');
        });
    }

    const clearAllFields = () => {
        setState({
            ...state,
            registerFormData: {
                email: '',
                firstName: '',
                lastName: '',
                phone: '',
                dob: moment().subtract(1, 'days'),
                password: '',
                cpassword: '',
                gender: 'Male',
                emailValidation: { valid: false, msg: '' },
                passwordValidation: { valid: false, msg: '' },
                cpasswordValidation: { valid: false, msg: '' },
                firstNameValidation: { valid: false, msg: '' },
                lastNameValidation: { valid: false, msg: '' },
                dobValidation: { valid: false, msg: '' },
                phoneValidation: { valid: false, msg: '' },
            },
            insuranceForm: {
                insurance: '',
                insuranceName:'',
                insurancePlan: '',
                insurancePlanName:'',
                insuaranceValidation: { valid: false, msg: '' },
                insuarancePlanValidation: { valid: false, msg: '' },
            },
        });
    }
    const onHideModal = () => {
        setState({
            ...state,
            registerFormData: {
                email: '',
                firstName: '',
                lastName: '',
                phone: '',
                dob: moment().subtract(1, 'days'),
                password: '',
                cpassword: '',
                gender: 'Male',
                emailValidation: { valid: false, msg: '' },
                passwordValidation: { valid: false, msg: '' },
                cpasswordValidation: { valid: false, msg: '' },
                firstNameValidation: { valid: false, msg: '' },
                lastNameValidation: { valid: false, msg: '' },
                dobValidation: { valid: false, msg: '' },
                phoneValidation: { valid: false, msg: '' },
            },
            insuranceForm: {
                insurance: '',
                insuranceName:'',
                insurancePlan: '',
                insurancePlanName:'',
                insuaranceValidation: { valid: false, msg: '' },
                insuarancePlanValidation: { valid: false, msg: '' },
            },
        });
        props.hideModal();
    }
    return (
        <Modal className="master_popup public_popup regPopup" tabIndex={1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" show={props.isRegister} onHide={onHideModal}>
            <Modal.Header closeButton>
                <Modal.Title>Register</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <>
                    <div className="form-row">

                        <div className="col-md-6 mb-3">
                            <label>First name</label>
                            <input type="text" className="form-control" id="firstname" placeholder="Enter first name"
                                onInput={(e: any) => {
                                    if (allowOnlyAlpha(e.target.value)) {
                                        setState({
                                            ...state,
                                            registerFormData: {
                                                ...state.registerFormData,
                                                firstName: e.target.value,
                                                firstNameValidation: { valid: false, msg: '' }
                                            }
                                        });
                                    } else if (e.target.value === "") {
                                        setState({
                                            ...state,
                                            registerFormData: {
                                                ...state.registerFormData,
                                                firstName: e.target.value
                                            }
                                        });
                                    }
                                }}
                                onChange={(e) => {
                                    if (allowOnlyAlpha(e.target.value)) {
                                        setState({
                                            ...state,
                                            registerFormData: {
                                                ...state.registerFormData,
                                                firstName: e.target.value,
                                                firstNameValidation: { valid: false, msg: '' }
                                            }
                                        });
                                    } else if (e.target.value === "") {
                                        setState({
                                            ...state,
                                            registerFormData: {
                                                ...state.registerFormData,
                                                firstName: e.target.value
                                            }
                                        });
                                    }
                                }} value={state.registerFormData.firstName} />
                            {
                                (state.registerFormData.firstNameValidation && state.registerFormData.firstNameValidation.valid) &&
                                <div className="is-invalid">{state.registerFormData.firstNameValidation.msg}</div>
                            }
                        </div>
                        <div className="col-md-6 mb-3">
                            <label>Last name</label>
                            <input type="text" className="form-control" id="lastname" placeholder="Enter last name"
                                onInput={(e: any) => {
                                    if (allowOnlyAlpha(e.target.value)) {
                                        setState({
                                            ...state,
                                            registerFormData: {
                                                ...state.registerFormData,
                                                lastName: e.target.value
                                            }
                                        });
                                    } else if (e.target.value === "") {
                                        setState({
                                            ...state,
                                            registerFormData: {
                                                ...state.registerFormData,
                                                lastName: e.target.value
                                            }
                                        });
                                    }
                                }}
                                onChange={(e) => {
                                    if (allowOnlyAlpha(e.target.value)) {
                                        setState({
                                            ...state,
                                            registerFormData: {
                                                ...state.registerFormData,
                                                lastName: e.target.value,
                                                lastNameValidation: { valid: false, msg: '' }
                                            }
                                        });
                                    } else if (e.target.value === "") {
                                        setState({
                                            ...state,
                                            registerFormData: {
                                                ...state.registerFormData,
                                                lastName: e.target.value
                                            }
                                        });
                                    }
                                    // else {
                                    //     setState({...state,
                                    //         registerFormData: {
                                    //             ...state.registerFormData,
                                    //             lastName: ''
                                    //         }
                                    //     });
                                    // }
                                }}
                                value={state.registerFormData.lastName} />
                            {
                                (state.registerFormData.lastNameValidation && state.registerFormData.lastNameValidation.valid) &&
                                <div className="is-invalid">{state.registerFormData.lastNameValidation.msg}</div>
                            }
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="col-md-6 mb-3">
                            <label>Email address</label>
                            <input type="email" className="form-control" id="email" placeholder="Enter email"
                                onBlur={(e) => {
                                    if (!(e.target.value === "" || !emailValidation(e.target.value))) {
                                        checkPhoneOrEmail(e.target.value, "email");
                                    }
                                }}
                                onChange={(e) => {
                                    setState({
                                        ...state,
                                        registerFormData: {
                                            ...state.registerFormData,
                                            email: e.target.value,
                                            emailValidation: { valid: false, msg: '' }
                                        }
                                    });
                                }}
                                value={state.registerFormData.email} />
                            {
                                (state.registerFormData.emailValidation && state.registerFormData.emailValidation.valid) &&
                                <div className="is-invalid">{state.registerFormData.emailValidation.msg}</div>
                            }
                        </div>
                        <div className="col-md-6 mb-3">
                            <label>Mobile number</label>
                            <input type="text" className="form-control" id="phone" maxLength={10} value={state.registerFormData.phone}
                            placeholder="Enter mobile"
                                onInput={(e: any) => {
                                    if (allowOnlyDigit(e.target.value)) {
                                        setState({
                                            ...state,
                                            registerFormData: {
                                                ...state.registerFormData,
                                                phone: e.target.value
                                            }
                                        });
                                    } else if (e.target.value === "") {
                                        setState({
                                            ...state,
                                            registerFormData: {
                                                ...state.registerFormData,
                                                phone: e.target.value
                                            }
                                        });
                                    }
                                }}
                                onChange={(e) => {
                                    if (e.target.value.length === 10 && allowOnlyDigit(e.target.value)) {
                                        setState({
                                            ...state,
                                            registerFormData: {
                                                ...state.registerFormData,
                                                phone: e.target.value,
                                                phoneValidation: { valid: false, msg: '' }
                                            }
                                        });
                                    } else
                                        if (allowOnlyDigit(e.target.value)) {
                                            setState({
                                                ...state,
                                                registerFormData: {
                                                    ...state.registerFormData,
                                                    phone: e.target.value
                                                }
                                            });
                                        } else if (e.target.value === "") {
                                            setState({
                                                ...state,
                                                registerFormData: {
                                                    ...state.registerFormData,
                                                    phone: e.target.value
                                                }
                                            });
                                        }
                                }} />
                            {
                                (state.registerFormData.phoneValidation && state.registerFormData.phoneValidation.valid) &&
                                <div className="is-invalid">{state.registerFormData.phoneValidation.msg}</div>
                            }
                        </div>
                        <div className="col-md-6 mb-3">
                            <label className="form-label">Gender</label>
                            <select className="form-control" name="gender" value={state.registerFormData.gender}
                                onChange={(e) => { setState({ ...state, registerFormData: { ...state.registerFormData, gender: e.target.value } }) }}>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                            </select>
                        </div>
                        <div className="col-md-6 mb-3">
                            <label className="d-block">Date of Birth</label>
                            <KeyboardDatePicker
                                margin="none"
                                id={"date-picker-dialog"}
                                name={"date-picker-dialog"}
                                // label="Date of Birth"
                                format="YYYY/MM/DD"
                                maxDate={moment().subtract(1, 'days')}
                                value={moment(state.registerFormData.dob)}
                                onChange={(date: any) => { handleChangeDate(date) }}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                            {
                                (state.registerFormData.dobValidation && state.registerFormData.dobValidation.valid) &&
                                <div className="is-invalid">{state.registerFormData.dobValidation.msg}</div>
                            }
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-6 mb-3">
                            <label>Password</label>
                            <input type="password" className="form-control" id="password" placeholder="Enter password"
                                onChange={(e) => {
                                    setState({
                                        ...state,
                                        registerFormData: {
                                            ...state.registerFormData,
                                            password: e.target.value,
                                            passwordValidation: { valid: false, msg: '' }
                                        }
                                    });
                                }} value={state.registerFormData.password} />
                            {
                                (state.registerFormData.passwordValidation && state.registerFormData.passwordValidation.valid) &&
                                <div className="is-invalid">{state.registerFormData.passwordValidation.msg}</div>
                            }
                        </div>
                        <div className="col-md-6 mb-3">
                            <label>Re Type Password</label>
                            <input type="password" className="form-control" id="cpassword" placeholder="Enter re-type password"
                                onChange={(e) => {
                                    setState({
                                        ...state,
                                        registerFormData: {
                                            ...state.registerFormData,
                                            cpassword: e.target.value,
                                            cpasswordValidation: { valid: false, msg: '' }
                                        }
                                    });
                                }} value={state.registerFormData.cpassword} />
                            {
                                (state.registerFormData.cpasswordValidation && state.registerFormData.cpasswordValidation.valid) &&
                                <div className="text-danger error">{state.registerFormData.cpasswordValidation.msg}</div>
                            }
                        </div>
                        <div className="col-md-6">
                            <label className="form-label">Is patient dependent or Individual ?</label>
                            <div className="d-flex align-items-center mb-3">
                                <div className="form-check me-3">
                                    <input className="form-check-input" type="radio" name="test" id="individual" onClick={() => { setState({ ...state, isDependent: false }) }} checked={!state.isDependent} />
                                    <label className="form-check-label" htmlFor="individual">Individual</label>
                                </div>
                                <div className="form-check me-3">
                                    <input className="form-check-input" type="radio" name="test" id="dependent" onClick={() => { setState({ ...state, isDependent: true }) }} checked={state.isDependent} />
                                    <label className="form-check-label" htmlFor="dependent">Dependent</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <label className="form-label">
                                Payment through insurance ?
                            </label>
                            <div className="d-flex align-items-center">
                                <div className="form-check me-3">
                                    <input onClick={() => { setState({ ...state, isPayThroughInsurance: true }) }} checked={state.isPayThroughInsurance} className="form-check-input" type="radio" name="paythroughinsurance" id="payinsurance-yes" value="yes" />
                                    <label className="form-check-label" htmlFor="payinsurance-yes">Yes</label>
                                </div>
                                <div className="form-check me-3">
                                    <input onClick={() => { setState({ ...state, isPayThroughInsurance: false }) }} checked={!state.isPayThroughInsurance} className="form-check-input" type="radio" name="paythroughinsurance" id="payinsurance-no" value="no" />
                                    <label className="form-check-label" htmlFor="payinsurance-no">No</label>
                                </div>
                            </div>
                        </div>
                        {
                            (state.isPayThroughInsurance) ?
                                <>
                                    <div className="col-md-6 mt-3 mt-md-0">
                                        <label className="form-label">Insurance name:</label>
                                        <select className="form-control" name="insurancename" value={state.insuranceForm.insurance}
                                            onChange={(e) => { handleInsuranceName(e.target.value) }}>
                                            <option value="">Select insurance</option>
                                            {
                                                state.insuranceList && state.insuranceList.length > 0 &&
                                                state.insuranceList.map((item: any, index: any) => {
                                                    return (
                                                        <option key={index + Math.random() + 'insurance'} value={item.id}>{item.name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                        {
                                            state.insuranceForm.insurance === 'other' &&
                                        <input type="text" className="form-control" placeholder="Enter insurance name" name="insurancename"
                                            value={state.insuranceForm.insuranceName}
                                            onChange={(e) => { setState({ ...state, insuranceForm: { ...state.insuranceForm, insuranceName: e.target.value, insuaranceValidation: { valid: false, msg: '' } } }) }} />
                                        }
                                        {/* <input type="text" className="form-control" placeholder="Enter insurance name" name="insurancename" ref={register({ required: true })} /> */}
                                        {state.insuranceForm.insuaranceValidation.valid &&
                                            <div className="is-invalid">Insurance name is required</div>
                                        }
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-label">Insurance plan name:</label>
                                        <select className="form-control" name="insurancename" value={state.insuranceForm.insurancePlan}
                                            onChange={(e) => { handleInsurancePlanName(e.target.value) }}>
                                            <option value="">Select insurance plan</option>
                                            {
                                                state.insurancePlanList && state.insurancePlanList.length > 0 &&
                                                state.insurancePlanList.map((item: any, index: any) => {
                                                    return (
                                                        <option key={index + Math.random() + 'insurance'} value={item.id}>{item.name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                        {
                                            state.insuranceForm.insurancePlan === 'other' &&
                                        <input type="text" className="form-control" placeholder="Enter insurance plan name" name="insuranceplanname"
                                            value={state.insuranceForm.insurancePlanName}
                                            onChange={(e) => { setState({ ...state, insuranceForm: { ...state.insuranceForm, insurancePlanName: e.target.value, insuarancePlanValidation: { valid: false, msg: '' } } }) }} />
                                        }
                                        {
                                            state.insuranceForm.insuarancePlanValidation.valid &&
                                            <div className="is-invalid">Insurance plan name is required</div>
                                        }
                                    </div>
                                    <div className="col-12 mt-3">
                                        <label className="form-label">Guarantor info</label>
                                        {
                                            !state.isDependent &&
                                            <div className="d-flex align-items-center">
                                                <div className="form-check me-3">
                                                    <input checked={true} className="form-check-input" type="checkbox" name="selfguarantor" id="selfguarantor" value="self" disabled
                                                    />
                                                    <label className="form-check-label" htmlFor="selfguarantor">Self</label>
                                                </div>
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="checkbox" name="secondaryguarantor" id="secondaryguarantor"
                                                        onChange={() => { setState({ ...state, isSecondary: !state.isSecondary }) }}
                                                        value="secondary" />
                                                    <label className="form-check-label" htmlFor="secondaryguarantor">Secondary</label>
                                                </div>
                                                <div className="formLinks text-primary">
                                                    {
                                                        state.isSecondary &&

                                                        <a
                                                            onClick={() => { setState({ ...state, isShowGurantorInfo: true }) }}
                                                        >Click to add guarantor info</a>
                                                    }
                                                </div>
                                            </div>
                                        }
                                        {
                                            (state.isDependent) &&
                                            <>
                                                <div className="d-flex align-items-center">
                                                    <div className="form-check me-3">
                                                        <input checked={true} className="form-check-input" type="checkbox" name="primaryguarantor" id="primaryguarantor" value="primary" disabled />
                                                        <label className="form-check-label" htmlFor="primaryguarantor">Primary Guarantor</label>
                                                    </div>
                                                    <div className="form-check me-3">
                                                        <input onChange={() => { setState({ ...state, isSecondaryGurantor: !state.isSecondaryGurantor }) }} className="form-check-input" type="checkbox" name="secondaryguarantor" id="secondaryguarantor" value="secondary" />
                                                        <label className="form-check-label" htmlFor="secondaryguarantor">Secondary Guarantor</label>
                                                    </div>
                                                    <div className="formLinks text-primary">
                                                        {
                                                            <a
                                                                onClick={() => { setState({ ...state, isShowGurantorInfo: true }) }}
                                                            >Click to add guarantor info</a>
                                                        }
                                                    </div>
                                                </div>

                                            </>
                                        }
                                    </div>
                                    {
                                        (state.addAsSecondary && !state.isDependent) &&
                                        <div className="alert alert-success alert-dismissible">Secondary Guarantor : {
                                            state.addAsSecondary.firstName + " " + state.addAsSecondary.lastName + " (" +
                                            state.addAsSecondary.email + ")"
                                        }</div>
                                    }
                                    {
                                        state.isDependent && state.guarantorList && state.guarantorList.length > 0 &&
                                        <div className="alert alert-success alert-dismissible">Primary Guarantor : {
                                            state.guarantorList[0].firstName + " " + state.guarantorList[0].lastName + " (" +
                                            state.guarantorList[0].email + ")"
                                        }</div>
                                    }
                                    {
                                        state.isDependent && state.guarantorList && state.guarantorList.length > 1 && state.isSecondaryGurantor &&
                                        <div className="alert alert-success alert-dismissible">Secondary Guarantor : {
                                            state.guarantorList[1].firstName + " " + state.guarantorList[1].lastName + " (" +
                                            state.guarantorList[1].email + ")"
                                        }</div>
                                    }

                                </>
                                :
                                <div className="col-12 d-flex justify-content-end mb-3">
                                <label className="text-muted small p-0 col-md-6">Payment through cash/credit/debit.</label>
                                </div>
                                }
                    </div>

                    <div className="d-flex justify-content-between align-items-center mt-3">
                        <div className="formLinks"><a onClick={() => { clickBackToLogin() }}>Back to Sign in</a></div>
                        <button disabled={state.emailInvalid} onClick={() => { handleRegister() }} type="submit" className="btn btn-primary">
                            {
                                state.isLoading &&
                                <span className="aft_load right">
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </span>
                            }
                            sign up</button>
                    </div>
                    {
                        state.isShowGurantorInfo &&
                        <GuarantorInfoModal
                            isDependent={state.isDependent}
                            // for individual
                            addAsSecondary={(data: any) => {
                                setState({ ...state, addAsSecondary: data, isShowGurantorInfo: false })
                            }}
                            //dependent
                            guarantorList={(data: any) => {
                                setState({ ...state, guarantorList: data, isShowGurantorInfo: false });
                            }}
                            isAddPrimaryGuarantor={state.guarantorList.length === 1 && state.isSecondaryGurantor?true:false}
                            isSecondaryGurantor={state.isSecondaryGurantor}
                            isGuarantorInfo={state.isShowGurantorInfo}
                            addSecondaryGuarantorList={(data:any)=>{
                                let ans = state.guarantorList;
                                ans.push(data);
                                setState({ ...state, guarantorList: ans, isShowGurantorInfo: false });

                            }}
                            hideModal={() => { setState({ ...state, isShowGurantorInfo: false }) }}
                        />
                    }
                </>
            </Modal.Body>
        </Modal>

    )
}

export default RegisterModal
// niveditapatient@searchresultsmedia.com
// Info12345
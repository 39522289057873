import React from "react";
import HeaderWhite from "../LandingPageSearchNew/pages/HeaderWhite/HeaderWhite";
import Slider from "react-slick";
import {
  allowAlphabetsAndSpace,
  checkIfEmptyOrNull,
  emailValidation,
  postalCodeValidation,
  urlValidator,
} from "../../utils/validators";
import { maskUSPhone, takeOnlyDigit } from "../../utils/global";
import { toast } from "react-toastify";
import { submitDoctorForm } from "../../services/dentist";
import Accordion from "react-bootstrap/Accordion";
import { Card, Button } from "react-bootstrap";
import { Helmet } from "react-helmet";

interface State {
  cities: any[];
  formData: {
    fullName: string;
    email: string;
    phone: string;
    city: string;
    postalCode: string;
    practiceName: string;
    website: string;
    maskedPhone: string;
  };
  loading: boolean;
  successMsg: { isShow: boolean; msg: string };
  hideForm: boolean;
}
export default class Dentist extends React.Component<{}, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      cities: [
        "British Columbia",
        "Ontario",
        "Newfoundland and Labrador",
        "Nova Scotia",
        "Prince Edward Island",
        "New Brunswick",
        "Quebec",
        "Manitoba",
        "Saskatchewan",
        "Alberta",
        "Northwest Territories",
        "Nunavut",
        "Yukon Territory",
      ].sort(),
      formData: {
        city: "",
        email: "",
        fullName: "",
        website: "",
        practiceName: "",
        phone: "",
        postalCode: "",
        maskedPhone: "",
      },
      successMsg: { isShow: false, msg: "" },
      hideForm: true,
      loading: false,
    };
  }
  render() {
    const tesTimo = {
      slidesToShow: 1,
      autoplay: true,
      fade: true,
      cssEase: "linear",
      dots: false,
      infinite: true,
      Speed: 1000,
    };
    return (
      <>
        <Helmet>
          <title>Dental Clinic Registration - XcarePro</title>
          <meta
            name="description"
            content="Are you looking to grow your dental practice? List your practice on XcarePro to improve your clinic’s visibility, awareness, patient bookings and patient retention."
          />
        </Helmet>
        <HeaderWhite></HeaderWhite>
        <section className="banner-section master-banner d-banner">
          <div className="bg-overlay"></div>
          <div className="container h-100">
            <div className="row h-100">
              <div className="col-12 col-md-7 col-lg-6 align-self-center text-start">
                <div className="txt-section text-center text-md-start">
                  <h1>Grow Your Clinic</h1>
                  <p>
                    Patients trust XcarePro to help them find a health care
                    provider that meets their needs. Grow your practice through
                    risk-free patient acquisition and help create more happy
                    patients today.
                  </p>
                  <a
                    className="btn btn-primary btn-lg"
                    href="/clinic-registration/#gotodentist-form"
                  >
                    Get Started Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="dentist_dashboard d-dashboard">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="bg_design">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/images/dentist_dashboard.svg"
                    }
                  />
                </div>
                <div className="dentist_pt">
                  <h2>Are you a health care provider or clinic?</h2>
                  <p className="intro_desc">
                    Increase Your Patient Base With XcarePro
                  </p>
                  <p>
                    There are common pain points that every clinic faces.
                    XcarePro is an all-in-one system that helps clinics by
                    providing:
                  </p>
                  <ul className="big_list">
                    <li>
                      <div>Consistent Patient Acquisition</div>
                    </li>
                    <li>
                      <div>Guaranteed Results</div>
                    </li>
                    <li>
                      <div>Patient Loyalty</div>
                    </li>
                    <li>
                      <div>Online Ratings & Reviews</div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <img
                  src={process.env.PUBLIC_URL + "/images/dashboard-img.png"}
                  className="img-fluid"
                />
                <div className="dashboard">
                  <p>
                    Improve your clinic’s visibility, awareness, patient
                    bookings and retention by listing your practice on XcarePro.
                  </p>
                  <a
                    href="/clinic-registration/#gotodentist-form"
                    className="btn btn-lg btn-primary"
                  >
                    Sign Up For Free
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="patients-dashboard">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="img-sec">
                  <img
                    src={process.env.PUBLIC_URL + "/images/patient-booked.png"}
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-12 col-md-6 align-self-center">
                <div className="txt-sec">
                  <h2 className="like-h1">
                    <strong>100% Risk-Free</strong>
                  </h2>
                  <p>
                    <span>
                      You don't pay until the patient's appointment is complete.
                    </span>
                    At XcarePro, we won't charge you anything until you are able
                    to successfully book your first appointment.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="d-testimonials">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12">
                <div className="dentist_slide">
                  <Slider {...tesTimo}>
                    <div className="slider-sec">
                      <div className="media d-md-flex d-block">
                        <div className="media-img">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/images/testimnl-user.png"
                            }
                            alt=""
                          />
                        </div>
                        <div className="media-body">
                          <p>
                            I have been using XcarePro for the last six months.
                            The application's dashboard is very simple to
                            understand and user-friendly to operate. I can
                            easily manage all of my appointments and also easily
                            get in touch with my patients.
                          </p>
                          <div className="q-name">Dr. Teresa Stark</div>
                          <div className="q-clinic">Mydental Care</div>
                        </div>
                      </div>
                    </div>
                    <div className="slider-sec">
                      <div className="media d-flex">
                        <div className="media-img">
                          <img
                            src={process.env.PUBLIC_URL + "/images/doc2.png"}
                            alt=""
                          />
                        </div>
                        <div className="media-body">
                          <p>
                            Amazing experience with XcarePro. My patient
                            appointments have increased since I started using
                            this application.
                          </p>
                          <div className="q-name">Dr. Terry Kim</div>
                          <div className="q-clinic">Metro Dental</div>
                        </div>
                      </div>
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="gotodentist-form" className="new-dentist-form">
          <div className="container">
            <div className="row">
              <div className="com-12 col-md-10 col-lg-8 mx-auto text-center">
                <h2 className="like-h1">
                  Start receiving <strong>new patients</strong> today.
                </h2>
                <p>
                  Fill out the form below and one our XcarePro representatives
                  will contact you shortly.
                </p>
                {this.state.successMsg.isShow === true && (
                  <label>{this.state.successMsg.msg}</label>
                )}
              </div>
              <div className="com-12  col-md-10 col-lg-8 mx-auto">
                <form className="dentist-form">
                  <div className="form-row">
                    <div className="form-group col-md-6 m-auto">
                      <label htmlFor="d-location" className="d-location">
                        <img
                          src={process.env.PUBLIC_URL + "/images/location.svg"}
                          alt=""
                        />
                        Select your office location
                      </label>
                      <div className="select-box">
                        <select
                          className="form-control"
                          id="d-location"
                          value={this.state.formData.city}
                          onChange={(e) => {
                            console.log(e.target.value);
                            this.setState({
                              hideForm: false,
                              formData: {
                                ...this.state.formData,
                                city: e.target.value,
                              },
                            });
                          }}
                        >
                          <option value="" disabled>
                            Select location
                          </option>
                          {this.state.cities.map((value, index) => {
                            return (
                              <option value={value} key={index + Math.random()}>
                                {value}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div
                    className="show-on-change"
                    style={{ display: this.state.hideForm ? "none" : "block" }}
                  >
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label htmlFor="d-name">FULL NAME</label>
                        <input
                          type="text"
                          className="form-control"
                          id="d-name"
                          placeholder="Enter your name"
                          onInput={(e: any) => {
                            if (allowAlphabetsAndSpace(e.target.value)) {
                              this.setState({
                                formData: {
                                  ...this.state.formData,
                                  fullName: e.target.value,
                                },
                              });
                            }
                          }}
                          onChange={(e) => {
                            if (allowAlphabetsAndSpace(e.target.value)) {
                              this.setState({
                                formData: {
                                  ...this.state.formData,
                                  fullName: e.target.value,
                                },
                              });
                            }
                          }}
                          value={this.state.formData.fullName}
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="d-email">Email Address</label>
                        <input
                          type="email"
                          className="form-control"
                          id="d-email"
                          placeholder="Enter your email address"
                          onInput={(e: any) => {
                            this.setState({
                              formData: {
                                ...this.state.formData,
                                email: e.target.value,
                              },
                            });
                          }}
                          onChange={(e) => {
                            this.setState({
                              formData: {
                                ...this.state.formData,
                                email: e.target.value,
                              },
                            });
                          }}
                          value={this.state.formData.email}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label htmlFor="d-number">Phone number</label>
                        <input
                          type="tel"
                          className="form-control"
                          id="d-number"
                          placeholder="Enter your phone"
                          onInput={(e: any) => {
                            if (this.state.formData.phone.length < 10) {
                              this.setState({
                                formData: {
                                  ...this.state.formData,
                                  phone: takeOnlyDigit(e.target.value),
                                  maskedPhone: maskUSPhone(e.target.value),
                                },
                              });
                            }
                          }}
                          onChange={(e) => {
                            if (this.state.formData.phone.length < 10) {
                              this.setState({
                                formData: {
                                  ...this.state.formData,
                                  phone: takeOnlyDigit(e.target.value),
                                  maskedPhone: maskUSPhone(e.target.value),
                                },
                              });
                            }
                          }}
                          value={this.state.formData.maskedPhone}
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="d-zipcode">
                          Postall/zip code for your practice
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="d-zipcode"
                          placeholder="L7M 5A5"
                          onInput={(e: any) => {
                            this.setState({
                              formData: {
                                ...this.state.formData,
                                postalCode: e.target.value,
                              },
                            });
                          }}
                          onChange={(e) => {
                            this.setState({
                              formData: {
                                ...this.state.formData,
                                postalCode: e.target.value,
                              },
                            });
                          }}
                          value={this.state.formData.postalCode}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label htmlFor="d-oname">Office Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="d-oname"
                          placeholder="Enter your practice name"
                          onInput={(e: any) => {
                            if (allowAlphabetsAndSpace(e.target.value)) {
                              this.setState({
                                formData: {
                                  ...this.state.formData,
                                  practiceName: e.target.value,
                                },
                              });
                            }
                          }}
                          onChange={(e) => {
                            if (allowAlphabetsAndSpace(e.target.value)) {
                              this.setState({
                                formData: {
                                  ...this.state.formData,
                                  practiceName: e.target.value,
                                },
                              });
                            }
                          }}
                          value={this.state.formData.practiceName}
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="d-website">Office Website</label>
                        <input
                          type="url"
                          className="form-control"
                          id="d-website"
                          placeholder="www.yourwebsite.com"
                          onInput={(e: any) => {
                            this.setState({
                              formData: {
                                ...this.state.formData,
                                website: e.target.value,
                              },
                            });
                          }}
                          onChange={(e) => {
                            this.setState({
                              formData: {
                                ...this.state.formData,
                                website: e.target.value,
                              },
                            });
                          }}
                          value={this.state.formData.website}
                        />
                      </div>
                    </div>
                    {this.state.loading ? (
                      <i className="fas fa-circle-notch fa-spin"></i>
                    ) : (
                      <a
                        className="btn btn-primary btn-lg mx-auto"
                        onClick={() => {
                          this.getStarted();
                        }}
                        href="javascript:void(0);"
                      >
                        Get Started
                      </a>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        <section className="faq">
          <div className="container">
            <div className="row">
              <div className="col-12  col-md-10 col-lg-8 mx-auto">
                <h2 className="like-h1 text-center">
                  Frequently Asked questions
                </h2>
                <Accordion className="faq-content">
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="0">
                        How Does XcarePro Work?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        <p>
                          XcarePro connects patients looking for health
                          providers to clinics that meet their criteria.
                          Patients are directed to the XcarePro website and then
                          complete a questionnaire to qualify their needs and
                          service requests. Patients are then displayed
                          practices in their area that fit their criteria. From
                          here, patients can review practice information and
                          book an appointment with the practice they choose
                          directly on the XcarePro platform.
                        </p>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="1">
                        Is XcarePro Operating In My Area?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="1">
                      <Card.Body>
                        <p>
                          XcarePro is currently operating in Ontario but will be
                          expanding across Canada in the near future. Contact
                          our team to see when our platform will be available in
                          your area.
                        </p>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="2">
                        How Do Patients Find Health Care Providers On XcarePro?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="2">
                      <Card.Body>
                        <p>
                          Patients find health care providers on our platform by
                          simply selecting clinic preferences and desired
                          services. From here, the XcarePro platform displays
                          all the clinics in their vicinity that fit their
                          criteria.
                        </p>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="3">
                        How Is XcarePro Different?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="3">
                      <Card.Body>
                        <p>
                          We strive to deliver value to you. XcarePro is unique
                          because we do not charge any setup or recurring fees
                          like other marketing services. There are zero hidden
                          fees and no commitments, you only pay once a patient
                          has completed their appointment at your clinic.{" "}
                        </p>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="4">
                        How Do I Sign Up For XcarePro?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="4">
                      <Card.Body>
                        <p>
                          All practices are required to complete our
                          registration form providing us with details about your
                          practice. Once your practice has been approved, we
                          will setup a profile for your practice which patients
                          will be able to easily discover. We will also create a
                          custom dashboard for your clinic so you can
                          communicate and manage all patient appointments.{" "}
                        </p>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="5">
                        How Much Does XcarePro Cost?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="5">
                      <Card.Body>
                        <p>
                          Practices only pay for patients that they receive and
                          that have actually completed treatment. There are no
                          setup or hidden fees.{" "}
                        </p>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </div>
            </div>
            <div className="text-center mt-5">
              <a
                className="btn btn-primary btn-lg"
                href="/clinic-registration/#gotodentist-form"
              >
                Get Started With XcarePro
              </a>
            </div>
          </div>
        </section>
      </>
    );
  }
  async getStarted() {
    if (checkIfEmptyOrNull(this.state.formData.fullName)) {
      toast.warn("Full name is required");
      return;
    } else if (checkIfEmptyOrNull(this.state.formData.email)) {
      toast.warn("Email is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    } else if (checkIfEmptyOrNull(this.state.formData.phone)) {
      toast.warn("Phone is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    } else if (checkIfEmptyOrNull(this.state.formData.postalCode)) {
      toast.warn("Postal code is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    } else if (checkIfEmptyOrNull(this.state.formData.practiceName)) {
      toast.warn("Office name is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    } else if (checkIfEmptyOrNull(this.state.formData.website)) {
      toast.warn("Office website is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    } else if (!emailValidation(this.state.formData.email)) {
      toast.warn("Email is invalid", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    } else if (this.state.formData.phone.length !== 10) {
      toast.warn("Phone is invalid", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    } else if (
      !postalCodeValidation(this.state.formData.postalCode) ||
      this.state.formData.postalCode.trim().length !== 6
    ) {
      toast.warn("Postal code is invalid", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    } else if (!urlValidator(this.state.formData.website)) {
      toast.warn("Website is invalid", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }
    try {
      this.setState({ loading: true });
      const request = JSON.parse(JSON.stringify(this.state.formData));
      delete request.maskedPhone;
      const result: any = await submitDoctorForm(request);
      if (result && result.response) {
        if (result.response.status === 200) {
          if (result.response.data && result.response.data.status) {
            if (result.response.data.status.error) {
              toast.error("Something went wrong");
              this.setState({ loading: false });
              return;
            } else if (result.response.data.status.msg) {
              console.log(this.state.hideForm);
              this.resetForm();
              this.setState({
                successMsg: {
                  isShow: true,
                  msg: result.response.data.status.msg,
                },
                hideForm: true,
                loading: false,
              });
              return;
            }
            this.setState({ loading: false });
            toast.error("Something went wrong");
            return;
          }
          this.setState({ loading: false });
          toast.error("Something went wrong");
          return;
        }
        this.setState({ loading: false });
        toast.error("Something went wrong");
        return;
      }
      this.setState({ loading: false });
      toast.error("Something went wrong");
      return;
    } catch (err) {
      this.setState({ loading: false });
      toast.error("Something went wrong");
    }
  }
  resetForm() {
    this.setState({
      formData: {
        city: "",
        email: "",
        fullName: "",
        website: "",
        practiceName: "",
        phone: "",
        postalCode: "",
        maskedPhone: "",
      },
    });
  }
}

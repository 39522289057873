import axios from 'axios';
import configs from '../../../configs/apiConfigs';
import { attachHeader } from '../../../utils/apiHandler'
import { BODY_TYPE, CommonApiOptions, METHOD_TYPE } from "../../../constants/api";
import { commonApi } from "../../../helpers/api";

export const getPatientList = (Id:any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + 'patient/'+Id+'/patientlist/',
        method: METHOD_TYPE.GET,
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
    };
    return commonApi(request);
}

export const addDependant = (uniqueId:any,data:any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + 'patient/'+uniqueId+'/dependant/add/',
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.RAW,
            data: data
        },
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
    };
    return commonApi(request);
}

export const bookappointmentSlotCheck = (data:any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + 'bookappointmentSlotCheck/',
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.RAW,
            data: data
        },
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
    };
    return commonApi(request);
}

export const appointmentRequest = (data:any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + 'patient/appointment/',
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.RAW,
            data: data
        },
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
    };
    return commonApi(request);
}

export const specialityWiseClinicList = (data: any) => {
    console.log('data', data);
    if (data.patientUniqueId) {
        const request: CommonApiOptions = {
            url: configs.API_URL + 'cliniclist/',
            method: METHOD_TYPE.POST,
             body: {
                type: BODY_TYPE.RAW,
                data: data
            },
            apiOptions: {
                headers: attachHeader(true),
                withCredentials: true
            },
        };
        return commonApi(request);
    } else {
        const request: CommonApiOptions = {
            url: configs.API_URL + 'cliniclist/',
            method: METHOD_TYPE.POST,
            body: {
                type: BODY_TYPE.RAW,
                data: data
            },
            apiOptions: {
                headers: {
                    xprApp: 'patient'
                },
                withCredentials: true
            }
        };
        return commonApi(request);
    }
}

export const fetchClinicList = (data: any) => {
    if (data.location.patientUniqueId) {
        const request: CommonApiOptions = {
            url: configs.API_URL + 'resultsClinic/',
            method: METHOD_TYPE.POST,
            body: {
                type: BODY_TYPE.RAW,
                data: data
            },
            apiOptions: {
                headers: attachHeader(true),
                withCredentials: true
            },
        };
        return commonApi(request);
    } else {
        const request: CommonApiOptions = {
            url: configs.API_URL + 'resultsClinic/',
            method: METHOD_TYPE.POST,
            body: {
                type: BODY_TYPE.RAW,
                data: data
            },
            apiOptions: {
                headers: {
                    xprApp: 'patient'
                },
                withCredentials: true
            }
        };
        return commonApi(request);
    }
}

export const bookAppointmentGuest = (data: any) => {
    console.log("data", data);
    const request: CommonApiOptions = {
        url: configs.API_URL + 'utility/bookappointment/',
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.RAW,
            data: data
        },
        apiOptions: {
            headers: attachHeader(false),
            withCredentials: true
        },
    };
    return commonApi(request);
}
import React from 'react';
import Axios from 'axios';
import configs from '../configs/apiConfigs';
interface Props {}
interface State {
    privacyPolicy: any;
}
class PrivacyPolicyNew extends React.Component<Props, State> {
    constructor(props:any) {
        super(props);
        this.state = {
            privacyPolicy:''
        };
    }
    componentDidMount() {
        Axios.get(configs.API_URL + 'config/', {headers: {xprApp:'front'}}).then((success) => {
            if (success.status === 200 && success.data.termOfUse) {
                this.setState({privacyPolicy: success.data.privacyPolicy});
            }
        }, (error) => {
            console.log(error);
        });
    }
    render() {
        return(
            <div dangerouslySetInnerHTML={{ __html: this.state.privacyPolicy }} />
        )
    }
}
export default PrivacyPolicyNew;
import React from "react";
import { Modal } from "react-bootstrap";
import "./BookPopUp.css";
import { registerUser, verifyCode } from "../../services/dentist";
import PinInput from "react-pin-input";
import InputMask from "react-input-mask";
import { takeOnlyDigitAndPlus, takeOnlyDigit } from "../../utils/global";
import Axios from "axios";
import apiConfigs from "../../configs/apiConfigs";
import {
  emailValidation,
  allowDigit,
  allowAlphabetsAndSpace,
} from "../../utils/validators";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import * as firebase from "firebase/app";
import "firebase/firestore";
import { signInWithCustomToken } from "../../utils/firebaseUtils";
import { KeyboardDatePicker, KeyboardTimePicker } from "@material-ui/pickers";
import moment from "moment";
import { CONTACTMETHODS } from "../../constants/contactMethods";
interface Props {
  modalNumber: any;
  data: any;
  change(status: any, redirect: any): void;
}
interface State {
  modalNumber: any;
  email: string;
  phone: string;
  fname: string;
  lname: string;
  emailValidation: { isInValid: boolean; msg: string };
  phoneValidation: { isInValid: boolean; msg: string };
  fnameValidation: { isInValid: boolean; msg: string };
  lnameValidation: { isInValid: boolean; msg: string };
  verificationCode: string;
  verificationCodeFalse: boolean;
  successResponse: any;
  password: string;
  cpassword: string;
  passwordValidation: { isInValid: boolean; msg: string };
  btnChange: { isShow: boolean; msg: string };
  redirectToHome: boolean;
  intervalTime: number;
  popUpOneLoader: boolean;
  popUpTwoLoader: boolean;
  popUpThreeLoader: boolean;
  verificationCodeValidation: { isInValid: boolean; msg: string };
  appointmentFor: any;
  dependantName: string;
  dependantRelation: string;
  isExistingClinic: boolean;
  isaddDependant: boolean;
  //Input
  firstName: any;
  lastName: any;
  relation: any;
  emailId: any;
  dob: any;
  mobile: any;
  gender: any;
  contactMethod: any;
  reminder: any;
  //AddedDependant
  addedDependant: any;
}
class BookPopUp extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      modalNumber: props.modalNumber,
      email: "",
      phone: "",
      fname: "",
      lname: "",
      emailValidation: { isInValid: false, msg: "" },
      phoneValidation: { isInValid: false, msg: "" },
      fnameValidation: { isInValid: false, msg: "" },
      lnameValidation: { isInValid: false, msg: "" },
      verificationCode: "",
      verificationCodeFalse: false,
      successResponse: "",
      password: "",
      cpassword: "",
      passwordValidation: { isInValid: false, msg: "" },
      btnChange: { isShow: false, msg: "" },
      redirectToHome: false,
      intervalTime: 30,
      popUpOneLoader: false,
      popUpTwoLoader: false,
      popUpThreeLoader: false,
      verificationCodeValidation: { isInValid: false, msg: "" },
      appointmentFor: "",
      dependantName: "",
      dependantRelation: "",
      isExistingClinic: false,
      isaddDependant: false,
      firstName: "",
      lastName: "",
      relation: "Son",
      emailId: "",
      dob: moment().subtract(1, "days"),
      mobile: "",
      gender: "Male",
      contactMethod: CONTACTMETHODS.EMAIL_TEXT,
      reminder: "",
      addedDependant: false,
    };
    this.hideHandle = this.hideHandle.bind(this);
  }
  componentDidMount() {
    console.log("thisProps", this.props);
  }
  shouldComponentUpdate(nextProps: any, nextState: any) {
    if (this.props !== nextProps) {
      console.log(nextProps);
      this.setState({ modalNumber: nextProps.modalNumber });
    }
    return true;
  }
  hideHandle() {
    this.props.change(false, false);
  }
  registerUser() {
    this.setState({ popUpOneLoader: true });
    if (this.state.fname === "" || this.state.fname.trim() === "") {
      this.setState({
        fnameValidation: { isInValid: true, msg: "First name required." },
        lnameValidation: { isInValid: false, msg: "" },
        emailValidation: { isInValid: false, msg: "" },
        phoneValidation: { isInValid: false, msg: "" },
        popUpOneLoader: false,
      });
    } else if (this.state.lname === "" || this.state.lname.trim() === "") {
      this.setState({
        lnameValidation: { isInValid: true, msg: "Last name required." },
        fnameValidation: { isInValid: false, msg: "" },
        emailValidation: { isInValid: false, msg: "" },
        phoneValidation: { isInValid: false, msg: "" },
        popUpOneLoader: false,
      });
    } else if (this.state.email === "") {
      this.setState({
        emailValidation: { isInValid: true, msg: "Email required." },
        lnameValidation: { isInValid: false, msg: "" },
        fnameValidation: { isInValid: false, msg: "" },
        phoneValidation: { isInValid: false, msg: "" },
        popUpOneLoader: false,
      });
    } else if (!emailValidation(this.state.email)) {
      this.setState({
        emailValidation: { isInValid: true, msg: "Email invalid" },
        lnameValidation: { isInValid: false, msg: "" },
        fnameValidation: { isInValid: false, msg: "" },
        phoneValidation: { isInValid: false, msg: "" },
        popUpOneLoader: false,
      });
    } else if (this.state.phone === "") {
      this.setState({
        phoneValidation: { isInValid: true, msg: "Phone required." },
        emailValidation: { isInValid: false, msg: "" },
        lnameValidation: { isInValid: false, msg: "" },
        fnameValidation: { isInValid: false, msg: "" },
        popUpOneLoader: false,
      });
    } else if (takeOnlyDigit(this.state.phone).length !== 11) {
      this.setState({
        emailValidation: { isInValid: false, msg: "" },
        lnameValidation: { isInValid: false, msg: "" },
        fnameValidation: { isInValid: false, msg: "" },
        phoneValidation: { isInValid: true, msg: "Phone invalid" },
        popUpOneLoader: false,
      });
    } else {
      registerUser({
        email: this.state.email,
        phone: takeOnlyDigitAndPlus(this.state.phone),
        fname: this.state.fname,
        lname: this.state.lname,
      })
        .then((success: any) => {
          if (success.response && success.response.status === 200) {
            if (
              success.response.data &&
              success.response.data.isNewPatient === false
            ) {
              this.setState({ modalNumber: 3 });
            } else if (
              success.response.data &&
              success.response.data.isNewPatient === true &&
              success.response.data.patient &&
              success.response.data.patient.id
            ) {
              this.setState({ modalNumber: 2 });
              const interval = setInterval(() => {
                if (this.state.intervalTime === 0) {
                  clearInterval(interval);
                } else {
                  this.setState({ intervalTime: this.state.intervalTime - 1 });
                }
              }, 1000);
            } else if (
              success.response.data &&
              success.response.data.isNewPatient === true
            ) {
              this.setState({ modalNumber: 1 });
              this.setState({
                phoneValidation: {
                  isInValid: true,
                  msg: success.response.data.status.msg,
                },
              });
            }
            console.log("ResponseData", success.response.data);
            this.setState({
              successResponse: success.response.data,
              appointmentFor:
                success.response.data &&
                success.response.data.dependant &&
                success.response.data.dependant.length > 0
                  ? 0
                  : "",
              popUpOneLoader: false,
            });
          }
        })
        .catch((error) => {
          this.setState({ popUpOneLoader: false });
          console.log(error);
        });
    }
  }
  verifyCode() {
    this.setState({ popUpTwoLoader: true });
    if (this.state.verificationCode.length < 4) {
      this.setState({
        verificationCodeValidation: {
          isInValid: true,
          msg: "Code should be of four digit",
        },
        popUpTwoLoader: false,
      });
      return;
    }
    this.setState({
      verificationCodeValidation: { isInValid: false, msg: "" },
      verificationCodeFalse: false,
    });
    console.log("ResponseSi", this.state.successResponse);
    verifyCode({
      id: this.state.successResponse.patient.id,
      email: this.state.successResponse.patient.email,
      phone: this.state.successResponse.patient.phone,
      fname: this.state.successResponse.patient.fname,
      lname: this.state.successResponse.patient.lname,
      code: this.state.verificationCode,
    })
      .then((success: any) => {
        this.setState({ popUpTwoLoader: false });
        if (success.response.status === 200) {
          if (success.response.data.status.error === false) {
            this.setState({ modalNumber: 3 });
            this.setState({ verificationCodeFalse: false });
            this.setState({ successResponse: success.response.data });
          } else {
            this.setState({ verificationCodeFalse: true });
          }
        }
      })
      .catch((error) => {
        this.setState({ popUpTwoLoader: false });
      });
  }
  registerUserConfirm() {
    try {
      this.setState({ popUpThreeLoader: true });
      if (this.state.password === "" || this.state.password.trim() === "") {
        this.setState({ popUpThreeLoader: false });
        this.setState({
          passwordValidation: { isInValid: true, msg: "Password required" },
        });
        return;
      } else if (
        this.state.cpassword === "" ||
        this.state.cpassword.trim() === ""
      ) {
        this.setState({ popUpThreeLoader: false });
        this.setState({
          passwordValidation: {
            isInValid: true,
            msg: "Confirm Password required",
          },
        });
        return;
      }
      if (this.state.password !== this.state.cpassword) {
        this.setState({ popUpThreeLoader: false });
        this.setState({
          passwordValidation: { isInValid: true, msg: "Password do not match" },
        });
        return;
      } else if (this.state.appointmentFor === "") {
        this.setState({ popUpThreeLoader: false });
        this.setState({
          passwordValidation: {
            isInValid: true,
            msg: "Please select appointment for.",
          },
        });
        return;
      } /* else if (this.state.appointmentFor === "2" && this.state.dependantName === "") {
                this.setState({ popUpThreeLoader: false });
                this.setState({ passwordValidation: { isInValid: true, msg: 'Please enter dependant name.' } });
                return;
            } else if (this.state.appointmentFor === "2" && this.state.dependantRelation === "") {
                this.setState({ popUpThreeLoader: false });
                this.setState({ passwordValidation: { isInValid: true, msg: 'Please enter dependance relation.' } });
                return;
            } */ else if (this.state.password === this.state.cpassword) {
        this.setState({ passwordValidation: { isInValid: false, msg: "" } });
        console.log(this.props.data);
        console.log(this.state.successResponse);
        if (this.props.data) {
          const clinic = {
            id: this.props.data.id,
            doctor: {
              id: this.props.data.doctors[0]
                ? this.props.data.doctors[0].id
                : "",
            },
          };
          const appointmentType = {
            id: this.props.data.doctors[0].potentialAppointmentTypes[0]
              ? this.props.data.doctors[0].potentialAppointmentTypes[0].id
              : "",
          };
          const request: any = localStorage.getItem(
            "ca.xp.wizard.quiz.request"
          );
          const requestObj = JSON.parse(request);
          const referrer = localStorage.getItem("referrer");
          if (this.state.successResponse.isNewPatient) {
            Object.assign(this.state.successResponse.patient, {
              password: this.state.password,
            });
            /*  Object.assign(requestObj, {
                             appointmentFor: this.state.appointmentFor === "1" ? "me" : "other",
                             dependantName: this.state.dependantName,
                             dependantRelation: this.state.dependantRelation
                         }); */
            const request = {
              isNewPatient: this.state.successResponse.isNewPatient,
              isExistingClinic: this.state.isExistingClinic,
              patient: this.state.successResponse.patient,
              clinic: clinic,
              appointmenttype: appointmentType,
              request: requestObj,
            };
            const appointmentFor =
              this.state.successResponse.dependant[this.state.appointmentFor];
            if (appointmentFor.isNew) {
              Object.assign(request, {
                appointmentFor: "new",
                dependant: {
                  appointmentFor: "new",
                  ...appointmentFor,
                },
              });
            } else {
              Object.assign(request, {
                appointmentFor: "existing",
                dependant: {
                  appointmentFor: "existing",
                  id: appointmentFor.id,
                  ...appointmentFor,
                },
              });
            }
            if (referrer && referrer !== "") {
              Object.assign(request, { referral: referrer });
            }
            Axios.post(
              apiConfigs.API_URL +
                "patient/" +
                this.state.successResponse.patient.id +
                "/appointment/",
              request,
              {
                headers: {
                  xprApp: "front",
                },
              }
            )
              .then(async (success: any) => {
                if (success.status === 200) {
                  if (success.data.status.error === false) {
                    this.patientLogin(
                      this.state.email,
                      this.state.password,
                      (token: any) => {
                        signInWithCustomToken(token)
                          .then(async (signInSuccess) => {
                            console.log("Firebase:", signInSuccess);
                            try {
                              await this.addStatusToFirebase(
                                success.data.appopintment,
                                success.data.patient,
                                this.props.data.uniqueId,
                                success.data.dependantUniqueId
                              );
                            } catch (error) {}
                            try {
                              localStorage.removeItem(
                                "ca.xp.wizard.quiz.request"
                              );
                              localStorage.removeItem(
                                "ca.xp.wizard.match.results"
                              );
                              localStorage.removeItem("referrer");
                            } catch (err) {
                              console.log(err);
                            }
                            this.setState({
                              btnChange: {
                                isShow: true,
                                msg: success.data.status.msg,
                              },
                              password: "",
                              cpassword: "",
                            });
                            this.setState({ redirectToHome: true });
                          })
                          .catch((error) => {});
                      }
                    );
                  } else {
                    this.setState({
                      passwordValidation: {
                        isInValid: true,
                        msg: success.data.status.msg,
                      },
                    });
                  }
                }
                this.setState({ popUpThreeLoader: false });
              })
              .catch((error) => {
                this.setState({ popUpThreeLoader: false });
                console.log(error);
              });
          } else {
            /*   Object.assign(requestObj, {
                              appointmentFor: this.state.appointmentFor === "1" ? "me" : "other",
                              dependantName: this.state.dependantName,
                              dependantRelation: this.state.dependantRelation
                          }); */
            const request = {
              isNewPatient: this.state.successResponse.isNewPatient,
              isExistingClinic: this.state.isExistingClinic,
              patient: {
                email: this.state.email,
                phone: takeOnlyDigitAndPlus(this.state.phone),
                fname: this.state.fname,
                lname: this.state.lname,
                password: this.state.password,
              },
              clinic: clinic,
              appointmenttype: appointmentType,
              request: requestObj,
            };
            const appointmentFor =
              this.state.successResponse.dependant[this.state.appointmentFor];
            if (appointmentFor.isNew) {
              Object.assign(request, {
                appointmentFor: "new",
                dependant: {
                  appointmentFor: "new",
                  ...appointmentFor,
                },
              });
            } else {
              Object.assign(request, {
                appointmentFor: "existing",
                dependant: {
                  appointmentFor: "existing",
                  id: appointmentFor.id,
                  ...appointmentFor,
                },
              });
            }
            console.log(request);
            Axios.post(apiConfigs.API_URL + "patient/appointment/", request, {
              headers: {
                xprApp: "front",
              },
            })
              .then(async (success: any) => {
                if (success.status === 200) {
                  if (success.data.status.error === false) {
                    this.patientLogin(
                      this.state.email,
                      this.state.password,
                      (token: any) => {
                        signInWithCustomToken(token)
                          .then(async (signInSuccess) => {
                            console.log("Firebase:", signInSuccess);
                            try {
                              await this.addStatusToFirebase(
                                success.data.appopintment,
                                success.data.patient,
                                this.props.data.uniqueId,
                                success.data.dependantUniqueId
                              );
                            } catch (error) {}
                            try {
                              localStorage.removeItem(
                                "ca.xp.wizard.quiz.request"
                              );
                              localStorage.removeItem(
                                "ca.xp.wizard.match.results"
                              );
                            } catch (err) {
                              console.log(err);
                            }
                            this.setState({
                              btnChange: {
                                isShow: true,
                                msg: success.data.status.msg,
                              },
                              password: "",
                              cpassword: "",
                            });
                            this.setState({ redirectToHome: true });
                          })
                          .catch((error) => {});
                      }
                    );
                  } else {
                    this.setState({
                      passwordValidation: {
                        isInValid: true,
                        msg: success.data.status.msg,
                      },
                    });
                  }
                }
                this.setState({ popUpThreeLoader: false });
              })
              .catch((error) => {
                this.setState({ popUpThreeLoader: false });
                console.log(error);
              });
          }
        }
      }
    } catch (err) {
      this.setState({ popUpThreeLoader: false });
      console.log(err);
    }
  }
  sendVerificationCode() {
    this.setState({
      popUpTwoLoader: true,
      verificationCodeFalse: false,
      verificationCodeValidation: { isInValid: false, msg: "" },
    });
    Axios.post(
      apiConfigs.API_URL +
        "patient/register/" +
        this.state.successResponse.patient.id +
        "/",
      {
        email: this.state.email,
        phone: takeOnlyDigitAndPlus(this.state.phone),
        fname: this.state.fname,
        lname: this.state.lname,
        resend: true,
      },
      {
        headers: {
          xprApp: "front",
        },
        withCredentials: true,
      }
    ).then(
      (success) => {
        this.setState({ popUpTwoLoader: false });
        if (success.status === 200) {
          if (success.data.status.error === false) {
            toast.success(success.data.status.msg);
            /* const interval = setInterval(() => {
                            if (this.state.intervalTime === 0) {
                                clearInterval(interval);
                            } else {
                                this.setState({ intervalTime: this.state.intervalTime - 1 });
                            }
                        }, 1000) */
          } else {
            toast.error(success.data.status.msg);
          }
        }
      },
      (error) => {
        this.setState({ popUpTwoLoader: false });
        toast.error("Something went wrong");
      }
    );
  }
  setCookie(response: any) {
    const key1 = "xprUser.patient-" + response.id;
    const key2 = "xpr_user_id";
    const key3 = "xprAuthUser";
    const key4 = "xprUserName";
    const key5 = "xprEmail";
    const key6 = "jwtToken";
    var json: any = {};
    json[key1] = true;
    json[key2] = response.uniqueId;
    json[key3] = response.authParam;
    json[key4] = response.firstName + " " + response.lastName;
    json[key5] = response.email;
    json[key6] = response.jwtToken;
    // Cookies.set("patient_cookie", json, { expires: 30 });
    // Cookies.set("patient_cookie", json, { domain: 'patient.staging.xcarepro.com', path: '/', expires: 30 });
    Cookies.set("patient_cookie", json, {
      domain: apiConfigs.COOKIE_DOMAIN,
      path: "/",
      expires: 30,
    });
  }
  addStatusToFirebase(
    appointmentId: any,
    patientId: any,
    clinicId: any,
    dependantId: any
  ) {
    console.log(appointmentId);
    var db = firebase.firestore();
    const obj = {
      appointmentId: Number(appointmentId),
      senderId: Number(patientId),
      sender: "patient",
      action: "Changed status from CREATED to REQUESTED.",
      actionType: "STATUS",
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      fromStatus: "CREATED",
      toStatus: "REQUESTED",
      sendToCId: clinicId.toString(),
      dependantId: Number(dependantId),
    };
    return new Promise<void>((res, rej) => {
      db.collection(apiConfigs.CHAT_COLLECTION_NAME)
        .doc()
        .set(obj)
        .then(() => {
          console.log("Document successfully written!");
          res();
        })
        .catch((error: any) => {
          console.error("Error writing document: ", error);
          res();
        });
    });
  }
  patientLogin(email: string, password: string, callback: any) {
    Axios.post(
      apiConfigs.API_URL + "patientlogin/",
      { email: email, password: password },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    ).then(
      (success: any) => {
        if (success.status === 200) {
          if (success.data.status.error === false) {
            this.setCookie(success.data);
            callback(success.data.jwtToken);
          }
        }
      },
      (error: any) => {}
    );
  }
  handleChangeDate = (date: any) => {
    this.setState({ dob: date });
  };
  handleAddDependant = () => {
    if (this.state.firstName === "" || this.state.firstName.trim() === "") {
      toast.warn("Please enter valid FirstName.");
      return;
    }
    if (this.state.lastName === "" || this.state.lastName.trim() === "") {
      toast.warn("Please enter valid LastName.");
      return;
    }

    if (!emailValidation(this.state.emailId)) {
      toast.warn("Please enter valid email.");
      return;
    }
    if (takeOnlyDigit(this.state.mobile).length !== 11) {
      toast.warn("Please enter valid mobile.");
      return;
    }
    const body = {
      fname: this.state.firstName,
      firstName: this.state.firstName,
      lname: this.state.lastName,
      lastName: this.state.lastName,
      relation: this.state.relation,
      email: this.state.emailId,
      phone: takeOnlyDigitAndPlus(this.state.phone),
      gender: this.state.gender,
      dob: this.state.dob,
      contactMethod: this.state.contactMethod,
      reminder: this.state.reminder,
      isNew: true,
    };
    this.setState({
      isaddDependant: false,
      successResponse: {
        ...this.state.successResponse,
        dependant: [...this.state.successResponse.dependant, body],
      },
    });
    // Object.assign({},this.state.successResponse.dependant,body)
    // console.log('Check',this.state.successResponse)
  };
  render() {
    console.log(this.state.appointmentFor);
    if (this.state.redirectToHome) {
      // return (<Redirect to="/"></Redirect>);
      window.location.href = apiConfigs.PATIENT_APPOINTMENT_URL;
    }
    return (
      <>
        <Modal show={true} onHide={this.hideHandle} centered>
          <div className="book-appointment-popup master_popup">
            <div className="modal-header">
              <button
                className="btn book-close-btn"
                onClick={(e) => {
                  this.props.change(false, false);
                  this.setState({
                    email: "",
                    phone: "",
                    fname: "",
                    lname: "",
                    emailValidation: { isInValid: false, msg: "" },
                    phoneValidation: { isInValid: false, msg: "" },
                    fnameValidation: { isInValid: false, msg: "" },
                    lnameValidation: { isInValid: false, msg: "" },
                    verificationCode: "",
                    verificationCodeFalse: false,
                    successResponse: "",
                    password: "",
                    cpassword: "",
                    passwordValidation: { isInValid: false, msg: "" },
                    btnChange: { isShow: false, msg: "" },
                    verificationCodeValidation: { isInValid: false, msg: "" },
                  });
                }}
              >
                <img
                  src={process.env.PUBLIC_URL + "/images/close-icon-white.svg"}
                  className="img-fluid"
                />
              </button>
              <div className="steps clearfix">
                {this.state.modalNumber === 0 && (
                  <>
                    <h6 className="step_tite">Existing Clinic ?</h6>
                  </>
                )}
                {this.state.modalNumber === 1 && (
                  <>
                    <h6 className="step_tite">Patient information</h6>
                  </>
                )}
                {this.state.modalNumber === 2 && (
                  <>
                    <h6 className="step_tite">Phone number verification</h6>
                  </>
                )}
                {this.state.modalNumber === 3 && (
                  <>
                    <h6 className="step_tite">Set your password</h6>
                  </>
                )}
                <ul>
                  <li
                    className={
                      "" + (this.state.modalNumber === 0 ? "active" : "")
                    }
                  >
                    <a>
                      <span className="number">Existing?</span>
                    </a>
                  </li>
                  <li
                    className={
                      "" + (this.state.modalNumber === 1 ? "active" : "")
                    }
                  >
                    <a>
                      <span className="number">Patient</span>
                    </a>
                  </li>
                  <li
                    className={
                      "" + (this.state.modalNumber === 2 ? "active" : "")
                    }
                  >
                    <a>
                      <span className="number">Verify</span>{" "}
                    </a>
                  </li>
                  <li
                    className={
                      "" + (this.state.modalNumber === 3 ? "active" : "")
                    }
                  >
                    <a>
                      <span className="number">Confirm</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="modal-body book-popup-sec ex_newpop">
              {this.state.modalNumber === 0 && (
                <div className="step-3">
                  <div className="publp_form">
                    <div className="publp_title text-center">
                      <h6>{this.props.data.name}</h6>
                    </div>

                    <div className="row justify-content-center">
                      <div className="col-12">
                        <div className="text-center">
                          <p>Is this clinic your existing clinic or new?</p>
                        </div>
                      </div>
                    </div>
                    <div className="form-group d-flex pop_btnsec">
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          this.setState({
                            isExistingClinic: true,
                            modalNumber: 1,
                          });
                        }}
                      >
                        Yes, Existing
                      </button>
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          this.setState({
                            isExistingClinic: false,
                            modalNumber: 1,
                          });
                        }}
                      >
                        No, It's new
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {this.state.modalNumber === 1 && (
                <>
                  <div className="step-1">
                    <div className="book-popup-form">
                      <div className="form-row">
                        <div className="form-group col-6">
                          <label>First Name *</label>
                          <input
                            type="text"
                            value={this.state.fname}
                            onChange={(e) => {
                              if (allowAlphabetsAndSpace(e.target.value)) {
                                this.setState({ fname: e.target.value });
                              }
                            }}
                            onInput={(e: any) => {
                              if (allowAlphabetsAndSpace(e.target.value)) {
                                this.setState({ fname: e.target.value });
                              }
                            }}
                            className="form-control"
                          />
                          {this.state.fnameValidation.isInValid === true && (
                            <div className="text-danger error">
                              {this.state.fnameValidation.msg}
                            </div>
                          )}
                        </div>

                        <div className="form-group col-6">
                          <label>Last Name *</label>
                          <input
                            type="text"
                            value={this.state.lname}
                            onChange={(e) => {
                              if (allowAlphabetsAndSpace(e.target.value)) {
                                this.setState({ lname: e.target.value });
                              }
                            }}
                            onInput={(e: any) => {
                              if (allowAlphabetsAndSpace(e.target.value)) {
                                this.setState({ lname: e.target.value });
                              }
                            }}
                            className="form-control"
                          />
                          {this.state.lnameValidation.isInValid === true && (
                            <div className="text-danger error">
                              {this.state.lnameValidation.msg}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="form-group">
                        <label>Email Address * </label>
                        <input
                          type="email"
                          value={this.state.email}
                          onChange={(e) => {
                            this.setState({ email: e.target.value });
                          }}
                          onInput={(e: any) => {
                            this.setState({ email: e.target.value });
                          }}
                          className="form-control"
                        />
                        {this.state.emailValidation.isInValid === true && (
                          <small className="form-text text-danger error">
                            {this.state.emailValidation.msg}
                          </small>
                        )}
                      </div>

                      <div className="form-group">
                        <label>Phone * </label>
                        <InputMask
                          mask="+1(999) 999-9999"
                          value={this.state.phone}
                          onChange={(e) => {
                            this.setState({ phone: e.target.value });
                          }}
                        >
                          {(inputProps: any) => (
                            <input
                              {...inputProps}
                              className="form-control"
                            ></input>
                          )}
                          {/* <input type="text" value={this.state.phone}
                                                                    onChange={(e) => {
                                                                        this.setState({ phone: e.target.value });
                                                                    }}
                                                                    onInput={(e: any) => {
                                                                        this.setState({ phone: e.target.value });
                                                                    }} placeholder="Phone number" className="form-control" /> */}
                        </InputMask>
                        {this.state.phoneValidation.isInValid === true && (
                          <div className="text-danger error">
                            {this.state.phoneValidation.msg}
                          </div>
                        )}
                      </div>
                      <div className="form-group pop_btnsec">
                        <button
                          className="btn btn-primary w-100"
                          disabled={this.state.popUpOneLoader}
                          onClick={(e) => {
                            this.registerUser();
                          }}
                        >
                          Continue
                        </button>
                        {this.state.popUpOneLoader && (
                          <div className="text-center cs_popload">
                            <i className="fas fa-circle-notch fa-spin"></i>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}

              {this.state.modalNumber === 2 && (
                <>
                  <div className="step-2 phonevfy">
                    <div className="book-popup-form">
                      <p className="step_info text-center">
                        We have sent you an access code via SMS for{" "}
                        <span>{takeOnlyDigitAndPlus(this.state.phone)}</span>
                      </p>
                      <div className="form-group vinput">
                        <label className="text-center">Enter code</label>
                        <PinInput
                          length={4}
                          initialValue={""}
                          onChange={(value, index) => {
                            this.setState({ verificationCode: value });
                          }}
                          type="numeric"
                          style={{ padding: "10px" }}
                          inputStyle={{}}
                          inputFocusStyle={{ borderColor: "blue" }}
                          onComplete={(value, index) => {
                            this.setState({ verificationCode: value });
                          }}
                        />
                        {this.state.verificationCodeFalse && (
                          <div className="text-danger error">
                            Verification code entered is incorrect.
                          </div>
                        )}
                        {this.state.verificationCodeValidation.isInValid ===
                          true && (
                          <div className="text-danger error">
                            {this.state.verificationCodeValidation.msg}
                          </div>
                        )}
                      </div>
                      <button
                        className="btn btn-primary w-100"
                        onClick={(e) => {
                          this.verifyCode();
                        }}
                        disabled={this.state.popUpTwoLoader}
                      >
                        Continue
                      </button>
                      {this.state.popUpTwoLoader && (
                        <div className="text-center cs_popload">
                          <i className="fas fa-circle-notch fa-spin"></i>
                        </div>
                      )}
                      {this.state.intervalTime !== 0 ? (
                        <div className="resend-txt">
                          Didn't received code? Resend after{" "}
                          {this.state.intervalTime} seconds.
                        </div>
                      ) : (
                        <>
                          {this.state.popUpTwoLoader === false && (
                            <div
                              className={"resend-txt"}
                              onClick={(e) => {
                                this.sendVerificationCode();
                              }}
                            >
                              Resend Code
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}

              {this.state.modalNumber === 3 && (
                <>
                  <div className="step-3">
                    <div className="book-popup-form">
                      {this.state.btnChange.isShow === true ? (
                        <label>{this.state.btnChange.msg}</label>
                      ) : (
                        <>
                          <div className="form-group">
                            <label>Password</label>
                            <input
                              type="password"
                              value={this.state.password}
                              onChange={(e) => {
                                this.setState({ password: e.target.value });
                              }}
                              onInput={(e: any) => {
                                this.setState({ password: e.target.value });
                              }}
                              className="form-control"
                            />
                          </div>
                          <div className="form-group">
                            <label>Confirm password</label>
                            <input
                              type="password"
                              value={this.state.cpassword}
                              onChange={(e) => {
                                this.setState({ cpassword: e.target.value });
                              }}
                              onInput={(e: any) => {
                                this.setState({ cpassword: e.target.value });
                              }}
                              className="form-control"
                            />
                          </div>
                          {this.state.passwordValidation.isInValid === true && (
                            <div className="text-danger error">
                              {this.state.passwordValidation.msg}
                            </div>
                          )}
                          <div className="form-group text-center">
                            <label>Appointment For</label>
                            {console.log("Dats", this.state.successResponse)}

                            {this.state.successResponse.isGuarantor && (
                              <button
                                className="btn btn-dark ml-3"
                                onClick={() => {
                                  this.setState({
                                    isaddDependant: !this.state.isaddDependant,
                                  });
                                }}
                              >
                                Add Dependant
                              </button>
                            )}
                            {!this.state.isaddDependant && (
                              <select
                                className="form-control mt-3"
                                value={this.state.appointmentFor}
                                onChange={(e) => {
                                  this.setState({
                                    appointmentFor: e.target.value,
                                  });
                                }}
                              >
                                {this.state.successResponse.dependant &&
                                  this.state.successResponse.dependant.length >
                                    0 &&
                                  this.state.successResponse.dependant.map(
                                    (item: any, index: number) => {
                                      return (
                                        <option key={item.id} value={index}>
                                          {item.firstName + " " + item.lastName}
                                        </option>
                                      );
                                    }
                                  )}
                              </select>
                            )}

                            {/* <select className="form-control" value={this.state.appointmentFor} onChange={(e) => {
                                                                this.setState({ appointmentFor: e.target.value });
                                                            }}>
                                                                <option value="0">Select appointment for</option>
                                                                <option value="1">Me</option>
                                                                <option value="2">Other</option>
                                                            </select> */}
                          </div>
                          {this.state.isaddDependant && (
                            <div className="card">
                              <div className="card-header">
                                Add new dependant
                              </div>
                              <div className="card-body bg-light">
                                <div className="form-row">
                                  <div className="col">
                                    <div className="form-group">
                                      <label>First Name</label>
                                      <input
                                        placeholder="First Name"
                                        type="text"
                                        className="form-control"
                                        value={this.state.firstName}
                                        onChange={(e) => {
                                          if (
                                            allowAlphabetsAndSpace(
                                              e.target.value
                                            )
                                          ) {
                                            this.setState({
                                              firstName: e.target.value,
                                            });
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="col">
                                    <div className="form-group">
                                      <label>Last Name</label>
                                      <input
                                        placeholder="Last Name"
                                        type="text"
                                        className="form-control"
                                        value={this.state.lastName}
                                        onChange={(e) => {
                                          if (
                                            allowAlphabetsAndSpace(
                                              e.target.value
                                            )
                                          ) {
                                            this.setState({
                                              lastName: e.target.value,
                                            });
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="form-row">
                                  <div className="col">
                                    <div className="form-group">
                                      <label>Dependent Relation</label>
                                      <select
                                        className="form-control"
                                        value={this.state.relation}
                                        onChange={(e) => {
                                          this.setState({
                                            relation: e.target.value,
                                          });
                                        }}
                                      >
                                        <option value="Son">Son</option>
                                        <option value="Daughter">
                                          Daughter
                                        </option>
                                        <option value="Mother">Mother</option>
                                        <option value="Father">Father</option>
                                        <option value="Spouse">Spouse</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col">
                                    <div className="form-group">
                                      <label>Gender</label>
                                      <select
                                        className="form-control"
                                        value={this.state.gender}
                                        onChange={(e) => {
                                          this.setState({
                                            gender: e.target.value,
                                          });
                                        }}
                                      >
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>

                                <div className="form-row">
                                  <div className="col">
                                    <div className="form-group">
                                      <label>Email</label>
                                      <input
                                        placeholder="Email"
                                        type="text"
                                        className="form-control"
                                        value={this.state.emailId}
                                        onChange={(e) => {
                                          this.setState({
                                            emailId: e.target.value,
                                          });
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="col">
                                    <div className="form-group">
                                      <label>Phone</label>
                                      <InputMask
                                        mask="+1(999) 999-9999"
                                        value={this.state.mobile}
                                        onChange={(e) => {
                                          this.setState({
                                            mobile: e.target.value,
                                          });
                                        }}
                                      >
                                        {(inputProps: any) => (
                                          <input
                                            {...inputProps}
                                            className="form-control"
                                          ></input>
                                        )}
                                      </InputMask>
                                    </div>
                                  </div>
                                </div>

                                <div className="form-row">
                                  <div className="col">
                                    <div className="form-group">
                                      <KeyboardDatePicker
                                        margin="normal"
                                        id={"date-picker-dialog"}
                                        name={"date-picker-dialog"}
                                        label="Date of Birth"
                                        format="YYYY/MM/DD"
                                        maxDate={moment().subtract(1, "days")}
                                        value={moment(this.state.dob)}
                                        onChange={(date: any) => {
                                          this.handleChangeDate(date);
                                        }}
                                        KeyboardButtonProps={{
                                          "aria-label": "change date",
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="form-row">
                                  <div className="col">
                                    <div className="form-group">
                                      <label>Contact Method</label>
                                      {/* <select className="form-control" value={this.state.contactMethod} onChange={(e) => {
                                                                                    this.setState({ contactMethod: e.target.value });
                                                                                }}>
                                                                                    <option value="Call">Call</option>
                                                                                    <option value="Text">Text</option>
                                                                                    <option value="Text & Email">Text & Email</option>
                                                                                </select> */}
                                      <div className="form-group">
                                        <div className="custom-control custom-radio mr-3">
                                          <input
                                            type="radio"
                                            id="call"
                                            name="customRadio"
                                            className="custom-control-input"
                                            onClick={() => {
                                              this.setState({
                                                contactMethod:
                                                  CONTACTMETHODS.EMAIL,
                                                reminder: "",
                                              });
                                            }}
                                            checked={
                                              this.state.contactMethod ===
                                              CONTACTMETHODS.EMAIL
                                                ? true
                                                : false
                                            }
                                          />
                                          <label
                                            className="custom-control-label"
                                            htmlFor="call"
                                          >
                                            Email Only
                                          </label>
                                        </div>

                                        <div className="custom-control custom-radio">
                                          <input
                                            type="radio"
                                            id="text"
                                            name="customRadio"
                                            className="custom-control-input"
                                            onClick={() => {
                                              this.setState({
                                                contactMethod:
                                                  CONTACTMETHODS.TEXT,
                                                reminder: "",
                                              });
                                            }}
                                            checked={
                                              this.state.contactMethod ===
                                              CONTACTMETHODS.TEXT
                                                ? true
                                                : false
                                            }
                                          />
                                          <label
                                            className="custom-control-label"
                                            htmlFor="text"
                                          >
                                            Text Only
                                          </label>
                                        </div>
                                        <div className="custom-control custom-radio">
                                          <input
                                            type="radio"
                                            id="Text & Email"
                                            name="customRadio"
                                            className="custom-control-input"
                                            onClick={() => {
                                              this.setState({
                                                contactMethod:
                                                  CONTACTMETHODS.EMAIL_TEXT,
                                                reminder: "",
                                              });
                                            }}
                                            checked={
                                              this.state.contactMethod ===
                                              CONTACTMETHODS.EMAIL_TEXT
                                                ? true
                                                : false
                                            }
                                          />
                                          <label
                                            className="custom-control-label"
                                            htmlFor="Text & Email"
                                          >
                                            Email & Text
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {/* <div className="col">
                                                                            <div className="form-group">
                                                                                <label>Reminder</label>
                                                                                <select className="form-control" value={this.state.reminder} onChange={(e) => {
                                                                                    this.setState({ reminder: e.target.value });
                                                                                }}>
                                                                                    <option value="yes">Yes</option>
                                                                                    <option value="no">No</option>
                                                                                </select>
                                                                            </div>
                                                                        </div> */}
                                </div>

                                <div className="form-group addept-btn text-end">
                                  <button
                                    className="btn btn-primary"
                                    onClick={() => {
                                      this.setState({ isaddDependant: false });
                                    }}
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    className="btn btn-dark"
                                    onClick={() => {
                                      this.handleAddDependant();
                                    }}
                                  >
                                    Submit
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                          {/* {
                                                            this.state.appointmentFor === "2" &&
                                                            <div>
                                                                <div className="form-group">
                                                                    <label>Dependent Name</label>
                                                                    <input type="text" className="form-control" value={this.state.dependantName} onChange={(e) => {
                                                                        if (allowAlphabetsAndSpace(e.target.value)) {
                                                                            this.setState({ dependantName: e.target.value });
                                                                        }
                                                                    }} />
                                                                </div>
                                                                <div className="form-group">
                                                                    <label>Dependent Relation</label>
                                                                    <input type="text" className="form-control" value={this.state.dependantRelation} onChange={(e) => {
                                                                        if (allowAlphabetsAndSpace(e.target.value)) {
                                                                            this.setState({ dependantRelation: e.target.value });
                                                                        }
                                                                    }} />
                                                                </div>
                                                            </div>
                                                        } */}
                          {this.state.isaddDependant === false && (
                            <div className="form-group pop_btnsec">
                              <button
                                className="btn btn-primary w-100"
                                onClick={(e) => {
                                  this.registerUserConfirm();
                                }}
                                disabled={this.state.popUpThreeLoader}
                              >
                                Confirm
                              </button>
                              {this.state.popUpThreeLoader && (
                                <div className="text-center cs_popload">
                                  <i className="fas fa-circle-notch fa-spin"></i>
                                </div>
                              )}
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </Modal>
      </>
    );
  }
}
export default BookPopUp;

import React from 'react';
import "./LoadingIndicator.css";
import '../../assets/css/clinic-search.scss';
import '../../assets/css/provider-clinic-list.scss';
export default class LoadingIndicator extends React.Component {
    render() {
        return (
            <>
                <div className="global_loder">
                    <svg version="1.1" id="L5" xmlnsXlink="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100" enableBackground="new 0 0 0 0" xmlSpace="preserve">
                        <circle fill="#ffffff" stroke="none" cx="6" cy="50" r="6">
                            <animateTransform attributeName="transform" dur="1s" type="translate" values="0 15 ; 0 -15; 0 15" repeatCount="indefinite" begin="0.1"></animateTransform>
                        </circle>
                        <circle fill="#ffffff" stroke="none" cx="30" cy="50" r="6">
                            <animateTransform attributeName="transform" dur="1s" type="translate" values="0 10 ; 0 -10; 0 10" repeatCount="indefinite" begin="0.2"></animateTransform>
                        </circle>
                        <circle fill="#ffffff" stroke="none" cx="54" cy="50" r="6">
                            <animateTransform attributeName="transform" dur="1s" type="translate" values="0 5 ; 0 -5; 0 5" repeatCount="indefinite" begin="0.3"></animateTransform>
                        </circle>
                    </svg>
                </div>
                 {/* <div className="global_loder">
                    <div className="loder_in shadow-lg bg-white rounded text-center">
                        <div className="spinner-border" role="status">
                        </div>
                        <div className="mt-2">Loading...</div>
                    </div>

                </div> */}
            </>
        )
    }
}
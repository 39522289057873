import React from 'react';
export function showAddress(address: any) {
    if (address.postal) {
        return (
            <>
                <div>{address.street ? address.street + ' ' : ''}
                {address.route !== "" ? address.route + ',' : ''}</div>
                <div>{address.locality && address.locality + ","} {address.address_level_1_short && address.address_level_1_short + ","} {address.postal && address.postal}</div>
            </>
        )
    }
    return (
        <>
             <div>{address.street ? address.street + ' ' : ''}
            {address.route !== "" ? address.route + ',' : ''}</div>
            <div>{address.locality && address.locality + ","} {address.address_level_1 && address.address_level_1}</div>
        </>
    )
}
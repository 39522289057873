import apiConfigs from '../../../configs/apiConfigs';
import { BODY_TYPE, CommonApiOptions, METHOD_TYPE } from "../../../constants/api";
import { commonApi } from "../../../helpers/api";
 // latest -----------
export const getMasterInsuranceList = () => {
    const request: CommonApiOptions = {
        url: apiConfigs.API_URL + 'master/insurance/',
        method: METHOD_TYPE.GET,
        apiOptions: {
            headers: {
                xprApp: 'patient'
            },
            withCredentials: true
        }
    };
    return commonApi(request);
}

export const registerUser = (data: any) => {
    const request: CommonApiOptions = {
        url: apiConfigs.API_URL + 'register1/',
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.RAW,
            data: data
        },
        apiOptions: {
            headers: {
                xprApp: 'patient'
            },
            withCredentials: true
        }
    };
    return commonApi(request);
}
export const verifyCode = (data: any, patientId: any) => {
    const request: CommonApiOptions = {
        url: apiConfigs.API_URL + "register1/" + patientId + "/",
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.RAW,
            data: data
        },
        apiOptions: {
            headers: {
                xprApp: 'patient'
            },
            withCredentials: true
        }
    }
    return commonApi(request);
}

export const checkLogin = (data: any) => {
    const request: CommonApiOptions = {
        url: apiConfigs.API_URL + "checklogin/",
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.RAW,
            data: data
        },
        apiOptions: {
            headers: {
                xprApp: 'patient'
            },
            withCredentials: true
        }
    }
    return commonApi(request);
}

export const patientLoginNew = (data: any) => {
    const request: CommonApiOptions = {
        url: apiConfigs.API_URL + "patientlogin1/",
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.RAW,
            data: data
        },
        apiOptions: {
            headers: {
                xprApp: 'patient'
            },
            withCredentials: true
        }
    }
    return commonApi(request);
}

export const checkForgotpasswordAccount = (data: any) => {
    const request: CommonApiOptions = {
        url: apiConfigs.API_URL + "forgot/",
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.RAW,
            data: data
        },
        apiOptions: {
            headers: {
                xprApp: 'patient'
            },
            withCredentials: true
        }
    }
    return commonApi(request);
}

export const forgotPassword = (data: any) => {
    const request: CommonApiOptions = {
        url: apiConfigs.API_URL + "forgotNew/",
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.RAW,
            data: data
        },
        apiOptions: {
            headers: {
                xprApp: 'patient'
            },
            withCredentials: true
        }
    }
    return commonApi(request);
};

export const verifyOtpForgotpassword = (data: any) => {
    const request: CommonApiOptions = {
        url: apiConfigs.API_URL + "varifyOtpForgot/",
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.RAW,
            data: data
        },
        apiOptions: {
            headers: {
                xprApp: 'patient'
            },
            withCredentials: true
        }
    }
    return commonApi(request);
};

export const resetPasswordApi = (data: any) => {
    const request: CommonApiOptions = {
        url: apiConfigs.API_URL + "reset-password/",
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.RAW,
            data: data
        },
        apiOptions: {
            headers: {
                xprApp: 'patient'
            },
            withCredentials: true
        }
    }
    return commonApi(request);
};
import React from 'react';
export const RatingComp: React.SFC<{ rating: number }> = (props) => {
    const elements = [];
    if (props.rating) {
        let rating = props.rating;
        for (let start = 0; start < 5; start++) {
            if (rating % 1 !== 0 && start === Math.ceil(rating) - 1) {
                elements.push(<i className="bi bi-star-half" key={start + "" + Math.random()}></i>);
            } else if (start < Math.ceil(rating)) {
                elements.push(<i className="bi bi-star-fill" key={start + "" + Math.random()} ></i>);
            } else {
                elements.push(<i className="bi bi-star" key={start + "" + Math.random()}></i>);
            }
        }
        return (<div className="rate-stars d-flex justify-content-center">{elements} </div>);
    }
    return null;
}
import { Avatar } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { NavLink, useHistory, useParams } from "react-router-dom";
import configs from "../../../../configs/apiConfigs";
import {
  fetchConsultationsSlots,
  fetchConsultationType,
  fetchDoctorProfile,
} from "../../../../services/dentist";
import { showAddress } from "../../components/ShowAddress/ShowAddress";
import {
  loadMap,
  setGoogleMap,
  setMarkerToPostion,
} from "../../../../utils/googleFunction";
import { AppointmentSchedulingXcare } from "../../components/AppointmentSchedulingXcare/AppointmentSchedulingXcare";
import moment from "moment";
import { fetchReviews } from "../../../../services/results";
import { RatingComp } from "../../../RatingComponent";
import HeaderWhite from "../HeaderWhite/HeaderWhite";
import Slider from "react-slick";
import { Accordion } from "react-bootstrap";
import Lightbox from "react-image-lightbox";
let googleMap;
export const ProviderProfileNewXcare = () => {
  const params: any = useParams();
  const [doctorProfile, setDoctorProfile]: any = useState(null);
  const [consultationTypes, setConsultationTypes]: any = useState(null);
  const [selectedConsultation, setSelectedConsultation] = useState("");
  const [slots, setSlots] = useState([]);
  const [firstAvailable, setFirstAvailable] = useState(null);
  const [reviews, setReviews]: any = useState(null);
  const [serviceExpanded, setServiceExpanded] = useState(false);
  const [accordianActiveKey, setAccordianActiveKey] = useState(null);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [reviewExpanded, setReviewExpanded] = useState(false);
  const [isShowHeader, setIsShowHeader] = useState(false);
  const history: any = useHistory();

  useEffect(() => {
    if (
      history &&
      history.location &&
      history.location.state &&
      history.location.state.isInternal
    ) {
      setIsShowHeader(true);
    }
  }, [history.location.state]);
  useEffect(() => {
    if (history.location && history.location.state && history.location.state) {
      console.log("date", history.location.state);
    }
    callFetchDoctorProfile();
    loadMap().then(
      (success) => {},
      (err) => {
        console.log(err);
      }
    );
    window.scrollTo(0, 0);
  }, []);
  const callFetchDoctorProfile = async () => {
    try {
      const response: any = await fetchDoctorProfile(params.uniqueId);
      if (response.response.data.status.error === false) {
        const data: any = response.response.data;
        console.log("doctorProfile", data);

        setDoctorProfile(data);
        // if (data.onlineSchedulingAdded) {
        callFetchConsultationTypes(data.uniqueId, data);
        // }
        callFetchClinicReviews(data.uniqueId);
        const latitude = parseFloat(data.address.latitude);
        const longitude = parseFloat(data.address.longitude);
        setGoogleMap({ latitude: latitude, longitude: longitude }, "map")
          ?.then((success: any) => {
            googleMap = success;
            setMarkerToPostion(googleMap, {
              latitude: latitude,
              longitude: longitude,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } catch (err) {
      console.log("err", err);
    }
  };
  const callFetchConsultationTypes = async (
    uniqueId: string,
    doctorProfile: any
  ) => {
    try {
      const response: any = await fetchConsultationType(
        uniqueId,
        "provider=" + params.uniqueId
      );
      console.log("consultationTypes", response.response.data);
      if (response.response.data.status.error === false) {
        console.log("data", response.response.data);
        setConsultationTypes(response.response.data);
        setSelectedConsultation(response?.response?.data?.types?.[0]?.id);

        callSlots({
          doctorId: doctorProfile?.provider?.id,
          clinicId: doctorProfile?.id,
          start_date: moment().format("YYYY-MM-DD"),
          end_date: moment().add(3, "day").format("YYYY-MM-DD"),
          consultationTypeId: response?.response?.data?.types?.[0]?.id,
        });
      }
    } catch (err) {
      console.log("err", err);
    }
  };
  const callSlots = async (obj: any) => {
    try {
      console.log(obj);
      let _obj = {
        doctorId: doctorProfile?.provider?.id,
        clinicId: doctorProfile?.id,
        consultationTypeId: selectedConsultation,
        ...obj,
      };
      const response: any = await fetchConsultationsSlots({
        appointmentData: [_obj],
      });
      setSlots(response?.response?.data?.appointments ?? []);
      setFirstAvailable(response?.response?.data?.firstAvailable);
      console.log("response", response);
    } catch (err) {
      console.log("err", err);
    }
  };
  const callFetchClinicReviews = async (uniqueId: string) => {
    try {
      const response: any = await fetchReviews(uniqueId);
      console.log(response);
      setReviews(response?.response?.data);
    } catch (err) {
      console.log("err", err);
    }
  };
  let speciality = "";
  if (doctorProfile?.specialities) {
    const elementFound = doctorProfile?.specialities?.findIndex(
      (spec: any, index: number) => {
        return spec.coreSpeciality === "1";
      }
    );
    if (elementFound === -1 && doctorProfile?.specialities.length > 0) {
      speciality = doctorProfile.specialities[0].name;
    } else {
      speciality = doctorProfile?.specialities[elementFound].name;
    }
  }
  let doctorName =
    doctorProfile && doctorProfile?.name
      ? doctorProfile?.name?.replace("Dr.", "")
      : "";
  doctorName = doctorName.trim();
  doctorName = doctorName.split(" ");
  const letterOne = doctorName[0] ? doctorName[0].charAt(0) : "";
  const letterTwo = doctorName[1] ? doctorName[1].charAt(0) : "";
  let parsedCertifications = [];
  try {
    parsedCertifications = doctorProfile?.certificates
      ? JSON.parse(doctorProfile?.certificates)
      : [];
  } catch (err) {
    parsedCertifications = [];
  }
  console.log("parsedCertifications", parsedCertifications);
  var settings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: true,
    dots: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const MapLink = (lat: any, lng: any) => {
    const url = `https://www.google.com/maps?q=${lat},${lng}`;
    window.open(url, "_blank");
  };

  const handleAccordianActiveKey = (eventKey: any) => {
    console.log("eventKey", eventKey);
    setAccordianActiveKey(eventKey);
  };
  return (
    <>
      {isShowHeader && <HeaderWhite></HeaderWhite>}
      <section>
        <div className="main-grid-sec mt-5">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-lg-6">
                <div className="d-sm-none d-md-none d-lg-none d-xl-none d-none mb-4">
                  <div className="about-dr align-items-center flex-wrap flex-md-nowrap justify-content-between">
                    <div className="profile d-flex align-items-center order-2 order-md-1">
                      <div
                        className="img-box me-3 text-center"
                        onClick={() => {
                          if (doctorName?.website) {
                            const url = `https://${doctorProfile?.website}`;
                            console.log("website", url);
                            window.open(url, "_blank");
                          }
                        }}
                      >
                        {doctorProfile?.provider?.photo ? (
                          <img
                            src={
                              configs.IMAGE_URL +
                              "doctor/" +
                              doctorProfile?.provider?.photo?.name +
                              "-300x300." +
                              doctorProfile?.provider?.photo?.type
                            }
                            alt=""
                            className="img-fluid"
                            width={"118px"}
                          />
                        ) : (
                          <Avatar>
                            {letterOne}
                            {letterTwo}
                          </Avatar>
                        )}
                      </div>
                      <div className="content-box">
                        <h4
                          onClick={() => {
                            if (doctorName?.website) {
                              const url = `https://${doctorProfile?.website}`;
                              console.log("website", url);
                              window.open(url, "_blank");
                            }
                          }}
                        >
                          {doctorProfile?.provider?.fullname}, {speciality}{" "}
                          <i className="bi bi-patch-check-fill ms-1 text-primary"></i>
                        </h4>
                        <div className="d-inline-flex align-items-center flex-wrap">
                          {showAddress(doctorProfile?.address ?? "")}
                          {doctorProfile?.address &&
                            doctorProfile?.onlineSchedulingLocation && (
                              <a
                                onClick={() => {
                                  MapLink(
                                    doctorProfile?.address?.latitude,
                                    doctorProfile?.address?.longitude
                                  );
                                }}
                                className="btn btn-link p-0 ms-1"
                              >
                                Get direction
                              </a>
                            )}
                        </div>
                        <div>
                          <button
                            className="btn btn-link p-0 text-decoration-none"
                            onClick={() => {
                              history.push({
                                pathname:
                                  "/" +
                                  doctorProfile?.name.split(" ").join("-") +
                                  "/" +
                                  doctorProfile?.uniqueId +
                                  "/c",
                                state: {
                                  isInternal: true,
                                },
                              });
                            }}
                          >
                            {doctorProfile?.name}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="practice-sidebar">
                  {consultationTypes?.types?.length > 0 && (
                    <AppointmentSchedulingXcare
                      handleConsultationTypeChange={(value: any, obj: any) => {
                        setSelectedConsultation(value);
                        callSlots(obj);
                      }}
                      selectedConsultation={selectedConsultation}
                      consultationTypes={consultationTypes}
                      slots={slots}
                      providerName={doctorProfile?.provider?.fullname}
                      clinicId={doctorProfile?.id}
                      doctorId={doctorProfile?.provider?.id}
                      appointmentTypeId={
                        doctorProfile?.provider?.potentialAppointmentTypes?.[0]
                          ?.id ?? ""
                      }
                      clinicUniueId={doctorProfile?.uniqueId}
                      firstAvailable={firstAvailable}
                      flexibleSchedulingOn={
                        doctorProfile?.onlineSchedulingFlexibleSchedule
                      }
                      source={params.location}
                      selectedAppointmentDate={
                        history.location?.state?.date ?? ""
                      }
                      doctorProfile={doctorProfile}
                      isDoctorProfile={true}
                    ></AppointmentSchedulingXcare>
                  )}
                </div>
              </div>
              <div className="col-md-12 col-lg-6">
                <div className="tab-view-practice">
                  <div id="tab-about" className="border-bottom tab-box">
                    <div className="about-dr d-flex align-items-center flex-wrap flex-md-nowrap justify-content-between">
                      <div className="profile d-flex align-items-center order-2 order-md-1">
                        <div className="img-box me-3 text-center">
                          {doctorProfile?.provider?.photo ? (
                            <img
                              src={
                                configs.IMAGE_URL +
                                "doctor/" +
                                doctorProfile?.provider?.photo?.name +
                                "-300x300." +
                                doctorProfile?.provider?.photo?.type
                              }
                              alt=""
                              className="img-fluid"
                            />
                          ) : (
                            <Avatar>
                              {letterOne}
                              {letterTwo}
                            </Avatar>
                          )}
                        </div>
                        <div className="content-box">
                          <h4>
                            {doctorProfile?.provider?.fullname}, {speciality}{" "}
                            <i className="bi bi-patch-check-fill ms-1 text-primary"></i>
                          </h4>
                          <div className="d-inline-flex align-items-center flex-wrap">
                            {showAddress(doctorProfile?.address ?? "")}
                            {doctorProfile?.address &&
                              doctorProfile?.onlineSchedulingLocation && (
                                <a
                                  onClick={() => {
                                    MapLink(
                                      doctorProfile?.address?.latitude,
                                      doctorProfile?.address?.longitude
                                    );
                                  }}
                                  className="btn btn-link p-0 ms-1"
                                >
                                  Get direction
                                </a>
                              )}
                          </div>

                          <div>
                            <button
                              className="btn btn-link p-0 text-decoration-none"
                              onClick={() => {
                                history.push({
                                  pathname:
                                    "/" +
                                    doctorProfile?.name.split(" ").join("-") +
                                    "/" +
                                    doctorProfile?.uniqueId +
                                    "/c",
                                  state: {
                                    isInternal: true,
                                  },
                                });
                              }}
                            >
                              {doctorProfile?.name}
                            </button>
                          </div>
                        </div>
                      </div>
                      {doctorProfile?.onlineSchedulingReview && (
                        <div className="review-detail-box text-center order-1 order-md-2 mx-auto mx-md-0">
                          <div className="avg-rating">
                            <strong>{reviews?.rating?.avg}</strong>/5
                          </div>
                          <RatingComp
                            rating={reviews?.rating?.avg ?? 0}
                          ></RatingComp>
                          <span className="no-of-review d-block">
                            {reviews?.rating?.total} Reviews
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="more-pics">
                      <a
                        onClick={() => {
                          setIsOpen(true);
                        }}
                        className="btn btn-link text-decoration-none px-0"
                      >
                        More photos
                      </a>
                    </div>
                    {/* {reviews &&
                      reviews?.reviews &&
                      reviews?.reviews.length > 0 &&
                      doctorProfile?.onlineSchedulingReview && (
                        <div className="top-review py-3 px-5 my-2">
                          <p>{reviews?.reviews[0]?.reviewText}</p>
                          <div style={{ fontWeight: 500 }}>
                            {reviews?.reviews[0]?.FullName}
                            <span>
                              <i className="bi bi-star-fill mx-1"></i>
                              {reviews?.reviews[0]?.rating}
                            </span>
                          </div>
                        </div>
                      )} */}
                    {doctorProfile?.onlineSchedulingAbout && (
                      <>
                        <div className="welcome-txt my-4 py-3">
                          <h4>Welcome to {doctorProfile?.name}</h4>
                          <div
                            className="d-inline clinic-desc-p"
                            dangerouslySetInnerHTML={{
                              __html:
                                doctorProfile?.provider?.description ?? "",
                            }}
                          ></div>
                          {/* <a href="" className="btn btn-link p-0 ms-2">read more</a> */}
                        </div>
                        <Accordion
                          defaultActiveKey="0"
                          onSelect={handleAccordianActiveKey}
                        >
                          <div className="faq">
                            <div className="accordion" id="profile-faq">
                              {doctorProfile?.provider?.questionResponse.map(
                                (item: any, index: any) => {
                                  return (
                                    <div
                                      className="accordion-item border-0"
                                      key={index}
                                    >
                                      <h2
                                        className="accordion-header"
                                        id="headingOne"
                                      >
                                        <Accordion.Toggle
                                          eventKey={item.id}
                                          className={
                                            accordianActiveKey === item.id
                                              ? "accordion-button p-0 bg-transparent border-0 "
                                              : "accordion-button p-0 bg-transparent border-0 collapsed"
                                          }
                                          as="button"
                                          data-bs-toggle="collapse"
                                          data-bs-target="#collapseOne"
                                          aria-expanded="false"
                                          aria-controls="collapseOne"
                                        >
                                          {item.title}
                                        </Accordion.Toggle>
                                      </h2>
                                      <div
                                        id="collapseOne"
                                        className="accordion-collapse collapse show "
                                        aria-labelledby="headingOne"
                                        data-bs-parent="#profile-faq"
                                      >
                                        <div className="accordion-body px-0 pb-0">
                                          <Accordion.Collapse
                                            eventKey={item.id}
                                          >
                                            <p>{item.response}</p>
                                          </Accordion.Collapse>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        </Accordion>
                      </>
                    )}
                  </div>
                  {doctorProfile?.onlineSchedulingPracticePhoto && (
                    <div id="tab-photos" className="border-bottom tab-box">
                      <h4>Practice photos</h4>
                      {/* Need to intigrate slide */}
                      <div className="photo-slider">
                        <Slider {...settings}>
                          {doctorProfile?.photos?.map(
                            (photo: any, index: number) => {
                              if (
                                photo.image &&
                                photo.image.name &&
                                photo.image.type
                              ) {
                                return (
                                  <div className="img-box">
                                    <img
                                      src={
                                        configs.IMAGE_URL +
                                        "/clinic/" +
                                        photo.image.name +
                                        "-500x500." +
                                        photo.image.type
                                      }
                                      alt=""
                                    />
                                  </div>
                                );
                              }
                              return null;
                            }
                          )}
                        </Slider>
                      </div>
                    </div>
                  )}
                </div>

                {/* <div className="tab-view-practice"></div> */}
              </div>

              <div className="col-12 mt-5">
                {doctorProfile?.onlineSchedulingFeatures && (
                  <div id="tab-highlights" className="border-bottom tab-box">
                    <h4>Preferences & Highlights</h4>
                    <div className="row highlights">
                      {doctorProfile?.highlights.map(
                        (item: any, index: any) => {
                          return (
                            <div
                              className="col-xl-4 col-lg-6 col-md-4 col-6 my-2 px-2"
                              key={index}
                            >
                              <div className="highlight-box d-flex p-2  rounded border align-items-center">
                                <div className="img-box me-3 text-center">
                                  <img
                                    src={require("../../assets/images/accepting-new-patients.svg")}
                                    // src={configs.IMAGE_URL + item.image}
                                    alt=""
                                  />
                                </div>
                                <div>
                                  <small>{item.title}</small>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                )}

                {doctorProfile?.onlineSchedulingServices && (
                  <div
                    id="tab-service"
                    className="border-bottom tab-box list-box"
                  >
                    <h4>Services</h4>
                    <ul className="d-flex justify-content-between flex-wrap">
                      {serviceExpanded
                        ? doctorProfile?.provider?.services.map(
                            (item: any, index: any) => (
                              <li key={index}>{item.name}</li>
                            )
                          )
                        : doctorProfile?.provider?.services
                            .slice(0, 10)
                            .map((item: any, index: any) => (
                              <li key={index}>{item.name}</li>
                            ))}
                    </ul>
                    {!serviceExpanded && (
                      <a
                        onClick={() => {
                          setServiceExpanded(true);
                        }}
                        className="btn btn-link text-decoration-none mt-3 p-0"
                      >
                        View more +
                      </a>
                    )}
                  </div>
                )}

                {doctorProfile?.onlineSchedulingOfficeHours && (
                  <div id="tab-hours" className="border-bottom tab-box">
                    <div className="d-flex inner-box flex-wrap">
                      <div className="hours-box">
                        <h4 className="d-flex justify-content-between align-items-center">
                          Office Hours <span>OPEN NOW</span>
                        </h4>
                        <ul className="mt-3">
                          {doctorProfile?.hours?.map((item: any) => {
                            return (
                              <li>
                                <span>{item.label}</span>
                                {item.startHour !== "Closed" ? (
                                  <span>
                                    {item.startHour} - {item.endHour}
                                  </span>
                                ) : (
                                  <span className="text-muted">Closed</span>
                                )}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                      {/* <div>
                        <h4>Links</h4>
                        <a
                          href={doctorProfile?.website}
                          className="mt-3 d-block"
                        >
                          <i className="bi bi-globe me-2"></i> Website
                        </a>
                      </div> */}
                    </div>
                  </div>
                )}

                {reviews &&
                  reviews?.reviews &&
                  doctorProfile?.onlineSchedulingReview && (
                    <div id="tab-reviews" className="tab-box">
                      <div className="d-flex pb-3 justify-content-between">
                        <div>
                          <h4 className="mb-1">Patient reviews</h4>
                          <p className="m-0">
                            Read About Other Patients' Experiences
                          </p>
                        </div>
                        <div className="review-detail-box text-center">
                          <div className="avg-rating">
                            <strong>{reviews?.rating?.avg}</strong>/5
                          </div>
                          <RatingComp
                            rating={reviews?.rating?.avg ?? 0}
                          ></RatingComp>
                          <span className="no-of-review d-block">
                            {reviews?.rating?.total} Reviews
                          </span>
                        </div>
                      </div>
                      <div className="review-box-grid">
                        {reviewExpanded
                          ? reviews?.reviews?.map(
                              (item: any, index: number) => {
                                return (
                                  <div
                                    className="review-box-nw my-3"
                                    key={index + "review"}
                                  >
                                    <RatingComp
                                      rating={Number(item.rating)}
                                    ></RatingComp>
                                    <p>{item.reviewText}</p>
                                    <div className="date-review small">
                                      {moment(item.date).format(
                                        "MMM. DD, YYYY"
                                      )}
                                    </div>
                                    <div className="date-review small">
                                      {item.FullName}
                                    </div>
                                  </div>
                                );
                              }
                            )
                          : reviews?.reviews
                              ?.slice(0, 5)
                              .map((item: any, index: number) => {
                                return (
                                  <div
                                    className="review-box-nw my-3"
                                    key={index + "review"}
                                  >
                                    <RatingComp
                                      rating={Number(item.rating)}
                                    ></RatingComp>
                                    <p>{item.reviewText}</p>
                                    <div className="date-review small">
                                      {moment(item.date).format(
                                        "MMM. DD, YYYY"
                                      )}
                                    </div>
                                    <div className="date-review small">
                                      {item.FullName}
                                    </div>
                                  </div>
                                );
                              })}
                      </div>
                      {!reviewExpanded && (
                        <div className="text-center">
                          <a
                            onClick={() => {
                              setReviewExpanded(true);
                            }}
                            className="btn btn-dark mt-3 btn-lg"
                          >
                            See more reviews
                          </a>
                        </div>
                      )}
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </section>

      {isShowHeader && (
        <footer className="border-top py-3 text-end new-footer ">
          <div className="container">
            <div className="row">
              <div className="col-12">
                © {moment().year()} Powered by XcarePro
              </div>
            </div>
          </div>
        </footer>
      )}
      {isOpen &&
        doctorProfile &&
        doctorProfile.dphotos &&
        doctorProfile.dphotos.length > 0 && (
          <div style={{ zIndex: 2000 }}>
            <Lightbox
              reactModalStyle={{ zIndex: 2000 }}
              mainSrc={
                configs.IMAGE_URL +
                "doctor/" +
                doctorProfile.dphotos[photoIndex].image.name +
                "-original." +
                doctorProfile.dphotos[photoIndex].image.type
              }
              nextSrc={
                configs.IMAGE_URL +
                "doctor/" +
                doctorProfile.dphotos[
                  (photoIndex + 1) % doctorProfile.dphotos.length
                ].image.name +
                "-original." +
                doctorProfile.dphotos[photoIndex].image.type
              }
              prevSrc={
                configs.IMAGE_URL +
                "doctor/" +
                doctorProfile.dphotos[
                  (photoIndex + doctorProfile.dphotos.length - 1) %
                    doctorProfile.dphotos.length
                ].image.name +
                "-original." +
                doctorProfile.dphotos[photoIndex].image.type
              }
              onCloseRequest={() => {
                setIsOpen(false);
              }}
              onMovePrevRequest={() =>
                setPhotoIndex(
                  (photoIndex + doctorProfile.dphotos.length - 1) %
                    doctorProfile.dphotos.length
                )
              }
              onMoveNextRequest={() =>
                setPhotoIndex((photoIndex + 1) % doctorProfile.dphotos.length)
              }
            />
          </div>
        )}
    </>
  );
};

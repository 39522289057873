import React from "react";
import { Helmet } from "react-helmet";
import HeaderWhite from "../LandingPageSearchNew/pages/HeaderWhite/HeaderWhite";
import Footer from "../Footer/Footer";
class About extends React.Component {
  constructor(props: any) {
    super(props);
  }
  render() {
    return (
      <>
        <Helmet>
          <title>About Us - XcarePro</title>
          <meta
            name="description"
            content="XcarePro is revolutionizing the way patients find and interact with healthcare providers. Visit our website to learn more about who we are and why we developed XcarePro."
          />
        </Helmet>
        <HeaderWhite></HeaderWhite>
        {/* <section className="banner-section master-banner sub-banner">
                    <div className="bg-overlay"></div>
                    <div className="container h-100">
                        <div className="row h-100">
                            <div className="col-12 align-self-center text-center">
                                <h1>
                                    About XcarePro 
                                </h1>
                            </div>
                        </div>
                    </div>
                </section> */}

        <div className="no_banner"></div>
        <section className="team-sec">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <h2 className="like-h1">
                  Revolutionizing The{" "}
                  <strong>Patient & Healthcare provider Interaction</strong>
                </h2>
                <p>
                  XcarePro is revolutionizing the way patients find and interact
                  with healthcare providers. Instead of patients searching for a
                  reputable source, we do the work by pairing the patient with a
                  top healthcare provider in their area. Our booking platform
                  simplifies the search, by connecting the patient to the
                  appropriate healthcare provider based on their needs. Our
                  platform acts as the line of communication between patient and
                  provider, presenting both parties with the best user
                  experience possible. Our game changing platform is making
                  dental care more practical, accessible and amicable.
                </p>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-12 text-center">
                <h2 className="like-h1">
                  <strong>Our Core Team</strong>
                </h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-4 text-center">
                <div className="team-box">
                  <div className="team-img">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/team_rakesh-desai.jpg"
                      }
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="team-name">Rakesh Desai</div>
                  <div className="team-desg">Chief Executive Officer</div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 text-center">
                <div className="team-box">
                  <div className="team-img">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/images/team_walter-heidary.jpg"
                      }
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="team-name">Dr. Walter Heidary, DDS</div>
                  <div className="team-desg">Senior Industry Advisor</div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 text-center">
                <div className="team-box">
                  <div className="team-img">
                    <img
                      src={process.env.PUBLIC_URL + "/images/lisa-philp.jpg"}
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="team-name">Lisa Philp</div>
                  <div className="team-desg">Advisory Board</div>
                </div>
              </div>
              {/* <div className="col-md-6 col-lg-4 text-center">
                                <div className="team-box">
                                    <div className="team-img">
                                        <img src={process.env.PUBLIC_URL + "/images/sandeep-walia.jpg"} className="img-fluid" alt="" />
                                    </div>
                                    <div className="team-name">Dr. Sandeep Walia, DDS</div>
                                    <div className="team-desg">Director Advisory Board</div>
                                </div>
                            </div> */}
              {/* <div className="col-md-6 col-lg-4 text-center">
                                <div className="team-box">
                                    <div className="team-img">
                                        <img src={process.env.PUBLIC_URL + "/images/vageesh-sabharwal.jpg"} className="img-fluid" alt="" />
                                    </div>
                                    <div className="team-name">Dr. Vageesh, DDS</div>
                                    <div className="team-desg">Advisory Board</div>
                                </div>
                            </div> */}
              <div className="col-md-6 col-lg-4 text-center">
                <div className="team-box">
                  <div className="team-img">
                    <img
                      src={process.env.PUBLIC_URL + "/images/andrew.jpg"}
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="team-name">Andrew Derayeh</div>
                  <div className="team-desg">Delivery Manager</div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 text-center">
                <div className="team-box">
                  <div className="team-img">
                    <img
                      src={process.env.PUBLIC_URL + "/images/yogesh.jpg"}
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="team-name">Yogesh Pathare</div>
                  <div className="team-desg">CHIEF PRODUCT OFFICER</div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 text-center">
                <div className="team-box">
                  <div className="team-img">
                    <img
                      src={process.env.PUBLIC_URL + "/images/jonathan.jpg"}
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="team-name">Jonathan Del Pozo</div>
                  <div className="team-desg">Social Media Manager</div>
                </div>
              </div>

              <div className="col-md-6 col-lg-4 text-center">
                <div className="team-box">
                  <div className="team-img">
                    <img
                      src={process.env.PUBLIC_URL + "/images/drew.jpg"}
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="team-name">Drew Block</div>
                  <div className="team-desg">DIGITAL MARKETING SPECIALIST</div>
                </div>
              </div>

              <div className="col-md-6 col-lg-4 text-center">
                <div className="team-box">
                  <div className="team-img">
                    <img
                      src={process.env.PUBLIC_URL + "/images/kayla.jpg"}
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="team-name">Kayla Cabral</div>
                  <div className="team-desg">ACCOUNT MANAGER</div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="dentist_dashboard">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="bg_design">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/images/dentist_dashboard.svg"
                    }
                    alt=""
                  />
                </div>
                <div className="dentist_pt">
                  <h2>How XcarePro Is Unique</h2>
                  <p className="intro_desc">
                    Our platform was created to connect the right patients with
                    the right dental practices at the right time. Through our
                    targeted patient acquisition, we guarantee results for your
                    practice. XcarePro benefits healthcare providers by
                    providing them with:
                  </p>
                  <ul className="big_list">
                    <li>
                      {/* <div><strong>You're in good company</strong></div> */}
                      <div>
                        Increased clinic revenue through acquiring more
                        qualified patients
                      </div>
                    </li>
                    <li>
                      <div>
                        More online reviews from satisfied patients through our
                        automated review software
                      </div>
                    </li>
                    <li>
                      <div>
                        Guaranteed results without having to risk your own
                        marketing dollars
                      </div>
                    </li>
                    <li>
                      <div>
                        Improved patient retention rates and frequency at which
                        patients return
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <img
                  src={process.env.PUBLIC_URL + "/images/dashboard-img.png"}
                  className="img-fluid"
                  alt=""
                />
                <div className="dashboard">
                  <p>
                    Are you a top doctor or healthcare provider? <br /> List
                    your practice on XcarePro
                  </p>
                  <a
                    href="/clinic-registration/#gotodentist-form"
                    className="btn btn-lg btn-primary"
                  >
                    Sign Up For Free
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*   <Footer></Footer> */}
      </>
    );
  }
}
export default About;

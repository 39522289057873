import Axios from "axios";
import apiConfigs from "../configs/apiConfigs";

export const fetchReviews = (clinicId: string, page: number = -1, records: number = -1, star: number = -1) => {
    let url = apiConfigs.API_URL + 'clinic/' + clinicId + '/reviewfetch/?';
    if (page !== -1) {
        url += 'page=' + page + '&';
    }
    if (records !== -1) {
        url += 'records=' + records + '&';
    }
    if (star !== -1) {
        url += 'star=' + star + '&';
    }
    return Axios.get(url, {
        headers: {
            xprApp: 'front'
        }
    }).then(response => ({ response }), error => ({ error }));
}

/* Fetch Clinic from location */
export const fetchClinicByLocation = (location: string): Promise<any> => {
    let url = apiConfigs.API_URL + 'location/' + location + '/';
    return new Promise((res, rej) => {
        try {
            Axios.get(url, {
                headers: { xprApp: 'front' }
            }).then((success) => {
                if (success && success.status === 200) {
                    res(success.data);
                } else {
                    rej(success);
                }
            }).catch((error) => {
                rej(error);
            });
        } catch (error) {
            rej(error);
        }
    });
}
export const fetchPatientRegister = (request: any): Promise<any> => {
    return new Promise((res, rej) => {
        try {
            Axios.post(apiConfigs.API_URL + 'patient/register/search/', request, {
                headers: {
                    'xprApp': 'front'
                }
            }).then((success: any) => {
                if (success && success.status === 200) {
                    res(success.data);
                } else {
                    rej(success);
                }
            }, (error: any) => {
                rej(error);
            }).catch((error) => {
                rej(error);
            });
        } catch (error) {
            rej(error);
        }
    });
}

/* Login api */
export const doLogin = (request: { email: string, password: string }): Promise<any> => {
    return new Promise((res, rej) => {
        try {
            Axios.post(apiConfigs.API_URL + 'flogin/', request, {
                headers: {
                    'xprApp': 'front'
                }
            }).then((success: any) => {
                if (success && success.status === 200 && success.data && success.data.status && success.data.status.error === false) {
                    res(success.data);
                } else {
                    rej(success.data);
                }
            }, (error: any) => {
                rej(error);
            }).catch((error) => {
                rej(error);
            });
        } catch (error) {
            rej(error);
        }
    });
}

export const doForgotPassword = (request: { email: string }): Promise<any> => {
    return new Promise((res, rej) => {
        try {
            Axios.post(apiConfigs.API_URL + 'fpassword/', request, {
                headers: {
                    'xprApp': 'front'
                }
            }).then((success: any) => {
                if (success && success.status === 200 && success.data && success.data.status && success.data.status.error === false) {
                    res(success.data);
                } else {
                    rej(success.data);
                }
            }, (error: any) => {
                rej(error);
            }).catch((error) => {
                rej(error);
            });
        } catch (error) {
            rej(error);
        }
    });
}

export const getInviteCodeDetails = (inviteCode: string) => {
    return new Promise((res, rej) => {
        try {
            Axios.post(apiConfigs.API_URL + 'info/' + inviteCode + '/', {}, {
                headers: {
                    'xprApp': 'front'
                }
            }).then((success: any) => {
                if (success && success.status === 200 && success.data && success.data.status && success.data.status.error === false) {
                    res(success.data);
                } else {
                    rej(success.data);
                }
            }, (error: any) => {
                rej(error);
            }).catch((error) => {
                rej(error);
            });
        } catch (error) {
            rej(error);
        }
    });
}

export const getHygieneFeatures = () => {
    return Axios.get(apiConfigs.API_URL + 'hygienes/').then((success) => {
        if (success.status !== 200) {
            throw success;
        } else if (success && success.data && success.data.status && success.data.status.error === true) {
            throw success;
        }
        return success.data;
    }).then(json => {
        return json;
    }).catch(error => {
        throw JSON.parse(JSON.stringify(error));
    });
}
import React from 'react';
import { IAppState } from '../../store';
import { connect } from 'react-redux';
import { CONSTANT } from '../../constants';
import { NavLink } from 'react-router-dom';
import { FormWizardState } from '../../store/formwizard/types';
import { addNotReadyReason } from '../../store/formwizard/actions';
interface Props {
    formwizardState: FormWizardState;
    addNotReadyReason: typeof addNotReadyReason;
    history: any;
}
interface State {
    otherReason: string;
}
class NotReadyReason extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            otherReason: ''
        }
    }
    componentDidMount() {
        if (this.props.formwizardState.formwizard.notreadyreason.value === "Other") {
            this.setState({ otherReason: this.props.formwizardState.formwizard.notreadyreason.reason });
        }
    }
    selectReadyToBook(readyToBook: any) {
        this.props.addNotReadyReason({ notreadytobook: { value: readyToBook } });
        if (readyToBook !== "Other") {
            this.props.history.push('/quiz/email');
        }
    }
    shouldComponentUpdate(nextProps: Props, nextState: any) {
        if (this.props !== nextProps) {
            if (nextProps.formwizardState.formwizard.notreadyreason.value === "Other") {
                this.setState({ otherReason: nextProps.formwizardState.formwizard.notreadyreason.reason });
            }
        }
        return true;
    }
    render() {
        const selectedNotReady: any = this.props.formwizardState.formwizard.notreadyreason;
        return (
            <div className="col-12 col-md-10 not-ready wizard">
                <div className="row justify-content-center gutter-5">
                    <div className="col-12">                       
                        <div className="txt-box">
                            <h4 className="title">What's stopping you from booking an appointment right now?</h4>
                            <div className="sub-title">In some cases, we can help you get the right information or remind you when you're ready.</div>
                        </div>
                    </div>
                    {
                        CONSTANT.QUIZ.readyOptions.map((option) => {
                            return (
                                <div className="col-lg-9 booking-reason">
                                    <button className={"custom-click btn " + ((selectedNotReady && selectedNotReady !== "" && selectedNotReady.value === option) ? 'active' : '')}
                                        onClick={(e) => { this.selectReadyToBook(option) }} >
                                        <div className="content-container">
                                            <h5 className="main-text">{option}</h5>
                                        </div>
                                    </button>
                                </div>
                            )
                        })
                    }
                    {
                        selectedNotReady.value === "Other" ?
                            <>
                                <div className="col-9 col-12">
                                    <input type="text" placeholder="Enter other reason..." value={this.state.otherReason}
                                        onInput={(e: any) => {
                                            this.setState({ otherReason: e.target.value });
                                        }}
                                        onChange={(e: any) => {
                                            this.setState({ otherReason: e.target.value });
                                        }} />
                                </div>
                                {
                                    (selectedNotReady.value === "Other" && this.state.otherReason !== '') &&
                                    <div className="col-9 col-12">
                                        <div className="bottom_bar">
                                            <button className="btn btn-toggle activeBtn"
                                                onClick={(e) => {
                                                    this.props.addNotReadyReason({ notreadytobook: { value: selectedNotReady.value, reason: this.state.otherReason } });
                                                    this.props.history.push('/quiz/email');
                                                }}
                                            >Contitue</button>
                                        </div>
                                    </div>
                                }
                            </>
                            : null
                    }
                </div>
            </div>


        )
    }
}
const mapStateToProps = (state: IAppState) => ({
    formwizardState: state.formwizard
});
const mapDispatchToProps = (dispatch: any) => ({
    addNotReadyReason: (notready: any) => {
        dispatch(addNotReadyReason(notready));
    }
});
export default connect(mapStateToProps, mapDispatchToProps)(NotReadyReason);
import { getCookie, removeCookie, prepareCookie } from '../utils/global';
import { logoutUser } from "./firebaseUtils";
/**
 * 
 * @param isAttachAuthParam true when request made after login
 * @param isMultiPart true if request is multipart/form-data : default to false
 */
export const attachHeader = (isAttachAuthParam: boolean, isMultiPart: boolean = false) => {
    try {
        const login = getCookie();
        console.log('loginCookie',login)
        if (isAttachAuthParam) {
            let headers = null;
            if (isMultiPart) {
                headers = {
                    'Content-Type': 'multipart/form-data',
                    // 'x-access-param': login.authParam,
                    // 'xpr_user_id': login.uniqueId,
                    'x-access-param': login.xprAuthUser,
                    'xpr_user_id': login.xpr_user_id,
                    'xcookie': prepareCookie(),
                    'xprApp': 'patient'
                }
            } else {
                headers = {
                    'Content-Type': 'application/json',
                    // 'x-access-param': login.authParam,
                    // 'xpr_user_id': login.uniqueId, 
                    'x-access-param': login.xprAuthUser,
                    'xpr_user_id': login.xpr_user_id,
                    'xcookie': prepareCookie(),
                    'xprApp': 'patient'
                }
            }
            console.log('Headers1',headers)
            return headers;
        }
        const headers = {
            'Content-Type': 'application/json',
        }
        console.log('Headers2',headers)
        return headers;
    } catch (error) {
        console.log('errorAttachHeader',error)
        removeCookie('patient_cookie')
        logoutUser();
        // window.location.href = '/';
    }
}
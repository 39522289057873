export const LABELS = {
    EMAIL: 'Email address',
    PASSWORD: 'Password',
    FORGOT_PASSWORD_DESC: 'Enter the email address or mobile number associated with your account.',
    REGISTER: 'Register New Account',
    BUTTON: {
        LOGIN: 'LOGIN',
        SEND_RESET_LINK: 'Forgot password',
        SIGNIN:'SIGN IN'
    },
    EMAILORPHONE: 'Email or mobile number',
    PHONE: 'Mobile number'
}
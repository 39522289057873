import React from "react";
import Header from "../Header/Header";
import Axios from "axios";
import apiConfigs from "../../configs/apiConfigs";
import {
  emailValidation,
  allowAlphabetsAndSpace,
} from "../../utils/validators";
import InputMask from "react-input-mask";
import { takeOnlyDigitAndPlus, takeOnlyDigit } from "../../utils/global";
import { CONSTANT } from "../../constants";
import { toast } from "react-toastify";
interface Props {}
interface State {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  createLogin: boolean;
  phone: string;
  referralLink: string;
}
class Referral extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      email: "",
      firstName: "",
      lastName: "",
      password: "",
      createLogin: false,
      phone: "",
      referralLink: "",
    };
  }
  onReferCheckClick() {
    if (this.state.email === "") {
      toast.warn("Email required");
      return;
    } else if (!emailValidation(this.state.email)) {
      toast.warn("Invalid email");
      return;
    }
    Axios.post(
      apiConfigs.API_URL + "referral/",
      { patient: { email: this.state.email } },
      { headers: { xprApp: "front" } }
    )
      .then((success) => {
        if (success.status === 200) {
          if (success.data.status.error === false) {
            if (success.data.isNewPatient) {
              this.setState({ createLogin: true, referralLink: "" });
            } else {
              this.setState({
                createLogin: false,
                referralLink:
                  "http://www.xcarepro.com/invite/" +
                  success.data.patient.referralCode,
              });
              console.log(success);
            }
          } else {
            toast.error(success.data.status.msg);
            console.log(success);
          }
        } else {
          console.log(success);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  onCreateLoginClick() {
    if (this.state.firstName === "") {
      toast.warn("First Name required");
      return;
    } else if (this.state.lastName === "") {
      toast.warn("Last Name required");
      return;
    } else if (this.state.phone === "") {
      toast.warn("Phone required");
      return;
    } else if (this.state.password === "") {
      toast.warn("Password Required");
      return;
    } else if (takeOnlyDigit(this.state.phone).length !== 11) {
      toast.warn("Invalid phone number");
      return;
    }
    Axios.post(
      apiConfigs.API_URL + "referral/",
      {
        isNewPatient: true,
        patient: {
          email: this.state.email,
          fname: this.state.firstName,
          lname: this.state.lastName,
          password: this.state.password,
          phone: takeOnlyDigitAndPlus(this.state.phone),
        },
      },
      { headers: { xprApp: "front" } }
    )
      .then((success) => {
        if (success.status === 200) {
          if (success.data.status.error === false) {
            if (success.data.status && success.data.status.msg) {
              this.setState({
                referralLink: success.data.status.msg,
                email: "",
                firstName: "",
                lastName: "",
                password: "",
                phone: "",
                createLogin: false,
              });
            }
          } else {
            toast.error(success.data.status.msg);
            console.log(success);
          }
        } else {
          console.log(success);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  render() {
    return (
      <>
        <Header></Header>
        <section className="referral-page">
          <div className="container h-100">
            <div className="row h-100 justify-content-between align-items-center">
              <div className="col-md-6">
                <div className="info-sec">
                  <div className="section-title">
                    Refer XcarePro <span>| Get ${CONSTANT.REWARD}</span>
                  </div>
                  <ul className="how-list">
                    <li>
                      <div className="d-flex">
                        <div className="media-img">1</div>
                        <div className="media-body">
                          <div className="title-txt">Share referral link</div>
                          <div className="des-txt">
                            Share your referral link with friends, family, or
                            colleagues.
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div className="media-img">2</div>
                        <div className="media-body">
                          <div className="title-txt">Track referrals</div>
                          <div className="des-txt">
                            Track how many referrals book a visit on your
                            dashboard.
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div className="media-img">3</div>
                        <div className="media-body">
                          <div className="title-txt">Get Rewarded</div>
                          <div className="des-txt">
                            You and your friend each get ${CONSTANT.REWARD}{" "}
                            after they visit the dentist.
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-5 col-md-6">
                <div className="referal-input-box text-center">
                  <div className="title-sec">
                    <div className="title-txt">Referral Program</div>
                    <p>
                      Both you and the person you refer get{" "}
                      <strong>${CONSTANT.REWARD}</strong> when they visit their
                      Xcarepro dentist.
                    </p>
                  </div>
                  <div className="input-sec">
                    <input
                      type="text"
                      placeholder="Email of family, friends, etc."
                      className="location-input"
                      value={this.state.email}
                      onInput={(e: any) => {
                        this.setState({ email: e.target.value });
                      }}
                      onChange={(e) => {
                        this.setState({ email: e.target.value });
                      }}
                    />
                    {this.state.createLogin === false && (
                      <button
                        className="btn btn-lg btn-primary"
                        onClick={(e) => {
                          this.onReferCheckClick();
                        }}
                      >
                        Submit
                      </button>
                    )}
                    {this.state.createLogin === true && (
                      <>
                        <div className="step-title">
                          Welcome! Please create your login.
                        </div>
                        <div className="form-row">
                          <div className="col">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="First name"
                              value={this.state.firstName}
                              onInput={(e: any) => {
                                if (allowAlphabetsAndSpace(e.target.value)) {
                                  this.setState({ firstName: e.target.value });
                                }
                              }}
                              onChange={(e) => {
                                if (allowAlphabetsAndSpace(e.target.value)) {
                                  this.setState({ firstName: e.target.value });
                                }
                              }}
                            />
                          </div>
                          <div className="col">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Last name"
                              value={this.state.lastName}
                              onInput={(e: any) => {
                                if (
                                  allowAlphabetsAndSpace(this.state.lastName)
                                ) {
                                  this.setState({ lastName: e.target.value });
                                }
                              }}
                              onChange={(e) => {
                                if (
                                  allowAlphabetsAndSpace(this.state.lastName)
                                ) {
                                  this.setState({ lastName: e.target.value });
                                }
                              }}
                            />
                          </div>
                          <div className="col">
                            <InputMask
                              mask="+1(999) 999-9999"
                              value={this.state.phone}
                              onInput={(e: any) => {
                                this.setState({ phone: e.target.value });
                              }}
                              onChange={(e) => {
                                this.setState({ phone: e.target.value });
                              }}
                            >
                              {(inputProps: any) => (
                                <input
                                  {...inputProps}
                                  placeholder="Phone number"
                                  className="form-control"
                                ></input>
                              )}
                            </InputMask>
                          </div>
                        </div>
                        <input
                          type="password"
                          placeholder="Password"
                          className="location-input"
                          value={this.state.password}
                          onInput={(e: any) => {
                            this.setState({ password: e.target.value });
                          }}
                          onChange={(e) => {
                            this.setState({ password: e.target.value });
                          }}
                        />
                        <button
                          className="btn btn-lg btn-primary"
                          onClick={(e) => {
                            this.onCreateLoginClick();
                          }}
                        >
                          Submit
                        </button>
                      </>
                    )}
                    {this.state.referralLink !== "" && (
                      <p>{this.state.referralLink}</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
export default Referral;

export enum FORM_WIZARD {
    ADD_LOCATION = 'ADD_LOCATION',
    ADD_ATTITUDE = 'ADD_ATTITUDE',
    ADD_REASAON = 'ADD_REASON',
    ADD_GOAL = 'ADD_GOAL',
    REMOVE_GOAL = 'REMOVE_GOAL',
    ADD_CRITERIA = 'ADD_CRITERIA',
    REMOVE_CRITERIA = 'REMOVE_CRITERIA',
    ADD_LAST_VISITED = 'ADD_LAST_VISITED',
    ADD_DENTAL_ANXIETY = 'ADD_DENTAL_ANXIETY',
    ADD_AVAILABILITY = 'ADD_AVALABILITY',
    REMOVE_AVAILABILITY = 'REMOVE_AVAILABILITY',
    ADD_HOW_SOON = 'ADD_HOW_SOON',
    ADD_INSURANCE = 'ADD_INSURANCE',
    ADD_INSURANCE_DETAIL = 'ADD_INSURANCE_DETAIL',
    ADD_GENDER = 'ADD_GENDER',
    ADD_SOCIAL_ASSISTANCE = 'ADD_SOCIAL_ASSISTANCE',
    ADD_READY_TO_BOOK = 'ADD_READY_TO_BOOK',
    ADD_INFO = 'ADD_INFO',
    ADD_DENTIST_SERVICE = 'ADD_DENTIST_SERVICE',
    ADD_DENTIST_URGENT = 'ADD_DENTIST_URGENT',
    REMOVE_DENTIST_SERVICE = 'REMOVE_DENTIST_SERVICE',
    ADD_NOT_READY_REASON = 'ADD_NOT_READY_REASON',
    SET_TO_INITIAL = 'SET_TO_INITIAL',
    ADD_PERCENTAGE = 'ADD_PERCENTAGE'
}
export interface FormWizard {
    location: any;
    attitude: any;
    reason: any;
    goals: any[];
    criterias: any[];
    lastVisited: any;
    dentalAnxiety: any;
    availabilities: any[];
    howSoon: any;
    insurance: any;
    insuranceDetail: any;
    gender: any;
    socialAssistance: any;
    readyToBook: any;
    email: any;
    phone: any;
    dentistServices: any[];
    dentistUrgent: any;
    notreadyreason: any;
}
/* Types */
export interface AddLocation {
    location: any;
}
export interface AddAttitude {
    attitude: any;
}
export interface AddReason {
    reason: any;
}
export interface AddGoal {
    goal: any;
}
export interface RemoveGoal {
    goal: any;
}
export interface AddCriteria {
    criteria: any;
}
export interface RemoveCriteria {
    criteria: any;
}
export interface AddLastVisited {
    lastVisited: any;
}
export interface AddDentalAnxiety {
    dentalAnxiety: any;
}

export interface AddAvailability {
    availability: any;
}
export interface RemoveAvailability {
    availability: any;
}
export interface AddHowSoon {
    howsoon: any;
}
export interface AddInsurance {
    insurance: any;
}
export interface AddInsuranceDetail {
    insuranceDetail: any;
}
export interface AddGender {
    gender: any;
}
export interface AddSocialAssistance {
    socialAssistance: any;
}
export interface AddReadyToBook {
    readyToBook: any;
}
export interface AddInfo {
    payload: {
        email: any;
        phone: any;
    }
}
export interface AddDentistService {
    dentistService: any;
}
export interface RemoveDentistService {
    dentistService: any;
}
export interface AddDentistUrgent {
    dentistUrgent: any;
}
export interface AddNotReadyReason {
    notreadytobook: any;
}
/* State */
export interface FormWizardState {
    formwizard: FormWizard;
    percent: number;
}
/* Action creators types */
export interface AddLocationAction {
    type: FORM_WIZARD.ADD_LOCATION,
    payload: AddLocation;
}
export interface AddAttitudeAction {
    type: FORM_WIZARD.ADD_ATTITUDE,
    payload: AddAttitude;
}
export interface AddGoalAction {
    type: FORM_WIZARD.ADD_GOAL,
    payload: AddGoal;
}
export interface RemoveGoalAction {
    type: FORM_WIZARD.REMOVE_GOAL,
    payload: RemoveGoal;
}
export interface AddReasonAction {
    type: FORM_WIZARD.ADD_REASAON,
    payload: AddReason;
}
export interface AddCriteriaAction {
    type: FORM_WIZARD.ADD_CRITERIA,
    payload: AddCriteria;
}
export interface RemoveCriteriaAction {
    type: FORM_WIZARD.REMOVE_CRITERIA,
    payload: RemoveCriteria;
}
export interface AddLastVisitedAction {
    type: FORM_WIZARD.ADD_LAST_VISITED,
    payload: AddLastVisited;
}
export interface AddDentalAnxietyAction {
    type: FORM_WIZARD.ADD_DENTAL_ANXIETY,
    payload: AddDentalAnxiety;
}
export interface AddAvailabilityAction {
    type: FORM_WIZARD.ADD_AVAILABILITY,
    payload: AddAvailability;
}
export interface RemoveAvailabilityAction {
    type: FORM_WIZARD.REMOVE_AVAILABILITY,
    payload: RemoveAvailability;
}
export interface AddHowSoonAction {
    type: FORM_WIZARD.ADD_HOW_SOON,
    payload: AddHowSoon;
}
export interface AddInsuranceAction {
    type: FORM_WIZARD.ADD_INSURANCE,
    payload: AddInsurance;
}
export interface AddInsuranceDetailAction {
    type: FORM_WIZARD.ADD_INSURANCE_DETAIL,
    payload: AddInsuranceDetail;
}
export interface AddGenderAction {
    type: FORM_WIZARD.ADD_GENDER,
    payload: AddGender;
}
export interface AddSocialAssistanceAction {
    type: FORM_WIZARD.ADD_SOCIAL_ASSISTANCE,
    payload: AddSocialAssistance;
}
export interface AddReadyToBookAction {
    type: FORM_WIZARD.ADD_READY_TO_BOOK,
    payload: AddReadyToBook;
}
export interface AddInfoAction {
    type: FORM_WIZARD.ADD_INFO,
    payload: AddInfo;
}
export interface AddDentistServiceAction {
    type: FORM_WIZARD.ADD_DENTIST_SERVICE,
    payload: AddDentistService;
}
export interface RemoveDentistServiceAction {
    type: FORM_WIZARD.REMOVE_DENTIST_SERVICE,
    payload: RemoveDentistService;
}
export interface AddDentistUrgentAction {
    type: FORM_WIZARD.ADD_DENTIST_URGENT,
    payload: AddDentistUrgent;
}
export interface AddNotReadyReasonAction {
    type: FORM_WIZARD.ADD_NOT_READY_REASON,
    payload: AddNotReadyReason;
}
export interface SetToInitialAction {
    type: FORM_WIZARD.SET_TO_INITIAL
}
export interface SetPercentageAction {
    type: FORM_WIZARD.ADD_PERCENTAGE,
    percent: any;
}
export type FormWizardType = AddLocationAction | AddAttitudeAction | AddReasonAction |
    AddGoalAction | RemoveGoalAction | AddCriteriaAction | RemoveCriteriaAction |
    AddLastVisitedAction | AddDentalAnxietyAction | AddAvailabilityAction | RemoveAvailabilityAction |
    AddHowSoonAction | AddInsuranceAction | AddInsuranceDetailAction |
    AddGenderAction | AddSocialAssistanceAction | AddReadyToBookAction |
    AddInfoAction | AddDentistServiceAction | RemoveDentistServiceAction |
    AddDentistUrgentAction | AddNotReadyReasonAction | SetToInitialAction | SetPercentageAction;
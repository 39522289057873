import React from 'react';
import { connect } from 'react-redux';
import { IAppState } from '../../store';
import { NavLink } from 'react-router-dom';
import { CONSTANT } from '../../constants';
import { FormWizardState } from '../../store/formwizard/types';
import { addInsuranceDetail } from '../../store/formwizard/actions';
import { fetchOtherInsuranceProvider } from '../../services/dentist';
import Select from 'react-select';
interface Props {
    formwizardState: FormWizardState;
    addInsuranceDetail: typeof addInsuranceDetail;
    history: any;
}
interface State {
    otherEnabled: boolean;
    otherInsuranceList: any[];
    selectedInsurance: any;
}
class InsuranceDetails extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            otherEnabled: false,
            otherInsuranceList: [],
            selectedInsurance: '',
        }
    }
    componentDidMount() {
        if (this.props.formwizardState.formwizard.insuranceDetail && this.props.formwizardState.formwizard.insuranceDetail.value
            && this.props.formwizardState.formwizard.insuranceDetail.value === "Other provider") {
            this.setState({ otherEnabled: true });
            fetchOtherInsuranceProvider().then((success: any) => {
                if (success.response.status === 200) {
                    if (success.response.data.status && success.response.data.status.error === false) {
                        this.setState({
                            otherInsuranceList: success.response.data.insurances ?
                                success.response.data.insurances.map((value: any) => {
                                    return {
                                        value: value.id,
                                        label: value.name
                                    }
                                })
                                : [],
                            selectedInsurance: { value: this.props.formwizardState.formwizard.insuranceDetail.id, label: this.props.formwizardState.formwizard.insuranceDetail.label }
                        });
                    }
                }
            });
        }
    }
    selectInsuranceDetail(insurance: any) {
        if (insurance.value === "Other provider") {
            this.props.addInsuranceDetail({ insuranceDetail: insurance });
            this.setState({ otherEnabled: true });
            fetchOtherInsuranceProvider().then((success: any) => {
                if (success.response.status === 200) {
                    if (success.response.data.status && success.response.data.status.error === false) {
                        this.setState({
                            otherInsuranceList: success.response.data.insurances ?
                                success.response.data.insurances.map((value: any) => {
                                    return {
                                        value: value.id,
                                        label: value.name
                                    }
                                })
                                : []
                        }, () => {
                        });
                    }
                }
            });
        } else {
            this.props.addInsuranceDetail({ insuranceDetail: insurance });
            this.props.history.push(insurance.link);
        }
    }
    shouldComponentUpdate(nextProps: Props, nextState: any) {
        if (this.props !== nextProps) {
            console.log(nextProps);
            if (nextProps.formwizardState.formwizard.insuranceDetail && nextProps.formwizardState.formwizard.insuranceDetail.value
                && nextProps.formwizardState.formwizard.insuranceDetail.value === "Other provider") {
                this.setState({ otherEnabled: true });
                fetchOtherInsuranceProvider().then((success: any) => {
                    if (success.response.status === 200) {
                        if (success.response.data.status && success.response.data.status.error === false) {
                            this.setState({
                                otherInsuranceList: success.response.data.insurances ?
                                    success.response.data.insurances.map((value: any) => {
                                        return {
                                            value: value.id,
                                            label: value.name
                                        }
                                    })
                                    : [],
                                selectedInsurance: { value: nextProps.formwizardState.formwizard.insuranceDetail.id, label: nextProps.formwizardState.formwizard.insuranceDetail.label }
                            });
                        }
                    }
                });
            }
        }
        return true;
    }
    render() {
        const selectedInsuranceDetail: any = this.props.formwizardState.formwizard.insuranceDetail;
        return (
            <div className="col-12 col-md-10 wizard insurance-providers">
                <div className="row justify-content-center gutter-5">
                    <div className="col-12">
                        {/* <div className="img-box">
                            <img src={process.env.PUBLIC_URL + "/images/insurance.svg"} className="img-fluid" />
                        </div> */}
                        <div className="txt-box">
                            <h4 className="title">Who is your dental insurance provider?</h4>
                            <div className="sub-title">If you’re not sure - don’t worry - you can change your answer later.</div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-9">
                        <div className="row">
                            {
                                CONSTANT.QUIZ.providers.map((provider) => {
                                    return (
                                        <div className="col-lg-4 mb-30" key={Math.random()}>
                                            <button
                                                className={"custom-click btn custom-click btn d-flex custom " + ((selectedInsuranceDetail && selectedInsuranceDetail !== "" && selectedInsuranceDetail.name === provider.name) ? 'active' : '')}
                                                onClick={(e) => { this.selectInsuranceDetail(provider) }}>
                                                <div className="img-container m-0 align-self-center w-100 text-center">
                                                    <img src={process.env.PUBLIC_URL + '/' + provider.img} />
                                                </div>
                                            </button>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="col-12 col-md-3">
                        <div className="row">
                            {
                                CONSTANT.QUIZ.providerOptions.map((oprovider: any) => {
                                    return (
                                        <div className="col-12 mb-30">
                                            <button
                                                className={"custom-click btn custom-other " + ((selectedInsuranceDetail && selectedInsuranceDetail !== "" && selectedInsuranceDetail.value === oprovider.value) ? 'active' : '')}
                                                onClick={(e) => { this.selectInsuranceDetail(oprovider) }}
                                            >
                                                <div className="img-container text-center">
                                                    <img src={process.env.PUBLIC_URL + '/' + oprovider.img} />
                                                </div>
                                                <div className="content-container">
                                                    <h5 className="main-text m-0">{oprovider.value}</h5>
                                                </div>
                                            </button>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                {
                    this.state.otherEnabled === true &&
                    <div className="row justify-content-center">
                        <div className="col-md-8 text-start form-sec" >
                            <label>Other insurance provider</label>
                            <Select
                                value={this.state.selectedInsurance}
                                onChange={(selected) => { this.setState({ selectedInsurance: selected }) }}
                                options={this.state.otherInsuranceList}
                            ></Select>

                            <div className="bottom_bar text-center">
                                <button className="btn btn-toggle activeBtn" onClick={(e) => {
                                    const selectedID = selectedInsuranceDetail;
                                    const selectedInsurance = this.state.selectedInsurance;
                                    this.props.addInsuranceDetail({
                                        insuranceDetail: { ...selectedID, id: selectedInsurance.value, label: selectedInsurance.label, link: '/gender' }
                                    });
                                    this.props.history.push('/quiz/gender');
                                    // this.selectInsuranceDetail({ ...selectedID, id: selectedInsurance.value, label: selectedInsurance.label, link: '/gender' });
                                }}>Continue</button>
                            </div>
                        </div>
                    </div>
                }
            </div>

        );
    }
}
const mapStateToProps = (state: IAppState) => ({
    formwizardState: state.formwizard
});
const mapDispatchToProps = (dispatch: any) => ({
    addInsuranceDetail: (insurance: any) => {
        dispatch(addInsuranceDetail(insurance));
    }
});
export default connect(mapStateToProps, mapDispatchToProps)(InsuranceDetails);
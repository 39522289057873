import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import {
  getInsuranceProvider,
  getSocialAssistance,
  getInsurancePlan,
  getInsuranceProviderNew,
} from "../../../../services/landingPageSearch";
import { connect, useDispatch } from "react-redux";

export const PaymentMethodModal = (props: any) => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState("insurance");
  const [paymentMethod, setPaymentMethod] = useState([
    { name: "Cash", active: true },
    { name: "Credit", active: true },
    { name: "Debit", active: true },
  ] as any);
  const [insuranceProviderList, setInsuranceProviderList] = useState([] as any);
  const [selectedInsuranceProvider, setSelectedInsuranceProvider] =
    useState("");
  const [insurancePlanList, setInsurancePlanList] = useState([
    {
      name: "Basic Plans",
      value: "basic_plans",
    },
    {
      name: "Comprehensive Plans",
      value: "comprehensive_plans",
    },
    {
      name: "Orthodontic Plans",
      value: "orthodontic_plans",
    },
    {
      name: "Group Benefits Plans",
      value: "group_benefits_plans",
    },
    {
      name: "Standalone Dental Insurance",
      value: "standalone_dental_insurance",
    },
  ]);
  const [selectedInsurancePlan, setSelectedInsurancePlan] =
    useState("basic_plans");
  const [socialAssistanceList, setSocialAssistanceList] = useState([]);
  const [selectedSocialAssistance, setSelectedSocialAssistance] = useState("");
  const [insuranceProviderOther, setinsuranceProviderOther] = useState("");
  const [insurancePoviderOtherValidation, setInsuranceProviderOtherValidation] =
    useState({ isValid: true, msg: "" });
  const [insurancePlanNameOther, setInsurancePlanNameOther] = useState("");
  const [
    insurancePlanNameOtherValidation,
    setInsurancePlanNameOtherValidation,
  ] = useState({ isValid: true, msg: "" });
  const dispatch = useDispatch();

  useEffect(() => {
    getInsuranceProviderApi();
    getSocialAssistanceApi();
  }, []);

  const getInsuranceProviderApi = () => {
    getInsuranceProviderNew().then((success: any) => {
      try {
        if (
          success &&
          success.response &&
          success.response.data &&
          success.response.status === 200
        ) {
          if (
            success.response.data &&
            success.response.data.status.error === false
          ) {
            setInsuranceProviderList([
              ...success.response.data.insurance,
              // {
              //   id: "other",
              //   name: "Other",
              //   plan: [{ id: "other", name: "Other" }],
              // },
            ]);
          } else {
            if (
              success.response.data &&
              success.response.data.status.error === true
            ) {
              toast.error(success.response.data.status.msg);
            }
          }
        }
      } catch (error) {
        toast.error("Something went wrong.");
        console.log("error", error);
      }
    });
  };
  const getSocialAssistanceApi = () => {
    getSocialAssistance().then((success: any) => {
      try {
        if (
          success &&
          success.response &&
          success.response.data &&
          success.response.status === 200
        ) {
          if (
            success.response.data &&
            success.response.data.status.error === false
          ) {
            setSocialAssistanceList(success.response.data.types);
          } else {
            if (
              success.response.data &&
              success.response.data.status.error === true
            ) {
              toast.error(success.response.data.status.msg);
            }
          }
        }
      } catch (error) {
        toast.error("Something went wrong.");
        console.log("error", error);
      }
    });
  };
  // NEW
  const changeInsuranceProvider = (value: any) => {
    let insurance = insuranceProviderList.find(
      (item: any) => item.id === value
    );
    if (value === "other") {
      insurance = {
        ...insurance,
        plan: [selectedInsurancePlan],
      };
    } else {
      insurance = {
        ...insurance,
        plan: [selectedInsurancePlan],
      };
    }
    if (insurance && insurance.plan.length > 0) {
      setSelectedInsuranceProvider(value);
      // setInsurancePlanList(insurance.plan);
      // setSelectedInsurancePlan(insurance.plan[0].id);
    }
  };

  const handleSocialAssistance = (value: any) => {
    setSelectedSocialAssistance(value);
  };
  const handlePaymentMethod = (value: any) => {
    let findIndex = paymentMethod.find(
      (item: any) => item.name === value && item.active === true
    );
    if (findIndex !== undefined) {
      let data = paymentMethod.map((item: any, index: number) => {
        if (item.name === findIndex.name) {
          return {
            ...item,
            active: false,
          };
        } else {
          return item;
        }
      });
      setPaymentMethod(data);
    } else {
      let data = paymentMethod.map((item: any) => {
        if (item.name === value) {
          return {
            ...item,
            active: true,
          };
        } else {
          return item;
        }
      });
      setPaymentMethod(data);
    }
  };
  const handleSaveButton = () => {
    if (selectedPaymentMethod === "insurance") {
      if (selectedInsuranceProvider === "") {
        toast.warn("Please select insurance provider.");
        return;
      }
    }
    if (selectedPaymentMethod === "cash") {
      let selectedData = paymentMethod.filter((item: any) => {
        return item.active === true;
      });
      if (selectedData.length <= 0) {
        toast.warn("Please select Payment method");
        return;
      }
    }
    if (selectedPaymentMethod === "socialAssistance") {
      if (selectedSocialAssistance === "") {
        toast.warn("Please select Social assistance");
        return;
      }
    }
    let selectedValue: any = "";
    let insuranceDetail: any;
    if (selectedPaymentMethod === "insurance") {
      selectedValue = selectedInsuranceProvider;
      insuranceDetail = {
        insurance: insuranceProviderList.find(
          (iItem) => iItem.id === selectedInsuranceProvider
        ),
        insuranceType: insurancePlanList.find(
          (iItem) => iItem.value === selectedInsurancePlan
        ),
      };
    } else if (selectedPaymentMethod === "cash") {
      selectedValue = paymentMethod.map((item: any) => {
        return item.name;
      });
    } else if (selectedPaymentMethod === "socialAssistance") {
      selectedValue = selectedSocialAssistance;
    }
    console.log("insuranceDetail", insuranceDetail);
    props.onClickSave(selectedPaymentMethod, selectedValue, insuranceDetail);
  };
  return (
    <Modal
      centered
      className="selectPaymentModel"
      tabIndex={1}
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      show={props.isShow}
      onHide={props.onHide}
    >
      <Modal.Body>
        <div className="search-popup">
          <div className="modal-header px-0">
            <h5 className="modal-title" id="exampleModalLabel">
              Help us find the right provider for you.
            </h5>
          </div>
          <div className="modal-body px-0">
            <div className="mb-3">
              <label className="d-block">How will you pay ?</label>
              <div className="form-check form-check-inline">
                <input
                  checked={selectedPaymentMethod === "insurance"}
                  onClick={() => {
                    setSelectedPaymentMethod("insurance");
                  }}
                  className="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio1"
                  value="option1"
                />
                <label className="form-check-label" htmlFor="inlineRadio1">
                  Insurance
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  checked={selectedPaymentMethod === "cash"}
                  onClick={() => {
                    setSelectedPaymentMethod("cash");
                  }}
                  className="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio2"
                  value="option2"
                />
                <label className="form-check-label" htmlFor="inlineRadio2">
                  Cash/Credit/Debit
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  checked={selectedPaymentMethod === "socialAssistance"}
                  onClick={() => {
                    setSelectedPaymentMethod("socialAssistance");
                  }}
                  className="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio3"
                  value="option3"
                />
                <label className="form-check-label" htmlFor="inlineRadio3">
                  Social assistance
                </label>
              </div>
            </div>
            {selectedPaymentMethod === "insurance" && (
              <div className="row mb-3">
                <div className="col-md-6 pr-md-2 py-2">
                  <label>Insurance provider</label>
                  <select
                    className="form-control"
                    id="exampleFormControlSelect3"
                    value={selectedInsuranceProvider}
                    onChange={(e) => {
                      changeInsuranceProvider(e.target.value);
                    }}
                  >
                    <option value="">Select Insurance</option>
                    {insuranceProviderList &&
                      insuranceProviderList.length > 0 &&
                      insuranceProviderList.map((item: any, index: number) => {
                        return (
                          <option
                            key={index + Math.random() + "Insurance_provider"}
                            value={item.id}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                  </select>
                  {/* {
                                        selectedInsuranceProvider === 'other' &&
                                        <input type="text" className="form-control" placeholder="Insurance name" value={insuranceProviderOther}
                                            onChange={(e) => {
                                                setInsuranceProviderOtherValidation({isValid:true,msg:''});
                                                setinsuranceProviderOther(e.target.value);
                                            }} />
                                    }
                                    {
                                        insurancePoviderOtherValidation.isValid === false && selectedInsuranceProvider === 'other' &&
                                        <div className="is-invalid">{insurancePoviderOtherValidation.msg}</div>
                                    } */}
                </div>
                <div className="col-md-6 pl-md-2 py-2">
                  <label>Insurance Type</label>
                  <select
                    className="form-control"
                    id="exampleFormControlSelect4"
                    value={selectedInsurancePlan}
                    onChange={(e) => {
                      setSelectedInsurancePlan(e.target.value);
                    }}
                  >
                    {insurancePlanList &&
                      insurancePlanList.length > 0 &&
                      insurancePlanList.map((item: any, index: any) => {
                        return (
                          <option
                            key={index + Math.random() + "plan"}
                            value={item.value}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                  </select>
                  {/* {
                                        selectedInsurancePlan === 'other' &&
                                        <input type="text" className="form-control" placeholder="Insurance plan name"
                                            value={insurancePlanNameOther}
                                            onChange={(e) => {
                                                setInsurancePlanNameOtherValidation({ isValid: true, msg: '' });
                                                setInsurancePlanNameOther(e.target.value);
                                            }}
                                        />
                                    }
                                    {
                                        insurancePlanNameOtherValidation.isValid === false && selectedInsurancePlan === 'other' &&
                                        <div className="is-invalid">{insurancePlanNameOtherValidation.msg}</div>
                                    } */}
                </div>
              </div>
            )}
            {selectedPaymentMethod === "cash" && (
              <div className="mb-3">
                {paymentMethod &&
                  paymentMethod.length > 0 &&
                  paymentMethod.map((item: any, index: number) => {
                    return (
                      <div key={index} className="form-check form-check-inline">
                        <input
                          onChange={() => {
                            handlePaymentMethod(item.name);
                          }}
                          checked={item.active}
                          className="form-check-input"
                          type="checkbox"
                          id={"inlineCheckbox" + item.name}
                          value={item.name}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={"inlineCheckbox" + item.name}
                        >
                          {item.name}
                        </label>
                      </div>
                    );
                  })}
              </div>
            )}
            {selectedPaymentMethod === "socialAssistance" && (
              <div className="mb-3">
                <label className="d-block">Social assistance</label>
                <select
                  onChange={(e) => {
                    handleSocialAssistance(e.target.value);
                  }}
                  className="form-control"
                  id="exampleFormControlSelect5"
                  value={selectedSocialAssistance}
                >
                  <option value="">Select Social Assistance</option>
                  {socialAssistanceList &&
                    socialAssistanceList.length > 0 &&
                    socialAssistanceList.map((item: any, index: number) => {
                      return (
                        <option
                          key={index + Math.random() + "soc_Ass"}
                          value={item.id}
                        >
                          {item.title}
                        </option>
                      );
                    })}
                </select>
              </div>
            )}
            <div className="d-flex  justify-content-end align-items-center">
              <button
                onClick={() => {
                  handleSaveButton();
                }}
                className="btn btn-primary btn-lg text-upercase mt-3"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const redirectParent = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + '/dentists/';
export const LOCATION = [
    { name: 'Burlington', link: redirectParent + 'burlington-on' },
    { name: 'Hamilton', link: redirectParent + 'hamilton-on' },
    { name: 'Oakville', link: redirectParent + 'oakville-on' },
    { name: 'Edmonton', link: redirectParent + 'edmonton-ab' },
    { name: 'Etobicoke', link: redirectParent + 'etobicoke-on' },
    { name: 'Brampton', link: redirectParent + 'brampton-on' },
    { name: 'Toronto', link: redirectParent + 'toronto-on' },
    { name: 'Mississauga', link: redirectParent + 'mississauga-on' },
]
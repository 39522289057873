import React, { useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { LoginForm } from "../../components/AppointmentSchedulingXcare/AppointmentSchedulingXcare";
import HeaderWhite from "../HeaderWhite/HeaderWhite";
import LeftImg from "../../assets/images/image.png";
import { isLogin } from "../../../../utils/global";
import { Accordion, Button, Card } from "react-bootstrap";

export const RewardCatalougePage = () => {
    const location = useLocation();
    const history = useHistory();
    useEffect(() => {
        if (isLogin('patient_cookie')) {
            history.replace('/');
        }
    }, []);
    return (
        <>
            <HeaderWhite location={location}></HeaderWhite>
            <section>
                <div className="main-grid-sec" style={{ marginTop: 80 }}>
                    <div className="container">
                        <h2 className="like-h1 text-center fw-bold">Xcare reward catalog</h2>
                        <h5 className="fst-normal my-3 text-center">Our reward catalog is filled with 1,00+ digital gift cards, prepaid cards, and charitable donations. With so many options, there’s something for everyone.</h5>
                        <div>
                            <img src={require('../../assets/images/reward-catalouge-1.png')} width={'100%'}></img>
                            <img src={require('../../assets/images/reward-catalouge-2.png')} width={'100%'}></img>
                            <img src={require('../../assets/images/reward-catalouge-3.png')} width={'100%'}></img>
                            <img src={require('../../assets/images/reward-catalouge-4.png')} width={'100%'}></img>
                            <img src={require('../../assets/images/reward-catalouge-5.png')} width={'100%'}></img>
                            <img src={require('../../assets/images/reward-catalouge-6.png')} width={'100%'}></img>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
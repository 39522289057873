import React from "react";
import Footer from "../Footer/Footer";
import HeaderWhite from "../LandingPageSearchNew/pages/HeaderWhite/HeaderWhite";
import { Helmet } from "react-helmet";
class HowItWorks extends React.Component {
  constructor(props: any) {
    super(props);
  }
  render() {
    return (
      <>
        <Helmet>
          <title>How It Works - XcarePro</title>
          <meta
            name="description"
            content="XcarePro is the risk-free patient acquisition tool for both dental practices and end-users looking for a great healthcare provider. Want to learn more about how XcarePro works? Visit our site today!"
          />
        </Helmet>
        <HeaderWhite></HeaderWhite>
        <section className="banner-section master-banner sub-banner">
          <div className="bg-overlay"></div>
          <div className="container h-100">
            <div className="row h-100">
              <div className="col-12 align-self-center text-center">
                <h1>How It Works</h1>
              </div>
            </div>
          </div>
        </section>
        <section className="how-it-work-sec how-it-work-steps">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <h2 className="like-h1">Current Patients</h2>
              </div>
            </div>
            <div className="row pt-4 justify-content-around flex-row-reverse">
              <div className="col-md-6 col-sm-12 col-12 text-center">
                <img src="/images/splash_screen.jpeg" className="screen-img" />
              </div>
              <div className="col-md-5 col-sm-12 col-12 align-self-center">
                <div className="dashboard_content top-title">
                  <p>
                    Patient downloads the Xcare app and creates their account.
                    The patient will be sent a verification code to confirm and
                    complete their account setup
                  </p>
                </div>
              </div>
            </div>
            <div className="row justify-content-around">
              <div className="col-md-6 col-sm-12 col-12 text-center">
                <img
                  src="/images/trusted-provider.jpg"
                  className="screen-img"
                />
              </div>
              <div className="col-md-5 col-sm-12 col-12 align-self-center">
                <div className="dashboard_content top-title">
                  <p>
                    Patient will be asked to connect to their existing dental
                    provider. If they don’t currently have a healthcare
                    provider, they can use our search functionality to find a
                    dental practice that meets their needs.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="row justify-content-around flex-row-reverse">
              <div className="col-md-6 col-sm-12 col-12 text-center">
                <img src="/images/clinic_details.jpeg" className="screen-img" />
              </div>
              <div className="col-md-5 col-sm-12 col-12 align-self-center">
                <div className="dashboard_content top-title">
                  <p>
                    Once a patient has selected their current provider, they can
                    view the availability of the healthcare providers at the
                    practice and can also book an appointment directly from this
                    profile page.
                  </p>
                </div>
              </div>
            </div>
            <div className="row justify-content-around">
              <div className="col-md-6 col-sm-12 col-12 text-center">
                <img
                  src="/images/book_appointment_type.jpeg"
                  className="screen-img multi-img"
                />
                <img
                  src="/images/prefer_time_slot.jpeg"
                  className="screen-img multi-img"
                />
              </div>
              <div className="col-md-5 col-sm-12 col-12 align-self-center">
                <div className="dashboard_content top-title">
                  <p>
                    The patient will then select the type of appointment they
                    require and the time that they prefer for their appointment.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="row justify-content-around flex-row-reverse">
              <div className="col-md-6 col-sm-12 col-12 text-center">
                <img
                  src="/images/book_appointment_submited.jpeg"
                  className="screen-img"
                />
              </div>
              <div className="col-md-5 col-sm-12 col-12 align-self-center">
                <div className="dashboard_content top-title">
                  <p>
                    Patients will review their booking request and will receive
                    confirmation once they have submitted their dental
                    appointment request.
                  </p>
                </div>
              </div>
            </div>
            <div className="row justify-content-around">
              <div className="col-md-6 col-sm-12 col-12 text-center">
                <img src="/images/chat.jpeg" className="screen-img" />
              </div>
              <div className="col-md-5 col-sm-12 col-12 align-self-center">
                <div className="dashboard_content top-title">
                  <p>
                    Once the patient appointment request is complete, the dental
                    practice and the patient can easily communicate via live
                    chat to confirm any necessary details for the appointment.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="row justify-content-around flex-row-reverse">
              <div className="col-md-6 col-sm-12 col-12 text-center">
                <img
                  src="/images/appointment_upcoming_scheduled_details.jpeg"
                  className="screen-img multi-img"
                />
                <img
                  src="/images/my-appointments.jpg"
                  className="screen-img multi-img"
                />
              </div>
              <div className="col-md-5 col-sm-12 col-12 align-self-center">
                <div className="dashboard_content top-title">
                  <p>
                    The clinic will then approve the request, and the patient
                    will receive a direct notification with all their
                    appointment details. All patient appointments will be stored
                    in the “My Appointments” section of the patient account.{" "}
                  </p>
                </div>
              </div>
            </div>
            {/* <div className="row justify-content-around">
              <div className="col-md-6 col-sm-12 col-12 text-center">
                <img
                  src="/images/payment_add_card.jpeg"
                  className="screen-img"
                />
              </div>
              <div className="col-md-5 col-sm-12 col-12 align-self-center">
                <div className="dashboard_content top-title">
                  <p>
                    24 hrs prior to the appointment the Patient will receive a
                    direct notification to complete pre-screening questions. The
                    answers to the pre-screening quiz will be updated on the
                    Clinic portal.
                  </p>
                </div>
              </div>
            </div> */}
            {/* <div className="row justify-content-around flex-row-reverse">
              <div className="col-md-6 col-sm-12 col-12 text-center">
                <img
                  src="/images/payment_saved_card.jpeg"
                  className="screen-img multi-img"
                />
                <img
                  src="/images/payment_alert.jpeg"
                  className="screen-img multi-img"
                />
              </div>
              <div className="col-md-5 col-sm-12 col-12 align-self-center">
                <div className="dashboard_content top-title">
                  <p>
                    On the day of the appointment, patients can easily check in
                    or let the practice know they may be late for their
                    appointment.
                  </p>
                </div>
              </div>
            </div> */}
            <div className="row justify-content-around">
              <div className="col-md-6 col-sm-12 col-12 text-center">
                <img
                  src="/images/payment_add_card.jpeg"
                  className="screen-img multi-img"
                />
                <img
                  src="/images/payment_success.jpeg"
                  className="screen-img multi-img"
                />
              </div>
              <div className="col-md-5 col-sm-12 col-12 align-self-center">
                <div className="dashboard_content top-title">
                  <p>
                    Once the appointment is complete, clinics can use our
                    contactless payment feature. This feature allows dental
                    practices to request payments via the app. All completed
                    appointments will be stored in “My Appointments” section of
                    the profile.
                  </p>
                </div>
              </div>
            </div>
            <div className="row text-center">
              <div className="col-12 align-self-center">
                <div className="dashboard_content top-title">
                  <p>
                    As patients continue to use the app, they can easily monitor
                    their insurance coverage, the points they have collected or
                    redeemed and give reviews for dental practices.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 col-12">
                <img
                  src="/images/insurance_detail_new.jpg"
                  className="screen-img"
                />
              </div>
              <div
                className="col-md-6 col-sm-12 col-12"
                style={{ paddingRight: "200px" }}
              >
                <img
                  src="/images/accounts_my_points.jpeg"
                  className="screen-img"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="dentist_dashboard">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="bg_design">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/images/dentist_dashboard.svg"
                    }
                  />
                </div>
                <div className="dentist_pt">
                  <h2>Are You A Healthcare provider?</h2>
                  <p className="intro_desc">
                    Increase Your Patient Base With XcarePro
                  </p>
                  <p>
                    There are common pain points that every dental practice
                    faces. XcarePro is the first points and rewards-based
                    systems that helps dental practices quickly and efficiently
                    eliminate their issues by providing:
                  </p>
                  <ul className="big_list">
                    <li>
                      <div>Consistent Patient Acquisition</div>
                    </li>
                    <li>
                      <div>Guaranteed Results </div>
                    </li>
                    <li>
                      <div>Patient Loyalty </div>
                    </li>
                    <li>
                      <div>Online Ratings & Reviews</div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <img
                  src={process.env.PUBLIC_URL + "/images/dashboard-img.png"}
                  className="img-fluid"
                />
                <div className="dashboard">
                  <p>
                    Improve your clinic’s visibility, awareness, patient
                    bookings and retention by listing your practice on XcarePro.
                  </p>
                  <a
                    href="/clinic-registration/#gotodentist-form"
                    className="btn btn-primary btn-lg"
                  >
                    Sign Up For Free
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <Footer></Footer> */}
      </>
    );
  }
}
export default HowItWorks;

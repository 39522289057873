import React, { useState, useEffect } from "react";
import HeaderWhite from "../HeaderWhite/HeaderWhite";
import Filter from "../Filter/Filter";
import Tab from "../ClinicAndProviderList/Tab";
import ProviderList from "../ClinicAndProviderList/ProviderList";
import ClinicList from "../ClinicAndProviderList/ClinicList";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { IAppState } from "../../../../store";
import { clinicAndProviderList } from "../../../../services/landingPageSearch";
import Map from "../../components/Map/Map";
import * as _ from "lodash";
import { useHistory } from "react-router-dom";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import moment from "moment";
function List() {
  const [selectedTab, setSelectedTab] = useState("provider");
  // const [isLoading,setIsLoading]=useState(false);
  const [clinicList, setClinicList] = useState([] as any);
  const [providerList, setProviderList] = useState([] as any);
  const [selectedClinic, setSelectedClinic] = useState("");
  const [selectedLatitude, setSelectedLatitude] = useState("");
  const [selectedLongitude, setSelectedLongitude] = useState("");
  const [iFram, setiFram] = useState(false);
  const history: any = useHistory();
  const [week, setWeek] = useState([]);
  const isLoading = useSelector((state: IAppState) => state.loading.pending);
  useEffect(() => {
    if (new URLSearchParams(history.location.search).get("iframe") === "yes") {
      setiFram(true);
    }
    // from clinic or provider profile
    if (
      history &&
      history.location &&
      history.location.state &&
      history.location.state.iFrame
    ) {
      setiFram(true);
    }

    if (
      history &&
      history.location &&
      history.location.state &&
      history.location.state.tab === "clinic"
    ) {
      setSelectedTab("clinic");
    }
  }, []);

  const handleSelectedTab = (tabName: any) => {
    setSelectedTab(tabName);
  };

  const clinicBookAppointment = () => {
    setSelectedTab("provider");
  };
  return (
    <>
      {!iFram && <HeaderWhite></HeaderWhite>}
      <section
        className={
          iFram ? "inPatientPortal listing-data-sec" : "listing-data-sec"
        }
      >
        {isLoading && (
          // <LoadingIndicator></LoadingIndicator>
          <></>
        )}
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="mt-4 pt-3">
                <Tab
                  changeTab={selectedTab}
                  selectedTab={handleSelectedTab}
                ></Tab>
                <div className="tab-content" id="listingTabContent">
                  {
                    <Filter
                      // clinicList={}
                      // providerList={}
                      // filterApply={handleFilterApply}
                      selectedClinic={selectedClinic}
                      week={week}
                      selectedTab={selectedTab}
                    />
                  }
                  {selectedTab === "provider" && (
                    <ProviderList
                      selectedWeek={(selectedWeek: any) => {
                        if (selectedWeek[0] !== week[0]) {
                          setWeek(selectedWeek);
                        }
                      }}
                    />
                  )}
                  {selectedTab === "clinic" && (
                    <ClinicList bookAppointmentClick={clinicBookAppointment} />
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <Map selectedTab={selectedTab} />
            </div>
          </div>
        </div>
      </section>
      <section className="common-footer-sec">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 text-center">
              <h2 className="h1">
                Thousands of providers.{" "}
                <span className="d-block text-primary">One app.</span>
              </h2>
              <p className="my-4">
                The Xcare app is the quickest, easiest way to book and keep
                track of your appointments.
              </p>
              <div className="mt-5">Scan the QR code to get the app now</div>
              <div className="d-flex justify-content-center">
                <a href="" className="mt-3">
                  <img
                    src={require("../../assets/images/appstore-desk.svg")}
                    alt=""
                  />
                </a>
                <a href="" className="mt-3 ms-5">
                  <img
                    src={require("../../assets/images/android-desk.svg")}
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer className="main-footer my-5">
        <div className="container">
          <div className="row">
            <div className="col-md-3 mb-md-0 mb-4">
              <img
                src={require("../../assets/images/logo-black.svg")}
                alt=""
                className="mb-4"
              />
              <p>
                Save time and effort in finding the right healthcare
                professional for your needs.
              </p>
            </div>
            <div className="col-md-9">
              <div className="row">
                <div className="col-md-3 col-6 mb-md-0 mb-4">
                  <strong>COMPANY</strong>
                  <ul>
                    <li>
                      <a href="">About</a>
                    </li>
                    <li>
                      <a href="">Patient Login</a>
                    </li>
                    <li>
                      <a href="">Promo Code?</a>
                    </li>
                    <li>
                      <a href="">Referrals</a>
                    </li>
                    <li>
                      <a href="">How It Works</a>
                    </li>
                  </ul>
                </div>
                <div className="col-md-3 col-6 mb-md-0 mb-4">
                  <strong>FOR DENTISTS</strong>
                  <ul>
                    <li>
                      <a href="">Practice Login</a>
                    </li>
                    <li>
                      <a href="">Grow Your Practice</a>
                    </li>
                  </ul>
                </div>
                <div className="col-md-3 col-6">
                  <strong>NEED HELP?</strong>
                  <ul>
                    <li>
                      <a href="">Patient</a>
                    </li>
                    <li>
                      <a href="">Practice</a>
                    </li>
                  </ul>
                </div>
                <div className="col-md-3 col-6">
                  <strong>LEGAL</strong>
                  <ul>
                    <li>
                      <a href="">Terms of Use</a>
                    </li>
                    <li>
                      <a href="">Privacy Policy</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-center border-top pt-4 mt-5">
              © {moment().year()} XcarePro. All rights reserved.
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
export default List;

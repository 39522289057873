import React from 'react';
import { connect } from 'react-redux';
import { IAppState } from '../../store';
import { NavLink } from 'react-router-dom';
import { CONSTANT } from '../../constants';
import { addSocialAssistance } from '../../store/formwizard/actions';
import { FormWizardState } from '../../store/formwizard/types';
interface Props {
    formwizardState: FormWizardState;
    addSocialAssistance: typeof addSocialAssistance;
    history: any;
}
class SocialAssistance extends React.Component<Props> {
    constructor(props: any) {
        super(props);
    }
    selectSocialAssistance(socialAssistance: any) {
        this.props.addSocialAssistance({ socialAssistance: socialAssistance });
        this.props.history.push('/quiz/gender');
    }
    render() {
        const selectedSocialAssistance: any = this.props.formwizardState.formwizard.socialAssistance;
        return (
            <div className="col-12 col-md-10 wizard last-visited anxiety">
                <div className="row justify-content-start gutter-5">
                    <div className="col-12">                       
                        <div className="txt-box">
                            <h4 className="title mb-15">Will you use a social assistance program to help pay for your visit?</h4>
                            <div className="sub-title">e.g. ODSP, Ontario Works, Healthy Smiles, IFHP</div>
                        </div>
                    </div>
                    {
                        CONSTANT.QUIZ.socialAssistance.map((assistance) => {
                            return (
                                <div className="col-lg-4 list-box">
                                    <button className={"custom-click btn " + ((selectedSocialAssistance && selectedSocialAssistance !== "" && selectedSocialAssistance.value === assistance.value) ? 'active' : '')} onClick={(e) => { this.selectSocialAssistance(assistance) }} >
                                        <div className="content-container">
                                            <h5 className="main-text">{assistance.value}</h5>
                                            <div className="sub-text">{assistance.label}</div>
                                        </div>
                                    </button>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state: IAppState) => ({
    formwizardState: state.formwizard
});
const mapDispatchToProps = (dispatch: any) => ({
    addSocialAssistance: (socialAssistance: any) => {
        dispatch(addSocialAssistance(socialAssistance));
    }
});
export default connect(mapStateToProps, mapDispatchToProps)(SocialAssistance);